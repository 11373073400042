.text-block {
  padding: 144px 0;
  display: flex;
  align-items: center;

  @media #{$desktop} {
    padding: 120px 0;
  }

  @media #{$phone} {
    padding: 40px 0;
  }
}

.text-block__title {
  letter-spacing: -0.3px;
  @include font-size(48);
  line-height: 1;
  font-family: $primary-family-bold;
  text-align: center;
  max-width: 680px;
  margin: 0 auto;

  @media #{$tablet} {
    @include font-size(32);
    max-width: 361px;
  }
  @media #{$phone} {
    @include font-size(24);
    max-width: 250px;
  }
}
