.order-alt {
  padding: 0 40px 40px;

  @media #{$large-screen} {
    padding: 0 20px 40px;
  }

  @media #{$tablet} {
    padding: 0;
  }

  .container {
    max-width: 1140px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    z-index: 1;
  }

  .btn--order {
    box-shadow: 0 15px 44px rgba(20, 197, 183, 0.3);
  }
}

.order-alt__inner {
  padding-top: 150px;
  padding-bottom: 100px;
  margin: 0 auto;
  border-radius: 15px;
  color: $black;
  background-color: #f7f8fa;
  position: relative;

  @media #{$large-screen} {
    padding-top: 95px;
  }

  @media #{$tablet} {
    padding-top: 80px;
  }

  @media #{$phone} {
    padding-top: 60px;
    padding-bottom: 130px;

    .order-alt--style2 & {
      padding-bottom: 100px;
    }
  }

  &:after {
    content: "";
    height: 70px;
    width: 100%;
    position: absolute;
    bottom: 110px;
    left: 0;
    background-color: #f7f8fa;
    border-radius: 15px;

    @media #{$tablet} {
      display: none;
    }
  }

  .container {
    max-width: 1320px;

    .order-alt--style2 & {
      max-width: 1210px;
    }
  }
}

.order-alt__content {
  width: calc(100% - 416px);
  padding-left: 100px;
  padding-right: 100px;

  @media #{$tablet} {
    padding-left: 0;
    padding-right: 40px;
    width: calc(100% - 316px);
  }

  @media #{$phone} {
    width: 100%;
    padding-right: 0;
  }
}



.order-alt__card {
  max-width: 416px;
  width: 100%;

  @media #{$tablet} {
    max-width: 316px;
  }

  @media #{$phone} {
    max-width: 376px;
    margin: 0 auto;
  }
}

.order-alt__title {
  max-width: 470px;
  font-style: normal;
  font-weight: normal;
  font-size: clamp(1.75rem, 4vw, 4.25rem);
  line-height: 1.3235;
  letter-spacing: -0.3px;
  color: $black;

  .order-alt--style2 & {
    font-size: clamp(1.75rem, 4vw, 3rem);
    margin-bottom: 45px;

    @media #{$large-screen} {
      margin-bottom: 30px;
    }

    @media #{$phone} {
      margin: 0 auto 20px;
    }
  }

  @media #{$large-screen} {
    max-width: 470px;
    margin-bottom: 30px;
  }

  @media #{$tablet} {
    max-width: 380px;
  }

  @media #{$phone} {
    margin: 0 auto 20px;
  }

  img {
    display: inline-block;
    max-width: 48px;
    margin-top: -10px;

    @media #{$phone} {
      max-width: 38px;
      margin-top: -4px;
    }

    @media #{$phone-portrait} {
      max-width: 28px;
      margin-top: -4px;
    }
  }
}

.order-alt__description {
  max-width: 404px;
  @include font-size(32);
  color: rgba($black, 0.8);
  margin-bottom: 30px;
  line-height: 1.4;

  .order-alt--style2 & {
    @include font-size(20);
    margin-bottom: 120px;

    @media #{$large-screen} {
      margin-bottom: 80px;
    }

    @media #{$tablet} {
      margin-bottom: 60px;
    }

    @media #{$phone} {
      margin: 0 auto 40px;
      @include font-size(16);
      letter-spacing: -0.23px;
    }
  }

  @media #{$large-screen} {
    @include font-size(20);
  }

  @media #{$tablet} {
    max-width: 380px;
  }

  @media #{$phone} {
    @include font-size(16);
    margin: 0 auto 40px;
  }

}

.order-alt__image {
  position: relative;
  width: 100%;
  max-width: 400px;


  @media #{$phone} {
    max-width: 380px;
    margin: 0 auto 30px;
  }

  @media #{$phone-portrait} {
    max-width: 280px;
  }
}

.order-alt__image--bottom {
  bottom: -55px;

  @media #{$large-screen} {
    bottom: -70px;
  }

  @media #{$phone} {
    bottom: -10px;
  }
}

.order-alt__card-primary,
.order-alt__card-secondary {
  border-radius: 15px;
  background-color: $white;
  margin-bottom: 5px;
  position: relative;
}

.order-alt__card-primary {
  padding: 44px 58px 45px;

  @media #{$tablet} {
    padding: 40px 28px;
  }

  .icon-plus {
    width: 50px;
    height: 50px;
    color: $primary-color;
    position: absolute;
    bottom: -27px;
    left: 60px;
    z-index: 2;

    @media #{$phone} {
      left: 23px;
    }
  }
}

.order-alt__card-secondary {
  padding-top: 20px;
  margin-bottom: 15px;
  box-shadow: 0 32px 44px rgba(0, 0, 0, 0.05);
}

.order-alt__secondary-inner {
  padding: 44px 58px 20px;

  @media #{$tablet} {
    padding: 40px 28px;
  }
}

.order-alt__card-title {
  @include font-size(20);
  font-family: $primary-family;
  margin-bottom: 30px;
  letter-spacing: -.26px;

  @media #{$phone} {
    @include font-size(16);
  }

  strong {
    display: block;
    @include font-size(24);
    font-family: $primary-family-medium;
    margin-bottom: 4px;

    @media #{$phone} {
      @include font-size(20);
    }
  }
}

.order-alt__card-price {
  font-family: $primary-family;
  @include font-size(20);
  line-height: 1;
  margin-bottom: 25px;

  @media #{$phone} {
    @include font-size(16);
  }

  .order-alt__price-amount {
    @include font-size(50);
    font-family: $primary-family-medium;
    display: block;
    margin-bottom: 5px;
    letter-spacing: -.34px;

    @media #{$phone} {
      @include font-size(45);
    }

    .currency-symbol {
      @include font-size(26);
    }
  }
}

.order-alt__note {
  font-family: $primary-family-medium;
  @include font-size(18);
  line-height: 1.2;
  padding: 29px 58px;
  background-color: rgba($primary-color, 0.05);
  color: $black;
  letter-spacing: -.2px;
  ;

  @media #{$tablet} {
    padding: 25px 28px;
  }

  strong {
    display: block;
    font-family: $primary-family-semibold;
    @include font-size(21);
    color: $primary-color;

    img {
      max-width: 20px;
      margin-top: -8px;
    }
  }
}
