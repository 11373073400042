.update-cfa {
  padding: 76px 0 120px;
  text-align: center;

  @media #{$phone} {
    padding: 65px 0;
  }

  .container {
    max-width: 680px;
  }
}

.update-cfa--alt {
  padding-top: 100px;

  @media #{$desktop} {
    padding: 60px 0 80px;
  }

  @media #{$phone} {
    padding-top: 0;
  }

}

.update-cfa__title {
  font-size: clamp(1.75rem, 5vw, 3rem);
  letter-spacing: -0.5px;
  line-height: calc(58/48);
  max-width: 516px;
  margin: 0 auto 24px;
}

.update-cfa__subtitle {
  font-family: $primary-family-semibold;
  @include font-size(38);
  line-height: 1.3;
  letter-spacing: -0.5px;
  font-weight: normal;

  @media #{$large-screen} {
    @include font-size(28);
  }

  @media #{$phone} {
    @include font-size(20);
  }

  br {
    @media screen and (min-width:769px) {
      display: none;
    }
  }
}

.update-cfa__description {
  @include font-size(20);
  line-height: calc(34/20);
  margin-bottom: 30px;

  .update-cfa--alt & {
    margin-bottom: 80px;

    @media #{$tablet} {
      margin-bottom: 40px;
    }
  }

  @media #{$phone} {
    @include font-size(16);
  }

  br {
    @media screen and (min-width:640px) {
      display: none;
    }
  }

  a {
    color: $primary-color;
    transition: all 300ms ease;
    font-family: $primary-family-semibold;

    &:hover,
    &:focus {
      color: $brand-color--secondary;
    }
  }
}

.update-cfa__link {
  @include font-size(18);
  line-height: calc(34/18);
  text-decoration: underline;
  color: $primary-color;
  transition: all 300ms ease;

  .update-cfa--alt & {
    margin-top: 10px;
    display: inline-block;
    vertical-align: top;

    @media #{$phone} {
      margin-bottom: 0;
    }
  }

  &:hover,
  &:focus {
    color: $brand-color--secondary;

  }

  svg {
    vertical-align: middle;
    margin-left: 7px;
    display: inline-block;
  }

  svg,
  path {
    stroke: currentColor !important;

  }
}

.update-cfa__sub-description {
  max-width: 460px;
  margin: 0 auto;
}
