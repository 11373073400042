.hero-halloween {
  padding: 0 20px;
  // margin-bottom: -315px;

  // @media #{$desktop} {
  //   margin-bottom: -150px;
  // }

  @media #{$tablet} {
    margin-bottom: 0;
    padding: 0;
  }

  .container {
    padding: 0;
    position: relative;
    z-index: 1;
  }
  .slick-dots {
    right: 45px;
    bottom: 20px;
    position: absolute;
    z-index: 1;

    @media #{$tablet} {
      position: static;
      display: flex;
      justify-content: center;
      margin-top: 35px;
    }

    li {
      &.slick-active {
        button {
          border-color: $orange !important;
          &::before {
            animation: move-left-to-right 3.7s linear forwards;
            background-color: $orange !important;
          }
        }
      }
    }
  }
  .price-table {
    .container {
      padding: 0 20px;
    }
  }
}

.hero-halloween__card {
  background-color: $light-gray;
  padding: 15px 0 60px;
  border-radius: 15px;

  @media #{$tablet} {
    border-radius: 0;
  }

  @media #{$phone} {
    padding: 35px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $white;
  }

  .awards--four {
    @media #{$tablet} {
      padding: 35px 0 50px;
    }
    @media #{$phone} {
      padding: 0 0 30px;
    }
  }
}
.hero-halloween__slider {
  width: 100%;
}

.hero-halloween__title {
  text-align: inherit;
  line-height: 1.125;
  letter-spacing: -0.3px;
  margin-bottom: 20px;
  @include font-size(45);
  text-align: center;

  @media #{$tablet} {
    @include font-size(36);
  }

  @media #{$phone} {
    letter-spacing: -0.03em;
  }

  img {
    display: none;

    @media #{$phone} {
      display: inline-block;
    }
  }

  br {
    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  strong {
    position: relative;
    @include font-size(340);
    display: block;
    letter-spacing: -0.03em;
    line-height: 1;
    color: var(--color);
    font-family: $primary-family-black;

    @media #{$small-screen} {
      @include font-size(280);
    }

    @media #{$desktop} {
      @include font-size(240);
    }

    @media #{$tablet} {
      line-height: 1;
      left: 0;
    }

    @media #{$phone} {
      @include font-size(200);
      letter-spacing: -0.03em;
    }

    @media #{$phone-portrait} {
      @include font-size(160);
    }

    @media screen and (max-width: 350px) {
      @include font-size(140);
    }
  }
}
.hero-halloween__item {
  @media #{$phone} {
    overflow: hidden;
  }
}
.hero-halloween__media {
  display: flex;
  justify-content: center;
  max-width: 1631px;
  width: 100%;
  margin: -317px auto -20px;

  @media #{$small-screen} {
    margin-top: -218px;
  }

  @media #{$phone} {
    margin-top: -135px;
  }

  .hero-halloween__item--0 & {
    right: -6px;
    @media #{$small-screen} {
      right: 0;
    }
  }

  .hero-halloween__item--1 & {
    right: -69px;
    @media #{$small-screen} {
      right: -30px;
    }
    @media #{$desktop} {
      right: 0;
    }

    @media #{$phone} {
      right: 12px;
      margin-top: -120px;
    }
  }

  .hero-halloween__item--2 & {
    right: -90px;
    margin: -285px auto -20px;

    @media #{$small-screen} {
      margin-top: -208px;
      right: -50px;
    }

    @media #{$desktop} {
      right: 0;
    }

    @media #{$phone} {
      margin-top: -120px;
      right: -9px;
    }
  }

  img {
    min-width: 1631px;

    @media #{$small-screen} {
      max-width: 1025px;
      min-width: auto;
    }
    @media #{$phone} {
      max-width: 657px;
      min-width: 607px;
    }
  }
}

.hero-halloween__content {
  max-width: 1020px;
  width: 100%;
  margin: 0 auto;
  text-align: center;

  @media #{$tablet} {
    padding: 0 20px;
  }

  @media #{$phone} {
    max-width: 351px;
  }

  p {
    @include font-size(26);
    line-height: calc(34 / 26);
    max-width: 545px;
    margin: 0 auto;
    opacity: 0.8;

    @media #{$tablet} {
      @include font-size(20);
    }

    @media #{$phone} {
      @include font-size(15);
      max-width: 327px;
    }
  }
}

.hero-halloween__content-text {
  .hero-halloween__item--0 & {
    max-width: 590px;

    @media #{$phone} {
      max-width: 326px;
    }
  }
  .hero-halloween__item--1 & {
    max-width: 765px;
    @media #{$desktop} {
      max-width: 525px;
    }
    @media #{$phone} {
      max-width: 326px;
    }
  }

  .hero-halloween__item--2 & {
    max-width: 692px;
    @media #{$desktop} {
      max-width: 525px;
    }
    @media #{$phone} {
      max-width: 326px;
    }
  }
}
.hero-halloween__content-text--desktop {
  @media #{$phone} {
    display: none;
  }
}
.hero-halloween__content-text--mobile {
  display: none;
  @media #{$phone} {
    display: block;
  }
}
.hero-halloween__subtitle {
  @include font-size(48);
  letter-spacing: -0.3px;
  line-height: 58/48;

  @media #{$desktop} {
    margin-bottom: 10px;
  }
  @media #{$tablet} {
    @include font-size(28);
  }

  @media #{$phone} {
    letter-spacing: -0.17px;
    @include font-size(26);
    line-height: 1.2;
  }

  br {
    @media #{$phone} {
      display: inherit !important;
    }
  }

  .hero-halloween__item--0 & {
    @media #{$phone} {
      max-width: 316px;
    }
  }
  .hero-halloween__item--1 & {
    @media #{$phone} {
      max-width: 295px;
    }
  }

  .hero-halloween__item--2 & {
    @media #{$phone} {
      max-width: 280px;
    }
  }

  strong {
    background-image: url("data:image/svg+xml,%3Csvg width='217' height='10' viewBox='0 0 217 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M213.857 6.99609C202.601 5.097 190.161 4.28565 178.618 4.24872C169.872 4.22075 161.156 3.47423 152.44 3.48657C136.264 3.50948 120.163 3.93189 103.992 3.95479C90.9974 3.97319 78.0596 4.79073 65.0401 4.80917C53.2396 4.82588 41.4148 5.02561 29.6477 4.28207C20.7537 3.72008 11.8771 4.12702 3.00093 3.29858' stroke='%23FB873B' stroke-width='6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center bottom;
    padding-bottom: 10px;
    display: inline-block;
    background-size: contain;

    @media #{$desktop} {
      padding-bottom: 5px;
    }

    @media #{$phone} {
      padding-bottom: 3px;
      background-image: url("data:image/svg+xml,%3Csvg width='105' height='5' viewBox='0 0 105 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M102.638 2.98064C97.2519 2.42098 91.2989 2.18384 85.7746 2.17588C81.5891 2.16985 77.418 1.95096 73.2468 1.95687C65.5057 1.96783 57.7998 2.09712 50.0608 2.10808C43.8421 2.11689 37.6502 2.36241 31.4195 2.37123C25.7721 2.37923 20.1131 2.44145 14.4819 2.22423C10.2256 2.06004 5.97748 2.18288 1.7298 1.93977' stroke='%23FB873B' stroke-width='3.34726' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
  }
}
