.podcast {
  position: relative;
  z-index: 1;
  padding: 0 40px 80px;

  @media #{$tablet} {
    padding: 0 0 60px;
  }

  @media #{$phone} {
    padding: 0 12px;
  }
}

.podcast__card {
  position: relative;
  z-index: 1;


  &:after {
    content: '';
    z-index: -1;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: -256px;
    background: linear-gradient(180deg, rgba(247, 248, 250, 0) 0%, #F7F8FA 51.85%);
    border-radius: 15px;

    @media #{$phone} {
      top: 0;
    }
  }
}

.podcast__grid {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 166px 148px 88px;
  max-width: 1388px;
  align-items: center;

  @media #{$desktop} {
    padding: 120px 90px 88px;
  }

  @media #{$small-screen} {
    padding: 80px 40px 68px;
  }

  @media #{$phone} {
    display: flex;
    flex-direction: column-reverse;
    padding: 50px 35px;
  }
}

.podcast__media,
.podcast__content {
  width: 100%;

  @media #{$desktop} {
    width: calc(50% - 30px);
  }

  @media #{$phone} {
    width: 100%;
  }
}

.podcast__media {
  max-width: 479px;

  @media #{$phone} {
    max-width: 100%;
    display: flex;
    justify-content: center;
  }

  img {
    filter: drop-shadow(0px 4px 19px rgba(0, 0, 0, 0.25));

    @media screen and (max-width: 540px) {
      max-width: 256px;
    }
  }
}

.podcast__content {
  max-width: 449px;
  text-align: center;

  @media #{$phone} {
    max-width: 100%;
    margin-bottom: 40px;
  }
}

.podcast__title {
  font-size: clamp(1.75rem, 5vw, 3rem);
  letter-spacing: -0.5px;
  line-height: calc(58/48);
  margin-bottom: 24px;
}

.podcast__description {
  @include font-size(20);
  line-height: calc(34/20);
  color: rgba($black, .8);
  margin-bottom: 24px;

  @media #{$phone} {
    @include font-size(16);
  }
}

.podcast__highlight-description {
  font-family: $primary-family-semibold;
  @include font-size(20);
  line-height: calc(34/20);

  a {
    color: $primary-color;
    text-decoration: underline;
    transition: all 300ms ease;

    &:hover,
    &:focus {
      color: $brand-color--secondary;
    }
  }
}
