.main--small-padding {
  padding-top: 60px;
}

.main {
  .yotpo-widget-referral-widget .yotpo-background.yotpo-left-align-background {
    min-height: 860px;
  }

  .yotpo-widget-referral-widget .yotpo-tile-wrapper {
    margin: 80px 0;

    @media screen and (max-width:620px) {
      margin: 0 auto;
    }
  }

  .yotpo-title-text,
  .yotpo-button-style,
  .yotpo-button-style span {
    font-weight: normal !important;
  }
}
