.mila-order {
  position: relative;
  z-index: 1;

  .container {
    position: static;
    padding: 0;
  }
}

.mila-order__wrapper {
  background-size: cover;
  background-position: center 85%;
  background-repeat: no-repeat;
  padding: 154px 16px 58px;
  aspect-ratio: 1440/1000;
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;

  @media #{$large-screen} {
    aspect-ratio: 1440/1394;
    background-position: center bottom;
  }

  @media #{$small-screen} {
    aspect-ratio: 1440/1494;
    padding-bottom: 20px;
  }

  @media #{$desktop} {
    aspect-ratio: 1440/1394;
    padding-top: 80px;
    background-size: cover;
  }

  @media screen and (max-width: 1280px) {
    background-size: auto 1156px;
  }

  @media #{$tablet} {
    padding: 90px 16px 20px;
    background-size: auto 1224px;
    min-height: 980px;
    aspect-ratio: unset;
  }

  @media #{$phone} {
    background-size: cover;
    min-height: 715px;
    padding: 60px 16px 70px;
    background-position: center;
  }
}

.mila-order__header {
  text-align: center;
  margin: 0 auto;
  max-width: 678px;

  @media #{$tablet} {
    max-width: 354px;
  }
}

.mila-order__title {
  @include font-size(68);
  letter-spacing: -0.8px;
  line-height: 1;
  position: relative;
  z-index: 2;
  margin: 0 0 24px;

  @media #{$desktop} {
    @include font-size(48);
    letter-spacing: -0.3px;
  }

  @media #{$tablet} {
    @include font-size(36);
    letter-spacing: -0.3px;
    margin-bottom: 18px;
  }

  @media #{$phone} {
    @include font-size(32);
    margin-bottom: 16px;
  }
}

.mila-order__short-description {
  @include font-size(28);
  letter-spacing: -0.3px;
  line-height: 1.5;
  margin: 0 auto;
  font-family: $primary-family-medium;
  max-width: 540px;

  @media #{$desktop} {
    @include font-size(22);
    max-width: 354px;
  }

  @media #{$phone} {
    @include font-size(20);
    letter-spacing: -0.4px;
    max-width: 326px;
    font-family: $primary-family-medium;
    line-height: 1.3;
  }
}

.mila-order__overflow {
  margin-top: auto;

  @media #{$phone} {
    overflow: hidden;
    text-align: center;
    margin-top: 305px;
  }
}

.mila-order__overflow-container {
  @media #{$phone} {
    display: inline-block;
    max-width: 100%;
  }
}

.mila-order__grid {
  max-width: 1038px;
  width: 100%;
  margin: 0 auto -60px;
  display: flex;
  gap: 14px;
  padding: 0 0 60px;
  overflow-x: auto;
  overflow-y: hidden;
  grid-template-columns: repeat(2, 1fr);

  @media #{$phone} {
    gap: 6px;
  }
}

.mila-order__card {
  padding: 30px 40px;
  background-color: $white;
  border-radius: 16px;
  width: 100%;
  z-index: 2;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;

  @media #{$desktop} {
    padding: 24px;
  }

  @media #{$phone} {
    padding: 16px 10px 10px;
    position: static;
    max-width: 176px;
    border-radius: 14px;
  }
}

.mila-order__card-content {
  margin-bottom: 17px;
}

.mila-order__card-footer {
  margin-top: auto;
}

.mila-order__card-title {
  @include font-size(36);
  line-height: 1;
  letter-spacing: -0.3px;
  font-family: $primary-family-bold;
  margin-bottom: 6px;

  @media #{$desktop} {
    @include font-size(24);
  }

  @media #{$phone} {
    @include font-size(16);
    margin-bottom: 4px;
  }
}

.mila-order__card-subtitle {
  @include font-size(16);
  line-height: calc(22/18);
  letter-spacing: -0.3px;
  font-family: $primary-family-semibold;
  font-weight: normal;
  margin-bottom: 24px;

  @media #{$phone} {
    @include font-size(12);
    margin-bottom: 12px;
  }
}

.mila-order__card-description {
  @include font-size(16);
  line-height: 1.3;
  margin-bottom: 24px;
  letter-spacing: -0.3px;

  @media #{$desktop} {
    margin-bottom: 16px;
    @include font-size(14);
  }

  @media #{$phone} {
    @include font-size(12);
    margin-bottom: 12px;
  }

  br {
    @media #{$phone} {
      display: none;
    }
  }
}

.mila-order__card-price {
  @include font-size(18);
  line-height: calc(22/18);
  font-family: $primary-family-semibold;
  letter-spacing: -0.3px;
  margin-bottom: 20px;

  @media #{$phone} {
    @include font-size(12);
    margin-bottom: 16px;
  }

  del {
    color: #90989B;

    @media #{$phone} {
      font-family: $primary-family;
    }
  }


  strong {
    font-family: inherit;
    font-weight: normal;

    @media #{$phone} {
      display: block;
      @include font-size(18);
      font-family: $primary-family-semibold;
    }

  }
}

.mila-order__card-button {
  padding: 20px 32px;
  background-color: $black;
  color: $white;
  border-radius: 100px;
  display: inline-block;
  @include font-size(18);
  line-height: 1;
  letter-spacing: -0.3px;
  text-decoration: none;
  font-family: $primary-family-semibold;
  text-transform: capitalize;
  min-width: 217px;
  text-align: center;
  width: 100%;

  @media #{$phone} {
    @include font-size(14);
    min-width: auto;
    padding: 10px 16px;
  }
}
