.leader-block {
  background: linear-gradient(180deg, rgba(247, 248, 250, 0) 0%, rgba(247, 248, 250, 0.7) 51.04%, #F7F8FA 100%);
  padding-bottom: 200px;
  padding-top: 200px;
  overflow: hidden;

  @media #{$large-screen} {
    padding-bottom: 140px;
    padding-top: 140px;
  }

  @media #{$tablet} {
    padding-bottom: 100px;
    padding-top: 100px;
  }

  @media #{$phone} {
    padding-bottom: 50px;
    padding-top: 50px;
  }

  .container {
    max-width: 100%;
    padding: 0;

    @media #{$desktop} {
      padding: 0 20px;
    }
  }
}

.leader-block__header {
  max-width: 1070px;
  margin: 0 auto 123px;
  text-align: center;

  @media #{$tablet} {
    margin-bottom: 100px;
  }

  @media #{$phone} {
    margin-bottom: 50px;
  }
}

.leader-block__carousel {
  position: relative;
  padding-left: 15vw;
  overflow: hidden;

  @media #{$desktop} {
    padding-left: 0;
  }

  .slick-list {
    margin: 0 15vw 0 0;
    overflow: visible !important;

    @media #{$desktop} {
      margin: 0;
    }
  }
}

.leader-block__slide-arrow {
  max-width: 1000px;
  margin: 0 auto 50px;
  position: relative;
  z-index: 2;

  @media #{$phone} {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
  }

  .slick-arrow {
    width: 62px;
    height: 62px;
    color: $black;
    z-index: 1;
    transition: 0.3s ease;
    margin-right: 10px;

    @media #{$tablet} {
      width: 45px;
      height: 45px;
    }

    @media #{$phone} {
      width: 30px;
      height: 30px;
      position: absolute;
      top: -190px;
    }

    svg {
      width: 62px;
      height: 62px;

      @media #{$tablet} {
        width: 45px;
        height: 45px;
      }

      @media #{$phone} {
        width: 30px;
        height: 30px;
      }
    }

    &.slick-disabled {
      color: $black;
      opacity: 0.08;

      @media #{$phone} {
        opacity: 0.5;
      }
    }

    &.leader-block__slide-next {
      @media #{$phone} {
        right: 0;
      }
    }
  }
}

.leader-block__header-title {
  margin-bottom: 40px;

  @media #{$phone} {
    margin-bottom: 20px;
    @include font-size(28);
  }
}

.leader-block__header-subtitle {
  font-family: $primary-family-medium;
  @include font-size(38);
  padding: 0 120px;
  line-height: 1.3;
  margin-bottom: 12px;

  @media #{$large-screen} {
    @include font-size(28);
    max-width: 820px;
    margin: 0 auto 12px;
  }

  @media #{$phone} {
    @include font-size(20);
    padding: 0;
  }
}

.leader-block__header-description {
  @include font-size(34);
  font-family: $alt-family;

  @media #{$phone} {
    @include font-size(20);
  }
}

.leader-block__slide--bed-room {
  padding-top: 142px;

  @media #{$phone} {
    padding-top: 0;
  }

  .leader-block__slide-image img {
    left: 20px;
    position: relative;

    @media #{$phone} {
      max-width: 560px;
    }

    @media #{$phone-portrait} {
      position: relative;
      max-width: 560px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  .leader-block__slide-title {
    margin-top: -500px;
    transition: all 300ms ease;

    @media #{$tablet} {
      margin-top: -330px;
    }

    @media #{$phone} {
      margin-top: -30px;
    }
  }
}

.leader-block__slide-header {
  padding-bottom: 200px;

  @media #{$tablet} {
    padding-bottom: 180px;
  }

  @media #{$phone} {
    padding-bottom: 0;
  }
}

.leader-block__slide-title {
  @include font-size(160);
  color: #E8E8E8;
  margin-top: -438px;
  text-align: left;

  .slick-current & {
    text-align: center;
    color: $primary-color;
  }

  @media screen and (max-width: 1500px) {
    @include font-size(130);
  }

  @media #{$desktop} {
    text-align: center;
    @include font-size(130);
  }

  @media #{$tablet} {
    @include font-size(100);
    margin-top: -280px;
  }

  @media #{$phone} {
    @include font-size(50);
    margin-top: -30px;
  }

  @media #{$phone-portrait} {
    @include font-size(40);
  }
}

.leader-block__slide-content {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.leader-block__slide-image {
  min-height: 460px;
  z-index: 1;
  position: relative;
  opacity: 0;
  transition: all 0.3s ease;

  .slick-current & {
    opacity: 1;
  }

  @media #{$tablet} {
    min-height: auto;
  }

  @media #{$phone} {
    display: flex;
    justify-content: center;
  }

  @media #{$phone-portrait} {
    display: block;
  }

  img {
    max-width: 1270px;
    margin: 0 auto;

    @media #{$tablet} {
      max-width: 700px;
    }

    @media #{$phone} {
      max-width: 560px;
    }

    @media #{$phone-portrait} {
      position: relative;
      max-width: 560px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}

.leader-block__line-chart {
  width: 50%;
  overflow: hidden;

  @media #{$desktop} {
    width: 52%;
  }

  @media #{$tablet} {
    width: 100%;
  }
}

.leader-block__media {
  max-width: 500px;
  width: 100%;
  height: auto;
  margin-top: -40px;

  @media #{$tablet} {
    margin: -40px auto 0;
    width: 100%;
  }
}

.leader-block__slide-description {
  width: 41%;
  color: rgba($black, 0.8);
  @include font-size(24);
  line-height: 1.7;

  @media #{$large-screen} {
    @include font-size(18);
  }

  @media #{$tablet} {
    width: 100%;
    margin-top: 30px;
  }

  @media #{$phone} {
    margin: 30px auto 0;
    @include font-size(16);
  }

  @media #{$phone-portrait} {
    margin: 10px auto 0;
  }

  p {
    margin-bottom: 40px;

    @media #{$phone} {
      margin-bottom: 20px;
    }
  }
}
