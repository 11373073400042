.hero--inner {
  padding-top: 150px;

  @media #{$phone} {
    margin-bottom: 0;
  }

  .container {
    position: relative;
    z-index: 1;
  }

  .hero__image {
    max-width: 880px;
    margin: 135px auto 0;

    @media #{$phone} {
      margin-top: 55px;
    }
  }

  .hero__title {
    @include font-size(80);
    max-width: 910px;
    line-height: 1.1;
    margin-bottom: 30px;

    @media #{$tablet} {
      @include font-size(60);
    }

    @media #{$phone} {
      @include font-size(38);
    }

    img {
      max-width: 80px;
      margin-top: -20px;

      @media #{$tablet} {
        max-width: 60px;
      }

      @media #{$phone} {
        max-width: 38px;
        margin-top: -10px;
      }
    }
  }

  .hero__description {
    @include font-size(32);
    max-width: 890px;
    margin: 0 auto;
    text-align: center;
    color: rgba($black, 0.8);

    @media #{$tablet} {
      @include font-size(24);
    }

    @media #{$phone} {
      @include font-size(20);
    }
  }
}

.hero--wave-bg {
  position: relative;
  padding-bottom: 160px;
  background: -moz-linear-gradient(-45deg, rgba(196, 196, 196, 0.16) 6.89%, rgba(191, 182, 182, 0) 110.33%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, rgba(196, 196, 196, 0.16) 6.89%, rgba(191, 182, 182, 0) 110.33%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, rgba(196, 196, 196, 0.16) 6.89%, rgba(191, 182, 182, 0) 110.33%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#29c4c4c4', endColorstr='#00bfb6b6', GradientType=1);

  /* IE6-9 fallback on horizontal gradient */
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 630px;
    position: absolute;
    left: 0;
    bottom: -120px;
    background: url("assets/images/lines.svg") no-repeat center center $white;

    @media #{$phone} {
      height: 350px;
      bottom: 0;
    }
  }

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 360px;
    position: absolute;
    left: 0;
    bottom: 450px;
    background: url("assets/images/wave-bg.png") no-repeat 0 0;
    background-size: cover;
    z-index: 0;

    @media #{$phone} {
      display: none;
    }
  }

  @media #{$tablet} {
    padding-top: 80px;
  }

  @media #{$phone} {
    padding-top: 60px;
  }
}

.column-content {
  padding-bottom: 200px;
  position: relative;
  overflow: hidden;

  @media #{$tablet} {
    padding-bottom: 100px;
  }

  @media #{$phone} {
    padding-top: 40px;
    padding-bottom: 60px;
  }

  &:after {
    content: "";
    display: block;
    height: 3px;
    background-color: #EBEBEB;
    width: 1516px;
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }

  .container {
    max-width: 1170px;
  }
}

.column-content__header {
  text-align: center;
  max-width: 1130px;
  margin: 0 auto 184px;

  @media #{$large-screen} {
    margin-bottom: 100px;
    max-width: 820px;
  }

  @media #{$tablet} {
    margin-bottom: 80px;
  }

  @media #{$phone} {
    margin-bottom: 40px;
  }
}

.column-content__header-title {
  @media #{$phone} {
    @include font-size(28);
  }
}

.column-content__title {
  font-family: $primary-family-medium;
  @include font-size(38);
  line-height: 1.3;

  @media #{$large-screen} {
    @include font-size(28);
  }

  @media #{$phone} {
    @include font-size(22);
    margin-bottom: 10px;
  }
}

.column-content__body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.column-content__block {
  width: 46%;
  @include font-size(22);

  @media #{$large-screen} {
    @include font-size(18);
  }

  @media #{$tablet} {
    width: 100%;
  }

  @media #{$phone} {
    @include font-size(16);
  }
}

.column-content__image {
  width: 50%;
  padding-top: 50px;
  position: relative;

  @media #{$tablet} {
    width: 100%;
    padding-top: 0;
  }

  @media #{$phone} {
    max-width: 400px;
    margin: 0 auto 25px;
  }

  img {
    position: absolute;
    max-width: 1040px;

    @media #{$large-screen} {
      max-width: 100%;
      position: static;
    }
  }
}

.column-content__tip {
  margin-bottom: 58px;
  line-height: 1;
  @include font-size(34);
  font-family: $alt-family;
  margin-top: 30px;

  @media #{$large-screen} {
    margin-bottom: 25px;
  }

  @media #{$tablet} {
    @include font-size(28);
  }

  @media #{$phone} {
    @include font-size(20);
    margin-top: 0;
  }
}

.column-content__description {
  color: rgba($black, 0.8);
  line-height: 1.8;
  padding-right: 57px;

  @media #{$tablet} {
    padding-right: 0;
  }
}

.fullwidth-content {
  padding-top: 173px;
  padding-bottom: 70px;
  position: relative;
  overflow: hidden;

  @media #{$large-screen} {
    padding-top: 120px;
  }

  @media #{$tablet} {
    padding-top: 90px;
  }

  @media #{$phone} {
    padding-top: 60px;
    padding-bottom: 50px;
  }

  &:after {
    content: "";
    display: block;
    height: 3px;
    background-color: #EBEBEB;
    width: 1516px;
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }

  .container {
    max-width: 1110px;
    text-align: center;
  }
}

.fullwidth-content__header {
  margin-bottom: 80px;

  @media #{$large-screen} {
    margin-bottom: 50px;
  }

  @media #{$phone} {
    margin-bottom: 30px;
  }
}

.fullwidth-content__header-title {
  max-width: 870px;
  margin: 0 auto 20px;

  @media #{$phone} {
    @include font-size(28);
  }
}

.fullwidth-content__header-description {
  font-family: $alt-family;
  @include font-size(34);
  max-width: 760px;
  margin: 0 auto;
  color: #1D1D1B;

  @media #{$tablet} {
    @include font-size(28);
  }

  @media #{$phone} {
    @include font-size(24);
    max-width: 280px;
  }

  img {
    max-width: 40px;

    @media #{$phone} {
      max-width: 30px;
    }
  }
}

.fullwidth-content__body {
  @include font-size(24);
  color: rgba($black, 0.8);
  line-height: 1.7;

  @media #{$large-screen} {
    @include font-size(18);
    max-width: 760px;
    margin: 0 auto;
  }

  @media #{$phone} {
    @include font-size(16);
  }
}

.fullwidth-content__image {
  max-width: 842px;
  margin: 147px auto 0;

  @media #{$tablet} {
    margin-top: 100px;
  }

  @media #{$phone} {
    margin-top: 50px;
  }
}

.comparison-bar {
  padding: 163px 0 0;

  @media #{$large-screen} {
    padding: 120px 0 0;
  }

  @media #{$tablet} {
    padding: 90px 0 0;
  }

  @media #{$phone} {
    padding: 60px 0 0;
  }

  .container {
    max-width: 1640px;
  }
}

.comparison-bar__header {
  max-width: 840px;
  margin: 0 auto 70px;
  text-align: center;

  @media #{$large-screen} {
    margin-bottom: 10px;
    max-width: 576px;
  }

  @media #{$phone} {
    margin-bottom: 40px;
  }
}

.comparison-bar__header-title {
  @media #{$phone} {
    @include font-size(28);
  }
}

.comparison-bar__header-description {
  font-family: $primary-family-medium;
  @include font-size(38);
  padding: 0 10px;
  margin-top: 32px;
  line-height: 1.35;

  @media #{$large-screen} {
    @include font-size(28);
  }

  @media #{$phone} {
    @include font-size(20);
    max-width: 300px;
    margin: 0 auto;
  }
}

.comparison-bar__description {
  text-align: center;
  max-width: 670px;
  margin: 0 auto 30px;
  @include font-size(24);
  color: rgba($black, 0.8);
  line-height: 1.7;

  @media #{$large-screen} {
    @include font-size(18);
    max-width: 480px;
  }

  @media #{$phone} {
    @include font-size(16);
  }
}

.comparison-bar__box {
  position: relative;
  padding-bottom: 60px;

  @media #{$phone} {
    padding-top: 110px
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #F7F8FA;
    border-radius: 31px;
    height: 435px;

    @media #{$tablet} {
      height: 100%;
    }
  }
}

.comparison-bar__image {
  position: relative;
  max-width: 1466px;
  margin: 0 auto;

  img {
    position: relative;
    left: -70px;

    @media #{$tablet} {
      left: -30px;
    }

    @media #{$phone} {
      left: -20px;
    }

    @media #{$phone-portrait} {
      left: -13px;
    }
  }
}

.comparison-bar__brief {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 1175px;
  margin: 35px auto 0;
  left: -10px;

  @media #{$desktop} {
    padding: 0 100px;
  }

  @media #{$tablet} {
    padding: 0 60px;
  }

  @media #{$phone} {
    position: static;
    padding: 0 50px;
  }

  @media #{$phone-portrait} {
    padding: 0 30px;
  }
}

.comparison-bar__group-label {
  font-family: $primary-family-medium;
  @include font-size(20);
  color: #363636;

  @media #{$tablet} {
    @include font-size(16);
  }

  svg {
    color: $black;
    width: 22px;
    height: 15px;
  }

  span {
    display: block;
    font-family: $primary-family;
    @include font-size(18);
    line-height: 1.2;

    @media #{$tablet} {
      @include font-size(14);
    }
  }

  p {
    margin-top: 8px;
    z-index: 1;
    position: relative;
  }
}

.comparison-bar__group-label--right {
  text-align: right;
}

.comparison-bar__indicator {
  display: flex;
  align-items: center;
  padding: 20px 25px;
  background-color: $white;
  border-radius: 50px;
  margin: 25px 30px 0;

  @media #{$tablet} {
    padding: 14px 20px;
    margin: 25px 15px 0;
  }

  @media #{$phone} {
    left: 50%;
    position: absolute;
    top: 0;
    min-width: 285px;
    margin-left: -142px
  }
}

.comparison-bar__indicator-item {
  font-family: $primary-family-medium;
  @include font-size(18);
  margin-right: 28px;

  @media #{$tablet} {
    margin-right: 15px;
    @include font-size(16);
  }

  &:last-child {
    margin-right: 0;
  }

  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    margin-right: 6px;
    border-radius: 50%;

    @media #{$tablet} {
      width: 15px;
      height: 15px;
      margin-right: 3px;
    }
  }
}

.comparison-bar__indicator-item--first {
  color: $primary-color;

  &:before {
    background-color: $primary-color;
  }
}

.comparison-bar__indicator-item--second {
  color: #B9B9B9;

  &:before {
    background-color: #B9B9B9;
  }
}

.comparison-bar__indicator-item--third {
  color: #DEDEDE;

  &:before {
    background-color: #DEDEDE;
  }
}


.symbol {
  display: flex;
  flex-wrap: wrap;
  margin-top: auto;
}

.symbol__item {
  font-family: $primary-family-medium;
  @include font-size(20);
  border: 3px solid #ECECEC;
  border-radius: 35px;
  padding: 3px 18px;
  margin-right: 13px;
  margin-bottom: 10px;

  &:last-child {
    margin-right: 0;
  }

  @media #{$tablet} {
    @include font-size(16);
  }

  @media #{$phone} {
    @include font-size(14);
  }

  @media #{$phone-portrait} {
    border: 2px solid #ECECEC;
    margin-right: 5px;
    padding: 5px 10px 3px;
    line-height: 1;
  }
}
