.two-size {
  padding: 0 16px 16px;

  @media #{$phone} {
    padding: 0 8px 4px;
  }
}

.two-size__card {
  background: #f3f3f3;
  position: relative;
  position: relative;
  display: flex;
  z-index: 1;
  border-radius: 16px;
  overflow: hidden;
  flex-direction: column;
  padding-top: 80px;

  @media #{$phone} {
    padding-top: 40px;
  }

  .container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
  }
}

.two-size__img-left,
.two-size__img-right {
  position: absolute;
  z-index: -1;
  bottom: -1px;
}

.two-size__img-left {
  left: 0;

  @media #{$medium-screen} {
    left: -4%;
    max-width: 305px;
  }

  @media #{$desktop} {
    max-width: 249px;
  }

  @media screen and (max-width: 1281px) {
    max-width: 200px;
  }

  @media #{$tablet} {
    max-width: 160px;
  }

  @media #{$phone} {
    max-width: 90px;
    left: -5px;
    transform: translateX(-47%);
  }

  @media screen and (max-width: 360px) {
    transform: translateX(-60%);
  }
}

.two-size__img-right {
  right: 0;

  @media #{$medium-screen} {
    right: -4%;
    max-width: 243px;
  }

  @media screen and (max-width: 1281px) {
    max-width: 180px;
  }

  @media #{$tablet} {
    max-width: 160px;
  }

  @media #{$phone} {
    max-width: 78px;
    right: 0;
    transform: translateX(50%);
  }

  @media screen and (max-width: 360px) {
    transform: translateX(60%);
  }
}

.two-size__header {
  text-align: center;
  width: 100%;
}

.two-size__title {
  @include font-size(48);
  letter-spacing: -0.3px;
  line-height: 1.1;
  max-width: 640px;
  margin: 0 auto 35px;

  @media #{$desktop} {
    max-width: 420px;
    @include font-size(32);
  }

  @media #{$phone} {
    margin-bottom: 16px;
    @include font-size(24);
    max-width: 325px;
  }
}

.two-size__sub-title {
  @include font-size(36);
  font-family: $primary-family-bold;
  line-height: 1.1;
  text-align: center;
  width: 100%;
  margin-bottom: 40px;
  letter-spacing: -0.3px;

  @media #{$tablet} {
    @include font-size(24);
  }

  @media #{$phone} {
    @include font-size(20);
    letter-spacing: -0.3px;
    margin-bottom: 90px;
  }

  strong {
    @include font-size(48);

    @media #{$tablet} {
      @include font-size(32);
    }

    @media #{$phone} {
      @include font-size(24);
    }
  }
}

.two-size__main-wrap {
  display: flex;
  justify-content: center;
  align-self: flex-end;
  aspect-ratio: 389/275;
  max-width: 775px;
  width: 100%;
  height: auto;

  @media #{$medium-screen} {
    max-width: 704px;
  }

  @media #{$desktop} {
    max-width: 620px;
  }

  @media screen and (max-width: 1280px) {
    max-width: 540px;
  }

  @media #{$tablet} {
    max-width: 400px;
  }

  @media #{$phone} {
    max-width: 210px;
  }
}

.two-size__wrap {
  position: relative;
  aspect-ratio: 389/275;
  width: 100%;
  height: auto;
}

.two-size__media {
  display: flex;
  justify-content: center;

  img {
    height: auto;
    width: 100%;
  }
}

.two-size__banner {
  display: inline-block;
  z-index: 2;
  max-width: 330px;
  width: 100%;
}

.two-size__body {
  position: relative;
  z-index: 1;
}

.two-size__banner-title {
  @include font-size(22);
  margin-bottom: 33px;
  font-family: $primary-family-semibold;
  font-weight: 400;
  line-height: 1;

  @media #{$tablet} {
    margin-bottom: 24px;
  }

  @media #{$phone} {
    margin-bottom: 6px;
    @include font-size(14);
  }
}

.two-size__banner-text {
  @include font-size(16);
  line-height: 1.3;
  font-family: $primary-family;
  letter-spacing: -0.3px;

  @media #{$phone} {
    max-width: 294px;
    @include font-size(11);
  }

  span {
    display: block;
    opacity: .6;
    @include font-size(14);

    @media #{$phone} {
      @include font-size(11);
      opacity: 1;
    }
  }

  strong {
    margin-bottom: 18px;
    display: block;
    font-family: $primary-family;

    @media #{$phone} {
      @include font-size(12);
      font-family: $primary-family-medium;
      margin-bottom: 8px;
    }
  }

  @media #{$phone} {
    @include font-size(11);
  }
}

.two-size__footer {
  position: relative;
  display: flex;
  padding: 68px 20px 56px;
  border-radius: 0 0 16px 16px;
  gap: 66px;
  justify-content: center;

  @media #{$desktop} {
    gap: 40px;
  }

  @media #{$tablet} {
    padding: 40px 20px;
    gap: 24px;
  }

  @media #{$phone} {
    padding: 30px 16px;
    gap: 20px;
  }

  &::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    border-top: 4px solid $light-gray;
    background-color: #e6e5e5;
    border-radius: 0 0 16px 16px;
  }
}
