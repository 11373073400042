.hero.hero--careers {
  padding-top: 80px;
  background: $white;

  @media screen and (max-width: 1439px) {
    padding-top: 0;
  }

  .container {
    max-width: 1365px;
    position: relative;
    z-index: 1;
    padding-bottom: 182px;

    @media screen and (max-width: 1439px) {
      padding-bottom: 0;
    }

    @media screen and (max-width: 640px) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .hero__content {
    margin: 0;
    text-align: left;
    max-width: 640px;
    width: 100%;
    position: relative;
    z-index: 2;
    padding: 30px 0;

    @media screen and (max-width: 1439px) {
      padding: 100px 0;
    }

    @media screen and (max-width: 640px) {
      padding: 19px 0;
    }

    @media #{$header} {
      max-width: 580px;
    }

    @media screen and (max-width: 640px) {
      order: 2;
    }
  }

  .hero__title {
    text-align: inherit;
    @include font-size(75);
    line-height: 1.125;
    letter-spacing: -0.3px;
    margin-bottom: 27px;

    @media screen and (max-width: 640px) {
      @include font-size(38);
    }

    @media screen and (max-width: 480px) {
      @include font-size(30);
    }

    strong {
      font-size: inherit;
      letter-spacing: inherit;
      max-width: none;
      display: inline;

      @media screen and (max-width: 640px) {
        background-color: #1CE4D5;
        color: $white;
      }
    }
  }

  .hero__description {
    max-width: 515px;
    margin-bottom: 60px;

    @media screen and (max-width: 480px) {
      max-width: 256px;
      margin-bottom: 24px;
    }
  }

  .hero__media {
    position: absolute;
    top: 0;
    bottom: 0;
    right: -205px;
    max-width: 1056px;
    width: 100%;

    z-index: 1;

    @media screen and (max-width: 1439px) {
      right: 0;
    }

    @media screen and (max-width: 640px) {
      aspect-ratio: 351/255;
      position: relative;
      border-radius: 15px;
      order: 1;
      right: auto;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-20%);
      max-width: 878px;
      width: 100%;
      background: rgb(255, 255, 255);
      background: linear-gradient(90deg, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);

      @media #{$header} {
        transform: translateX(-10%);
      }

      @media screen and (max-width: 640px) {
        max-width: 100%;
        transform: none;
        background-image: url('assets/images/careers-hero-gradient.svg');
        background-repeat: repeat-x;
        height: 227px;
        bottom: -64px;
        top: auto;
      }
    }
  }

  .hero__image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right center;
    height: 100% !important;
  }

  .hero__btn {
    @media screen and (max-width: 640px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    button {
      &:not(:last-child) {
        margin-right: 12px;

        @media screen and (max-width: 640px) {
          margin-right: 16px;
        }
      }
    }
  }
}


.title-heading {
  text-align: center;
  padding: clamp(2.0625rem, 10vw, 9.6875rem) 0 clamp(2.5rem, 10vw, 11.875rem);

  .container {
    max-width: 966px;
  }

  .title-heading__title {
    font-size: clamp(1.125rem, 5vw, 3.4375rem);
    font-weight: 600;
    font-family: $primary-family-semibold;
    line-height: 1.15;

    strong {
      font-family: inherit;
      font-weight: inherit;
      position: relative;
      z-index: 1;

      &:after {
        content: '';
        position: absolute;
        background-image: url("data:image/svg+xml,%3Csvg width='221' height='20' viewBox='0 0 221 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.44617 11.5069C9.11964 13.0623 15.275 11.2149 20.7073 10.4923C39.5424 7.98682 58.3921 5.89184 76.8699 4.5744C126.21 1.05648 170.802 10.6182 217 16' stroke='%231CE4D5' stroke-width='7' stroke-linecap='round'/%3E%3C/svg%3E%0A");
        top: calc(100% - 7px);
        left: 0;
        z-index: -1;
        display: block;
        width: 100%;
        height: 20px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center top;

        @media screen and (max-width: 640px) {
          top: 100%;
        }
      }
    }
  }
}

.image-content {
  padding: 0 0 253px;

  @media screen and (max-width: 1439px) {
    padding-bottom: 200px;
  }

  @media #{$desktop} {
    padding-bottom: 150px;
  }

  @media screen and (max-width: 640px) {
    padding-bottom: 55px;
  }
}

.image-content__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;

  @media #{$header} {
    display: flex;
    flex-wrap: wrap;
    gap: 0;
    flex-direction: row-reverse;
  }
}

.image-content__column {
  &:first-child {
    @media #{$header} {
      width: 40%;
    }
  }

  &:last-child {
    @media #{$header} {
      width: 60%;
    }
  }
}

.image-content__media {
  position: relative;
  z-index: 1;


  img {
    position: absolute;
    right: 180px;
    top: 30px;
    max-width: 1022px;

    @media screen and (max-width: 1439px) {
      right: 40px;
    }

    @media #{$desktop} {
      max-width: 872px;
    }

    @media #{$header} {
      max-width: 572px;
      right: -100px;
      top: 120px;
    }

    @media screen and (max-width: 640px) {
      max-width: 310px;
      right: -64px;
    }
  }
}

.image-content__content {
  height: auto;
  max-width: 504px;
  width: 100%;

  @media #{$header} {
    position: relative;
    z-index: 2;
  }
}

.image-content__title {
  font-style: normal;
  font-weight: 700;
  font-size: clamp(2.125rem, 6vw, 4.25rem);
  line-height: 1.147;
  letter-spacing: -0.3px;
  color: #000000;
  margin-top: 60px;

  @media #{$desktop} {
    margin-top: 0;
  }

  strong {
    color: white;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: black;
  }
}

.image-content__description {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.8;
  max-width: 520px;
  margin-top: 50px;

  @media #{$desktop} {
    margin-top: 30px;
  }

  @media screen and (max-width: 640px) {
    font-size: 13px;
    line-height: 1.69;
    margin-top: 17px;
  }
}


.highlighted-block {
  &.highlighted-block--alt {
    .container {
      max-width: 1640px;
    }
  }
}

.highlighted-block__card {
  background-color: #0A56EA;
  padding: 161px 40px 120px;
  color: $white;
  border-radius: 15px;
  text-align: center;
  position: relative;
  z-index: 1;

  @media #{$desktop} {
    padding: 100px 30px;
  }

  @media #{$header} {
    padding: 80px 36px;
  }

  @media #{$phone} {
    padding: 45px 35px 55px;
  }

  .highlighted-block__title {
    margin-bottom: 28px;

    @media screen and (max-width: 640px) {
      @include font-size(20);
      letter-spacing: -.31px;
      margin-bottom: 20px;
    }
  }
}

.highlighted-block__content {
  max-width: 779px;
  margin: 0 auto;
  width: 100%;
}

.highlighted-block__description {
  max-width: 630px;
  margin: 0 auto;
  @include font-size(24);
  line-height: 1.66;

  @media screen and (max-width: 640px) {
    @include font-size(14);
  }
}

.highlighted-block__filter-icons {
  z-index: -1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  list-style-type: none;

  @media #{$desktop} {
    display: none;
  }

  li {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    position: absolute;
    z-index: -1;
    max-width: 200px;

    strong {
      font-size: 28px;
      text-transform: capitalize;
      margin-top: 10px;
      display: inline-block;
      vertical-align: top;
    }

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 123px;
      width: 123px;
      border-radius: 50%;
      font-size: 76px;
    }

    &:first-child {
      top: 280px;
      left: 82px;

      span {
        background-color: #FF7892;
      }
    }

    &:nth-child(2) {
      top: -31px;
      left: 345px;

      @media screen and (max-width: 1600px) {
        left: 200px;
      }

      span {
        background-color: #FCDC99;
      }
    }

    &:nth-child(3) {
      top: 36px;
      right: 307px;

      @media screen and (max-width: 1630px) {
        right: 150px;
      }

      @media screen and (max-width: 1349px) {
        right: 80px;
        top: -48px;
      }

      span {
        background-color: #FF9C00;
      }
    }

    &:nth-child(4) {
      top: 300px;
      right: 132px;

      @media screen and (max-width: 1600px) {
        right: 80px;
      }

      @media screen and (max-width: 1349px) {
        right: 20px;
        top: 320px;
      }

      span {
        background-color: #C4A7FF;
      }
    }
  }
}


//section - contentImage
.media-content {
  padding: 196px 0 207px;

  @media screen and (max-width: 1439px) {
    padding: 120px 0;
  }

  @media screen and (max-width: 640px) {
    padding: 57px 0 174px;
  }

  .container {
    max-width: 1330px;
  }
}

.media-content__grid {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.media-content__content {
  width: 58.33%;
  position: relative;
  z-index: 2;

  @media screen and (max-width: 640px) {
    width: 99%;
  }
}

.media-content__image {
  position: relative;
  z-index: 1;
  width: 41.66%;

  @media screen and (max-width: 640px) {
    width: 1%;
  }

  img {
    position: absolute;
    z-index: 1;
    max-width: 1024px;
    left: -63px;

    @media screen and (max-width: 1439px) {
      max-width: 840px;
      top: 40px;
    }

    @media #{$desktop} {
      max-width: 640px;
      bottom: -100px;
      top: auto;
    }

    @media screen and (max-width: 640px) {
      max-width: 440px;
      right: -184px;
      left: auto;
      bottom: -164px;
    }
  }
}

.media-content__title {
  font-style: normal;
  font-weight: 700;
  font-size: clamp(2rem, 5vw, 4.25rem);
  line-height: 1.14;
  letter-spacing: -0.3px;
  margin-bottom: 52px;

  @media screen and (max-width: 640px) {
    margin-bottom: 20px;
  }
}

.media-content__description {
  font-style: normal;
  font-weight: 400;
  @include font-size(24);
  line-height: 1.66;
  max-width: 524px;
  mix-blend-mode: normal;
  opacity: 0.8;

  @media screen and (max-width: 640px) {
    @include font-size(13);
    max-width: 230px;
  }
}


.general-content {
  padding: 63px 0;

  &.general-content--alt {
    padding: 120px 0;

    @media #{$phone} {
      padding: 60px 0;
    }
  }
}



.general-content__content {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  text-align: center;


  .general-content--left & {
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.general-content__title {
  font-style: normal;
  font-weight: 700;
  font-size: clamp(2rem, 5vw, 4.25rem);
  line-height: 1.14;
  letter-spacing: -0.3px;
  color: $black;
  margin-bottom: 46px;

  @media #{$phone} {
    margin-bottom: 25px;
  }
}

.general-content__description {
  max-width: 780px;
  font-style: normal;
  font-weight: 400;
  @include font-size(24);
  line-height: 1.66;
  color: $black;
  mix-blend-mode: normal;
  opacity: 0.8;

  @media #{$phone} {
    @include font-size(13);
  }

  a {
    color: $primary-color;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}


.box-content {
  padding: 40px 0;

  .container {
    max-width: 1744px;
    position: relative;
    z-index: 1;

    @media screen and (max-width: 1400px) {
      padding: 0 0 0 20px;
    }

    @media screen and (max-width: 640px) {
      padding: 0 15px;
    }
  }

  .slick-dots li.slick-active button {
    background-color: $white;
    border-color: $primary-color;
  }

  .slick-dots li button {
    border-color: #979797;
  }

  .slick-dots li.slick-active button::before {
    background-color: $primary-color;
  }

  .slick-dots {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 100%;
  }

  .slick-track,
  .slick-list {
    width: 100%;
  }

  .slick-track {
    display: flex;
    justify-content: flex-start;
  }

  .slick-list {
    overflow: visible;
  }

  .slick-slider {
    padding: 40px 100px;


    @media screen and (max-width: 1600px) {
      padding: 40px 100px 40px 0;
    }

    @media screen and (max-width: 1400px) {
      padding: 40px 60px 40px 0;
    }

    @media screen and (max-width: 640px) {
      padding: 40px 5px;
    }
  }
}

.box-content__grid {
  position: relative;
  z-index: 1;
}

.box-content__column {
  padding: 0 10px;

  @media screen and (max-width: 640px) {
    padding: 0 5px;
  }

  &:first-child {
    .box-content__card {
      padding-bottom: 103px;
    }
  }

  &:nth-child(2) {
    .box-content__card {
      min-height: 290px;
    }
  }

  &:nth-child(3) {
    .box-content__card {
      min-height: 270px;
    }
  }

  &:nth-child(4) {
    .box-content__card {
      min-height: 233px;
    }
  }

  &:nth-child(5) {
    .box-content__card {
      min-height: 212px;
      padding-bottom: 24px;
    }
  }
}

.box-content__card {
  background: $white;
  box-shadow: 7px 10px 23px 1px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 43px 30px 55px;
  position: relative;
  color: #B1B1B2;

  @media #{$desktop} {
    padding: 43px 25px 55px;
  }

  @media screen and (max-width: 640px) {
    box-shadow: 7px 10px 23px -5px rgba(0, 0, 0, 0.25);
    min-height: auto !important;
    padding: 20px 15px 32px !important;
  }
}

.box-content__description {
  @include font-size(20);

  @media #{$desktop} {
    @include font-size(18);
  }

  @media screen and (max-width: 640px) {
    @include font-size(13);
  }
}

.box-content__title {
  font-style: normal;
  font-weight: 600;
  @include font-size(48);
  line-height: 1;
  font-family: $primary-family-semibold;
  letter-spacing: -0.3px;
  margin-bottom: 21px;
  text-align: center;

  @media #{$desktop} {
    @include font-size(36);
  }

  @media #{$header} {
    @include font-size(33);
  }

  @media screen and (max-width: 640px) {
    margin-bottom: 10px;
  }
}

.box-content__badge {
  position: absolute;
  top: calc(100% - 38px);
  left: 35.8%;

  @media screen and (max-width: 640px) {
    top: calc(100% - 16px);
  }

  img {
    max-width: 105px;

    @media screen and (max-width: 1400px) {
      max-width: 85px;
    }

    @media #{$desktop} {
      max-width: 60px;
    }
  }
}

.box-content__slogan {
  font-family: $alt-family;
  font-style: normal;
  font-weight: 400;
  @include font-size(45);
  line-height: 0.8;
  text-align: center;
  letter-spacing: -0.3px;
  color: #1ce4d5;
  transform: rotate(-30deg);
  position: absolute;
  z-index: 8;
  max-width: 254px;
  right: -98px;
  top: calc(100% + 20px);

  @media screen and (max-width: 640px) {
    right: -18px;
    line-height: .75;
    max-width: 127px;
    @include font-size(24);
    top: calc(100% - 23px);
  }
}


.join-faqs {
  padding: 40px 0;

  @media #{$phone} {
    padding: 40px 0 80px;
  }

  .container {
    max-width: 1544px;
  }
}

.join-faqs__title {
  letter-spacing: -.3px;

  @media #{$phone} {
    @include font-size(32px);
  }
}

.join-faqs__list {
  max-width: 1208px;
}

.join-faqs__content {
  padding: 118px 0;

  @media screen and (max-width: 1024px) {
    padding: 60px 0;
  }

  @media #{$header} {
    padding: 40px 0;
  }

  @media screen and (max-width: 640px) {
    padding: 0;
  }
}

.join-faqs__card {
  padding: 40px 89px;

  @media #{$desktop} {
    padding: 40px;
  }

  @media #{$header} {
    padding: 40px 20px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: -54px;
    left: 0;
    z-index: -1;
    background-color: $light-gray;
    border-radius: 15px;

    @media screen and (max-width: 640px) {
      right: 15px;
      left: 15px;
    }
  }
}

.join-faqs__description {
  @include font-size(20);
  line-height: 1.7;

  @media screen and (max-width: 640px) {
    @include font-size(13);
  }

  a {
    color: inherit;
    text-decoration: underline;
    transition: all 300ms ease;

    &:hover,
    &:focus {
      color: #0A56EA;
    }
  }
}

.join-faqs__grid {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 100px;

  @media #{$phone} {
    margin-bottom: 60px;
  }

  @media screen and (max-width: 640px) {
    margin-bottom: 30px;
  }
}

.join-faqs__column {
  &:first-child {
    width: 41.66%;

    @media #{$phone} {
      width: 60%;
    }
  }

  &:last-child {
    width: 58.33%;

    @media #{$phone} {
      width: 40%;
    }
  }
}

.join-faqs__content {
  max-width: 425px;
  position: relative;
  z-index: 1;
}

.join-faqs__figure {
  position: relative;
  z-index: 1;

  img {
    max-width: 987px;

    @media #{$desktop} {
      max-width: 780px;
    }

    @media screen and (max-width: 1024px) {
      max-width: 640px;
    }

    @media screen and (max-width: 640px) {
      max-width: 408px;
      position: absolute;
      top: -84px;
      left: -20px;
      z-index: -1;
    }
  }
}

.join-faqs__list {
  @media #{$phone} {
    margin: 0 -20px;
  }
}
