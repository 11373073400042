.product-block {
  padding: 100px 0 285px;
  position: relative;
  overflow: hidden;
  z-index: 1;

  @media #{$large-screen} {
    padding: 100px 0 220px;
  }

  @media #{$tablet} {
    padding: 100px 0;
  }

  @media #{$phone} {
    padding: 60px 0;
  }

  &:after {
    content: "";
    display: block;
    height: 3px;
    background-color: #ebebeb;
    width: 1516px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;
  }
}

.product-block__intro {
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
  @include font-size(32);
  position: relative;
  z-index: 2;

  @media #{$large-screen} {
    @include font-size(20);
    max-width: 550px;
  }

  @media #{$phone} {
    @include font-size(18);
    margin-bottom: 30px;
    max-width: 280px;
  }
}

.product-block__description {
  @media #{$large-screen} {
    line-height: 1.7;
  }

  @media #{$phone} {
    @include font-size(16);
  }
}

.product-block__title {
  margin-bottom: 65px;

  @media #{$large-screen} {
    margin-bottom: 30px;
  }

  @media #{$tablet} {
    margin-bottom: 45px;
  }

  @media #{$phone} {
    margin-bottom: 20px;
    @include font-size(28);
  }
}

.product-block__inner {
  position: relative;
}

.product-block__items {
  max-width: 452px;
  list-style: none;
  padding-left: 0;
  margin-top: 250px;
  position: relative;
  z-index: 2;

  @media #{$large-screen} {
    margin-top: 100px;
  }

  @media #{$tablet} {
    margin: 0 auto;
  }

  @media #{$phone} {
    max-width: inherit;
    margin: -120px -20px 0;
  }


  .slick-track {
    @media #{$phone} {
      display: flex;
    }

    .slick-slide {
      @media #{$phone} {
        display: flex;
        height: auto;
      }

      >div {
        @media #{$phone} {
          display: flex;
          margin: 40px 8px !important;
        }
      }
    }
  }
}

.product-block__items-item {
  border: 2px solid #e6e6e6;
  margin-top: -2px;
  padding: 26px 40px 30px;
  cursor: pointer;

  @media #{$phone} {
    box-shadow: 0 12px 50px rgba(0, 0, 0, 0.06);
    border-radius: 15px;
    padding: 21px 46px 22px 30px !important;
    background-color: $white;
    height: inherit !important;
  }

  &:first-child {
    border-radius: 15px 15px 0 0;

    @media #{$phone} {
      border-radius: 15px;
    }
  }

  &:last-child {
    border-radius: 0 0 15px 15px;

    @media #{$phone} {
      border-radius: 15px;
    }
  }
}

.product-block__items-item.product-block__items-item--is-active {
  .price-card__price {
    color: $primary-color;

    strong {
      color: #252525;
    }
  }

  .price-card {
    box-shadow: 0 12px 50px rgba(0, 0, 0, 0.08);
    border-radius: 15px;
    margin-left: -15px;
    margin-right: -15px;
    background-color: $white;

    @media #{$phone} {
      margin: 0 !important;
      box-shadow: none;
    }

    .price-card__inner {
      padding: 57px 53px 43px;

      @media #{$tablet} {
        padding: 57px 35px 43px;
      }

      @media #{$phone} {
        padding: 0;
      }
    }
  }
}

.slick-current {
  .product-block__items-item {
    border-color: transparent;

    .price-card__title {
      color: $primary-color;
    }

    .price-card__price {
      color: $primary-color;

      strong {
        color: #252525;
      }
    }
  }
}

.product-block__items-title {
  @include font-size(18);
  font-family: $primary-family;
  margin-bottom: 0;

  @media #{$phone} {
    height: auto !important;
    opacity: 1 !important;
    display: none !important;
  }

  strong {
    font-family: $primary-family-medium;
    @include font-size(26);
    display: block;
    margin-bottom: 3px;

    @media #{$phone-portrait} {
      @include font-size(22);
    }
  }
}

.price-card {
  transition: box-shadow 300ms ease, background-color 300ms ease;

  .btn--small {
    font-family: $primary-family-semibold;
    @include font-size(20);
    padding: 12px 36px;
  }

  @media #{$phone} {
    height: auto !important;
    opacity: 1;
    visibility: visible;
  }
}

.price-card__image {
  opacity: 0;
  width: 310px;
  position: absolute;
  top: -40px;
  right: -35px;
  pointer-events: none;

  @media #{$phone} {
    display: none;
  }
}

.price-card__title {
  font-family: $primary-family;
  @include font-size(18);
  color: $black;
  margin-bottom: 34px;
  max-width: 230px;

  @media #{$phone} {
    font-family: $primary-family-medium;
    @include font-size(22);
    margin-bottom: 10px;
    color: #636363;
  }

  strong {
    display: block;
    font-family: $primary-family-semibold;
    @include font-size(28);
    color: $primary-color;
    margin-bottom: 4px;

    @media #{$phone} {
      display: none;
    }
  }
}

.price-card__description {
  max-width: 180px;
  margin-bottom: 30px;
  color: #8d8d8d;
  line-height: 1.2;

  @media #{$phone} {
    @include font-size(12);
    margin-bottom: 43px;
  }
}


.price-card__flex-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.price-card__footer,
.price-card__flex-footer {
  .btn--small {
    @media #{$phone} {
      display: none;
    }
  }
}

.price-card__footer--discount {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .price-card__price {
    position: relative;
    z-index: 1;

    strong {
      margin-bottom: -3px;
    }
  }

  .price-card__discount-percentage {
    color: #1CE4D5;
    text-align: right;
    font-family: $primary-family-medium;
    @include font-size(16);
    font-style: normal;
    line-height: normal;
    position: absolute;
    bottom: 100%;
    right: 0;
    left: 0;
    margin-right: 0;
    width: 100%;
    white-space: nowrap;
  }


}

.price-card__price {
  display: inline-block;
  @include font-size(16);

  @media #{$phone} {
    @include font-size(12);
  }

  strong {
    display: block;
    font-family: $primary-family-medium;
    @include font-size(36);
    color: #636363;

    span {
      color: #979797;
      margin-right: 15px;
      vertical-align: middle;
      overflow: hidden;
      position: relative;
      @include font-size(28);

      img {
        position: absolute;
        top: 5px;
        transform: rotate(3.42deg);
        width: 100%;
      }

      @media #{$phone} {
        @include font-size(20);
      }
    }

    @media #{$phone} {
      @include font-size(24);
    }
  }
}

.product-block__items-item--is-active {
  border-color: transparent;
  padding: 0;
  margin-top: -10px;
  margin-bottom: -10px;
  background-color: $white;
  position: relative;

  @media #{$phone} {
    margin-top: 0;
    margin-bottom: 0;
  }

  .product-block__items-title {
    opacity: 0;
  }

  .price-card__image {
    opacity: 1;
    transition: opacity .3s ease;
  }
}

.product-items {
  padding-left: 0;
  list-style: none;

  @media #{$tablet} {
    margin-top: 30px;
    margin-bottom: 30px;
    min-height: 510px;
  }

  @media #{$phone} {
    margin-bottom: 0;
    min-height: 360px;
  }
}

.product-items__item {
  position: absolute;
  top: -120px;
  right: -460px;
  z-index: 1;
  max-width: 1302px;
  max-height: 932px;

  @media #{$large-screen} {
    right: -290px;
    max-width: 1100px;
  }

  @media #{$desktop} {
    max-width: 952px;
    right: -220px;
  }

  @media #{$tablet} {
    right: 0;
    top: 0;
    height: 0;
  }

  img {
    transform-origin: right center;

    @media #{$tablet} {
      height: 0;
      opacity: 0;
      visibility: hidden;
      max-height: 520px;
      margin: 0 auto;
      display: block;
      left: 0 !important;
    }

    @media #{$phone} {
      max-height: 370px;
    }
  }

  &.product-items__item--is-active {
    @media #{$tablet} {
      height: auto;
      width: 100%;
    }

    img {
      @media #{$tablet} {
        height: auto;
        opacity: 1;
        visibility: visible;
      }

      @media #{$phone} {
        width: auto;
        margin: 0 auto;
        max-width: 100%;
        position: relative;
      }

      @media #{$phone-portrait} {
        left: -23% !important;
        width: 160%;
        max-width: inherit;
        position: relative;
      }
    }
  }
}

.product-items__item--first {
  img {
    padding-top: 210px;
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.62, 0.28, 0.23, 0.99);

    @media #{$tablet} {
      padding-top: 0;
    }
  }

  &.product-items__item--is-active {
    img {
      opacity: 1;
    }
  }
}

.product-items__item--second {
  img {
    opacity: 0;
    transform: scale(1.2);
    transition: none;

    @media #{$tablet} {
      transform: scale(1) !important;
      transition: none !important;
    }
  }

  &.product-items__item--is-active {
    img {
      opacity: 1;
      transform: scale(1);
      transition: transform 0.3s cubic-bezier(0.62, 0.28, 0.23, 0.99);
    }
  }
}

.product-items__item--third {
  img {
    position: relative;
    opacity: 0;
    transition: opacity 0.2s cubic-bezier(0.62, 0.28, 0.23, 0.99);
  }

  &.product-items__item--is-active {
    img {
      opacity: 1;
    }
  }
}

.product-items__item--second,
.product-items__item--third {
  img {
    max-width: 1300px;

    @media #{$desktop} {
      padding-top: 210px;
      max-width: 900px;
    }

    @media #{$tablet} {
      padding-top: 0;
    }
  }
}
