.performace-stats {
  padding: 180px 0 80px;
  background-color: $white;

  @media #{$small-screen} {
    padding: 80px 0;
  }

  @media #{$tablet} {
    padding: 60px 0;
  }

  @media #{$phone} {
    padding: 40px 0 60px;
  }
}

.performace-stats__header {
  margin: 0 auto 56px;
  max-width: 850px;
  width: 100%;
  text-align: center;

  @media #{$tablet} {
    margin-bottom: 32px;
  }
}

.performace-stats__title {
  @include font-size(48);
  letter-spacing: -0.31px;
  font-family: $primary-family-bold;
  line-height: calc(58/48);
  margin: 0 auto 48px;
  width: 100%;

  @media #{$small-screen} {
    @include font-size(36);
    margin-bottom: 28px;
  }

  @media #{$phone} {
    @include font-size(28);
  }
}

.performace-stats__description {
  @include font-size(20);
  line-height: calc(34/20);

  @media #{$small-screen} {
    @include font-size(18);
    line-height: 1.5;
  }

  @media #{$phone} {
    @include font-size(16);
  }
}

.performace-stats__table {
  display: flex;
  justify-content: space-between;

  @media #{$tablet} {
    display: none;
  }
}

.performace-stats__col--label {
  display: flex;
  align-items: flex-end;
  padding: 45px 0;

  @media #{$small-screen} {
    padding: 30px 0;
  }
}

.performace-stats__col--values {
  width: calc(100% - 220px);
  padding: 45px 23px;
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0 24px 70px rgba(0, 0, 0, 0.05);

  @media #{$small-screen} {
    width: calc(100% - 180px);
    padding: 30px 20px;
  }
}

.stats-item {
  flex: 1;
  padding: 20px 0 50px;
  border-radius: 10px 10px 0 0;

  @media #{$small-screen} {
    padding-bottom: 20px;
  }

  &.stats-item--current {
    background-color: var(--secondary-color)
  }
}

.stats-item__label {
  text-align: left;
  margin-right: auto;
  display: none;

  @media #{$tablet} {
    display: block;
  }
}

.performace-stats__labels {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;

  li {
    display: flex;
    align-items: center;
    min-height: 84px;
    @include font-size(16);
    font-family: $primary-family-semibold;
    letter-spacing: -0.002em;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.7);

    @media #{$small-screen} {
      @include font-size(14);
      min-height: 64px;
    }

    small {
      font-family: $primary-family;
      font-size: 0.9375rem;
      text-transform: none;
      padding-left: 5px;
      color: rgba(0, 0, 0, 0.5);
      text-transform: none;
    }
  }
}


.stats-grid__head {
  display: flex;
  flex: 1;
  gap: 8px;

  @media #{$small-screen} {
    gap: 4px;
  }
}

.stats-item__title {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  word-wrap: break-word;
  text-align: center;
  @include font-size(18);
  font-family: $primary-family-medium;
  line-height: calc(22/18);
  text-wrap: balance;

  img {
    max-width: 38px;
    margin-bottom: 16px;
  }
}

.stats-grid__body {
  width: 100%;

  .stats-item__list {
    display: flex;
    gap: 8px;
    width: 100%;
    list-style: none;
    text-align: center;
    padding: 10px 0;

    @media #{$small-screen} {
      gap: 4px;
      padding: 0;
    }

    &:last-child {
      li {
        &::after {
          border-radius: 0 0 10px 10px;
        }

        &.list-stats--hidden {
          &::after {
            bottom: -10px;
          }
        }
      }
    }

    li {
      letter-spacing: 0.001em;
      @include font-size(16);
      flex: 1;
      padding: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 64px;
      position: relative;
      z-index: 1;

      &.bold-text {
        font-family: $primary-family-medium;
      }

      &.list-stats--current {
        &::after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: -10px;
          bottom: -10px;
          background-color: var(--secondary-color);
          z-index: -1;
        }
      }

      &.list-stats--hidden {
        color: transparent;

        &::after {
          content: '';
          position: absolute;
          left: 10px;
          right: 10px;
          bottom: -20px;
          top: -10px;
          z-index: -1;
          background-color: #F9F9F9;
          border-radius: 10px;
        }
      }

      &.list-stats--hidden-center {
        color: rgba(0, 0, 0, 0.3);
      }

      .list-stats--highlited {
        border-radius: 10px;
        text-align: center;
        font-family: $primary-family-medium;
        background-color: var(--primary-color);
        color: $white;
        display: block;
        width: 100%;
        padding: 13px 15px 12px;
      }
    }
  }
}

//Slider
.performace-stats__slider {
  display: none;
  margin: 0 -10px;

  @media #{$tablet} {
    display: block;
  }

  .slick-list {
    overflow: visible;
  }

  .slick-track {
    display: flex;
  }

  .slick-dots {
    position: absolute;
    top: calc(100% + 13px);
    display: flex;
    justify-content: center;
    gap: 5px;
    left: 0;
    right: 0;

    li {
      margin: 0;

      &.slick-active {
        button {
          border-color: var(--primary-color);

          &::before {
            background-color: var(--primary-color);
            animation: move-left-to-right 5.8s linear infinite;
          }

          &:hover,
          &:focus {
            border-color: var(--primary-color);
          }
        }
      }

      button {
        border: 2px solid var(--primary-color);

        &:hover,
        &:focus {
          border-color: var(--primary-color);
        }
      }
    }
  }
}

.performace-stats__item {
  padding: 0 10px;

  @media #{$phone} {
    padding: 0 5px;
  }
}

.performace-stats__card {
  width: 350px;
  border-radius: 15px;
  background-color: $white;
  box-shadow: 0 14px 84px rgba(0, 0, 0, 0.08);
  color: #494949;
  padding: 32px;

  @media screen and (max-width:390px) {
    max-width: 350px;
    width: 100%;
    min-width: 300px;
  }

  .stats-item__list {
    list-style: none;
    padding: 0;

    li {
      display: flex;
      align-items: flex-start;
      @include font-size(16);
      font-family: $primary-family;
      line-height: 1.4;
      border-bottom: 1px solid #F1F1F1;
      justify-content: space-between;
      padding: 10px 0;
      position: relative;
      z-index: 1;

      &.bold-text {
        font-family: $primary-family-medium;
      }

      .list-stats--highlited {
        &::after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: -11px;
          right: -11px;
          background-color: var(--secondary-color);
          z-index: -1;
          border-radius: 4px;
        }
      }

      &:before {
        content: attr(data-title);
        display: inline-block;
        vertical-align: middle;
        font-family: $primary-family-medium;
      }
    }
  }
}

.performace-stats__card-title {
  text-align: center;
  @include font-size(14);
  font-family: $primary-family-medium;
  text-transform: uppercase;

  small {
    font-family: $primary-family;
    font-size: inherit;
  }

  .icon {
    margin-left: 8px;
    height: 22px;
    width: 22px;
    color: #C7C7C7;
  }
}
