.hero--background-image {
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 372px;
  padding-bottom: 210px;
  position: relative;
  overflow: hidden;

  @media #{$large-screen} {
    padding-top: 272px;
    padding-bottom: 110px;
  }

  @media #{$tablet} {
    padding-top: 200px;
    padding-bottom: 50px;
  }

  @media #{$phone} {
    padding-top: 160px;
    margin-bottom: 0;
    background: none !important;
  }

  &:before {
    content: "";
    width: 100%;
    height: calc(100% + 300px);
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(236, 236, 236, 0) 4.76%, #FCFCFC 70.07%);

    @media #{$phone} {
      display: none;
    }
  }

  .container {
    position: relative;
    z-index: 1;
  }

  .hero__title {
    @include font-size(90);
    line-height: 1.1;
    max-width: 920px;
    margin-bottom: 280px;

    @media #{$tablet} {
      @include font-size(60);
      margin-bottom: 70px;
    }

    @media #{$phone} {
      @include font-size(38);
      margin-bottom: 53px;
    }
  }

  .hero__content {
    @media #{$phone} {
      padding: 0 25px;
    }
  }
}

.hero__mobile-image {
  display: none;
  position: relative;

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(252, 252, 252, 0.55);
  }

  &:after {
    content: "";
    width: 100%;
    height: 317px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(236, 236, 236, 0) 4.76%, #FCFCFC 70.07%);
  }

  @media #{$phone} {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.hero__highlighted-text {
  @include font-size(32);
  font-family: $primary-family-semibold;
  max-width: 585px;
  margin: 0 auto 122px;
  text-align: center;

  @media #{$large-screen} {
    margin-bottom: 60px;
  }

  @media #{$tablet} {
    @include font-size(24);
  }

  @media #{$phone} {
    font-family: $primary-family-medium;
    @include font-size(20);
    padding: 0 30px;
  }
}

.hero__content {
  max-width: 835px;
  text-align: center;
  margin: 0 auto;
  @include font-size(24);
  line-height: 1.6;

  @media #{$large-screen} {
    @include font-size(20);
  }

  @media #{$phone} {
    @include font-size(16);
  }

  p {
    margin-bottom: 36px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.emoji-line {
  @include font-size(38);
  display: flex;
  justify-content: center;
  margin-bottom: 40px;

  @media #{$phone} {
    margin-bottom: 23px;
  }

  p {
    margin: 0;

    @media #{$phone} {
      @include font-size(24);
      max-width: 27px;
    }
  }
}

.sbsic-block {
  background-color: #FCFCFC;
  padding-top: 195px;

  @media #{$large-screen} {
    padding-top: 100px;
  }

  @media #{$tablet} {
    padding-top: 50px;
  }

  @media #{$phone} {
    padding-top: 0;
  }

  .container {
    max-width: 1720px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.sbsic-block__image {
  width: 44%;
  position: relative;

  @media #{$tablet} {
    width: 100%;
    margin-bottom: 30px;
    left: 0;
    text-align: center;
  }

  @media #{$phone} {
    width: 100%;
  }

  img {
    @media #{$tablet} {
      max-width: 500px;
      width: 100%;
    }
  }
}

.sbsic-block__content {
  width: 56%;
  padding-top: 70px;
  padding-bottom: 180px;

  @media #{$large-screen} {
    padding-bottom: 60px;
  }

  @media #{$tablet} {
    width: 100%;
    padding-top: 0;
  }

  @media #{$phone} {
    width: 100%;
  }
}

.sbsic-block__title {
  max-width: 750px;
  margin-bottom: 90px;

  @media #{$large-screen} {
    margin-bottom: 60px;
    max-width: 550px;
  }

  @media #{$tablet} {
    margin-bottom: 30px;
  }
}

.sbsic-block__description {
  max-width: 645px;
  line-height: 1.5;
  @include font-size(24);

  @media #{$large-screen} {
    @include font-size(20);
  }

  @media #{$phone} {
    @include font-size(16);
  }

  p {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.grid-card {
  padding-top: 270px;
  padding-bottom: 180px;

  @media #{$large-screen} {
    padding-top: 150px;
    padding-bottom: 150px;
  }

  @media #{$tablet} {
    padding-top: 50px;
    padding-bottom: 70px;
  }

  .container {
    max-width: 1152px;
  }
}

.grid-card__intro {
  margin-bottom: 120px;
  padding-left: 20px;

  @media #{$large-screen} {
    margin-bottom: 90px;
  }

  @media #{$tablet} {
    margin-bottom: 60px;
  }

  @media #{$phone} {
    padding-left: 0;
    margin-bottom: 30px;
  }
}

.grid-card__intro-title {
  line-height: 1.2;
  max-width: 790px;
  margin-bottom: 80px;

  @media #{$large-screen} {
    margin-bottom: 60px;
    max-width: 650px;
  }

  @media #{$tablet} {
    margin-bottom: 40px;
  }

  img {
    max-width: 68px;
    display: inline-block;
    margin-top: -10px;
    margin-left: 20px;

    @media #{$large-screen} {
      max-width: 48px;
    }

    @media #{$phone} {
      margin-left: 9px;
    }
  }
}

.grid-card__intro-description {
  @include font-size(32);
  color: rgba($black, 0.8);
  max-width: 630px;

  @media #{$large-screen} {
    @include font-size(20);
  }

  @media #{$phone} {
    @include font-size(16);
  }

  p {
    margin-bottom: 45px;

    @media #{$tablet} {
      margin-bottom: 30px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.grid-card__list {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 286px;

  @media #{$large-screen} {
    margin-bottom: 200px;
  }

  @media #{$tablet} {
    margin-bottom: 120px;
  }
}

.grid-card__list-item {
  background: #FFFFFF;
  box-shadow: 0px 22px 34px rgba(0, 0, 0, 0.0538134);
  border-radius: 15px;
  width: calc(33.33% - 42px);
  margin: 0 21px 46px;
  padding: 64px 30px 54px 40px;
  @include font-size(24);

  @media #{$large-screen} {
    @include font-size(20);
    padding: 54px 20px 44px 30px;
  }

  @media #{$tablet} {
    @include font-size(18);
  }

  @media #{$phone} {
    width: calc(50% - 20px);
    margin: 0 10px 20px;
    @include font-size(14);
  }

  @media #{$phone-portrait} {
    @include font-size(12);
    padding: 30px 18px;
    margin: 0 8px 12px;
  }

  img {
    margin-bottom: 45px;

    @media #{$tablet} {
      max-width: 50px;
      margin-bottom: 30px;
    }

    @media #{$phone} {
      max-width: 40px;
      margin-bottom: 15px;
    }

    @media #{$phone-portrait} {
      max-width: 30px;
    }
  }

  p {
    max-width: 210px;
    line-height: 1.5;

    @media #{$phone-portrait} {
      max-width: 100%;
    }
  }
}

.grid-card__list-item--fullwidth {
  p {
    max-width: 100%;
  }
}

.grid-card__bottom-title {
  max-width: 640px;
  margin: 0 auto;
  text-align: center;
  @include font-size(38);
  font-family: $primary-family-medium;
  line-height: 1.4;
  position: relative;

  @media #{$tablet} {
    @include font-size(28);
  }

  @media #{$phone} {
    @include font-size(20);
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: -120px;
    left: calc(50% - 47px);
    width: 94px;
    height: 94px;
    background: url("assets/images/leaf-fluttering-in-wind.png") no-repeat 0 0;
    background-size: contain;

    @media #{$tablet} {
      top: -90px;
      left: calc(50% - 37px);
      width: 74px;
      height: 74px;
    }

    @media #{$phone} {
      top: -70px;
      left: calc(50% - 37px);
      width: 54px;
      height: 54px;
    }
  }
}

.overlay-block {
  padding: 0 40px 20px;

  @media screen and (max-width: 1300px) {
    padding: 0 0 50px;
    background-color: #f3f4f6;
  }

  .container {
    max-width: 100%;
    padding-left: 17%;

    @media #{$phone} {
      padding-left: 9%;
    }

    @media #{$phone-portrait} {
      padding-left: 20px;
    }
  }
}

.overlay-block__inner {
  padding: 175px 0 920px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  border-radius: 15px;

  @media screen and (max-width: 1300px) {
    padding: 65px 0 0;
    background: none !important;
    border-radius: 0;
  }
}

.overlay-block__title {
  max-width: 515px;
  margin-bottom: 58px;
  line-height: 1.1;

  @media #{$large-screen} {
    margin-bottom: 45px;
  }

  @media #{$phone} {
    margin-bottom: 25px;
    @include font-size(28);
  }
}

.overlay-block__description {
  @include font-size(24);
  max-width: 490px;
  line-height: 1.55;

  @media #{$large-screen} {
    @include font-size(20);
  }

  @media #{$phone} {
    @include font-size(14);
  }
}

.overlay-block__image {
  display: none;

  @media screen and (max-width: 1300px) {
    display: block;
    margin-top: -50px;
  }

  img {
    width: 100%;
  }
}

.order-block__content--large {
  .order-block__title {
    max-width: 400px;
  }

  .order-block__description {
    max-width: 300px;
  }
}
