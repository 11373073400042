.hero--filter {
  padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $white;
  overflow: hidden;
  min-height: calc(100vh - 150px);

  @media #{$tablet} {
    padding-top: 0;
  }

  @media #{$phone} {
    display: block;
    padding-top: 60px;
    padding-bottom: 100px;
    margin-bottom: 0;
    min-height: inherit;
  }

  .container {
    width: 100%;
    position: relative;
  }

  .slick-dots .slick-active button {
    &::before {
      animation: move-left-to-right 3.7s linear forwards;
    }
  }

  .hero-block__carousel {
    display: none;
  }

  .hero-block__carousel.slick-initialized {
    display: block;
  }

  .hero-block__carousel .slick-dots {
    justify-content: center;
  }
}

.hero-block__title {
  @include font-size(100);
  margin-bottom: 60px;
  text-align: center;
  letter-spacing: -1px;

  @media #{$large-screen} {
    @include font-size(72);
    margin-bottom: 40px;
  }

  @media #{$tablet} {
    @include font-size(48);
    margin-bottom: 20px;
  }

  @media #{$phone} {
    @include font-size(38);
    line-height: 1.2;
  }

  @media #{$phone-portrait} {
    @include font-size(28);
  }

  strong {
    color: #F42D53;
  }

  img {
    max-width: 90px;
    display: inline-block;
    margin-top: -10px;
    margin-left: 20px;
    transform: scale(0);
    vertical-align: middle;

    .slick-current & {
      animation: scaleUp 200ms cubic-bezier(0.22, -4, 0.36, 2) forwards;
    }

    @media #{$large-screen} {
      max-width: 55px;
    }

    @media #{$tablet} {
      max-width: 45px;
      margin-top: -4px;
    }

    @media #{$phone} {
      max-width: 38px;
      margin-left: 10px;
    }

    @media #{$phone-portrait} {
      max-width: 30px;
    }
  }
}

@keyframes scaleUp {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

.hero-block__subtitle {
  font-family: $alt-family;
  @include font-size(41);
  color: rgba($black, 0.8);

  @media #{$large-screen} {
    @include font-size(30);
  }

  @media #{$tablet} {
    @include font-size(28);
  }

  @media #{$phone} {
    @include font-size(26);
  }
}

.hero-block__content {
  @include font-size(24);
  max-width: 640px;
  margin: 0 auto;
  text-align: center;
  line-height: 1.7;

  @media #{$large-screen} {
    @include font-size(20);
  }

  @media #{$tablet} {
    @include font-size(18);
  }

  @media #{$phone} {
    @include font-size(16);
  }
}

.hero-block__description {
  margin-bottom: 28px;
}

.hero-block__inner {
  max-width: 1145px;
  margin: 0 auto 60px;
  width: 100%;

  @media #{$large-screen} {
    margin-bottom: 50px;
  }

  @media #{$tablet} {
    margin-bottom: 40px;
  }

  @media #{$phone} {
    margin-bottom: 30px;
  }
}

.hero-block__slide-image {
  position: relative;

  img {
    position: relative;
    z-index: 1;
  }
}

.hero-block__slide--0 .slick-dots li.slick-active button {
  border-color: #F42D53;

  &:before {
    background-color: #F42D53;
  }
}

.hero-block__slide--1 .slick-dots li.slick-active button {
  border-color: #F27E0F;

  &:before {
    background-color: #F27E0F;
  }
}

.hero-block__slide--2 .slick-dots li.slick-active button {
  border-color: #3E4649;

  &:before {
    background-color: #3E4649;
  }
}

.hero-block__slide--3 .slick-dots li.slick-active button {
  border-color: #FFC140;

  &:before {
    background-color: #FFC140;
  }
}

.hero-block__slide--4 .slick-dots li.slick-active button {
  border-color: #0A56EA;

  &:before {
    background-color: #0A56EA;
  }
}

.hero-block__slide--5 .slick-dots li.slick-active button {
  border-color: #1CE4D5;

  &:before {
    background-color: #1CE4D5;
  }
}

.hero-block__slide--6 .slick-dots li.slick-active button {
  border-color: #8852F6;

  &:before {
    background-color: #8852F6;
  }
}

.hero-block__letters {
  border-right: 5px solid $black;
  animation: blink-caret .5s step-end infinite;
  transition: all 400ms ease;
}

.hero-block__slide--0 .hero-block__letters {
  animation: blink-caret0 .5s step-end infinite;
}

.hero-block__slide--1 .hero-block__letters {
  animation: blink-caret1 .5s step-end infinite;
}

.hero-block__slide--2 .hero-block__letters {
  animation: blink-caret2 .5s step-end infinite;
}

.hero-block__slide--3 .hero-block__letters {
  animation: blink-caret3 .5s step-end infinite;
}

.hero-block__slide--4 .hero-block__letters {
  animation: blink-caret4 .5s step-end infinite;
}

.hero-block__slide--5 .hero-block__letters {
  animation: blink-caret5 .5s step-end infinite;
}

.hero-block__slide--6 .hero-block__letters {
  animation: blink-caret6 .5s step-end infinite;
}

@keyframes blink-caret0 {

  0%,
  100% {
    border-color: transparent
  }

  50% {
    border-color: #F42D53;
  }
}

@keyframes blink-caret1 {

  0%,
  100% {
    border-color: transparent
  }

  50% {
    border-color: #F27E0F;
  }
}

@keyframes blink-caret2 {

  0%,
  100% {
    border-color: transparent
  }

  50% {
    border-color: #3E4649;
  }
}

@keyframes blink-caret3 {

  0%,
  100% {
    border-color: transparent
  }

  50% {
    border-color: #FFC140;
  }
}

@keyframes blink-caret4 {

  0%,
  100% {
    border-color: transparent
  }

  50% {
    border-color: #0A56EA;
  }
}

@keyframes blink-caret5 {

  0%,
  100% {
    border-color: transparent
  }

  50% {
    border-color: #1CE4D5;
  }
}

@keyframes blink-caret6 {

  0%,
  100% {
    border-color: transparent
  }

  50% {
    border-color: #8852F6;
  }
}
