.main {
  padding-top: 109px;
  overflow: hidden;
}

.main--alt {
  padding-top: 92px;

  @media #{$phone} {
    padding-top: 70px;
  }
}

.slick-dots {
  list-style: none;
  display: flex !important;
  padding-left: 0;

  li {
    margin: 0 8px;
    display: flex;
    border-radius: 40px;
    height: 16px;

    @media #{$phone} {
      border-radius: 28px;
      height: 12px;
      margin: 0 5px;
    }

    &:first-child {
      margin-left: 0;
    }

    &.slick-active button {
      width: 32px;
      border-color: #f42d53;
      border-radius: 40px;
      position: relative;

      @media #{$phone} {
        border-radius: 28px;
        width: 26px;
      }

      &::before {
        background-color: #f42d53;
        animation: move-left-to-right 2.5s linear infinite;
      }
    }

    button {
      text-indent: -999em;
      width: 16px;
      height: 16px;
      border: 3px solid #d3d3d3;
      border-radius: 50%;
      transition: 0.3s ease;
      font-size: 0;
      position: relative;
      overflow: hidden;

      &::before {
        content: "";
        display: block;
        height: 100%;
        width: 100%;
        top: 0;
        border-radius: 40px;
        position: absolute;
      }

      @media #{$phone} {
        width: 12px;
        height: 12px;
        border: 2px solid #d3d3d3;
        overflow: hidden;
      }
    }

    &:hover button {
      border-color: #f42d53;
    }
  }
}

@-webkit-keyframes move-left-to-right {
  0% {
    transform: translateX(-26px);
  }

  100% {
    transform: translateX(0);
  }
}

@-moz-keyframes move-left-to-right {
  0% {
    transform: translateX(-26px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes move-left-to-right {
  0% {
    transform: translateX(-26px);
  }

  100% {
    transform: translateX(0);
  }
}

@-webkit-keyframes move-top-to-bottom {
  0% {
    transform: translateY(-26px);
  }

  100% {
    transform: translateY(0);
  }
}

@-moz-keyframes move-top-to-bottom {
  0% {
    transform: translateY(-26px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes move-top-to-bottom {
  0% {
    transform: translateY(-26px);
  }

  100% {
    transform: translateY(0);
  }
}

.batch-block {
  padding: 133px 0;
  margin-top: -400px;

  @media #{$tablet} {
    padding: 100px 0 70px;
  }

  @media #{$phone} {
    padding: 80px 0 50px;
  }
}

.batch-block__badge {
  border-radius: 50%;
  width: 177px;
  height: 177px;
  background-color: $black;
  color: $white;
  @include font-size(18);
  text-align: center;
  font-family: $primary-family-semibold;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: -35px;
  right: 57px;

  @media #{$phone} {
    border-radius: 30px;
    padding: 9px 20px;
    flex-direction: row;
    font-family: $primary-family-medium;
    width: auto;
    height: auto;
    top: -20px;
    right: auto;
    left: 40px;
  }

  img {
    max-width: 30px;

    @media #{$phone} {
      max-width: 20px;
      margin-left: 7px;
    }
  }

  p {
    margin-bottom: 12px;
    line-height: 1.2;

    @media #{$phone} {
      margin-bottom: 0;
    }
  }

  strong {
    text-transform: uppercase;
    font-family: $primary-family-semibold;

    @media #{$phone} {
      text-transform: none;
      font-family: $primary-family-medium;
    }
  }
}

.batch-block__title {
  @include font-size(20);
  font-family: $primary-family-bold;
  margin-bottom: 2px;
  color: $black;
}

.batch-block__inner {
  max-width: 1070px;
  margin: 0 auto;
  box-shadow: 0 50px 120px rgba(0, 0, 0, 0.05);
  padding: 47px 57px 55px 67px;
  border-radius: 15px;
  background-color: $white;
  position: relative;

  @media #{$phone} {
    padding: 53px 35px 20px 43px;
  }
}

.batch-block__description {
  max-width: 645px;
  width: 72%;
  @include font-size(20);
  color: rgba($black, 0.8);

  @media #{$phone} {
    width: 100%;
  }

  img {
    display: inline-block;
    max-width: 20px;
    margin-top: -4px;
  }
}

.progress-bar-wrapper {
  background: #ffffff;
  padding: 10px 0 55px;
  border-radius: 5px;

  .progress-bar {
    overflow-x: scroll;
    overflow-y: hidden;
    list-style-type: none;
    padding-left: 0;
    padding-top: 50px;
    -webkit-overflow-scrolling: touch;

    @media #{$tablet} {
      display: flex;
      flex-wrap: nowrap;
      overflow: auto;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.progress-bar__step-title {
  margin-top: 23px;

  .progress-bar__step-subtitle {
    @include font-size(20);
    font-family: $primary-family;
    display: block;
    margin-top: 5px;
    color: rgba($black, 0.8);
  }
}

.progress-bar li {
  float: left;
  width: 25%;
  position: relative;
  z-index: 1;
  @include font-size(24);
  font-family: $primary-family-bold;
  line-height: 1.3;
  color: rgba($black, 0.3);
  min-width: 210px;

  .progress-bar__step-date {
    @include font-size(20);
    font-family: $primary-family;
    color: rgba($black, 0.8);
    position: absolute;
    top: -40px;
    left: 0;
  }

  &.progress-bar__step--active {
    color: $black;

    &:before {
      border-color: #1ce4d5;
      background-color: #1ce4d5;
    }
  }

  @media #{$tablet} {
    flex: 0 0 auto;
    min-width: 235px;
  }
}

.progress-bar li:before {
  content: "";
  display: block;
  font-size: 10px;
  line-height: 24px;
  color: #d5dbdb;
  background: #ffffff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid $black;
}

.progress-bar li:after {
  content: "";
  width: calc(100% - 32px);
  height: 3px;
  background: #ebebeb;
  position: absolute;
  margin: auto;
  left: 26px;
  top: 9px;
  z-index: -1;
}

.progress-bar li:last-child:after {
  display: none;
}

.progress-percentage {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;

  .btn {
    @include font-size(18);
    font-family: $primary-family-semibold;
    padding: 19px 30px;
    min-width: 217px;
    text-align: center;

    @media #{$phone} {
      width: 100%;
      max-width: 100%;
    }
  }
}

.progress-percentage__inner {
  width: 65.2%;
  padding-top: 6px;
  margin-right: 30px;

  @media #{$tablet} {
    width: 57.2%;
    margin-right: 20px;
  }

  @media #{$phone} {
    width: 100%;
    margin-right: 0;
    margin-bottom: 40px;
  }
}

.progress-percentage__text {
  font-family: $primary-family-medium;
  @include font-size(20);
  color: rgba($black, 0.8);

  img {
    max-width: 20px;
    display: inline-block;
    margin-top: -6px;
  }
}

.progress-percentage__line {
  display: block;
  width: 100%;
  height: 20px;
  position: relative;
  border-radius: 20px;
  background-color: #f4f4f4;
  overflow: hidden;
  margin-bottom: 10px;
}

.progress-percentage__fill {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: $black;
  border-radius: 20px;
}

.review-carousel {
  @media #{$tablet} {
    padding-top: 50px;
  }

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;
    position: relative;
  }

  .slick-dots {
    justify-content: center;
    margin-top: -140px;

    @media #{$phone} {
      margin-top: -146px;
    }

    li {
      button {
        border-color: $primary-color;
        overflow: hidden;
        transition: ease all 150ms;
      }

      &.slick-active button {
        border-color: $primary-color;
        transition: ease all 150ms;

        &::before {
          background-color: $primary-color;
          animation: move-left-to-right 7s cubic-bezier(0.07, 0, 0.73, 1);
        }
      }

      &:hover button {
        border-color: $primary-color;
      }
    }
  }
}

.review-carousel__title {
  text-align: center;
  @include font-size(16);
  font-family: $primary-family-medium;
  max-width: 500px;
  margin: auto auto 0;

  @media #{$phone} {
    @include font-size(14);
  }

  &:before {
    content: "";
    width: 90px;
    height: 2px;
    background-color: #ebebeb;
    display: block;
    margin: 0 auto 50px;

    @media #{$phone} {
      margin: 0 auto 30px;
    }
  }

  strong {
    display: block;
    @include font-size(20);
    font-family: $primary-family-bold;
    line-height: 1.4;
    margin-bottom: 15px;

    @media #{$phone} {
      @include font-size(16);
    }
  }
}

.review-carousel__item {
  margin: 0 23px 222px;
  height: 100%;

  @media #{$tablet} {
    margin-bottom: 180px;
  }
}

.review-carousel__item-inner {
  background-color: white;
  box-shadow: 0 50px 120px rgba(0, 0, 0, 0.04);
  border-radius: 15px;
  height: 100%;
  margin: 0 auto;
  padding: 85px 40px 60px;
  display: flex;
  flex-direction: column;

  @media #{$phone} {
    padding: 35px 20px 30px;
  }

  @media #{$phone-portrait} {
    padding-top: 35px;
  }
}

.home-review--no-badge {
  .review-box__inner {
    @media #{$phone} {
      padding: 40px 0 30px;
      margin-top: 40px;
    }
  }
}

.review-carousel__item-content {
  font-family: $primary-family-medium;
  font-style: italic;
  @include font-size(28);
  text-align: center;
  quotes: "\201c""\201d";
  color: $black;
  max-width: 800px;
  margin: 0 auto;

  @media #{$tablet} {
    @include font-size(24);
    padding: 0 40px;
  }

  @media #{$phone} {
    @include font-size(18);
    padding: 0;
  }

  p {
    line-height: 1.4;
    margin-bottom: 50px;

    @media #{$phone} {
      line-height: 1.7;
      margin-bottom: 30px;
    }

    strong {
      font-family: $primary-family-medium;
      position: relative;
      background-image: -o-linear-gradient(#ddfcfa calc(100% - 10px),
          #ddfcfa 10px);
      background-image: linear-gradient(#ddfcfa calc(100% - 10px),
          #ddfcfa 10px);
      background-repeat: no-repeat;
      background-size: 100% 96%;
      background-position: bottom;
      transition: background-size 1s;
    }

    .underline {
      position: relative;
      display: inline-block;

      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 22px;
        background: url("assets/images/under-line.svg") no-repeat 0 65%;
        background-size: contain;

        .home-review--red & {
          background: url("assets/images/underline-red.svg") no-repeat 0 65%;
          background-size: contain;
        }

        @media #{$phone-portrait} {
          top: 12px;
        }
      }
    }
  }

  p:before,
  p:after {
    font-family: $alt-family;
    @include font-size(70);
    color: $primary-color;
    line-height: 1;

    .home-review--red & {
      color: #f42d53;
    }

    @media #{$phone} {
      @include font-size(40);
    }
  }

  p:before {
    content: open-quote;
    left: -15px;
    position: relative;
    top: 8px;

    @media #{$phone} {
      left: -8px;
      top: 8px;
    }
  }

  p:after {
    content: close-quote;
    height: 0;
    display: inline-block;
    margin-top: -22px;
    line-height: 1;
    position: relative;
    top: 22px;
    right: -22px;

    @media #{$phone} {
      top: 12px;
      right: -8px;
    }
  }

  footer {
    font-family: $alt-family;
    line-height: 1.2;
    @include font-size(32);
    color: #878787;

    @media #{$phone} {
      @include font-size(24);
      line-height: 1;
    }
  }
}

.shipping-block {
  margin-top: -215px;
  padding-bottom: 143px;

  @media #{$phone} {
    margin-top: -155px;
    padding-bottom: 110px;
  }
}

.shipping-block__inner {
  max-width: 884px;
  margin: 0 auto;
  background-color: $white;
  padding: 70px 100px;
  @include font-size(22);
  text-align: center;
  box-shadow: 0px 50px 120px rgba(0, 0, 0, 0.04);
  border-radius: 15px;
  position: relative;

  @media #{$tablet} {
    padding: 80px 30px 60px;
  }
}

.shipping-block__content {
  margin-bottom: 60px;
  line-height: 1.6;

  @media #{$tablet} {
    @include font-size(18);
    margin-top: 15px;
  }
}

.hero__badge {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  background-color: #262626;
  color: $white;
  @include font-size(15);
  text-align: center;
  font-family: $primary-family;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: -55px;
  right: -30px;
  z-index: 1;
  transition: opacity 0.2s ease;

  @media #{$tablet} {
    width: 115px;
    height: 115px;
    right: auto;
    left: calc(50% - 60px);
  }

  @media #{$phone-portrait} {
    top: -60px;
    width: 115px;
    height: 115px;
    left: calc(50% - 57px);
    padding: 5px;
  }

  img {
    max-width: 12px;
    display: inline;
    margin-top: -2px;
  }

  p {
    margin-bottom: 0;
    line-height: 1.5;
    padding: 0 40px;
    @include font-size(12);

    @media #{$tablet} {
      @include font-size(10);
    }
  }

  strong {
    display: block;
    text-transform: uppercase;
    @include font-size(14);
    line-height: 1.3;
    font-family: $primary-family-semibold;
    margin-bottom: 3px;

    @media #{$tablet} {
      @include font-size(12);
    }
  }
}

.hero__badge--large {
  width: 177px;
  height: 177px;
  transform: rotate(10deg);
  left: 64%;

  @media #{$desktop} {
    left: 70%;
  }

  @media #{$tablet} {
    left: calc(50% + 200px);
  }

  @media #{$phone} {
    display: none;
    left: auto !important;
    right: 10px !important;
  }

  @media #{$phone-portrait} {
    width: 95px;
    height: 95px;
  }

  p {
    @include font-size(15);
  }

  strong {
    @include font-size(18);
  }
}

.review-carousel__item-image {
  margin-top: auto;
  text-align: center;

  @media #{$phone} {
    max-width: 150px;
    margin: auto auto 0;
  }

  img {
    display: inline-block;
  }
}

.thumbnail-block {
  margin: 40px 80px 70px;
  // margin-top: -270px;
  box-shadow: 0 32px 44px rgba(0, 0, 0, 0.0538134);
  border-radius: 15px;
  overflow: hidden;
  color: $white;
  position: relative;
  z-index: 10;

  @media #{$tablet} {
    width: 100%;
    // margin: -270px 0 0;
    margin: 0;
    border-radius: 0;
  }

  @media #{$phone} {
    margin-top: 0;
  }
}

.thumbnail-block__image-container {
  border-radius: 15px;
  overflow: hidden;
  position: relative;

  @media #{$tablet} {
    min-height: auto;
    border-radius: 0;
  }

  @media #{$phone} {
    background: none !important;
    border-radius: 0;
  }
}

.thumbnail-block__image {
  box-shadow: 0 12px 50px rgba(0, 0, 0, 0.08);
}

.thumbnail-block__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;

  @media #{$tablet} {
    position: static;
    background-color: $primary-color;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 35px;
    left: 0;
    width: 100%;
    height: calc(100% - 35px);
    background: rgba(49, 63, 61, 0.5);
    border-radius: 0 0 15px 15px;

    @media #{$tablet} {
      display: none;
    }
  }
}

.thumbnail-block__overlay-inner {
  max-width: 1114px;
  padding: 0 20px;
  margin: 0 auto;
  position: relative;
  z-index: 1;

  @media #{$tablet} {
    margin: -35px auto 0;
  }

  .btn--white {
    padding: 22px 30px 22px 25px;

    svg {
      margin-right: 12px;
    }
  }
}

.thumbnail-block__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 45px 0 80px;

  @media #{$tablet} {
    padding: 45px 0 60px;
  }

  @media #{$phone-portrait} {
    max-width: 290px;
  }
}

.thumbnail-block__title {
  @include font-size(46);
  width: 35.3%;
  line-height: 1.1;

  @media #{$large-screen} {
    width: 29%;
    line-height: 1.2;
  }

  @media #{$tablet} {
    @include font-size(36);
    width: 100%;
  }

  @media #{$phone} {
    @include font-size(32);
    padding-right: 30px;
    line-height: 1.2;
  }

  >span {
    white-space: nowrap;
    text-transform: uppercase;
    font-style: italic;

    sup {
      font-style: normal;
      font-family: $primary-family;
      font-size: 40%;
      top: -1.2em;
      margin-left: 7px;
    }
  }
}

.thumbnail-block__description {
  width: 53.5%;
  @include font-size(26);
  padding-top: 5px;

  @media #{$large-screen} {
    @include font-size(22);
    width: 58.5%;
    line-height: 1.55;
    padding-right: 40px;
  }

  @media #{$tablet} {
    width: 100%;
    padding-right: 0;
  }

  @media #{$phone} {
    @include font-size(20);
    line-height: 1.3;
  }
}

.video-block {
  padding-top: 150px;
  position: relative;
  overflow: hidden;

  @media #{$large-screen} {
    padding-top: 165px;
  }

  @media #{$tablet} {
    padding-top: 100px;
  }

  @media #{$phone} {
    padding-top: 60px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .container {
    z-index: 2;
  }
}

.video-block__content {
  max-width: 860px;
  margin: 0 auto;
  text-align: center;
  @include font-size(32);

  @media #{$large-screen} {
    @include font-size(20);
    max-width: 620px;
  }

  @media #{$phone} {
    @include font-size(16);
    text-align: left;
  }
}

.video-block__title {
  margin-bottom: 96px;

  @media #{$large-screen} {
    margin-bottom: 70px;
    padding: 0 10px;
  }

  @media #{$phone} {
    margin-bottom: 40px;
    @include font-size(28);
    padding: 0 10px;
  }
}

.video-block__subtitle {
  font-family: $primary-family-medium;
  line-height: 1.3;
  margin-bottom: 35px;

  @media #{$large-screen} {
    @include font-size(28);
  }

  @media #{$phone} {
    @include font-size(20);
    padding: 0 4px;
  }
}

.video-wrapper {
  position: relative;
  margin-top: -60px;

  @media #{$tablet} {
    margin-top: -20px;
  }

  @media #{$phone} {
    margin-top: 0;
    order: -1;
    width: 100%;
  }

  video {
    width: 100%;
    display: block;
  }
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;
  overflow: hidden;

  @media #{$phone} {
    height: auto;
    overflow: visible;
    padding-bottom: 0;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;

    @media #{$phone} {
      position: relative;
      left: -25%;
      height: 150%;
      width: 150%;
    }
  }
}

.feature-block {
  padding-top: 320px;
  overflow: hidden;

  @media #{$large-screen} {
    padding-bottom: 90px;
    padding-top: 180px;
  }

  @media #{$tablet} {
    padding-top: 100px;
    margin-bottom: 0;
  }

  @media #{$phone} {
    padding-top: 60px;
    padding-bottom: 30px;
  }

  .container {
    max-width: 1215px;
  }
}

.feature-block--top-gap {
  @media #{$large-screen} {
    padding-top: 320px;
  }

  @media #{$desktop} {
    padding-top: 270px;
  }

  @media #{$tablet} {
    padding-top: 160px;
  }

  @media #{$phone} {
    padding-top: 60px;
  }
}

.feature-block__item {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 300px;

  @media #{$large-screen} {
    align-items: center;
    margin-bottom: 40px;
  }

  @media #{$phone} {
    margin-bottom: 30px;
  }
}

.feature-block--reverse {
  flex-direction: row-reverse;
}

.feature-block__item--video .feature-block__content {
  left: -60px;
  position: relative;

  @media #{$desktop} {
    left: 0;
  }
}

.feature-block__content {
  max-width: 600px;
  width: 51%;
  @include font-size(32);

  @media #{$large-screen} {
    width: 44%;
    @include font-size(20);
    line-height: 1.6;
  }

  @media #{$tablet} {
    width: 100%;
    max-width: 100%;
  }

  @media #{$phone} {
    @include font-size(16);
    border-bottom: 3px solid #ebebeb;
    padding-bottom: 20px;
  }
}

.feature-block__title {
  margin-bottom: 55px;

  @media #{$large-screen} {
    margin-bottom: 35px;
  }

  @media #{$tablet} {
    @include font-size(38);
  }

  @media #{$phone} {
    @include font-size(28);
    line-height: 1.3;
  }

  .strikethrough {
    position: relative;
    display: inline-block;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: url("assets/images/curve-line.png") no-repeat 0 65%;
      background-size: contain;
    }
  }

  img {
    max-width: 65px;
    margin-top: -10px;

    @media #{$large-screen} {
      max-width: 48px;
    }

    @media #{$tablet} {
      max-width: 38px;
    }

    @media #{$phone} {
      max-width: 28px;
    }
  }
}

.feature-block__subtitle {
  font-family: $primary-family-medium;
  line-height: 1.3;
  margin-bottom: 50px;

  @media #{$large-screen} {
    @include font-size(28);
  }

  @media #{$phone} {
    @include font-size(20);
    margin-bottom: 25px;
    line-height: 1.4;
  }
}

.feature-block__image {
  max-width: 571px;
  width: 48.6%;
  position: relative;
  min-height: 640px;

  @media #{$tablet} {
    width: 100%;
    margin-bottom: 30px;
    min-height: auto;
  }

  @media #{$phone} {
    padding-right: 100px;
  }

  @media #{$phone-portrait} {
    padding-right: 70px;
  }

  img {
    position: absolute;
    top: 0;
    max-width: 640px;

    @media #{$tablet} {
      max-width: 100%;
      position: static;
    }
  }

  video {
    max-width: 570px;
    width: 100%;
    height: auto;

    @media #{$tablet} {
      max-width: 470px;
    }

    @media #{$phone} {
      max-width: 100%;
    }
  }
}

.feature-block__description {
  margin-bottom: 20px;
  color: rgba($black, 0.8);
}

.feature-block--large-image {
  position: relative;
  left: 100px;
  margin-bottom: 355px;

  @media #{$large-screen} {
    align-items: flex-end;
    margin-bottom: 285px;
  }

  @media #{$desktop} {
    left: 0;
  }

  @media #{$tablet} {
    left: 0;
    margin-bottom: 70px;
  }

  @media #{$phone} {
    margin-bottom: 38px;
  }

  .feature-block__content {
    padding-top: 105px;
  }

  .feature-block__subtitle {
    margin-bottom: 70px;

    @media #{$phone} {
      margin-bottom: 50px;
    }
  }

  .feature-block__title {
    padding-right: 30px;
  }

  .feature-block__image {
    width: 48%;
    position: relative;
    min-height: 836px;

    @media #{$tablet} {
      width: 100%;
      margin-bottom: 30px;
      min-height: inherit;
      padding-right: 0;
    }

    img {
      position: absolute;
      right: 100px;
      max-width: initial;
      height: 100%;
      width: auto;

      @media #{$tablet} {
        position: static;
        max-width: 100%;
        height: auto;
        width: auto;
      }
    }
  }

  .feature-block__content {
    width: 42%;
    padding-top: 105px;

    @media #{$tablet} {
      width: 100%;
      padding-top: 0;
    }

    @media #{$phone} {
      padding-bottom: 50px;
    }

    &--last {
      @media #{$phone} {
        padding-bottom: 0;
        border-bottom: 0;
      }
    }
  }
}

.feature-block--square-image {
  margin-bottom: 50px;

  @media #{$phone} {
    margin-bottom: 0;
  }

  .feature-block__content {
    @media #{$large-screen} {
      @include font-size(28);
    }

    @media #{$tablet} {
      @include font-size(20);
    }
  }

  .feature-block__image {
    width: 45.5%;
    position: relative;
    min-height: 750px;

    @media #{$tablet} {
      width: 100%;
      margin-bottom: 30px;
      min-height: inherit;
      padding-right: 0;
    }

    @media #{$phone} {
      padding: 0 30px;
    }

    img {
      position: absolute;
      left: 0;
      top: -110px;
      max-width: initial;
      height: 100%;
      width: auto;

      @media #{$tablet} {
        position: static;
        max-width: 100%;
        height: auto;
        width: auto;
      }
    }
  }

  .feature-block__content {
    width: 47.5%;
    margin-bottom: 200px;
    left: -140px;
    position: relative;

    .link-arrow {
      @media #{$phone} {
        display: none;
      }
    }

    @media #{$large-screen} {
      max-width: 394px;
    }

    @media #{$desktop} {
      left: 0;
    }

    @media #{$tablet} {
      width: 100%;
      max-width: 100%;
      @include font-size(22);
      margin-bottom: 50px;
    }

    @media #{$phone} {
      @include font-size(20);
      border: none;
      margin-bottom: 28px;
      max-width: 100%;
    }
  }

  .feature-block__description {
    max-width: 310px;
    position: relative;

    .link-arrow {
      position: absolute;
      top: 0;
      right: -30px;
    }
  }
}

.link-arrow {
  color: $black;
  transition: 0.3s ease;

  &:hover {
    color: $primary-color;
  }

  svg {
    width: 46px;
    height: 23px;
  }
}

.highlighted-block {
  color: $white;
  padding: 0 40px;

  @media #{$large-screen} {
    padding: 0 20px;
  }

  @media #{$tablet} {
    padding: 0;
  }

  .container {
    max-width: 1330px;
  }

  .slick-dots li.slick-active button {
    &::before {
      animation: move-left-to-right 8.5s linear infinite;
    }
  }
}

.highlighted-block__inner {
  padding-top: 132px;
  border-radius: 15px;
  color: $white;
  transition: background-color 0.1s ease;
  position: relative;
  z-index: 1;

  @media #{$large-screen} {
    padding-top: 65px;
  }

  @media #{$tablet} {
    border-radius: 0;
  }

  @media #{$phone} {
    padding-top: 50px;
    padding-bottom: 53px;
  }
}

.highlighted-block__background-carousel {
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  border-radius: 15px;
  z-index: 1;

  @media #{$tablet} {
    border-radius: 0;
  }

  .slick-list,
  .slick-track,
  .highlighted-block__background-slide {
    height: 100%;
    border-radius: 15px;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    @media #{$tablet} {
      border-radius: 0;
    }
  }
}

.highlighted-block__background-slide--bg-1 {
  background-color: #f42d53;
}

.highlighted-block__background-slide--bg-2 {
  background-color: #f27e0f;
}

.highlighted-block__background-slide--bg-3 {
  background-color: #ffc140;
}

.highlighted-block__background-slide--bg-4 {
  background-color: #8852f6;
}

.highlighted-block__background-slide--bg-5 {
  background-color: #3e4649;
}

.highlighted-block__background-slide--bg-6 {
  background-color: #1ce4d5;
}

.highlighted-block__background-slide--bg-7 {
  background-color: #0a56ea;
}

.highlighted-block__intro {
  text-align: center;
  max-width: 850px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  z-index: 4;

  @media #{$large-screen} {
    margin-top: 0;
    max-width: 570px;
  }

  @media #{$desktop} {
    max-width: 710px;
  }

  @media #{$phone} {
    margin-top: 10px;
  }

  @media #{$phone-portrait} {
    max-width: 320px;
  }
}

.highlighted-block__intro-content {
  @include font-size(32);
  color: rgba(#fff3f6, 0.9);
  line-height: 1.45;

  @media #{$large-screen} {
    @include font-size(20);
    line-height: 1.6;
  }

  @media #{$phone} {
    margin-bottom: 20px;
    @include font-size(16);
  }
}

.highlighted-block__intro-title {
  margin-bottom: 38px;
  line-height: 1.2;

  @media #{$large-screen} {
    margin-bottom: 27px;
  }

  @media #{$phone} {
    @include font-size(28);
    padding: 0 24px;
  }
}

.highlighted-block__carousel {
  max-width: 2500px;
  margin: -225px auto 0;
  position: relative;
  z-index: 2;

  @media #{$large-screen} {
    margin-top: -225px;
  }

  @media #{$tablet} {
    margin-top: 0;
  }

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;

    &.slick-current {
      position: relative;
      z-index: 9;
    }
  }

  .slick-dots {
    margin-top: 33px;
    justify-content: center;

    li {
      margin: 0 5px;
    }
  }

  .slick-dots li.slick-active button {
    border-color: #fff;

    &:before {
      background-color: #fff;
    }
  }

  .slick-dots li:hover button {
    border-color: #ffffff;
  }
}

.highlighted-block__slider-arrow {
  @media screen and (min-width: 1365px) {
    position: absolute;
    bottom: 140px;
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    z-index: 3;
  }

  @media screen and (min-width: 2580px) {
    max-width: 2500px;
    left: calc(50% - 1250px);
  }

  @media #{$large-screen} {
    position: absolute;
    bottom: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 3;
  }

  @media #{$phone} {
    display: none;
  }

  .highlighted-block__arrow-inner {
    @media screen and (min-width: 1365px) {
      width: 58%;
      margin-left: 6%;
      max-width: 800px;
    }

    @media #{$large-screen} {
      margin-left: 6%;
      max-width: 630px;
      width: 58%;
    }
  }

  .highlighted-block__arrow-space {
    max-width: 1405px;
    width: 29%;

    @media #{$large-screen} {
      width: 33%;
    }
  }

  .slick-arrow {
    position: absolute;
    bottom: 130px;
    width: 45px;
    height: 45px;
    color: #fff;
    z-index: 1;
    transition: 0.3s ease;

    @media #{$large-screen} {
      position: static;
      margin-right: 5px;
    }

    @media #{$phone} {
      bottom: -65px;
    }

    svg {
      width: 45px;
      height: 45px;
    }

    &.slick-disabled {
      color: $black;
      opacity: 0.15;
    }
  }

  .highlighted-block__next-button {
    left: calc(16% + 58px);

    @media screen and (min-width: 1365px) {
      left: 5px;
      position: relative;
      bottom: 0;
    }

    @media #{$phone} {
      right: 20px;
      left: auto;
    }
  }

  .highlighted-block__prev-button {
    left: 16%;

    @media screen and (min-width: 1365px) {
      left: 0;
      position: relative;
      bottom: 0;
    }

    @media #{$phone} {
      right: 76px;
      left: auto;
    }
  }
}

.highlighted-block__item {
  position: relative;
  display: flex !important;
  flex-wrap: wrap;
  align-items: flex-end;
  overflow: hidden;
  border-radius: 15px;
  justify-content: center;

  @media #{$phone-portrait} {
    justify-content: flex-end;
  }
}

.highlighted-block__item-card {
  width: 71.3%;
}

.highlighted-block__item-image {
  max-width: 1405px;
  height: 936px;
  width: 29%;
  position: relative;
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.7, 0, 0.3, 1);

  .slick-active & {
    opacity: 1;
  }

  @media #{$large-screen} {
    min-height: 700px;
    height: auto;
    width: 33%;
  }

  @media #{$tablet} {
    min-height: inherit;
  }

  @media #{$phone} {
    display: none;
  }

  img {
    position: absolute;
    bottom: 0;
    left: -100px;

    @media screen and (max-width: 1600px) {
      height: auto;
      left: -50px;
    }

    @media #{$phone} {
      position: static;
      width: 100%;
      min-width: inherit;
    }

    @media #{$phone-portrait} {
      min-width: inherit;
      height: 100%;
      width: auto;
      max-width: inherit;
    }
  }

  @media #{$phone} {
    height: auto;
    width: 100%;
    padding-top: 45px;
    left: 150px;
    min-height: inherit;
  }

  @media #{$phone-portrait} {
    max-width: 320px;
    width: 100%;
    left: 0;
    height: 258px;
  }
}

.highlighted-block__item-image--1 {
  img {
    min-width: 744px;
    left: -80px;

    @media #{$large-screen} {
      left: -30px;
    }

    @media #{$desktop} {
      min-width: 560px;
      left: -30px;
    }

    @media #{$tablet} {
      min-width: 500px;
    }

    @media screen and (max-width: 850px) {
      min-width: 460px;
    }
  }
}

.highlighted-block__item-image--2 {
  img {
    left: 133px;
    min-width: 510px;
    bottom: -10px;

    @media #{$desktop} {
      left: 30px;
      min-width: 400px;
    }

    @media #{$tablet} {
      left: -20px;
      min-width: 380px;
    }
  }
}

.highlighted-block__item-image--3 {
  img {
    left: 140px;
    min-width: 501px;

    @media #{$desktop} {
      left: 20px;
      min-width: 400px;
    }

    @media #{$tablet} {
      left: -20px;
      min-width: 380px;
    }
  }
}

.highlighted-block__item-image--4 {
  img {
    left: 100px;
    min-width: 604px;

    @media #{$desktop} {
      left: 10px;
      min-width: 450px;
    }

    @media #{$tablet} {
      min-width: 400px;
    }
  }
}

.highlighted-block__item-image--5 {
  img {
    left: -25px;
    min-width: 793px;

    @media #{$desktop} {
      min-width: 600px;
      left: -30px;
    }

    @media #{$tablet} {
      min-width: 590px;
    }

    @media screen and (max-width: 850px) {
      min-width: 490px;
    }
  }
}

.highlighted-block__item-image--6 {
  img {
    left: 210px;
    min-width: 380px;
    max-width: 380px;

    @media #{$desktop} {
      left: 60px;
      width: 290px;
      min-width: inherit;
    }

    @media #{$tablet} {
      left: 10px;
      min-width: 280px;
    }
  }
}

.highlighted-block__item-image--7 {
  img {
    left: 105px;
    min-width: 633px;

    @media #{$desktop} {
      left: 10px;
      min-width: 520px;
    }

    @media #{$tablet} {
      left: -20px;
      min-width: 450px;
    }
  }
}

.content-card {
  background-color: $white;
  color: $black;
  border-radius: 15px;
  text-align: left;
  max-width: 800px;
  box-shadow: 0 32px 44px rgba(0, 0, 0, 0.0538134);
  margin: 285px 0 210px 6%;
  min-height: 540px;
  width: 58%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.7, 0, 0.3, 1);

  .slick-active & {
    opacity: 1;
  }

  @media #{$large-screen} {
    min-height: 405px;
    max-width: 630px;
    margin: 285px 0 115px 6%;
  }

  @media #{$tablet} {
    margin-top: 120px;
  }

  @media #{$phone} {
    margin: 20px auto 0;
    width: calc(100% - 40px);
    min-height: 440px;
  }

  @media #{$phone-portrait} {
    max-width: 310px;
  }

  .btn {
    @include font-size(18);
    border: none !important;
    padding: 16px 40px;
    margin: 360px auto 0;
    display: block;
    width: 180px;
    text-align: center;

    @media #{$large-screen} {
      @include font-size(16);
      padding: 13px 30px;
      width: 150px;
      margin-top: 308px;
    }

    @media #{$tablet} {
      padding: 13px 20px;
      @include font-size(14);
      width: 130px;
      text-align: center;
      margin-top: 0;
      position: absolute;
      bottom: 60px;
    }

    @media #{$phone} {
      position: static;
    }

    @media #{$phone-portrait} {
      @include font-size(16);
      width: 150px;
    }
  }
}

.content-card__detail {
  width: 62%;
  padding: 60px 50px 65px 65px;

  @media #{$large-screen} {
    width: 62%;
    padding: 60px 40px 45px 50px;
  }

  @media #{$tablet} {
    padding: 55px 40px;
  }

  @media #{$phone} {
    width: 100%;
    padding: 53px 45px 30px;
  }
}

.content-card__image {
  max-width: 334px;
  left: -99px;
  position: absolute;
  top: 45px;

  @media #{$large-screen} {
    margin: -20px 0 27px;
    max-width: 289px;
    top: 35px;
  }

  @media #{$tablet} {
    max-width: 189px;
    left: -50px;
  }

  @media #{$phone} {
    margin: 0 auto 17px;
    top: 0;
    left: -20px;
    position: relative;
  }

  @media #{$phone-portrait} {
    margin: 0 auto 10px;
  }
}

.content-card__list-wrap {
  background-color: rgba(#8a8a8a, 0.1);
  width: 38%;
  padding: 0 30px 50px;
  position: relative;

  @media #{$large-screen} {
    width: 38%;
    padding: 0 30px 40px;
  }

  @media #{$tablet} {
    padding: 0 20px 30px;
  }

  @media #{$phone} {
    width: 100%;
    padding: 20px 45px 35px;
  }
}

.content-card__title {
  @include font-size(46);
  max-width: 385px;
  transition: color 0.1s ease;

  @media #{$large-screen} {
    @include font-size(28);
    line-height: 1.3;
    margin-bottom: 15px;
  }

  @media #{$tablet} {
    @include font-size(24);
  }

  @media #{$phone} {
    @include font-size(28);
  }

  img {
    max-width: 46px;
    margin-top: -10px;
    display: inline-block;
    vertical-align: middle;

    @media #{$large-screen} {
      max-width: 28px;
    }
  }
}

.content-card__subtitle {
  font-family: $primary-family-medium;
  @include font-size(26);
  max-width: 370px;
  margin-bottom: 35px;

  @media #{$large-screen} {
    @include font-size(20);
    margin-bottom: 30px;
    max-width: 230px;
  }

  @media #{$tablet} {
    @include font-size(18);
  }

  @media #{$phone} {
    @include font-size(20);
  }

  @media #{$phone-portrait} {
    margin-bottom: 20px;
  }
}

.content-card__column-layout {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.content-card__description {
  @include font-size(22);
  line-height: 1.5;

  @media #{$large-screen} {
    @include font-size(18);
  }

  @media #{$tablet} {
    width: 100%;
    @include font-size(16);
    max-width: 500px;
  }

  @media #{$phone} {
    padding-right: 0;
  }
}

.content-card__list {
  list-style: none;
  padding-top: 27px;
  margin: 0 auto 40px;
  padding-left: 0;
  width: 170px;

  @media #{$large-screen} {
    padding-top: 7px;
    padding-left: 30px;
    width: auto;
  }

  @media #{$tablet} {
    width: 100%;
    padding-left: 0;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  @media #{$phone} {
    width: 100%;
    padding-left: 0;
    margin-top: 20px;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    border-left: 0;
    border-top: 3px solid #000;
    padding-top: 30px;
  }

  @media #{$phone-portrait} {
    margin-top: 0;
    border-top: none;
    padding-top: 0;
  }

  li {
    margin-bottom: 22px;

    @media #{$phone} {
      margin-bottom: 18px;
      width: 100%;
    }

    @media #{$phone-portrait} {
      margin-bottom: 30px;
      width: 48%;
    }

    &:last-child {
      margin-bottom: 10px;
      width: 100%;
    }
  }

  strong {
    line-height: 1.3;
    font-family: $primary-family-medium;
    @include font-size(20);
    display: block;

    @media #{$large-screen} {
      @include font-size(16);
    }

    @media #{$phone} {
      @include font-size(15);
    }

    @media #{$phone-portrait} {
      @include font-size(16);
    }
  }
}

.review-column {
  padding: 108px 0;
  position: relative;
  z-index: 1;
  background: -moz-linear-gradient(top,
      rgba(0, 0, 0, 0) 0%,
      rgba(196, 196, 196, 0.05) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top,
      rgba(0, 0, 0, 0) 0%,
      rgba(196, 196, 196, 0.05) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(196, 196, 196, 0.05) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#c4c4c4', GradientType=0);

  /* IE6-9 */
  @media #{$tablet} {
    padding: 80px 0;
  }

  @media #{$phone} {
    padding-bottom: 60px;
  }

  @media #{$phone-portrait} {
    padding-top: 290px;
  }

  &:before {
    content: "";
    display: block;
    width: 449px;
    height: 598px;
    background: url("assets/images/blue-love-balloon.png") no-repeat 0 0;
    background-size: cover;
    position: absolute;
    top: 130px;
    left: -102px;

    @media #{$small-screen} {
      width: 309px;
      height: 428px;
    }

    @media #{$tablet} {
      display: block;
      background: url("assets/images/blue-love-balloon-small.png") no-repeat 0 0;
      background-size: cover;
      width: 263px;
      height: 251px;
      top: -43px;
      left: -90px;
    }

    @media #{$phone} {
      width: 223px;
      height: 221px;
      top: -83px;
      left: -70px;
    }

    @media #{$phone-portrait} {
      width: 363px;
      height: 371px;
      top: -3px;
    }
  }

  &:after {
    content: "";
    display: block;
    width: 246px;
    height: 599px;
    background: url("assets/images/yellow-love-balloon.png") no-repeat 0 0;
    background-size: cover;
    position: absolute;
    top: -130px;
    right: 0;

    @media #{$small-screen} {
      width: 156px;
      height: 409px;
      top: -50px;
    }

    @media #{$tablet} {
      display: none;
    }
  }
}

.review-column__quote {
  max-width: 875px;
  margin: 0 auto;
  padding: 80px 0 170px;

  @media #{$large-screen} {
    padding: 30px 0 60px;
  }

  @media #{$phone} {
    padding: 0 0 30px;
  }
}

.review-column__content {
  font-family: $primary-family-semibold;
  @include font-size(34);
  margin-bottom: 30px;
  text-align: center;
  quotes: "\201c""\201d";
  color: #010101;

  @media #{$tablet} {
    @include font-size(30);
    padding: 0 40px;
  }

  @media #{$phone} {
    @include font-size(24);
    padding: 0;
  }

  @media #{$phone-portrait} {
    @include font-size(20);
    font-family: $primary-family-medium;
  }

  p {
    line-height: 1.4;
    margin-bottom: 17px;
  }

  p:before,
  p:after {
    font-family: $alt-family;
    @include font-size(70);
    color: $primary-color;
    line-height: 1;

    @media #{$phone} {
      @include font-size(44);
    }
  }

  p:before {
    content: open-quote;
    left: -15px;
    position: relative;
    top: 8px;

    @media #{$phone} {
      left: -5px;
      top: 8px;
    }
  }

  p:after {
    content: close-quote;
    height: 0;
    display: inline-block;
    margin-top: -22px;
    line-height: 1;
    position: relative;
    top: 22px;
    right: -22px;

    @media #{$phone} {
      top: 11px;
      right: -6px;
    }
  }

  footer {
    font-family: $alt-family;
    line-height: 1.2;
    @include font-size(44);
    color: $black;

    @media #{$phone} {
      @include font-size(28);
      line-height: 1;
    }
  }
}

.review-column__grid {
  .container {
    @media screen and (max-width: 600px) {
      padding: 0;
    }
  }
}

.review-column__grid-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;

  @media #{$tablet} {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 600px) {
    margin-bottom: 62px;
  }

  .slick-list {
    width: 100%;
  }

  .slick-track {
    padding-bottom: 60px;
    display: flex;

    @media screen and (max-width: 600px) {
      padding-bottom: 30px;
    }
  }

  .slick-dots {
    justify-content: center;
    width: 100%;

    li {
      button {
        transition: ease all 150ms;
      }

      &.slick-active button {
        border-color: #494949;
        transition: ease all 150ms;

        &::before {
          background-color: #494949;
          animation: move-left-to-right 6.5s cubic-bezier(0.07, 0, 0.73, 1);
        }
      }

      &:hover button {
        border-color: #494949;
      }
    }
  }
}

.review-block__grid-item {
  position: relative;
  z-index: 2;
  padding: 0 15px;
  height: inherit !important;

  @media #{$tablet} {
    width: 100%;
    margin-bottom: 20px;
  }

  .review-block__content {
    @include font-size(18);
    word-break: break-word;

    @media #{$phone} {
      @include font-size(20);
      color: rgba($black, 0.8);
    }
  }

  .review-block__item-inner {
    @media screen and (max-width: 600px) {
      height: 100%;
    }
  }

  .review-block__item-inner:hover,
  .review-block__item-inner:hover .review-block__profile-image {
    transform: scale(1);
    box-shadow: none;
  }

  .review-block__item-inner:hover .review-block__item-bg {
    height: 100%;
  }
}

.review-block__grid-item--1 {
  z-index: 3;
}

.review-block__grid-item--2 {
  z-index: 2;
}

.review-block__grid-item--3 {
  z-index: 1;
}

.review-column__btn {
  text-align: center;

  @media #{$phone-portrait} {
    padding: 0 20px;
  }

  .btn {
    padding: 27px 73px;
    max-width: inherit;

    @media #{$phone-portrait} {
      width: 100%;
      text-align: center;
      padding: 15px 30px;
    }
  }
}

.app-block {
  padding: 220px 0 285px;
  position: relative;

  @media #{$large-screen} {
    padding-top: 130px;
    padding-bottom: 170px;
  }

  @media #{$tablet} {
    padding: 80px 0 100px;
  }

  @media #{$phone} {
    padding: 60px 0 85px;
  }

  .container {
    max-width: 1425px;
  }
}

.app-block__intro {
  max-width: 1030px;
  margin: 0 auto;
  text-align: center;
  @include font-size(32);

  @media #{$large-screen} {
    @include font-size(20);
    max-width: 700px;
    line-height: 1.6;
  }

  @media #{$phone} {
    @include font-size(16);
    max-width: 310px;
  }
}

.app-block__title {
  margin-bottom: 65px;

  @media #{$large-screen} {
    margin-bottom: 32px;
  }

  @media #{$tablet} {
    margin-bottom: 45px;
  }

  @media #{$phone} {
    margin-bottom: 30px;
    @include font-size(28);
    padding: 0 20px;
  }
}

.app-block__carousel-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 100px;

  @media #{$tablet} {
    margin-top: 45px;
  }

  @media #{$phone} {
    margin-top: 30px;
  }
}

.app-block__image-carousel {
  width: 41.2%;

  @media #{$tablet} {
    width: 100%;
  }
}

.app-block__image-slide {
  @media #{$tablet} {
    padding-right: 120px;
  }

  @media #{$phone} {
    padding-right: 100px;
  }

  @media #{$phone-portrait} {
    padding-right: 70px;
  }

  img {
    @media #{$tablet} {
      max-width: 571px;
      margin: 0 auto;
      width: 100%;
    }
  }
}

.app-block__image {
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.7, 0, 0.3, 1);

  .slick-active & {
    opacity: 1;
  }
}

.app-block__card-wrapper {
  width: 43.5%;
  margin-left: 103px;
  padding-top: 40px;

  @media screen and (max-width: 1200px) {
    width: 53.5%;
    margin-left: 0;
  }

  @media #{$tablet} {
    width: 100%;
    margin-left: 0;
  }

  @media #{$phone} {
    padding-top: 0;
  }
}

.app-block__card-carousel {
  margin-bottom: 32px;

  @media #{$phone} {
    margin-bottom: 0;
  }

  .slick-track {
    @media #{$tablet} {
      display: flex;
    }
  }

  .slick-slide {
    width: 100% !important;

    &.slick-cloned,
    &.slick-active,
    &.slick-current {
      .app-block__card-slide {
        animation: fadeIn 1s ease-in-out forwards;
      }
    }

    &.slick-current {
      .app-block__card-slide {
        border-color: transparent !important;
        box-shadow: 0 12px 50px rgba(0, 0, 0, 0.08);
        border-color: transparent;
      }

      .progress-bar {
        opacity: 1;

        &:before {
          animation: move-left-to-right 3s ease-in-out forwards;
        }
      }
    }

    &.slick-cloned {
      @media #{$phone} {
        .app-block__card-slide {
          animation: fadeIn 1s ease-in-out forwards;
        }

        .app-block__card-slide {
          border-color: transparent !important;
          box-shadow: 0 12px 50px rgba(0, 0, 0, 0.08);
          border-color: transparent;
        }

        .progress-bar {
          opacity: 1;

          &:before {
            animation: move-left-to-right 3s ease-in-out infinite;
          }
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.app-block__card-slide {
  background: #ffffff;
  border-radius: 15px;
  padding: 38px 40px 45px;
  border: 2px solid #e6e6e6 !important;
  cursor: pointer;
  position: relative;
  margin: 8px 40px;
  transition: all 0.3s ease;
  height: 210px !important;
  position: relative;
  width: auto !important;
  opacity: 0;
  animation: fadeOut 1s ease-in-out forwards;

  @media #{$large-screen} {
    height: 200px !important;
  }

  @media #{$tablet} {
    margin: 40px 8px;
    height: inherit !important;
  }

  @media #{$phone} {
    padding: 30px 33px 55px;
  }

  .progress-bar {
    display: block;
    height: 3px;
    background-color: #eeeeee;
    position: absolute;
    bottom: 25px;
    width: calc(100% - 80px);
    left: 40px;
    overflow: hidden;
    opacity: 0;

    &:before {
      content: "";
      display: block;
      height: 100%;
      width: 100%;
      background-color: $primary-color;
      top: 0;
      position: absolute;
    }
  }
}

.app-block__card-title {
  font-family: $primary-family-medium;
  @include font-size(26);
  margin-bottom: 10px;

  @media #{$large-screen} {
    @include font-size(22);
  }

  @media #{$phone} {
    @include font-size(20);
    margin-bottom: 15px;
  }
}

.app-block__card-description {
  color: #8d8d8d;

  @media #{$large-screen} {
    @include font-size(16);
  }

  @media #{$phone} {
    line-height: 1.6;
  }
}

.app-link {
  margin-left: 35px;
  display: flex;

  @media #{$desktop} {
    justify-content: center;
    flex-wrap: wrap;
  }

  @media #{$phone} {
    margin-left: 0;
    justify-content: center;
    flex-wrap: wrap;
  }

  .btn {
    margin-left: 10px;
    @include font-size(16);
    padding: 20px 22px 19px 53px;
    position: relative;
    min-width: 257px;
    font-family: $primary-family-medium;
    box-shadow: 0 12px 50px rgba(0, 0, 0, 0.08);

    @media #{$desktop} {
      margin-bottom: 10px;
    }

    img {
      position: absolute;
      top: 18px;
      left: 22px;
      max-width: 24px;
    }

    &.btn--apple-icon {
      img {
        top: 15px;
      }
    }
  }

  .btn--black {
    background-color: $black;
    border-color: $black;
  }
}

.option-block {
  background-color: #262626;
  color: $white;
  padding: 225px 0;
  position: relative;
  min-height: 1658px;
  overflow: hidden;

  @media #{$large-screen} {
    min-height: 1200px;
    padding-top: 140px;
  }

  @media #{$desktop} {
    min-height: 1000px;
  }

  @media #{$tablet} {
    padding: 100px 0 210px;
    min-height: inherit;
  }

  @media #{$phone} {
    padding: 70px 0 85px;
  }
}

.option-block__intro {
  max-width: 920px;
  margin: 0 auto;
  padding: 0 20px 390px;
  text-align: center;
  @include font-size(32);
  position: relative;
  z-index: 1;

  @media #{$large-screen} {
    @include font-size(20);
    max-width: 800px;
    padding-bottom: 200px;
  }

  @media #{$tablet} {
    padding-bottom: 130px;
    max-width: 510px;
  }

  @media #{$phone} {
    @include font-size(18);
    max-width: 340px;
    padding-bottom: 0;
  }
}

.option-block__title {
  margin-bottom: 70px;

  @media #{$large-screen} {
    margin-bottom: 32px;
  }

  @media #{$phone} {
    @include font-size(28);
    line-height: 1.3;
    padding: 0 40px;
    margin-bottom: 32px;
  }
}

.option-block__description {
  color: #b2b2b2;
  @include font-size(20);
  line-height: 1.6;

  @media #{$large-screen} {
    line-height: 1.8;
    max-width: 560px;
    margin: 0 auto;
  }

  @media #{$phone} {
    @include font-size(16);
  }
}

.option-block__image-carousel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #262626;

  @media #{$phone} {
    display: none;
  }

  .slick-list,
  .slick-track,
  .option-block__image-slide,
  .option-block__image-bg {
    height: 100%;
  }
}

.option-block__image-bg {
  background-repeat: no-repeat;
  background-position: 100% 210px;
  background-size: 1480px auto;
  min-height: 1650px;
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.7, 0, 0.3, 1);

  .slick-active & {
    opacity: 1;
  }

  @media screen and (min-width: 2000px) {
    background-position: 70% 200px;
  }

  @media #{$large-screen} {
    min-height: 1200px;
    background-size: 1170px auto;
    background-position: 60% 40px;
  }

  @media #{$desktop} {
    min-height: 1000px;
    background-size: 1000px auto;
  }

  @media #{$tablet} {
    min-height: 800px;
    background-size: 800px auto;
    background-position: 60% 170px;
  }
}

.option-block__carousel {
  @media screen and (min-width: 2000px) {
    margin-top: -140px;
  }

  @media #{$phone} {
    margin-top: -60px;
  }

  @media #{$phone-portrait} {
    margin-top: -80px;
  }

  .slick-dots {
    max-width: 1380px;
    margin: 50px auto 0;
    padding: 0 30px;
    justify-content: flex-start;

    @media #{$tablet} {
      padding: 0 20px;
    }

    @media #{$phone-portrait} {
      max-width: 210px;
      padding: 0;
      margin-top: 30px;
    }

    li {
      button {
        border-color: $white;
        overflow: hidden;
        transition: ease all 150ms;
      }

      &.slick-active button {
        border-color: $white;
        transition: ease all 150ms;

        &::before {
          background-color: $white;
          animation: move-left-to-right 7s cubic-bezier(0.07, 0, 0.73, 1);
        }
      }

      &:hover button {
        border-color: $white;
      }
    }
  }
}

.option-block__slide {
  display: flex !important;
  align-items: center;

  .container {
    width: 100%;

    @media #{$phone} {
      padding: 0;
    }
  }
}

.option-block__slide-content {
  max-width: 300px;
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.7, 0, 0.3, 1);

  .slick-active & {
    opacity: 1;
  }

  @media #{$phone} {
    max-width: 70%;
    padding: 0 20px;
    margin-top: -70px;
    position: relative;
  }

  @media #{$phone-portrait} {
    max-width: 210px;
    margin: -50px auto 0;
    padding: 0;
  }
}

.option-block__slide-image {
  display: none;

  @media #{$phone} {
    display: block;
    position: relative;
    left: -6%;
    width: 108%;
  }

  @media #{$phone-portrait} {
    left: -16%;
    width: 128%;
  }
}

.option-block__slide-title {
  font-family: $primary-family-semibold;
  @include font-size(34);
  line-height: 1.25;
  margin-bottom: 27px;

  @media #{$large-screen} {
    @include font-size(28);
  }

  @media #{$phone} {
    @include font-size(20);
  }
}

.option-block__slide-description {
  font-family: $primary-family;
  @include font-size(22);
  line-height: 1.5;

  @media #{$large-screen} {
    @include font-size(20);
  }

  @media #{$phone} {
    @include font-size(16);
  }
}

.content-block {
  padding: 215px 0 170px;

  @media #{$large-screen} {
    padding: 155px 0 170px;
  }

  @media #{$tablet} {
    padding: 100px 0;
  }

  @media #{$phone} {
    padding: 65px 0;
  }
}

.content-block__intro {
  max-width: 1050px;
  margin: 0 auto 118px;
  text-align: center;
  @include font-size(32);
  position: relative;
  z-index: 1;

  @media #{$large-screen} {
    max-width: 620px;
    @include font-size(20);
    margin-bottom: 40px;
  }

  @media #{$phone} {
    @include font-size(16);
    max-width: 300px;
    margin-bottom: 30px;
  }
}

.content-block__intro-title {
  padding: 0 50px 12px;

  @media #{$tablet} {
    padding: 0 0 50px;
  }

  @media #{$phone} {
    padding: 0 0 20px;
    @include font-size(28);
  }
}

.content-block__image {
  font-size: 4rem;
  max-width: 80px;
  margin: 0 auto 30px;
}

.content-block__intro-description {
  line-height: 1.7;
}

.content-block__description {
  @include font-size(32);
  text-align: center;
  max-width: 1050px;
  margin: 0 auto 50px;

  @media #{$large-screen} {
    max-width: 620px;
    @include font-size(20);
    margin-bottom: 100px;
  }

  @media #{$tablet} {
    @include font-size(22);
  }

  @media #{$phone} {
    @include font-size(16);
    max-width: 280px;
    margin-bottom: 60px;
  }
}

.content-block__card-wrap {
  display: flex;
  flex-wrap: wrap;
}

.content-block__card {
  border: 3px solid #d9d9d9;
  width: calc(33.33% - 30px);
  margin: 0 15px 30px;
  border-radius: 15px;
  padding: 70px 65px;
  text-align: center;

  @media #{$large-screen} {
    padding: 70px 50px;
  }

  @media #{$desktop} {
    padding: 70px 35px;
  }

  @media #{$tablet} {
    padding: 40px 25px;
    width: calc(33.33% - 20px);
    margin: 0 10px 20px;
  }

  @media #{$phone} {
    width: 100%;
    margin: 0 0 20px;
    padding: 50px;
  }
}

.content-block__card-title {
  font-family: $primary-family-semibold;
  @include font-size(34);
  margin-bottom: 54px;

  @media #{$large-screen} {
    @include font-size(28);
    line-height: 1.4;
    margin-bottom: 27px;
  }

  @media #{$tablet} {
    @include font-size(22);
    margin-bottom: 24px;
  }

  @media #{$phone} {
    @include font-size(20);
  }
}

.content-block__card-description {
  @include font-size(22);
  line-height: 1.5;

  @media #{$large-screen} {
    @include font-size(18);
  }

  @media #{$phone} {
    @include font-size(16);
  }
}

.order-block {
  padding: 0 40px;

  @media #{$large-screen} {
    padding: 0 20px 40px;
  }

  @media #{$tablet} {
    padding: 0;
  }

  .container {
    max-width: 1140px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    z-index: 1;

    @media #{$tablet} {
      padding-bottom: 110px;
    }

    @media #{$phone} {
      padding-bottom: 20px;
    }
  }

  .btn--order {
    box-shadow: 0 15px 44px rgba(20, 197, 183, 0.3);
  }
}

.order-block--bottom-space {
  padding-bottom: 103px;

  @media #{$tablet} {
    padding-bottom: 0;
  }

  .order-block__description {
    margin-bottom: 0;
  }
}

.order-block__inner {
  padding-top: 150px;
  margin: 0 auto;
  border-radius: 15px;
  color: $black;
  background-color: #f7f8fa;
  position: relative;

  @media #{$large-screen} {
    padding-top: 95px;
  }

  @media #{$tablet} {
    padding-top: 100px;
  }

  @media #{$phone} {
    padding-top: 40px;
    padding-bottom: 50px;
  }

  &:before {
    content: "";
    height: 140px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $white;

    @media #{$large-screen} {
      display: none;
    }
  }

  &:after {
    content: "";
    height: 70px;
    width: 100%;
    position: absolute;
    bottom: 110px;
    left: 0;
    background-color: #f7f8fa;
    border-radius: 15px;

    @media #{$tablet} {
      display: none;
    }
  }
}

.order-block__content {
  width: 56.7%;

  @media #{$phone} {
    width: 100%;
  }
}

.order-block__card {
  width: 40%;

  @media #{$large-screen} {
    width: 37.2%;
  }

  @media #{$tablet} {
    width: 40%;
  }

  @media #{$phone} {
    width: 100%;
  }
}

.order-block__title {
  max-width: 470px;

  @media #{$large-screen} {
    max-width: 470px;
    margin-bottom: 30px;
  }

  @media #{$phone-portrait} {
    @include font-size(28);
    text-align: center;
    max-width: 280px;
    margin: 0 auto 20px;
  }

  img {
    display: inline-block;
    max-width: 48px;
    margin-top: -10px;

    @media #{$phone} {
      max-width: 38px;
      margin-top: -4px;
    }

    @media #{$phone-portrait} {
      max-width: 28px;
      margin-top: -4px;
    }
  }
}

.order-block__description {
  max-width: 460px;
  @include font-size(32);
  color: rgba($black, 0.8);
  margin-bottom: 30px;
  line-height: 1.4;

  @media #{$large-screen} {
    @include font-size(20);
    max-width: 290px;
  }

  @media #{$tablet} {
    margin-bottom: 0;
  }

  @media #{$phone} {
    @include font-size(16);
    margin-bottom: 20px;
  }

  @media #{$phone-portrait} {
    max-width: 220px;
    text-align: center;
    margin: 0 auto 20px;
  }
}

.order-block__image {
  position: relative;
  width: 100%;
  left: -62px;
  overflow: hidden;
  bottom: 0;

  @media #{$large-screen} {
    left: -55px;
    width: 90%;
    bottom: 20px;
  }

  @media #{$desktop} {
    left: -9%;
  }

  @media #{$tablet} {
    bottom: 0;
    width: 100%;
  }

  @media #{$phone} {
    left: 0;
    max-width: 380px;
    margin: 0 auto;
    bottom: 15px;
  }
}

.order-block__image--bottom {
  bottom: -55px;

  @media #{$large-screen} {
    bottom: -70px;
  }

  @media #{$phone} {
    bottom: -10px;
  }
}

.order-block__card-primary,
.order-block__card-secondary {
  border-radius: 15px;
  background-color: $white;
  margin-bottom: 5px;
  position: relative;
}

.order-block__card-primary {
  padding: 44px 58px 45px;

  @media #{$tablet} {
    padding: 40px 28px;
  }

  .icon-plus {
    width: 50px;
    height: 50px;
    color: $primary-color;
    position: absolute;
    bottom: -27px;
    left: 60px;
    z-index: 2;

    @media #{$phone} {
      left: 23px;
    }
  }
}

.order-block__card-secondary {
  padding-top: 20px;
  margin-bottom: 15px;
  box-shadow: 0 32px 44px rgba(0, 0, 0, 0.05);
}

.order-block__secondary-inner {
  padding: 44px 58px 20px;

  @media #{$tablet} {
    padding: 40px 28px;
  }
}

.order-block__card-title {
  @include font-size(20);
  font-family: $primary-family;
  margin-bottom: 30px;

  @media #{$phone} {
    @include font-size(16);
  }

  strong {
    display: block;
    @include font-size(24);
    font-family: $primary-family-medium;
    margin-bottom: 4px;

    @media #{$phone} {
      @include font-size(20);
    }
  }
}

.order-block__discount-price,
.filter-order__discount-price {
  font-family: $primary-family-medium;
  letter-spacing: normal;
  display: inline-block;
  position: relative;
  z-index: 1;
  padding: 0 13px 0 2px;
  @include font-size(42);

  @media #{$phone} {
    @include font-size(34);
  }

  &::after {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg width='116' height='32' viewBox='0 0 116 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.00002 29.5726C17.2243 23.1694 51.9522 3.01114 113.824 2.56366' stroke='%23F42D53' stroke-width='3.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    position: absolute;
    top: 6px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
}

.order-block__card-price {
  font-family: $primary-family;
  @include font-size(20);
  line-height: 1;
  margin-bottom: 25px;

  @media #{$phone} {
    @include font-size(16);
  }

  .order-block__price-amount,
  .filter-order__price-amount {
    @include font-size(58);
    font-family: $primary-family-semibold;
    display: block;
    margin-bottom: 5px;
    letter-spacing: -3.2px;

    @media #{$tablet} {
      @include font-size(45);
    }

    @media #{$phone} {
      @include font-size(40);
    }

    .currency-symbol {
      @include font-size(26);
    }

    &.order-block__price-amount--alt {
      @include font-size(50);
      font-family: $primary-family-medium;

      @media #{$phone} {
        @include font-size(36);
      }
    }
  }
}

.order-block__note {
  font-family: $primary-family-medium;
  @include font-size(18);
  line-height: 1.2;
  padding: 29px 58px;
  background-color: rgba($primary-color, 0.05);
  color: $black;

  @media #{$tablet} {
    padding: 25px 28px;
  }

  strong {
    display: block;
    font-family: $primary-family-semibold;
    @include font-size(21);
    color: $primary-color;

    img {
      max-width: 20px;
      margin-top: -8px;
    }
  }
}

// info Billboard
.info-billboard {
  margin: 0 20px;
  background-image: url(assets/images/virus-bg-large.jpg);
  border-radius: 15px;
  padding: 150px 0 80px;
  overflow: hidden;
  position: relative;

  @media #{$tablet} {
    padding: 80px 0;
    margin: 0;
    border-radius: 0;
  }

  .container {
    @media #{$large-screen} {
      max-width: 1200px;
    }

    @media #{$desktop} {
      padding-left: 30px;
      padding-right: 30px;
    }

    >.btn {
      display: none;
      text-align: center;
      max-width: 450px;
      margin-top: 60px;

      @media #{$tablet} {
        display: inline-block;
      }
    }
  }

  .virus {
    position: absolute;

    &--left {
      top: 0;
      left: 0;
      width: 31vw;
      min-width: 500px;

      @media #{$tablet} {
        left: -100px;
      }
    }

    &--right {
      top: 0;
      transform: translateY(-35%);
      right: 10px;
      max-width: 265px;

      @media #{$tablet} {
        top: 50%;
        transform: translateY(-50%);
        right: -120px;
      }
    }

    &--bottom {
      bottom: 0;
      left: 43%;
      transform: translate(-50%, 55%);
      bottom: 0;
      max-width: 700px;

      @media #{$tablet} {
        left: 100%;
        transform: translate(-50%, 80%) rotate(90deg);
        bottom: 100%;
      }
    }
  }
}

.info-billboard__wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.info-billboard__content {
  text-align: left;
  max-width: 600px;
  width: 51%;
  @include font-size(32);
  color: $white;
  font-family: $primary-family-medium;
  line-height: 1.35;
  position: relative;
  letter-spacing: -0.3px;

  @media #{$large-screen} {
    width: 44%;
    @include font-size(28);
    max-width: 500px;
  }

  @media #{$tablet} {
    width: 90%;
    max-width: 90%;
    margin-bottom: 60px;
  }

  @media #{$phone} {
    @include font-size(20);
  }

  p {
    max-width: 500px;
    margin-bottom: 0;

    @media #{$large-screen} {
      max-width: 450px;
    }

    @media #{$tablet} {
      max-width: 600px;
    }
  }

  .btn {
    margin-top: 60px;

    @media #{$large-screen} {
      margin-top: 45px;
    }

    @media #{$tablet} {
      display: none;
    }
  }
}

.info-billboard__title {
  @include font-size(48);
  margin-bottom: 66px;
  color: $white;

  @media #{$large-screen} {
    margin-bottom: 35px;
  }

  @media #{$tablet} {
    margin-bottom: 25px;
  }

  @media #{$phone} {
    @include font-size(28);
    line-height: 1.285;
  }

  img {
    max-width: 65px;
    margin-top: -10px;

    @media #{$large-screen} {
      max-width: 48px;
    }

    @media #{$tablet} {
      max-width: 38px;
      margin-top: -7px;
    }

    @media #{$phone} {
      max-width: 28px;
      margin-top: -3px;
    }
  }
}

.info-billboard__media {
  max-width: 585px;
  width: 50.6%;
  position: relative;
  min-height: 705px;

  @media #{$desktop} {
    max-width: 600px;
  }

  @media #{$tablet} {
    width: 100%;
    min-height: auto;
    max-width: 100%;
  }

  img {
    @media #{$phone} {
      width: 105%;
      max-width: 105%;
    }
  }
}

/* Preorder Changes */
.hero--home-center {
  .hero__image--preorder {
    max-width: 964px;
    margin: -158px auto 0;
    left: -11px;

    @media #{$tablet} {
      max-width: 762px;
      margin: -120px auto 0;
      left: -5px;
    }

    @media #{$phone} {
      max-width: 472px;
      margin: -80px auto 0;
    }

    @media #{$phone-portrait} {
      max-width: 370px;
      margin: -60px auto 0;
      left: 0;
      padding-bottom: 50px;
    }
  }

  .hero__bottom-content--preorder {
    max-width: 660px;
    margin: 0 auto;
    text-align: center;
    position: relative;

    @media #{$phone} {
      max-width: 400px;
    }

    @media #{$phone-portrait} {
      padding: 0 20px;
    }

    .hero__subtitle {
      max-width: 540px;
      margin: 0 auto 32px;

      strong {
        position: relative;

        &:before {}
      }
    }

    .hero__description {
      padding: 0;
      margin-bottom: 40px;

      @media #{$phone} {
        padding: 0 10px;
      }

      p {
        margin-bottom: 22px;
        padding: 0 10px;
        @include font-size(22);
        line-height: 1.35;

        @media #{$phone} {
          @include font-size(16);
        }

        strong {
          @include font-size(28);
          font-family: $primary-family-medium;
          color: $black;

          @media #{$phone} {
            @include font-size(20);
          }
        }
      }
    }

    .hero__preorder {
      p {
        @include font-size(22);
        margin-bottom: 3px;
        color: rgba(0, 0, 0, 0.8);

        @media #{$phone} {
          @include font-size(16);
        }

        a {
          font-family: $primary-family-semibold;
          color: $black;
          text-decoration: underline;
        }

        strong {
          font-family: $primary-family-semibold;
          color: $black;
        }
      }

      .btn {
        padding: 29px 70px;
        margin-bottom: 35px;
        @include font-size(20);
        box-shadow: 0 15px 44px rgba($primary-color, 0.3);

        @media #{$phone} {
          @include font-size(18);
          padding: 15px 50px;
          margin-bottom: 25px;
        }
      }
    }
  }
}

.hero__title {
  img {
    &.spinning-hand {
      animation: circular-wave 2s linear infinite;
      transform-origin: 48% 74%;
    }
  }
}

@-webkit-keyframes circular-wave {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(-60deg);
  }

  75% {
    transform: rotate(60deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@-moz-keyframes circular-wave {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(-60deg);
  }

  75% {
    transform: rotate(60deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes circular-wave {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(-60deg);
  }

  75% {
    transform: rotate(60deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.voice-block {
  padding: 130px 0 0;
  position: relative;

  @media #{$tablet} {
    padding: 100px 0 60px;
  }

  @media #{$phone} {
    padding: 60px 0 0;
  }
}

.voice-block__video {
  width: 100%;
  margin-top: -620px;

  @media #{$phone} {
    margin-top: 0;
    left: 50%;
    position: relative;
    width: 480px;
    transform: translate(-70%, 0);
  }
}

.voice-block__title {
  max-width: 460px;
  @include font-size(48);

  @media #{$phone} {
    @include font-size(28);
    max-width: 240px;
    margin: 0 auto 37px;
  }
}

.voice-block__subtitle {
  @include font-size(28);
  font-family: $primary-family-medium;
  line-height: 1.4;
  margin-bottom: 145px;

  @media #{$tablet} {
    margin-bottom: 95px;
  }

  @media #{$phone} {
    font-family: $primary-family;
    @include font-size(20);
    margin-bottom: 40px;
  }
}

.voice-block__content {
  max-width: 610px;
  padding: 0 20px;
  z-index: 5;
  position: relative;
  text-align: left;

  @media #{$phone} {
    text-align: center;
    margin: 0 auto;
  }
}

.voice-block__description {
  @include font-size(20);
  margin-bottom: 57px;

  @media #{$phone} {
    margin-bottom: 45px;
  }
}

.voice-block__logo {
  display: flex;

  @media #{$phone} {
    justify-content: center;
  }

  .voice-block__logo-image {
    max-width: 208px;
    margin: 0 9px;

    a {
      cursor: pointer;
    }
  }
}

.voice-block__container {
  max-width: 1308px;
  margin: 0 auto;
}

img[src=""],
img:not([src]) {
  visibility: hidden;
}

/* Hero Slider */
.halo {
  color: $white;
  background-color: $black;
  border-radius: 15px;
  overflow: hidden;

  @media #{$tablet} {
    border-radius: 0;
  }
}

.halo__title {
  color: $white;
  position: relative;
  padding-top: 20px;

  @media #{$tablet} {
    text-align: center;
    line-height: 1;
    margin-bottom: 0;
  }

  @media #{$phone} {
    padding-top: 40px;
  }

  strong {
    @include font-size(435);

    @media #{$small-screen} {
      @include font-size(213);
    }

    @media #{$phone} {
      @include font-size(120);
      margin-bottom: 10px;
      display: block;
    }
  }

  span {
    position: absolute;
    top: 95px;
    @include font-size(38);
    left: 350px;

    @media #{$small-screen} {
      left: 180px;
      @include font-size(16);
      top: 57px;
    }

    @media #{$tablet} {
      display: none;
    }
  }
}

.halo__thumbnail {
  max-width: 600px;
  margin-top: -88px;
  margin-left: -30px;
  position: relative;
  max-height: 600px;

  @media #{$small-screen} {
    margin-top: 20px;
    margin-left: 0;
    max-width: 600px;
  }

  @media #{$tablet} {
    margin: 0 auto;
    max-width: 400px;
    max-height: 400px;
  }

  @media #{$phone} {
    max-height: 320px;
  }
}

.halo__content {
  @include font-size(20);
  display: flex;
  flex-wrap: wrap;
  color: rgba($white, 0.8);
  margin-top: -100px;
  z-index: 1;
  position: relative;

  @media #{$small-screen} {
    margin-top: -50px;
  }

  @media #{$tablet} {
    margin-top: 0;
    flex-direction: column-reverse;
  }
}

.halo__description {
  max-width: 525px;
  padding-left: 56px;
  line-height: 1.5;

  @media #{$small-screen} {
    width: calc(100% - 600px);
    margin-top: 50px;
  }

  @media #{$tablet} {
    text-align: center;
    padding-left: 0;
    max-width: 600px;
    margin: 0 auto 40px;
    width: inherit;
  }

  @media #{$phone} {
    @include font-size(15);
    padding: 0;
    margin-bottom: 40px;
  }

  strong {
    font-family: $primary-family-semibold;

    @media #{$phone} {
      color: $white;
    }
  }

  p {
    margin-bottom: 35px;

    @media #{$phone} {
      line-height: 1.3;
      margin-bottom: 10px;
    }
  }

  .btn {
    max-width: 280px;
    text-align: center;
    width: 100%;
    padding: 23px 20px;
    font-size: 18px;

    @media #{$phone-portrait} {
      max-width: 220px;
      padding: 16px 20px;
      font-size: 16px;
    }
  }
}

.halo__description-text {
  color: $white;

  @media #{$phone} {
    margin-bottom: 30px;
  }
}

.halo__subtitle {
  font-family: $primary-family-medium;
  @include font-size(30);
  color: rgba($white, 0.8);
  margin-bottom: 40px;

  @media #{$small-screen} {
    margin-top: -8px;
    font-family: $primary-family;
  }

  @media #{$phone} {
    @include font-size(20);
    margin-bottom: 26px;
  }
}

.awards-icon {
  max-width: 87px;
  position: absolute;
  left: -47px;
  top: 270px;

  @media #{$desktop} {
    left: 0;
  }

  @media #{$small-screen} {
    max-width: 87px;
    position: absolute;
    left: 40px;
    top: 207px;
  }

  @media #{$phone} {
    max-width: 58px;
    left: 23px;
    top: 145px;
  }
}

.hero .hero__subtitle.hero__subtitle--alt {
  margin-top: 35px;
  margin-bottom: 35px;

  .heroFlex--valentine & {
    margin-bottom: 15px;
  }

  @media #{$phone} {
    // max-width: 218px;
    max-width: 100%;
    margin: 0 auto 30px;
    letter-spacing: -0.02em;

    .heroFlex--valentine & {
      max-width: 100%;
    }
  }

  .hidden {
    @media #{$phone} {
      display: none;
    }
  }
}

.hero__description.hero__description--small {
  max-width: 904px;
  margin: 0 auto;
  line-height: 1.1;
  // letter-spacing: -0.5px;

  .heroFlex--valentine & {
    line-height: calc(34 / 26);
  }

  @media #{$phone} {
    @include font-size(20);
    letter-spacing: -0.03em;
    line-height: 1.3;
    margin: 0 30px 30px;
  }
}

.hero__content--desktop {
  display: block;

  @media #{$tablet} {
    display: none;
  }
}

.hero__content--phone {
  display: none;

  @media #{$tablet} {
    margin-top: 40px !important;
    display: block;
  }

  @media #{$phone} {
    .heroFlex--valentine & {
      margin-top: 30px !important;
    }
  }
}
