.video-popup {
  position: fixed;
  inset: 0 0 0 0;
  z-index: 9999;
  padding: 44px;
  display: flex;
}

.video-popup__overlay {
  background-color: #404040;
  position: absolute;
  inset: 0 0 0 0;
  z-index: 1;
  display: block;
}

.video-popup__inner {
  position: relative;
  width: 100%;
  z-index: 1;
  pointer-events: none;
}

.video-popup__iframe {
  aspect-ratio: 110.22/62;
  max-width: 100%;
  max-height: 100%;
  width: auto !important;
  height: auto !important;
  margin: 0 auto;
  top: 50%;
  position: relative;
  transform: translateY(-50%);
  pointer-events: all;
}

.video-popup__close {
  background: rgba(30, 30, 30, 0.6);
  border: 0;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  margin: 0;
  padding: 10px;
  position: absolute;
  transition: color 0.2s;
  vertical-align: top;
  visibility: inherit;
  width: 44px;
  color: #ccc;
  top: 0;
  right: 0;
  z-index: 9;

  &:hover,
  &:focus {
    color: white;
  }

  svg {
    height: 24px;
    width: 24px;
    fill: currentColor;
  }
}
