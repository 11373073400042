/*--------------------------------------------------------------
# Color
--------------------------------------------------------------*/
$primary-color: #1ce4d5;
$gray: #8f8f8f;
$light-gray: #f9f9f9;
$black: #000000;
$white: #ffffff;
$white--secondary: #ececec;
$gray-light: #979797;
$dark-gray: #373737;
$brand-color--primary: #ffc140;
$brand-color--secondary: #f42d53;
$brand-color--tertiary: #1ce4d5;
$orange: #fb873b;

/*--------------------------------------------------------------
# Fonts
-------------------------------------------------------------*/
$primary-family: "Graphik-Regular", sans-serif;
$primary-family-black: "Graphik-Black", sans-serif;
$primary-family-bold: "Graphik-Bold", sans-serif;
$primary-family-semibold: "Graphik-Semibold", sans-serif;
$primary-family-medium: "Graphik-Medium", sans-serif;
$alt-family: "EasyNotes", sans-serif;

/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/

$font__code: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
$font__pre: "Courier 10 Pitch", Courier, monospace;
$font__line-height-body: 1.4;
$font__line-height-pre: 1.6;

/*--------------------------------------------------------------
# Structure
--------------------------------------------------------------*/
$size__site-main: 100%;
$size__site-sidebar: 25%;

// Media Queries
$large-screen-width: 1800px;
$medium-screen-width: 1600px;
$small-screen-width: 1439px;
$container: 1365px;
$phone-width: 768px;
$phone-portrait-width: 450px;
$tablet-width: 1024px;
$header-width: 960px;

$large-screen: "screen and (max-width: #{$large-screen-width})";
$medium-screen: "screen and (max-width: #{$medium-screen-width})";
$small-screen: "screen and (max-width: #{$small-screen-width})";
$desktop: "screen and (max-width: #{$container})";
$tablet: "screen and (max-width: #{$tablet-width})";
$header: "screen and (max-width: #{$header-width})";
$phone: "screen and (max-width: #{$phone-width})";
$phone-portrait: "screen and (max-width: #{$phone-portrait-width})";
