.mila-comparison {
  padding: 0 20px;

  @media #{$phone} {
    padding: 0 8px;
  }

  .container {
    padding: 0;
    max-width: 100%;
  }
}

.mila-comparison__card {
  background-color: #F6F6F6;
  padding: 120px 0 110px;
  border-radius: 16px;
  overflow: hidden;

  @media #{$desktop} {
    padding: 120px 0 110px;
  }

  @media #{$tablet} {
    padding: 100px 0 110px 12px;
  }

  @media #{$phone} {
    padding: 80px 0 110px 6px;
  }
}

.mila-comparison__header {
  max-width: 844px;
  margin: 0 auto 23px;
  text-align: center;

  @media #{$tablet} {
    max-width: 651px;
    margin-bottom: 48px;
  }

  @media #{$phone} {
    max-width: 350px;
  }
}

.mila-comparison__title {
  @include font-size(48);
  letter-spacing: -0.3px;
  line-height: 1;
  max-width: 658px;
  margin: 0 auto 32px;
  text-wrap: balance;

  @media #{$tablet} {
    @include font-size(32);
  }

  @media #{$phone} {
    @include font-size(24);
    line-height: 1.1;
    margin-bottom: 24px;
  }
}

.mila-comparison__sub-title {
  @include font-size(28);
  line-height: calc(38 / 28);
  letter-spacing: -0.3px;
  font-family: $primary-family-medium;
  max-width: 728px;
  margin: 0 auto 24px;

  @media #{$small-screen} {
    @include font-size(24);
    max-width: 768px;
  }

  @media #{$tablet} {
    @include font-size(20);
    max-width: 640px;
  }

  @media #{$phone} {
    max-width: 299px;
    margin: 0 auto 20px;
    @include font-size(18);
  }
}

.mila-comparison__short-description {
  @include font-size(22);
  line-height: 1.3;
  letter-spacing: -0.391px;
  @include font-size(22);
  max-width: 466px;
  margin: 0 auto;

  @media #{$desktop} {
    @include font-size(20);
    letter-spacing: -0.4px;
  }

  @media #{$phone} {
    @include font-size(16);
    max-width: 334px;
    margin: 0 auto;
  }
}

.mila-comparison__body {
  width: 100%;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 2060px) {
    padding-left: 72px;
  }

  @media #{$small-screen} {
    padding-left: 50px;
  }

  @media #{$tablet} {
    padding-left: 0;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 299px;
    left: 0;
    right: 0;
    height: 1px;
    z-index: -1;
    opacity: .2;
    background-color: $black;

    @media #{$tablet} {
      bottom: 336px;
    }

    @media #{$phone} {
      bottom: 363px;
    }
  }
}

.mila-comparison__grid {
  display: flex;
  align-items: flex-end;
  gap: 32px;
  width: 100%;
  max-width: 2060px;
  margin: 0 auto;

  @media #{$desktop} {
    padding-bottom: 37px;
  }

  @media #{$tablet} {
    gap: 16px;

  }

  @media #{$phone} {
    gap: 10px;
  }
}

//Comparison
.main-filter {
  background-color: #EEEEEE;
  border: 1px solid #e6e6e6;
  border-radius: 14px;
  min-width: 222px;
  padding: 50px 23px;
  text-align: center;

  @media #{$tablet} {
    padding: 39px 18px;
    min-width: 175px;
  }
}

.main-filter__image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 218px;
  position: relative;
  margin: 0 -23px 41px;

  @media #{$tablet} {
    margin: 0 -18px 30px;
    height: 175px;
  }

  img {
    position: absolute;
    bottom: 0;
    width: 100%;
    opacity: 0;
    animation: fadeInOut 0.3s ease forwards;

    &.active {
      left: auto;
      animation: fadeInOut 0.3s ease forwards, indexUp 0.3s ease forwards;
    }
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes indexUp {
  0% {
    z-index: 1;
  }

  100% {
    z-index: 2;
  }
}

.main-filter__image-air {
  max-width: 187px;
  right: auto;
  left: 5px;

  @media #{$tablet} {
    max-width: 142px;

  }

  &.active {
    right: -6px;
    left: auto;

    @media #{$tablet} {
      right: 5px;
    }
  }
}

.main-filter__image-mini {
  max-width: 190px;
  left: -7px;

  @media #{$tablet} {
    max-width: 150px;
  }

  &.active {
    bottom: -20px;
    right: 6px;
    left: auto;

    @media #{$tablet} {
      right: 2px;
      bottom: -10px;
    }
  }
}

.main-filter__title {
  @include font-size(18);
  letter-spacing: -0.3px;
  line-height: calc(22/18);
  color: $black;
  font-family: $primary-family-semibold;
  margin-bottom: 40px;
  font-weight: normal;

  @media #{$tablet} {
    margin-bottom: 24px;
    @include font-size(14);
  }
}

.main-filter__button {
  @include font-size(18);
  letter-spacing: -0.3px;
  line-height: calc(22/18);
  background-color: $white;
  border-radius: 40px;
  padding: 9px 18px;
  width: 100%;
  margin-top: 10px;
  font-family: $primary-family-semibold;
  display: flex;
  gap: 14px;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s ease;

  @media #{$tablet} {
    padding: 7px 15px;
    gap: 10px;
    @include font-size(14);
    margin-top: 8px;
  }

  &:hover {
    background-color: $black;
    color: $white;
  }

  .icon {
    height: 17px;
    width: 17px;
  }
}

.main-filter__list,
.competitor__list {
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    line-height: 1.3;
    @include font-size(16);
    letter-spacing: -0.3px;
    white-space: nowrap;

    @media #{$tablet} {
      @include font-size(14);
      font-family: $primary-family-semibold;
    }

    &:not(:last-child) {
      margin-bottom: 8px;

      @media #{$phone} {
        margin-bottom: 6px;
      }
    }

    strong {
      display: none;

      @media #{$tablet} {
        display: block;
        font-family: inherit;
        font-weight: inherit;
        color: #90989B;
      }
    }

    span {
      display: block;

      >span {
        color: #F42C52;
      }
    }
  }
}

.main-filter__name,
.competitor__name {
  margin-bottom: 17px;
  @include font-size(18);
  letter-spacing: -0.3px;
  line-height: calc(22/18);
  font-weight: normal;
  font-family: $primary-family-semibold;

  @media #{$tablet} {
    margin-bottom: 13px;
    @include font-size(16);
  }
}

.competitors__slider-wrap {
  width: 100%;
  padding-bottom: 88px;
  margin-bottom: -88px;
  overflow: hidden;
}

.competitors__slider {
  position: static;
  z-index: 1;

  // &.arrows-hidden {
  //   .slick-arrow {
  //     opacity: 0 !important;
  //     pointer-events: none !important;
  //   }
  // }

  .slick-list {
    .slick-track {
      display: flex;

      .slick-slide {
        height: auto;
        display: flex;

        >div {
          display: flex;
          align-items: flex-end;
        }
      }
    }
  }

  .slick-arrow {
    color: $black;
    bottom: -46px;
    position: absolute;
    z-index: 1;
    transition: all 0.3s ease;
    height: 46px;
    width: 46px;

    &:hover {
      transform: scale(1.06);
    }

    &.slick-prev {
      left: calc(50% - 52px);

      @media #{$tablet} {
        left: calc(50% - 49px);
      }
    }

    &.slick-next {
      left: calc(50% + 6px);

      @media #{$tablet} {
        left: calc(50% + 3px);
      }
    }

    &.slick-disabled {
      opacity: .3;
      pointer-events: none;
    }

    .icon {
      height: 46px;
      width: 46px;
    }
  }
}

.competitor {
  padding: 50px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 0 15px;
  white-space: nowrap;

  @media #{$tablet} {
    padding: 39px 0;
    margin: 0 5px;
  }

  @media #{$phone} {
    max-width: 220px;
    padding: 39px 0;
  }
}

.competitor__body {
  flex: 0 0 auto;
}

.competitor__image {
  margin-bottom: 41px;

  @media #{$tablet} {
    margin-bottom: 25px;
  }

  img {
    max-width: 100%;
    height: auto;

    @media #{$tablet} {
      max-width: 78%;
      margin: 0 auto;
    }
  }
}

.comparison-details {
  opacity: 1;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  margin-right: 15px;
  white-space: nowrap;
  text-align: right;
  max-width: 140px;

  @media #{$tablet} {
    display: none;
  }
}

.comparison-details__image {
  img {
    position: relative;
    left: -93px;

    @media #{$small-screen} {
      left: -70px;
    }
  }
}

.comparison-details__name {
  opacity: 0;
}

.comparison-details__list {
  padding: 0;
  list-style-type: none;
  margin: 0;

  li {
    line-height: 1.3;
    @include font-size(16);
    letter-spacing: -0.3px;
    opacity: .6;
    color: $black;
    white-space: nowrap;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
