// Rem output with px fallback
@mixin font-size($sizeValue) {
  font-size: calc($sizeValue / 16) + rem;
}

// Center block
@mixin center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

// Clearfix
@mixin clearfix() {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

/*
Font face mixin
*/

@mixin fontFace($font-family,$font-name, $file-path) {
  @font-face {
    font: {
      family: $font-family;
      weight: normal;
      style: normal;
    }
    src: url('#{$file-path+$font-name}.eot');
    src: local($font-family), local($font-family),
    url('#{$file-path+$font-name}.eot?#iefix') format('embedded-opentype'),
    url('#{$file-path+$font-name}.woff') format('woff'),
    url('#{$file-path+$font-name}.ttf') format('truetype');
  }
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes $animation-name {
    @content;
  }
  @-moz-keyframes $animation-name {
    @content;
  }
  @keyframes $animation-name {
    @content;
  }
}
