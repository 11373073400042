.ces-hero {
  padding: 0 20px 20px;

  @media screen and (max-width:850px) {
    padding: 0 0 20px;
  }
}

.ces-hero__card {
  width: 100%;
  background-color: $light-gray;
  border-radius: 15px;
  padding: 64px 45px;

  @media #{$desktop} {
    padding: 54px 25px 44px;
  }

  @media screen and (max-width:850px) {
    padding: 32px 0;
  }

  .container {
    max-width: 1440px;
  }
}

.ces-hero__title {
  text-align: center;
  @include font-size(36);
  letter-spacing: -0.03em;
  font-family: $primary-family-bold;
  max-width: 485px;
  width: 100%;
  margin: 0 auto 54px;
  line-height: 1.1;

  @media #{$phone} {
    @include font-size(28);
    margin-bottom: 20px;
  }


  span {
    background-image: url("data:image/svg+xml,%3Csvg width='152' height='10' viewBox='0 0 152 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.00021 6.99512C10.7978 5.26766 19.4088 4.5527 27.396 4.55335C33.4474 4.55384 39.4818 3.88776 45.5124 3.92556C56.7043 3.99571 67.8432 4.43619 79.032 4.50632C88.0227 4.56267 96.9706 5.3601 105.979 5.41656C114.144 5.46773 122.324 5.68875 130.47 5.03465C136.626 4.54027 142.766 4.94456 148.912 4.20297' stroke='%231CE4D5' stroke-width='6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-size: contain;
    background-position: left bottom;
    background-repeat: no-repeat;
    padding-bottom: 6px;
    display: inline-block;

    @media #{$phone} {
      padding-bottom: 3px;
    }
  }

  strong {
    @include font-size(240);
    display: block;
    line-height: calc(204/240);
    color: $brand-color--tertiary;
    margin-top: 10px;

    @media #{$header} {
      @include font-size(200);
    }

    @media #{$phone} {
      @include font-size(160);
    }

    @media #{$phone-portrait} {
      @include font-size(120);
    }

    &::selection {
      background-color: $brand-color--tertiary;
      color: $white;
    }
  }
}

.ces-hero__list {

  .slick-list {
    @media screen and (min-width: 841px) {
      width: 100%;
    }

    .slick-track {
      display: flex;

      .slick-slide {
        @media screen and (min-width: 851px) {
          flex: 1;
        }

        &:not(:last-child) {
          margin-right: 15px;

          @media screen and (min-width: 851px) {
            margin-right: 30px;
          }
        }
      }

      >.slick-slide {
        height: auto;
        display: flex;

        >div {
          @media screen and (min-width: 851px) {
            width: 100%;
          }
        }
      }
    }
  }

  .slick-dots {
    justify-content: center;
    margin-top: 16px;

    li {
      margin: 0 4px;

      &.slick-active {
        button {
          background-color: $brand-color--tertiary;
        }
      }

      button {
        border: none;
        background-color: #C7C7C7;
        height: 8px;
        width: 8px;

        &::before {
          display: none;
        }
      }
    }
  }
}

.ces-hero__item {
  display: flex;
  height: 100%;
  width: 100%;

  &.ces-hero__item--0 {
    .featured-card__image {
      width: 327.29px;
      margin-left: -130px;

      @media #{$small-screen} {
        width: 254px;
        margin-left: -100px;
      }

      @media screen and (max-width:1280px) {
        width: 100%;
        margin-left: 0;
        padding-left: 12px;
      }
    }
  }
}

.featured-card__image {
  width: 100%;
}

.featured-card {
  border-radius: 16px;
  background-color: $white;
  padding: 24px 36px 24px 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  width: 100%;

  @media screen and (max-width:1280px) {
    flex-wrap: wrap;
    justify-content: center;
    padding: 32px 24px;
  }

  @media screen and (max-width:850px) {
    width: 259px;
  }

  @media #{$phone} {
    border-radius: 10px;
  }
}

.featured-card__media {
  width: 100%;

  @media screen and (max-width:1280px) {
    display: flex;
    justify-content: center;
    margin-bottom: 14px;
  }
}

.featured-card__footer {
  flex: 0 0 auto;

  @media screen and (max-width:1280px) {
    width: 100%;
    flex: none;
    text-align: center;
    margin-top: auto;
  }
}

.featured-card__footer {
  position: relative;
  z-index: 1;

  .btn--small {
    @include font-size(16);
    letter-spacing: -0.29px;
    padding: 13px 40px;
    text-align: center;

    @media screen and (max-width:1280px) {
      width: 100%;
    }
  }
}

.featured-card__title {
  @include font-size(24);
  letter-spacing: -.45px;
  font-family: $primary-family-semibold;
  margin-bottom: 4px;
  line-height: 1.2;
}

.featured-card__info {
  @include font-size(16);
  line-height: 1;
  margin-bottom: 32px;

  @media screen and (max-width:1280px) {
    margin-bottom: 16px;
  }
}

.ces-newletter {
  padding: 16px 32px;
  background-color: $white;
  border-radius: 15px;
  box-shadow: 0 14px 40px rgba(#D6D6D6, .4);
  margin-top: 30px;

  @media screen and (max-width:850px) {
    padding: 24px;
  }
}

.newletter-form {
  display: flex;
  align-items: center;
  gap: 25px;

  @media #{$tablet} {
    gap: 15px;
  }

  @media screen and (max-width:850px) {
    flex-wrap: wrap;
    justify-content: center;
    gap: 0;
  }

  .btn--medium {
    @include font-size(18);
    letter-spacing: -0.29px;
    text-align: center;
    padding: 14px 40px 13px;

    @media screen and (max-width:1280px) {
      min-width: 280px;
      padding: 23px 40px;
    }

    @media #{$tablet} {
      padding: 14px 40px 13px;
      min-width: auto;
    }

    @media screen and (max-width:850px) {
      max-width: 302px;
      width: 100%;
    }
  }
}

.newletter-form__group {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  gap: 25px;

  @media #{$tablet} {
    gap: 10px;
  }

  @media screen and (max-width:850px) {
    flex-wrap: wrap;
    justify-content: center;
    gap: 0;
  }
}

.newletter-form__button {
  position: relative;
  z-index: 1;

  @media screen and (max-width:850px) {
    width: 100%;
    text-align: center;
  }

  &.newletter-form__button--loading {
    .btn {
      opacity: .2;
    }
  }

  .loading {
    position: absolute;
    height: 50px;
    width: 50px;
    top: calc(50% - 25px);
    right: calc(50% - 25px);
    z-index: 1;
  }
}

.newletter-form__input {
  flex: 1 1 auto;

  @media screen and (max-width:850px) {
    width: 100%;
    text-align: center;
  }

  &::placeholder {
    color: #C7C7C7;

    @media screen and (max-width:850px) {
      text-align: center;
    }
  }
}

.newletter-form__label {
  font-family: $primary-family-medium;
}

.ces-popup-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  // display: none;
}

.ces-popup-wrapper__overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: rgba(#000000, .5);
}

.ces-popup__overflow {
  overflow-y: auto;
  overflow-x: hidden;
  display: grid;
  padding: 40px;
  z-index: 2;
  position: relative;
  height: 100%;
  pointer-events: none;
  align-items: center;

  @media #{$phone} {
    padding: 40px 20px 20px;
  }
}

.ces-popup__card {
  background-color: $white;
  border-radius: 16px;
  padding: 40px;
  max-width: 1026px;
  width: 100%;
  margin: 0 auto;
  height: auto;
  position: relative;
  z-index: 1;
  pointer-events: all;

  @media #{$phone} {
    padding: 40px 20px;
    border-radius: 10px;
  }
}

.ces-popup__video-holder {
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 40px;

  @media #{$phone} {
    border-radius: 10px;
  }
}

.ces-popup__iframe {
  aspect-ratio: 946/531;
}

.ces-popup__header {
  text-align: center;
}

.ces-popup__title {
  color: $brand-color--tertiary;
  font-size: clamp(2rem, 7vw, 5rem);
  letter-spacing: -0.45px;
  line-height: 1;
  margin-bottom: 12px;
}

.ces-popup__subtitle {
  @include font-size(24);
  font-family: $primary-family-semibold;
  line-height: 1;
  margin-bottom: 30px;

  @media #{$phone} {
    @include font-size(18);
    margin-bottom: 20px;
  }
}

.ces-popup__description {
  max-width: 642px;
  width: 100%;
  margin: 0 auto;
  line-height: 1.5;
  text-wrap: pretty;
}

.ces-popup__body {
  margin-top: 40px;
}

.ces-popup__table {
  border-radius: 16px;
  border-spacing: 0;
  border: 1px solid #E5E5E5;
  overflow: hidden;

  @media #{$phone} {
    border-radius: 10px;
  }

  tr {

    th,
    td {
      padding: 24px;

      @media #{$phone} {
        padding: 20px;
      }

      &:last-child {
        text-align: center;
      }

      &:first-child {
        border-right: 1px solid #E5E5E5;
      }
    }

    th {
      @include font-size(20);
      letter-spacing: -0.26px;
      font-family: $primary-family-medium;
      font-weight: normal;
      text-align: left;
    }

    td {
      border-top: 1px solid #E5E5E5;
      @include font-size(16);
      letter-spacing: -0.22px;
      line-height: 1.25;

      &:first-child {
        color: rgba(0, 0, 0, .6);
        font-family: $primary-family-medium;
      }
    }
  }

  thead {
    background-color: #FCFCFC;
  }
}

.ces-popup__close {
  height: 56px;
  width: 56px;
  border-radius: 50%;
  position: absolute;
  left: calc(100% + 16px);
  top: 0;
  z-index: 1;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 300ms ease;

  @media screen and (max-width:1280px) {
    height: 40px;
    width: 40px;
    top: 0;
    left: calc(100% - 40px);
  }

  &:hover,
  &:focus {
    background-color: $brand-color--tertiary;

    @media #{$phone} {
      background-color: transparent;
      color: $brand-color--tertiary
    }
  }

  .icon {
    height: 20px;
    width: 20px;

    @media screen and (max-width:1280px) {
      height: 16px;
      width: 16px;
    }
  }
}


.newsletter-message {
  padding: 16px 32px;
  background-color: $white;
  border-radius: 15px;
  box-shadow: 0 14px 40px rgba(#D6D6D6, .4);
  margin-top: 5px;
  color: $white;
  font-family: $primary-family-medium;

  @media screen and (max-width:850px) {
    padding: 16px 24px;
    text-align: center;
  }

  &.newsletter-message--success {
    background-color: $brand-color--tertiary;
  }

  &.newsletter-message--error {
    background-color: $brand-color--secondary;
  }
}
