.product-testimonial {
  padding: 80px 0;
  background-color: #F7F7F7;

  @media #{$phone} {
    padding: 40px 0 70px;
  }
}

.product-testimonial__title {
  max-width: 620px;
  width: 100%;
  margin: 0 auto 64px;
  @include font-size(56);
  line-height: 1;
  letter-spacing: -0.31px;
  text-align: center;

  @media #{$small-screen} {
    @include font-size(40);
    margin-bottom: 40px;
  }

  @media #{$tablet} {
    @include font-size(32);
  }

  @media #{$phone} {
    @include font-size(28);
    margin-bottom: 32px;
    max-width: 350px;
  }
}

.product-testimonial__grid {
  margin: 0 -15px;
  position: relative;
  z-index: 1;

  @media #{$small-screen} {
    margin: 0 -10px;
  }

  .slick-list {
    overflow: visible;

    .slick-track {
      display: flex;

      .slick-slide {
        height: auto;
        display: flex;

        >div {
          display: flex;
        }
      }
    }
  }

  .slick-dots {
    position: absolute;
    top: calc(100% + 13px);
    display: flex;
    justify-content: center;
    gap: 5px;
    left: 0;
    right: 0;
    z-index: 1;

    li {
      margin: 0;

      &.slick-active {
        button {
          border-color: var(--primary-color);

          &::before {
            background-color: var(--primary-color);
            animation: move-left-to-right 5.8s linear infinite;
          }

          &:hover,
          &:focus {
            border-color: var(--primary-color);
          }
        }
      }

      button {
        border: 2px solid var(--primary-color);

        &:hover,
        &:focus {
          border-color: var(--primary-color);
        }
      }
    }
  }
}

.product-testimonial__item {
  padding: 0 15px;
  display: flex;

  @media #{$small-screen} {
    padding: 0 10px;
  }
}

.product-testimonial__card {
  box-shadow: 0 14px 84px rgba(0, 0, 0, 0.08);
  background-color: $white;
  border-radius: 15px;
  padding: 40px;
  height: 100%;
  display: flex;

  @media #{$small-screen} {
    padding: 32px;
  }

  @media #{$tablet} {
    width: 350px;
  }

  @media screen and (max-width:390px) {
    width: 300px;
  }
}

.product-testimonial__quote {
  padding-top: 26px;
  position: relative;
  z-index: 1;
  @include font-size(24);
  line-height: 1.5;
  display: flex;
  flex-wrap: wrap;

  @media #{$small-screen} {
    @include font-size(20);
  }

  &:before {
    display: none;
  }

  &::after {
    content: '';
    position: absolute;
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='14' viewBox='0 0 22 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.51985 14L0 11.6245L6.51985 0L10.7148 1.71841L6.51985 14ZM17.083 14L10.5632 11.6245L17.083 0L21.278 1.71841L17.083 14Z' fill='black'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    width: 22px;
    height: 14px;
    top: 0;
    left: 0;
  }

  p {
    margin-bottom: 0;
  }
}

.product-testimonial__quote-author {
  font-style: normal;
  padding-top: 40px;
  margin-top: auto;

  @media #{$small-screen} {
    padding-top: 20px;
    @include font-size(18);
  }

  strong {
    @include font-size(22);
    font-family: $primary-family-semibold;
    line-height: calc(30/22);
    display: block;
    margin-bottom: 8px;

    @media #{$small-screen} {
      @include font-size(20);
    }

    @media #{$phone} {
      margin-bottom: 0;
    }
  }
}
