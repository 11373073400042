.product-order {}

.product-order--alt {
  padding: 80px 30px;

  @media #{$small-screen} {
    padding: 60px 20px;
  }

  @media #{$tablet} {
    padding: 40px 20px;
  }
}

.product-order__card {
  background-color: #F7F8FA;
  border-radius: 16px;
  padding: 80px 0;

  @media #{$tablet} {
    padding: 60px 0;
  }
}

.product-order__grid {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  @media #{$phone} {
    flex-direction: column-reverse;
    align-items: center;
  }

  .product-order--alt & {
    justify-content: space-between;
    flex-wrap: nowrap;

    @media #{$phone} {
      flex-direction: column;
    }
  }
}

.product-order__content {
  width: calc(100% - 410px);
  padding: 160px 140px 78px 0;

  @media #{$small-screen} {
    padding: 80px 80px 80px 0;
  }

  @media #{$tablet} {
    width: calc(100% - 350px);
    padding: 60px 40px 60px 0;
  }

  @media #{$phone} {
    width: 100%;
    padding: 80px 0 40px;
  }

  .product-order--alt & {
    padding: 0;
    max-width: 739px;
    width: 100%;

    @media #{$small-screen} {
      padding-right: 40px;
    }

    @media #{$phone} {
      display: flex;
      flex-direction: column-reverse;
      text-align: center;
      padding-right: 0;
    }
  }
}

.order-wrapper {
  margin-top: -140px;

  @media #{$tablet} {
    margin-top: -45px;
  }
}

.order-card {
  filter: drop-shadow(0 0 44px rgba($black, .05));
  margin-bottom: 12px;
}

.order-card__holder {
  max-width: 410px;
  width: 100%;

  @media #{$tablet} {
    max-width: 350px;
  }

  .product-order--alt & {
    padding-top: 43px;

    @media #{$small-screen} {
      padding-top: 0;
    }
  }
}

.order-card__header,
.order-card__body {
  padding: 40px;
  background-color: $white;
  border-radius: 16px;

  @media #{$tablet} {
    padding: 32px;
  }
}

.order-card__devider {
  width: calc(100% - 30px);
  margin: 0 auto -1px;
  position: relative;
  z-index: 1;

  svg {
    width: 100%;
    height: auto;
    display: block;
  }

  &::after {
    content: '';
    position: absolute;
    height: 50px;
    width: 50px;
    background-image: url("data:image/svg+xml,%3Csvg width='50' height='50' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z' fill='%23F42C52'/%3E%3Cpath d='M25.5 15.5V35.5' stroke='white' stroke-width='3' stroke-linecap='round'/%3E%3Cpath d='M15.5 25.5H35.5' stroke='white' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    left: calc(50% - 25px);
    top: calc(50% - 25px);
    z-index: 1;
  }
}

.order-card__price-info {
  @include font-size(20);
  letter-spacing: -.45px;
  line-height: 1;

  @media #{$tablet} {
    @include font-size(16);
  }

  >span {
    display: inline-block;
    @include font-size(28);
    font-family: $primary-family-semibold;
    margin-bottom: 8px;

    @media #{$tablet} {
      @include font-size(24);
      margin-bottom: 4px;
    }
  }

  strong {
    @include font-size(64);
    font-family: $primary-family-medium;
    letter-spacing: -3.2px;
    margin-top: 24px;
    margin-bottom: 8px;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: flex-end;

    @media #{$tablet} {
      @include font-size(48);
      margin-bottom: 4px;
    }

    sub {
      @include font-size(26);
      letter-spacing: -1.39px;
      bottom: auto;

      @media #{$tablet} {
        @include font-size(24);
      }
    }

    .order-card__old-price {
      position: relative;
      z-index: 1;
      display: inline-block;
      vertical-align: top;
      @include font-size(48);
      color: #979797;
      margin-right: 16px;

      &::after {
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg width='116' height='32' viewBox='0 0 116 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.00002 29.5726C17.2243 23.1694 51.9522 3.01114 113.824 2.56366' stroke='%23F42D53' stroke-width='3.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        position: absolute;
        top: 6px;
        bottom: 0;
        left: -6px;
        right: -6px;
        z-index: 1;
      }
    }

    .order-card__offer-price {}
  }
}

.order-card__title {
  display: inline-block;
  @include font-size(28);
  font-family: $primary-family-semibold;
  margin-bottom: 24px;
  letter-spacing: -.45px;
  line-height: 1;

  @media #{$tablet} {
    @include font-size(24);
  }
}

.custom-radio {
  display: block;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.custom-radio__input {
  display: none;

  &:checked {
    +.custom-radio__content {
      border-color: var(--primary-color);
      background-color: var(--secondary-color);

      @media #{$tablet} {
        background-color: $white;
      }

      &::before {
        background-color: var(--primary-color);
        opacity: 1;
      }

      &::after {
        border-color: var(--primary-color);

        @media #{$tablet} {
          border-color: #ECECEC;
        }
      }
    }
  }
}

.custom-radio__content {
  padding: 21px 21px 21px 61px;
  @include font-size(18px);
  letter-spacing: -.45px;
  line-height: calc(22 / 18);
  position: relative;
  z-index: 1;
  border: 3px solid #ECECEC;
  border-radius: 12px;
  background-color: $white;
  transition: all 300ms ease;

  @media #{$tablet} {
    @include font-size(16);
    padding: 13px 13px 13px 49px;
  }

  &::after,
  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    transform: translateY(-50%);
    top: 50%;
    border-radius: 50%;
    transition: all 300ms ease;
  }

  &::after {
    height: 24px;
    width: 24px;
    border: 2px solid #ECECEC;
    left: 24px;

    @media #{$tablet} {
      height: 20px;
      width: 20px;
      left: 16px;
    }
  }

  &::before {
    height: 10px;
    width: 10px;
    background-color: #ECECEC;
    left: 31px;
    opacity: 0;

    @media #{$tablet} {
      height: 8px;
      width: 8px;
      left: 22px;
    }
  }
}

.custom-radio__price {
  display: inline-block;
  @include font-size(28);
  font-family: $primary-family-semibold;
  margin-bottom: 8px;
  line-height: 1;

  @media #{$tablet} {
    @include font-size(20);
    margin-bottom: 4px;
  }
}

.shop-button {
  padding: 32px 40px;
  border-radius: 16px;
  background-color: var(--primary-color);
  display: block;
  text-align: center;
  @include font-size(20);
  letter-spacing: -0.29px;
  color: $white;
  text-decoration: none;
  font-family: $primary-family-semibold;
  line-height: 1;
  margin-bottom: 24px;
  transition: all 300ms ease;
  will-change: transform;

  @media #{$tablet} {
    padding: 24px 40px;
  }

  &:hover,
  &:focus {
    transform: scale(1.02)
  }
}

.order-wrapper__addition-text {
  @include font-size(18);
  line-height: calc(22/18);
  text-align: center;
  margin-bottom: 0;

  a {
    font-family: $primary-family-medium;
    color: var(--primary-color);
    text-decoration: none;

    @media #{$tablet} {
      white-space: nowrap;
    }

    &:hover,
    &:focus {
      border-bottom: 2px solid var(--primary-color);
    }
  }
}


.product-order__title {
  @include font-size(48);
  letter-spacing: -0.31px;
  font-family: $primary-family-bold;
  line-height: calc(58/48);
  margin-bottom: 24px;

  @media #{$tablet} {
    @include font-size(36);
  }

  @media #{$phone} {
    @include font-size(28);
    margin-bottom: 32px;
  }

  .product-order--alt & {
    @include font-size(80);
    line-height: 1;

    @media screen and (max-width: 1600px) {
      @include font-size(60);
    }

    @media #{$small-screen} {
      @include font-size(48);
    }

    @media #{$tablet} {
      @include font-size(36);
    }

    @media #{$phone} {
      @include font-size(32);
      margin-bottom: 24px;
    }
  }
}

.product-order__description {
  @include font-size(20);
  line-height: calc(34/20);

  @media #{$tablet} {
    @include font-size(18)
  }

  @media #{$phone} {
    @include font-size(16)
  }

  .product-order--alt & {
    @include font-size(28);
    line-height: calc(42/28);

    @media #{$small-screen} {
      @include font-size(24)
    }

    @media #{$tablet} {
      @include font-size(20)
    }

    @media #{$phone} {
      max-width: 350px;
      margin: 0 auto 20px;
    }
  }
}

.product-order__image {
  margin-top: 52px;

  @media #{$small-screen} {
    max-width: 460px;
    margin-top: 30px;
  }

  @media #{$phone} {
    margin: 0 auto 30px;
    max-width: 320px;
  }


}

//Product showcase Slider

.product-showcase {
  margin-top: 48px;


  @media #{$tablet} {
    margin-top: 40px;
  }

  @media #{$phone} {
    margin-top: 30px;
  }

  .slick-list {
    border-radius: 22px;
    overflow: hidden;
    background-color: #F7F7F7;

    @media #{$tablet} {
      border-radius: 10px;
    }
  }

  .slick-track {
    display: flex;

    .slick-slide {
      height: auto;

      >div {
        height: 100%;
        display: flex;
      }
    }
  }

  .slick-dots {
    position: absolute;
    top: calc(100% + 13px);
    display: flex;
    justify-content: center;
    gap: 5px;
    left: 0;
    right: 0;

    li {
      margin: 0;

      &.slick-active {
        button {
          border-color: var(--primary-color);

          &::before {
            background-color: var(--primary-color);
            animation: move-left-to-right 5.8s linear infinite;
          }

          &:hover,
          &:focus {
            border-color: var(--primary-color);
          }
        }
      }

      button {
        border: 2px solid var(--primary-color);

        &:hover,
        &:focus {
          border-color: var(--primary-color);
        }

        &::before {}
      }
    }
  }

  .product-showcase__item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.product-showcase__image {
  width: 100%;
  margin: 0 auto;
  vertical-align: top;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  img {
    width: 100%;
    margin: 0 auto;
    vertical-align: top;
  }
}


.product-services {
  filter: drop-shadow(0 0 44px rgba($black, .05));
  border-radius: 16px;
  background-color: $white;
  margin-top: 64px;
  padding: 8px 32px;

  @media #{$tablet} {
    margin-top: 30px;
  }

  @media #{$phone} {
    display: none;
  }
}

.product-services__list {
  list-style: none;
  padding-left: 0;

  li {
    @include font-size(18);
    line-height: calc(22/18);
    font-family: $primary-family-medium;
    padding-left: 56px;
    position: relative;
    z-index: 1;
    padding: 28px 0 28px 56px;
    letter-spacing: -0.3px;

    @media #{$tablet} {
      @include font-size(16);
    }

    &:not(:last-child) {
      &::after {
        display: block;
      }
    }



    &::after {
      content: '';
      border-radius: 5px;
      position: absolute;
      left: 0;
      right: 0;
      z-index: -1;
      height: 2px;
      background-color: #ECECEC;
      bottom: 0;
      display: none;
    }

    svg {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
}
