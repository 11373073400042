.app-hero {
  padding: 90px 0;

  @media #{$tablet} {
    padding: 60px 0;
  }

  @media #{$phone} {
    padding: 35px 0 50px;
  }

  .container {
    max-width: 1256px;
  }
}

.app-hero__header {
  max-width: 764px;
  margin: 0 auto;
  width: 100%;
  text-align: center;

  @media #{$phone} {
    padding: 0 10px;
  }
}

.app-hero__heading {
  font-size: clamp(2rem, 4vw, 4.375rem);
  line-height: calc(80/70);
  letter-spacing: -.3px;
}

.app-hero__description {
  @include font-size(27);
  line-height: 1.5;
  margin-bottom: 40px;

  @media #{$tablet} {
    @include font-size(20);
    letter-spacing: -0.3px;
    line-height: calc(27/20);
    margin-bottom: 20px;
  }
}

.app-hero__grid {
  display: flex;
}

.app-hero__image {
  width: calc(100% - 528px);
  padding: 0 124px 0 73px;

  @media screen and (max-width:1200px) {
    padding: 0 80px 0 60px;
  }

  @media screen and (max-width:960px) {
    padding: 40px 40px 0 0;
    width: 45%;
  }

  img {
    @media screen and (min-width:768px) and (max-width:960px) {
      position: relative;
      left: -80px;
      max-width: calc(100% + 80px);
    }
  }

  @media #{$phone} {
    padding: 0;
    max-width: 319px;
    width: 100%;
    margin: 0 auto 36px;
    left: -40px;
    position: relative;
  }
}

.app-hero__image--for-phone {
  display: none;

  @media #{$phone} {
    display: block;
  }
}

.app-hero__image--for-desktop {
  @media #{$phone} {
    display: none;
  }
}

.app-hero__content {
  text-align: center;
  max-width: 528px;
  width: 100%;
  padding-top: 84px;

  @media screen and (max-width:960px) {
    max-width: 100%;
    width: 55%;
    padding-top: 40px;
  }

  @media #{$phone} {
    margin: 0 auto;
    width: 100%;
    padding-top: 20px;
  }

  .icon-stars {
    width: 178px;
    margin-bottom: 45px;

    @media #{$tablet} {
      margin-bottom: 30px;
    }

    @media #{$phone} {
      max-width: 108px;
      margin-bottom: 14px;
    }
  }
}

.app-hero__quote-card {

  @media #{$phone} {
    max-width: 273px;
    width: 100%;
    border-radius: 20px;
    box-shadow: 0px 15.3081px 63.7836px rgba(0, 0, 0, 0.08);
    background-color: $white;
    padding: 32px 12px 16px;
    margin: 0 auto 53px;
  }
}

.app-hero__quote {
  max-width: 403px;
  width: 100%;
  margin: 0 auto 65px;
  line-height: auto;
  @include font-size(24);
  line-height: calc(26/24);
  letter-spacing: -.03em;

  @media #{$tablet} {
    margin-bottom: 40px;
  }

  @media #{$phone} {
    @include font-size(16);
    line-height: calc(18/16);
    margin-bottom: 0;
  }

  p {
    margin-bottom: 26px;

    @media #{$phone} {
      margin-bottom: 16px;
    }
  }
}

.app-hero__author {
  display: inline-block;
  font-style: normal;

  strong {
    @media #{$phone} {
      font-family: $primary-family-medium;
    }
  }
}

.app-hero__buttons {
  display: flex;
  gap: 28px;
  justify-content: center;
  margin-bottom: 90px;
  flex-wrap: wrap;

  @media #{$desktop} {
    gap: 20px;
  }

  @media #{$tablet} {
    margin-bottom: 50px;
  }

  @media #{$phone} {
    margin-bottom: 60px;
  }

  .btn--app-ios {
    background-color: $black;
    border-color: $black;
  }

  .btn--app-android {
    background-color: $white;
    border-color: $white;
    color: $black;
    box-shadow: 0px 12px 50px rgba(0, 0, 0, 0.08);
  }
}

.btn--app-ios,
.btn--app-android {
  min-width: 250px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  @include font-size(16);
  padding: 18px 65px;
  will-change: transform;
  backface-visibility: hidden;
  font-family: $primary-family-medium;
  font-weight: normal;
  transform: translateZ(0);
  text-align: center;
  letter-spacing: -.3px;
  line-height: calc(18/16);

  @media #{$phone} {
    min-width: 200px;
    padding: 13px 20px;
  }

  img {
    margin-right: 10px;
  }

  &:hover,
  &:focus {
    transform: translateZ(0) scale(1.05);
  }
}

.app-hero__badge {
  max-width: 352px;
  width: 100%;
  margin: 0 auto;
  min-height: 100px;

  @media #{$phone} {
    max-width: 312px;
  }
}
