.spoiler-alert {
  background-color: $white;
  padding: 188px 0;

  @media #{$desktop} {
    padding: 120px 0;
  }

  @media #{$phone} {
    padding: 160px 0 200px;
  }
}

.spoiler-alert__header {
  text-align: center;
}

.spoiler-alert__title {
  letter-spacing: -0.3px;
  @include font-size(28);
  line-height: 1.1;
  max-width: 600px;
  margin: 0 auto 16px;

  @media #{$phone} {
    max-width: 220px;
    margin-bottom: 14px;
    @include font-size(18);
  }

  br {
    @media #{$phone} {
      display: none;
    }
  }
}

.spoiler-alert__description {
  @include font-size(22);
  letter-spacing: -0.3px;
  font-family: $primary-family;
  line-height: calc(32 / 22);
  position: relative;
  z-index: 1;
  display: inline-block;
  max-width: 445px;
  margin: 0 auto;

  @media #{$phone} {
    @include font-size(16);
    max-width: 240px;
    line-height: 1.3;
  }

  strong {
    font-family: $primary-family-semibold;
    text-transform: capitalize;
  }

  span {
    position: relative;
    display: inline-block;

    &::after {
      content: "";
      display: inline-block;
      background-color: $white;
      border-radius: 4px;
      background: rgba(217, 217, 217, 0.70);
      backdrop-filter: blur(7.5px);
      left: -0.1em;
      right: -0.1em;
      bottom: 0.1em;
      top: 0.1em;
      position: absolute;

      .in-view & {
        opacity: 0;
        transition: opacity 0.6s ease-in-out 1s;
      }
    }
  }
}
