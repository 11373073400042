.hero--alt {
  padding-top: 90px;
  padding-bottom: 127px;
  background: $light-gray;

  @media #{$tablet} {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  @media #{$phone} {
    padding-top: 50px;
    padding-bottom: 50px;
    margin-bottom: 0;
  }

  .container {
    max-width: 1356px;
  }

  .hero__grid {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .hero__media,
  .hero__content {
    width: 50%;
    text-align: left;
  }

  .hero__media {
    padding-top: 40px;

    @media #{$phone-portrait} {
      width: 40%;
      padding-top: 18px;
    }

    img {
      max-width: 520px;
      width: 100%;

      @media #{$desktop} {
        max-width: 420px;
      }

      @media #{$phone-portrait} {
        max-width: 186px;
        position: relative;
        left: -52px;
        width: auto;
      }
    }
  }

  .hero__content {
    @media #{$phone-portrait} {
      width: 60%;
    }
  }

  .hero__description {
    color: #1D1D1B;
    @include font-size(34);
    font-family: $alt-family;
    max-width: 477px;
    line-height: 1;
    margin-bottom: 60px;

    @media #{$desktop} {
      margin-bottom: 40px;
    }

    @media #{$tablet} {
      @include font-size(28);
    }

    @media #{$phone} {
      @include font-size(24);
      margin-bottom: 30px;
    }

    @media #{$phone-portrait} {
      @include font-size(15);
    }
  }

  .hero__content-img {
    margin-bottom: 54px;
    max-width: 420px;

    @media #{$desktop} {
      margin-bottom: 34px;
      max-width: 320px;
    }

    @media #{$phone} {
      max-width: 186px;

      margin-bottom: 22px;
    }
  }


  .hero__title--fancy {
    line-height: 1;
    @include font-size(60);
    margin-bottom: 44px;
    text-align: left;
    letter-spacing: -.3px;

    @media #{$desktop} {
      margin-bottom: 34px;
    }

    @media #{$tablet} {
      @include font-size(40);
    }

    @media #{$phone} {
      @include font-size(32);
      margin-bottom: 24px;
    }

    @media #{$phone-portrait} {
      @include font-size(20);
      margin-bottom: 18px;
    }

    .primary-title {
      display: block;
      margin: 52px 0 40px;

      @media #{$tablet} {
        margin: 32px auto 20px;
      }
    }

    .secondary-title {
      display: block;
      line-height: 1.1;
    }
  }
}
