.hero-carousel {
  padding: 0 0 2px;

  @media #{$tablet} {
    padding-top: 0;
  }

  .slick-dots {
    justify-content: center;
    gap: 10px;

    li {
      margin: 0;
      height: auto;
      border-radius: none;

      &.slick-active {
        button {
          outline-color: $black;
          width: 24px;

          &:hover,
          &:focus {
            outline: 2px solid $black !important;
          }

          &::after {
            transform: translateX(var(--progress));
          }
        }
      }

      button {
        height: 6px;
        width: 6px;
        outline: 2px solid #d3d3d3;
        will-change: width;
        border: none;
        overflow: hidden;

        &:hover,
        &:focus {
          border-color: none;
          outline: 2px solid #d3d3d3 !important;

          &::after {
            background-color: $black;
          }
        }

        &::before {
          display: none;
        }

        &::after {
          content: "";
          height: 6px;
          border-radius: inherit;
          left: -1px;
          top: 0;
          background-color: $black;
          position: absolute;
          z-index: 1;
          width: 28px;
          transform: translateX(-100%);
        }
      }
    }
  }
}

.hero-carousel__container {
  margin: 0 auto;
  width: 100%;
  padding: 0 26px;
  max-width: 1540px;

  @media #{$desktop} {
    padding: 0 20px;
  }

  @media #{$phone} {
    padding: 0;
  }

  .slick-list {
    overflow: visible;
    margin: 0 0 24px 0 !important;

    @media #{$phone} {
      overflow: hidden;
    }

    .slick-track {
      display: flex;

      .slick-slide {
        display: flex;
        height: auto;

        @media #{$phone} {
          margin: 0 16px;
        }

        >div {
          display: flex;
          width: 100%;
        }
      }
    }
  }
}

.hero-carousel__item {
  min-height: 680px;
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 137px 110px 140px;
  max-width: 1440px;
  margin: 0 10px;
  border-radius: 16px;
  background-color: #ccccce;

  @media #{$desktop} {
    padding: 90px 60px;
    min-height: 560px;
  }

  @media #{$tablet} {
    min-height: 500px;
  }

  @media #{$phone} {

    padding: 0 0 40px;
    max-width: 480px;
    min-height: auto;
    margin: 0 auto;

  }
}

.hero-carousel__item--black-friday {
  color: $white;
  background-color: #1C1B1A;
}

.hero-carousel__item-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 16px;

  @media #{$phone} {
    position: static;
    aspect-ratio: auto 716 / 622;
    width: 100%;
    display: block;
    border-radius: 16px 16px 0 0;
  }
}

.hero-carousel__item-content {
  @media #{$phone} {
    padding: 0 30px;
    margin-top: -25px;

    .hero-carousel__item--black-friday & {
      margin-top: 0;
    }
  }

  .button {
    @include font-size(18);

    @media #{$phone} {
      @include font-size(14);
      padding: 10px 16px;
      font-family: $primary-family-semibold;
    }
  }
}

.hero-carousel__item-content-inner {
  .hero-carousel__item--black-friday & {
    max-width: 432px;

    @media #{$tablet} {
      max-width: 320px;
    }

    @media #{$phone} {
      max-width: 274px;
    }
  }
}

.hero-carousel__leadtext {
  @include font-size(18);
  font-family: $primary-family-medium;
  line-height: 1.5;
  font-weight: normal;
  margin-bottom: 8px;
  display: block;
  text-transform: uppercase;

  @media #{$desktop} {
    @include font-size(16);
  }

  @media #{$phone} {
    @include font-size(14);
    font-family: $primary-family-semibold;
  }
}

.hero-carousel__title {
  @include font-size(48);
  margin-bottom: 18px;
  line-height: 1.1;
  letter-spacing: -0.3px;

  @media #{$desktop} {
    @include font-size(40);
  }

  @media #{$tablet} {
    @include font-size(36);
  }

  @media #{$phone} {
    @include font-size(24);

    .hero-carousel__item--black-friday & {
      max-width: 210px;
    }
  }
}

.hero-carousel__description {
  @include font-size(18);
  font-family: $primary-family;
  line-height: 1.3;
  font-weight: normal;
  letter-spacing: -0.3px;
  max-width: 273px;
  margin-bottom: 124px;

  .hero-carousel__item--black-friday & {
    max-width: 366px
  }

  @media #{$desktop} {
    @include font-size(16);
    margin-bottom: 80px;
  }

  @media #{$tablet} {
    margin-bottom: 40px;
  }

  @media #{$phone} {
    @include font-size(14);
    margin-bottom: 32px;
  }
}

.hero-carousel__offer-card {
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  bottom: 98px;
  right: 80px;
  z-index: 1;
  text-align: right;

  @media #{$desktop} {
    bottom: 80px;
  }

  @media #{$tablet} {
    bottom: 40px;
    right: 40px;
  }

  @media #{$phone} {
    position: relative;
    bottom: 0;
    right: 0;
    padding: 46px 30px 60px;
    text-align: left;
  }
}

.hero-carousel__offer-title {
  display: inline-block;
  vertical-align: middle;
  @include font-size(173);
  line-height: .8;
  letter-spacing: -0.03em;

  @media #{$desktop} {
    @include font-size(140);
  }

  @media #{$tablet} {
    @include font-size(100);
  }

  @media #{$phone} {
    @include font-size(77);
  }
}

.hero-carousel__badge {
  @include font-size(16);
  font-family: $primary-family-medium;
  background-color: $black;
  padding: 30px 16px 10px;
  border-radius: 50%;
  color: $white;
  height: 193px;
  width: 193px;
  text-align: center;
  align-content: center;
  letter-spacing: -0.03em;
  transform: rotate(6.708deg);
  line-height: 1;
  position: absolute;
  top: 100px;
  right: 120px;
  z-index: 9;

  .hero-carousel__item--black-friday & {
    background-color: #FFB317;
    color: $black;
    @include font-size(22);
    left: -55px;
    bottom: -37px;
    top: auto;
    right: auto;
    transform: rotate(5.708deg);

    @media #{$desktop} {
      @include font-size(18);
      bottom: -30px;
    }

    @media #{$tablet} {
      @include font-size(16);
      height: 120px;
      width: 120px;
      padding: 25px 10px 10px;
      left: -30px;
      bottom: -20px;
    }

    @media #{$phone} {
      @include font-size(13);
      height: 93px;
      width: 93px;
      padding: 15px 10px;
      transform: rotate(4.305deg);
      bottom: 32px;
      right: -5px;
      left: auto;
    }
  }

  @media #{$desktop} {
    height: 160px;
    width: 160px;
    @include font-size(14);
    top: 80px;
    right: 80px;
  }

  @media #{$tablet} {
    top: 40px;
    right: 40px;
    height: 140px;
    width: 140px;
  }

  @media #{$phone} {
    @include font-size(10);
    height: 113px;
    width: 113px;
    padding: 25px 10px 10px;
    right: 12px;
    top: 20px;
  }

  span {
    @include font-size(26);
    font-family: $primary-family-bold;
    display: block;
    margin-bottom: 4px;

    @media #{$desktop} {
      @include font-size(20);
    }

    @media #{$tablet} {
      @include font-size(18);
    }

    @media #{$phone} {
      @include font-size(16);
      margin-bottom: 2px;
    }
  }

  strong {
    @include font-size(34);
    font-family: $primary-family-bold;
    display: block;
    margin-top: 12px;
    font-weight: normal;

    .hero-carousel__item--black-friday & {
      @include font-size(60);
      line-height: 1;
      margin-top: 8px;
    }

    @media #{$desktop} {
      @include font-size(28);
      margin-top: 10px;

      .hero-carousel__item--black-friday & {
        @include font-size(50);
        margin-top: 6px;
      }
    }

    @media #{$tablet} {
      @include font-size(26);

      .hero-carousel__item--black-friday & {
        @include font-size(40);
        margin-top: 4px;
      }
    }

    @media #{$phone} {
      @include font-size(20);
      margin-top: 6px;

      .hero-carousel__item--black-friday & {
        @include font-size(27);
      }
    }
  }
}
