.awards-credit {
  position: relative;
  z-index: 1;
  padding: 100px 0;
  background-color: $black;

  @media #{$desktop} {
    padding: 80px 0;
  }
}

.awards-credit__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  z-index: -1;

  @media #{$phone} {
    aspect-ratio: 745/407;
    height: auto;
    width: 745px;
    height: auto;
    left: 50%;
    transform: translateX(-50%);
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg,
        rgba(0, 0, 0, 0.09) 20.66%,
        rgba(0, 0, 0, 0.9) 85.26%);
    background-size: cover;
    background-position: center;
    z-index: -1;

    @media #{$phone} {
      background: linear-gradient(180deg,
          rgba(0, 0, 0, 0.09) 20.66%,
          rgba(0, 0, 0, 1) 100%);
    }
  }
}

.awards-credit__header {
  text-align: center;
  color: $white;
  margin-bottom: 60px;

  @media #{$desktop} {
    margin-bottom: 40px;
  }

  @media #{$phone} {
    margin-bottom: 32px;
  }
}

.awards-credit__title {
  letter-spacing: -0.3px;
  @include font-size(48);
  line-height: 1;
  font-family: $primary-family-bold;
  text-align: center;
  max-width: 660px;
  margin: 0 auto;

  @media #{$tablet} {
    @include font-size(32);
    max-width: 361px;
  }

  @media #{$phone} {
    @include font-size(24);
    max-width: 252px;
  }
}

.awards-credit__body {
  color: $white;
  max-width: 1270px;
  margin: 0 auto;
  width: 100%;
  text-align: center;
}

.marquee-content {
  display: block;
}

.awards-credit__marquee {
  display: flex;
  flex-direction: column;
}

.awards-credit__body-item {
  flex-shrink: 0;
  padding: 10px 0;
  color: $white;
}

.awards-credit__logos {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
  margin-bottom: 68px;

  @media #{$tablet} {
    margin-bottom: 30px;
  }

  @media #{$phone} {
    flex-direction: column;
    gap: 0;
    margin: 0 auto 40px;
  }
}

.awards-credit__logo {
  width: 100%;
  height: auto;
  position: relative;

  &:last-child {
    &::after {
      display: none;
    }
  }

  &:not(:last-child) {
    @media #{$phone} {
      margin-bottom: 37px;
    }
  }

  &::after {
    content: "";
    height: 5px;
    width: 5px;
    border-radius: 50%;
    display: none;
    background-color: $white;
    left: 50%;
    transform: translate(-50%);
    bottom: -22px;
    z-index: 1;
    position: absolute;

    @media #{$phone} {
      display: block;
    }
  }

  img {
    @media #{$phone} {
      margin: 0 auto;
    }
  }
}

.awards-credit__text {
  @include font-size(22);
  line-height: 1.6;
  letter-spacing: -0.8px;
  font-family: $primary-family;

  @media #{$tablet} {
    @include font-size(18);
  }

  @media #{$phone} {
    @include font-size(16);
  }

  strong {
    font-family: $primary-family-medium;
    text-align: center;
  }
}
