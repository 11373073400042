.hero-christmas {
  padding: 0 20px 20px;

  @media #{$phone} {
    padding: 10px;
  }
}

.hero-christmas__card {
  background-color: #EDF0F7;
  border-radius: 15px;
  padding: 99px 0 0;
  overflow: hidden;
  position: relative;
  z-index: 1;

  @media #{$desktop} {
    padding: 90px 0 50px;
  }

  @media #{$phone} {
    padding: 40px 0;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-image: url('assets/images/snow.png');
    background-size: contain;
    background-repeat: repeat;
    background-position: center center;
    pointer-events: none;
  }
}

.hero-christmas__card-inner {
  max-width: 1440px;
  padding: 0 20px;
  margin: 0 auto;

  @media #{$phone} {
    padding: 0;
  }
}

.hero-christmas__title {
  font-size: clamp(1.75rem, 4vw, 3rem);
  letter-spacing: -.03em;
  font-family: $primary-family-bold;
  text-align: center;
  line-height: 1;

  @media #{$phone} {
    margin-bottom: 0;
    margin-left: -.3em;
  }

  strong {
    color: $brand-color--secondary;
    display: block;
    font-size: clamp(9.375rem, 22vw, 19.375rem);
    line-height: calc(310/290);
    letter-spacing: -.03em;
    line-height: 1;
    font-family: $primary-family-black;
  }

  .is-desktop {
    @media #{$phone} {
      display: none;
    }
  }

  .is-phone {
    display: none;

    @media #{$phone} {
      display: block;
    }
  }
}

.hero-christmas__image {
  width: calc(100% + 95px);
  margin: -170px -70px -160px -48px;

  @media #{$desktop} {
    margin: -150px -70px -120px -48px;
  }

  @media #{$tablet} {
    margin: -120px -70px -100px -48px;
  }

  @media #{$phone} {
    margin: -90px -70px -80px -48px;
  }

  @media screen and (max-width: 640px) {
    margin: -74px -70px 0 -48px;
    display: flex;
    justify-content: center;
  }

  img {
    @media screen and (max-width: 640px) {
      max-width: none;
      width: 640px;
      margin: 0 auto;
    }

  }

  .blank-placeholder {
    @media screen and (max-width: 640px) {
      aspect-ratio: auto !important;
      height: 364px;
    }
  }
}


.hero-christmas__content {
  max-width: 640px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 2;

  p {
    @include font-size(26);
    line-height: calc(34/26);

    @media #{$tablet} {
      @include font-size(20)
    }

    @media #{$phone} {
      @include font-size(14)
    }
  }
}

.hero-christmas__subtitle {
  @include font-size(48);
  letter-spacing: -0.3px;
  line-height: calc(58/48);

  @media #{$tablet} {
    @include font-size(28);
  }

  @media #{$phone} {
    letter-spacing: -0.17px;
    @include font-size(26);
    line-height: calc(32/26);
    margin-bottom: 15px;
  }

  strong {
    background-image: url("data:image/svg+xml,%3Csvg width='311' height='9' viewBox='0 0 311 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M307.237 5.61996C291.006 4.62607 273.067 4.21129 256.419 4.20658C243.806 4.20301 231.237 3.81786 218.667 3.83566C195.338 3.86869 172.116 4.11377 148.794 4.14679C130.054 4.17333 111.394 4.62414 92.6177 4.65073C75.599 4.67483 58.5451 4.79614 41.5757 4.41651C28.7496 4.12957 15.9474 4.3571 3.14731 3.92866' stroke='%23F42D53' stroke-width='6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center bottom;
    padding-bottom: 2px;
    display: inline-block;
    background-size: contain;

    @media #{$phone} {
      padding-bottom: 0;
      background-image: url("data:image/svg+xml,%3Csvg width='119' height='5' viewBox='0 0 119 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M116.449 2.88954C110.363 2.40354 103.638 2.19896 97.3966 2.19404C92.668 2.19031 87.9557 2.00098 83.2431 2.00765C74.4973 2.02004 65.7913 2.13558 57.048 2.14796C50.0223 2.15791 43.0267 2.37424 35.9874 2.38421C29.6071 2.39324 23.2136 2.44956 16.8516 2.26222C12.0431 2.12063 7.24356 2.22928 2.44472 2.01886' stroke='%23F42D53' stroke-width='3.34726' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
  }
}

.is-phone {
  @media (min-width:768px) {
    display: none;
  }
}

.is-desktop {
  @media (max-width:767px) {
    display: none;
  }
}
