.install-block {
  padding: 80px 0 62px;

  @media #{$tablet} {
    padding: 60px 0 50px;
  }

  @media #{$phone} {
    padding: 50px 0 30px;
  }

  .container {
    max-width: 1270px;

    @media #{$phone} {
      overflow: hidden;
    }
  }
}

.install-block__intro {
  padding-left: 20%;

  @media #{$tablet} {
    padding-left: 0;
  }
}

.install-block__intro-subtitle {
  @include font-size(18);
  font-family: $primary-family-medium;
  margin-bottom: 25px;
}

.install-block__intro-title {
  @include font-size(60);
  margin-bottom: 15px;
  max-width: 450px;
  line-height: 1.05;

  @media #{$large-screen} {
    @include font-size(48);
  }

  @media #{$tablet} {
    @include font-size(38);
  }
}

.install-block__intro-description {
  max-width: 786px;
  @include font-size(24);
  color: rgba($black, 0.8);
  line-height: 1.6;

  @media #{$large-screen} {
    @include font-size(20);
    max-width: 640px;
  }

  @media #{$desktop} {
    max-width: 640px;
  }

  @media #{$phone} {
    @include font-size(16);
  }
}

.install-block__carousel-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 92px;
  position: relative;
  margin-bottom: 92px;
  overflow: hidden;

  @media #{$tablet} {
    margin-top: 45px;
    margin-bottom: 60px;
  }

  @media #{$phone} {
    margin-top: 30px;
  }
}

.install-block__image-carousel {
  width: 46.4%;

  @media #{$desktop} {
    left: -50px;
  }

  @media #{$tablet} {
    width: 100%;
    left: 0;
  }
}

.install-block__image-slide {
  @media #{$tablet} {
    padding-right: 120px;
  }

  @media #{$phone} {
    padding-right: 100px;
  }

  @media #{$phone-portrait} {
    padding-right: 70px;
  }

  img {
    @media #{$tablet} {
      max-width: 400px;
      margin: 0 auto;
      width: 100%;
    }
  }
}

.install-block__card-wrapper {
  width: 48%;
  margin-top: 40px;
  position: relative;
  left: 100px;

  @media #{$desktop} {
    left: 0;
  }

  @media screen and (max-width: 1200px) {
    width: 53.5%;
    margin-left: 0;
  }

  @media #{$tablet} {
    width: 100%;
    margin-left: 0;
  }

  @media #{$phone} {
    padding-top: 0;
  }
}

.install-block__card-carousel {
  .slick-list {
    overflow: visible;

    @media #{$tablet} {
      height: auto !important;
    }
  }

  .slick-track {
    @media #{$tablet} {
      display: flex !important;
    }

    .slick-slide {
      @media #{$tablet} {
        height: auto !important;
      }

      &.slick-current {
        .install-block__card-slide {
          border-color: transparent !important;
          box-shadow: 0 12px 50px rgba(0, 0, 0, 0.08);

          .install-block__card-title {
            font-family: $primary-family-semibold;
          }
        }
      }

      > div {
        padding: 0 40px;

        @media #{$tablet} {
          padding: 0 10px;
          height: 100%;
        }

        .install-block__card-slide {
          @media #{$tablet} {
            height: 100%;
          }
        }
      }
    }
  }
}

.install-block__card-slide {
  background: #ffffff;
  border-radius: 15px;
  padding: 28px 40px 45px;
  border: 2px solid #e6e6e6 !important;
  cursor: pointer;
  position: relative;
  margin: 8px 0;
  transition: all 0.3s ease;
  max-width: 100%;

  @media #{$large-screen} {
    padding: 18px 30px 25px;
  }

  @media #{$tablet} {
    margin: 0;
  }

  @media #{$phone} {
    padding: 30px 33px 55px;
  }
}

.install-block__card-number {
  font-family: $primary-family-semibold;
  @include font-size(30);
  color: $primary-color;
  display: block;
  margin-bottom: 12px;
}

.install-block__card-title {
  font-family: $primary-family-medium;
  @include font-size(26);
  margin-bottom: 15px;

  @media #{$large-screen} {
    @include font-size(20);
  }

  @media #{$phone} {
    @include font-size(18);
    margin-bottom: 15px;
  }
}

.install-block__card-description {
  color: #8d8d8d;
  @include font-size(18);
  margin-bottom: 24px;

  @media #{$desktop} {
    @include font-size(16);
  }

  @media #{$phone} {
    line-height: 1.6;
  }
}

.install-block__bottom-content {
  @include font-size(26);
  text-align: center;

  @media #{$phone} {
    @include font-size(20);
  }

  @media #{$phone-portrait} {
    @include font-size(16);
  }

  a {
    color: $primary-color;
    transition: all 0.3s ease;

    &:hover {
      color: darken($primary-color, 20%);
    }
  }
}

.app-link--small {
  margin-left: 0;

  @media #{$desktop} {
    justify-content: flex-start;
  }

  .btn {
    padding: 14px 22px 14px 53px;
    min-width: auto;

    @media #{$tablet} {
      @include font-size(15);
      margin-left: 0;
      min-width: 163px;
    }

    img {
      top: 12px;
    }

    &.btn--apple-icon img {
      top: 10px;
    }
  }
}
