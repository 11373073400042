.offer-price-table {
  padding: 30px 0 16px;

  @media #{$desktop} {
    padding: 30px 0 50px;
  }

  @media #{$phone} {
    padding: 0 0 20px;
  }
}

.offer-price-table__grid {
  display: flex;
  gap: 30px;

  @media #{$tablet} {
    gap: 20px;
  }

  @media #{$phone} {
    flex-direction: column-reverse;
  }
}

.offer-price-table__column {
  &:first-child {
    width: 25%;
    opacity: .75;

    @media #{$phone} {
      width: 100%;
      display: none;
    }
  }

  &:last-child {
    width: 75%;

    @media #{$phone} {
      width: 100%;
    }
  }
}

.offer-card {
  background-color: $white;
  box-shadow: 0px 32px 44px 0px rgba(0, 0, 0, 0.05);
  border-radius: 16px;

  &.offer-card--black-friday {
    border: 4px solid #292929;
  }
}

.offer-card__header {
  text-align: center;
  padding: 25px 40px;
  border-radius: 10px 10px 0 0;

  @media #{$desktop} {
    padding: 25px 30px;
  }

  @media #{$tablet} {
    padding: 25px 20px;
  }

  .offer-card--regular & {
    background-color: #F3F3F3;
  }

  .offer-card--black-friday & {
    background-color: #292929;
    color: $white;
    padding-top: 21px;
  }
}

.offer-card__title {
  color: $black;
  font-family: $primary-family-medium;
  @include font-size(16);
  line-height: 1.1;
  letter-spacing: -0.26px;
  margin-bottom: 0;

  .offer-card--regular & {
    color: rgba($black, .5);
  }

  .offer-card--black-friday & {
    color: $white;
  }
}

.offer-card__body {
  padding: 40px;

  @media #{$desktop} {
    padding: 30px;
  }

  @media #{$tablet} {
    padding: 25px;
  }

  @media #{$phone} {
    padding: 40px;
  }


  .offer-card--black-friday & {
    display: flex;
  }

  &.offer-card__body--desktop {
    @media #{$phone} {
      display: none;
    }
  }

  &.offer-card__body--phone {
    display: none;

    @media #{$phone} {
      padding: 40px 0;
      display: block;
    }
  }

  .slick-dots {
    margin: 24px auto -16px;
    display: flex;
    justify-content: center;

    li {
      &.slick-active {
        button {
          width: 12px;
          height: 12px;
          border-color: $primary-color;
          background-color: $primary-color;

          &::before {
            background-color: $primary-color;
          }
        }
      }
    }
  }
}

.offer-card__item {
  width: 33.33%;

  &:not(:first-child) {
    padding-left: 32px;
    border-left: 1px solid #E5E5E5;

    @media #{$desktop} {
      padding-left: 24px;
    }

    @media #{$tablet} {
      padding-left: 20px;
    }
  }

  &:not(:last-child) {
    padding-right: 32px;

    @media #{$desktop} {
      padding-right: 24px;
    }

    @media #{$tablet} {
      padding-right: 20px;
    }
  }

  @media #{$phone} {
    width: 252px !important;
    border-right: 1px solid #E5E5E5;
    padding: 0 32px;
  }
}

.offer-card__body--phone {
  .slick-current {
    .offer-card__item {
      opacity: 1;
    }
  }

  .offer-card__item {
    opacity: .3;
    transition: all 300ms ease;
  }
}

.offer-card__count {
  color: $black;
  font-family: $primary-family-medium;
  @include font-size(24);
  line-height: 1.1;
  letter-spacing: -0.26px;
  margin-bottom: 6px;

  @media #{$desktop} {
    @include font-size(20);
  }
}

.offer-card__save-info {
  color: $black;
  font-family: $primary-family;
  @include font-size(20);
  line-height: 1;
  letter-spacing: -0.22px;
  margin-bottom: 32px;

  @media #{$desktop} {
    @include font-size(16);
    margin-bottom: 24px;
  }

  @media #{$phone} {
    margin-bottom: 32px;
  }
}

.offer-card__regular-price {
  color: #C2C2C2;
  font-family: $primary-family-medium;
  @include font-size(50);
  line-height: 1;
  letter-spacing: -3.2px;
  position: relative;
  display: inline-block;
  z-index: 1;
  margin-bottom: 8px;

  @media #{$desktop} {
    @include font-size(40);
  }

  @media #{$tablet} {
    @include font-size(28);
  }

  @media #{$phone} {
    @include font-size(40);
  }

  &::after {
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg width='220' height='26' viewBox='0 0 220 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.99343 23.5726C31.2496 18.0081 97.6716 -0.255907 218.015 2.79133' stroke='black' stroke-width='3.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    position: absolute;
    top: 3px;
    bottom: 0;
    left: -3px;
    right: -8px;

    @media #{$phone} {
      left: -5px;
      right: -11px;
    }
  }
}

.currency-sign {
  @include font-size(26);
  letter-spacing: -1.387px;
  margin-right: 4px;
  display: inline-block;

  @media #{$desktop} {
    @include font-size(22);
  }
}

.offer-card__offer-price {
  color: $black;
  font-family: $primary-family-medium;
  @include font-size(50);
  line-height: 1;
  letter-spacing: -3.2px;
  margin-bottom: 5px;

  @media #{$desktop} {
    @include font-size(40);
  }

  @media #{$tablet} {
    @include font-size(28);
  }

  @media #{$phone} {
    @include font-size(40);
  }
}

.offer-card__autofill-info {
  color: rgba($black, 1);
  margin-bottom: 32px;

  @media #{$desktop} {
    margin-bottom: 24px;
  }

  @media #{$phone} {
    margin-bottom: 32px;
  }

  &.offer-card__autofill-info--light {
    color: #C2C2C2;
  }

  font-family: $primary-family;
  @include font-size(20);
  line-height: 1;
  letter-spacing: -0.22px;

  @media #{$desktop} {
    @include font-size(16);
  }
}
