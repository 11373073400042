/*--------------------------------------------------------------
# Fields
--------------------------------------------------------------*/
input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
	color: #4B4B4B;
	border: none;
	border-radius: 0;
	padding: 9px 12px;
	box-shadow: none;
	background: transparent;
	@include font-size(18);
	&:focus {
		outline: none;
		color: $black;
	}
}