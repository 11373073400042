html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
  box-sizing: inherit;
}

body {
  background: #FFFFFF;
  /* Fallback for when there is no custom background color defined. */
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.components-loading {
  overflow: hidden !important;
  height: 100vh;
}

blockquote,
q {
  quotes: "" "";

  &:before,
  &:after {
    content: "";
  }
}

hr {
  background-color: #666;
  border: 0;
  height: 1px;
}

/*--------------------------------------------------------------
# List
--------------------------------------------------------------*/
ul,
ol {
  margin: 0;
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
}

li>ul,
li>ol {
  margin: 0;

}

dt {
  font-weight: bold;
}

dd {
  margin: 0;
}

img {
  height: auto;
  max-width: 100%;
  vertical-align: top;
}

figure {
  margin: 0;
}

table {
  margin: 0;
  width: 100%;
}

sup {
  font-size: 65%;
  top: -0.5em;
}

.image-placeholder {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
  display: block;
  z-index: 8;
  border-radius: 10px;
  animation: gleam 2s ease-in-out infinite;
  background-image: -webkit-linear-gradient(left, #E9EAED, #f8f8f8, #E9EAED);
  background-size: 600px auto;
}

@keyframes gleam {
  0% {
    background-position: 0 0
  }

  100% {
    background-position: 600px 0
  }
}

.blank-placeholder {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
  display: block;
  z-index: 8;
}

.img-relative {
  position: relative;
  z-index: 1;

  img {
    transition: opacity 300ms ease;
  }
}

.img-absolute {
  position: absolute;
  z-index: 1;
  top: 0;

  .offer-block__image & {
    bottom: 0;
  }
}

.img-absolute img {
  .offer-block__image & {
    height: 100%;
    width: auto;
    margin: 0 auto;
  }
}

.fallback-loading {
  width: 100%;
  background-color: $black;
  position: relative;
  display: flex;
  align-items: center;

  &::after {
    content: '';
    position: fixed;
    z-index: 997;
    height: 100vh;
    left: 0;
    top: 0;
    right: 0;
    animation: gleam 2s ease-in-out infinite;
    background-image: -webkit-linear-gradient(left, #E9EAED, #f8f8f8, #E9EAED);
    background-size: 600px auto;
  }
}
