.mila-feeds {
  padding: 142px 0 95px;

  @media #{$desktop} {
    padding: 100px 0 70px;
  }

  @media #{$tablet} {
    padding: 80px 0 50px;
  }

  @media #{$phone} {
    padding: 80px 0;
  }
}

.mila-feeds__header {
  text-align: center;
  max-width: 930px;
  margin: 0 auto 60px;

  @media #{$phone} {
    max-width: 334px;
    margin-bottom: 56px;
  }
}

.mila-feeds__title {
  letter-spacing: -0.3px;
  @include font-size(48);
  line-height: 1;
  font-family: $primary-family-bold;
  text-align: center;
  max-width: 680px;
  margin: 0 auto 24px;

  @media #{$tablet} {
    @include font-size(32);
    max-width: 308px;
  }

  @media #{$phone} {
    @include font-size(24);
  }
}

.mila-feeds__short-description {
  @include font-size(22);
  letter-spacing: -0.3px;
  line-height: 1.5;

  @media #{$phone} {
    @include font-size(18);
    letter-spacing: -0.4px;
  }
}

.mila-feeds__grid {
  display: flex;
  gap: 27px;
}

.mila-feeds__slider {

  @media #{$tablet} {
    padding-bottom: 102px;
  }

  .slick-list {
    overflow: visible;
    width: 100%;
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    padding: 0 13px;
    flex: 0 0 auto;

    @media #{$phone} {
      padding: 0 10px;
    }
  }

  .slick-arrow {
    color: $black;
    bottom: 0;
    position: absolute;
    z-index: 1;
    transition: transform 0.3s ease;

    &:hover,
    &:focus {
      transform: scale(1.06);
    }

    &.slick-prev {
      left: calc(50% - 52px);
    }

    &.slick-next {
      left: calc(50% + 6px);
    }

    .icon {
      height: 46px;
      width: 46px;
    }
  }
}

.mila-feeds__item {
  flex: 0 0 auto;
}

.mila-feeds__item-feed {
  height: auto;
  position: relative;
  border-radius: 14px;
  aspect-ratio: 306/542;
  cursor: pointer;
  max-width: 306px;
  width: 100%;

  @media #{$phone} {
    max-width: 234px;
  }
}

.mila-feeds__item-poster {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  border-radius: inherit;
  z-index: 1;
  transition: opacity 0.3s ease;

  @media screen and (min-width: 769px) {
    opacity: 1 !important;
  }

  &:hover {
    @media screen and (min-width: 769px) {
      opacity: 0 !important;
    }
  }

  .slick-active.slick-center & {
    opacity: 0 !important;
  }
}


.mila-feeds__item-video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: inherit;
  background-color: $light-gray;
}
