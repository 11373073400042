.airblivious-section {
  // padding: 80px 0;

  // @media #{$phone} {
  //   padding: 80px 0 28px;
  // }
  padding: 20px 0 80px;

  @media #{$phone} {
    padding: 20px 0 28px;
  }

  .container {
    @media #{$phone} {
      padding: 0 30px;
    }
  }

  .slick-slide {
    display: block;
  }

  //   .slick-dots {
  //     justify-content: center;
  //     gap: 10px;
  //     position: absolute;
  //     left: 0;
  //     right: 0;
  //     display: none !important;


  //     @media #{$phone} {
  //       top: 272px;
  //       display: flex !important;
  //     }

  //     li {
  //       margin: 0;
  //       height: auto;
  //       border-radius: none;

  //       &.slick-active {
  //         button {
  //           outline-color: $black;
  //           width: 24px;

  //           &:hover,
  //           &:focus {
  //             outline: 2px solid $black !important;
  //           }

  //           &::after {
  //             transform: translateX(var(--progress));
  //           }
  //         }
  //       }

  //       button {
  //         height: 6px;
  //         width: 6px;
  //         outline: 2px solid #d3d3d3;
  //         will-change: width;
  //         border: none;
  //         overflow: hidden;

  //         &:hover,
  //         &:focus {
  //           border-color: none;
  //           outline: 2px solid #d3d3d3 !important;

  //           &::after {
  //             background-color: $black;
  //           }
  //         }

  //         &::before {
  //           display: none;
  //         }

  //         &::after {
  //           content: "";
  //           height: 6px;
  //           border-radius: inherit;
  //           left: -1px;
  //           top: 0;
  //           background-color: $black;
  //           position: absolute;
  //           z-index: 1;
  //           width: 28px;
  //           transform: translateX(-100%);
  //         }
  //       }
  //     }
  //   }

}

.airblivious-section__header {
  text-align: center;
  margin-bottom: 80px;

  @media #{$desktop} {
    margin-bottom: 50px;
  }

  @media #{$phone} {
    margin-bottom: 48px;
  }
}

.airblivious-section__title {
  letter-spacing: -0.8px;
  @include font-size(68);
  margin-bottom: 10px;
  line-height: 1;
  margin-bottom: 10px;

  @media #{$desktop} {
    @include font-size(48);
    letter-spacing: -0.3px;
  }

  @media #{$tablet} {
    @include font-size(36);
    margin-bottom: 24px;
    line-height: 1.1;
  }

  @media #{$phone} {
    line-height: 1;
    @include font-size(32);
  }
}

.airblivious-section__sub-title {
  color: $black;
  text-align: center;
  font-family: $primary-family;
  @include font-size(28);
  line-height: 1;
  letter-spacing: -0.391px;
  margin-bottom: 24px;

  @media #{$desktop} {
    margin-bottom: 20px;
    @include font-size(24);
  }

  @media #{$phone} {
    @include font-size(20);
    margin-bottom: 14px;
    letter-spacing: -0.3px;
  }

  strong {
    font-family: $primary-family-bold;
  }
}

.airblivious-section___description {
  @include font-size(22);
  line-height: 1.4;
  letter-spacing: -0.39px;
  max-width: 484px;
  margin: 0 auto;

  @media #{$desktop} {
    @include font-size(18);
  }

  @media #{$phone} {
    line-height: 1.3;
    @include font-size(16);
    max-width: 286px;
    letter-spacing: -0.4px;
  }
}

.airblivious-section__item {
  width: 560px;

  @media #{$desktop} {
    width: 420px;
  }

  @media #{$tablet} {
    width: 358px;
  }

  @media #{$phone} {
    width: 330px;
  }
}

.airblivious-section__video {
  background-color: $light-gray;
}


.airblivious-section__media {
  margin-bottom: 27px;
  width: 100%;
  height: auto;
  aspect-ratio: 560/408;

  @media #{$desktop} {
    margin-bottom: 20px;
  }

  @media #{$tablet} {
    margin-bottom: 16px;
  }

  @media #{$phone} {
    margin-bottom: 32px;
    // margin-bottom: 70px;
  }

  video,
  img {
    width: 100%;
    height: auto;
    border-radius: 30px;
    aspect-ratio: 560/408;
    object-fit: cover;
    object-position: center bottom;

    @media #{$desktop} {
      border-radius: 20px;
    }

    @media #{$tablet} {
      border-radius: 16px;
    }
  }
}

.airblivious-section__quote {
  @include font-size(18);
  line-height: 1.4;
  font-style: italic;
  letter-spacing: -0.39px;
  max-width: 519px;
  margin: 0 auto;
  text-align: center;
  padding: 0 20px;

  @media #{$desktop} {
    @include font-size(16);
    letter-spacing: -0.1px;
    line-height: 1.3;
  }

  @media #{$phone} {
    max-width: 312px;
  }
}

.airblivious-section__slider {
  @media #{$tablet} {
    padding-bottom: 72px;
  }

  .slick-list {
    overflow: visible !important;
  }

  &.no-transition {
    .slick-track {
      transition: none !important;
    }
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    padding: 0 6px;

    @media #{$phone} {
      padding: 0 4px;
    }
  }

  .slick-arrow {
    color: $black;
    bottom: 0;
    position: absolute;
    z-index: 1;
    transition: transform 0.3s ease;

    &:hover,
    &:focus {
      transform: scale(1.06);
    }

    &.slick-prev {
      left: calc(50% - 52px);
    }

    &.slick-next {
      left: calc(50% + 6px);
    }

    .icon {
      height: 46px;
      width: 46px;
    }
  }
}
