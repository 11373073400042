.air-insights {
  padding: 20px;

  @media #{$large-screen} {
    padding: 16px;
  }

  @media #{$phone} {
    padding: 8px;
  }

  .container {
    max-width: 1185px;
    padding: 120px 20px;

    @media #{$large-screen} {
      padding: 80px 16px;
    }
  }

  .slick-dots {
    justify-content: center;
    margin-top: 48px;
    gap: 10px;
    position: relative;
    z-index: 2;

    li {
      margin: 0;
      height: auto;
      border-radius: none;

      &.slick-active {
        button {
          outline-color: $black;
          width: 24px;

          &:hover,
          &:focus {
            outline: 2px solid $black !important;
          }

          &::after {
            transform: translateX(var(--progress));
          }
        }
      }

      button {
        height: 6px;
        width: 6px;
        outline: 2px solid #d3d3d3;
        will-change: width;
        border: none;
        overflow: hidden;

        &:hover,
        &:focus {
          border-color: none;
          outline: 2px solid #d3d3d3 !important;

          &::after {
            background-color: $black;
          }
        }

        &::before {
          display: none;
        }

        &::after {
          content: "";
          height: 6px;
          border-radius: inherit;
          left: -1px;
          top: 0;
          background-color: $black;
          position: absolute;
          z-index: 1;
          width: 28px;
          transform: translateX(-100%);
        }
      }
    }
  }
}

.air-insights__card {
  background-color: #f2f2f2;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
}

.air-insights__header {
  text-align: center;
  margin-bottom: 60px;

  @media #{$phone} {
    margin-bottom: 32px;
  }
}

.air-insights__heading {
  max-width: 824px;
  width: 100%;
  @include font-size(48);
  letter-spacing: -0.08px;
  line-height: 1;
  margin: 0 auto 30px;

  @media #{$desktop} {
    @include font-size(36);
    letter-spacing: -0.3px;
    margin-bottom: 24px;
    max-width: 690px;
  }

  @media #{$phone} {
    @include font-size(24);
    max-width: 304px;
  }
}

.air-insights__short-intro {
  line-height: calc(38 / 28);
  @include font-size(28);
  letter-spacing: -0.3px;
  max-width: 746px;
  margin: 0 auto;
  font-family: $primary-family-medium;

  @media #{$small-screen} {
    @include font-size(24);
    max-width: 640px;
  }

  @media #{$tablet} {
    @include font-size(20);
  }

  @media #{$phone} {
    max-width: 334px;
    margin: 0 auto;
  }
}

.air-insights__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}

.air-insights__slider {
  .slick-list {
    overflow: visible !important;
  }

  .slick-list,
  .slick-track {
    width: 100%;
  }

  .slick-track,
  .slick-slide {
    display: flex;
    height: auto;
  }

  .slick-slide {
    padding: 0 8px;

    >div {
      display: flex;
    }
  }
}

.air-insights__item {
  width: 100%;
  display: flex !important;
  flex-direction: column;
  flex: 0 0 auto;
  border: 1px solid #d3d3d3;
  border-radius: 16px;
  background-color: $white;

  @media #{$phone} {
    width: 306px;
  }
}

.air-insights__media {
  flex: 0 0 auto;
  aspect-ratio: 560/404;
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-top: auto;
  position: relative;
  bottom: -1px;

  @media #{$phone} {
    aspect-ratio: 306/308;
  }
}

.air-insights__video {
  object-fit: cover;
  object-position: center;
  width: 100%;
  vertical-align: top;
  border-radius: inherit;
  height: 100%;
  width: 100%;
  border-radius: 0 0 16px 16px;
  background-color: $light-gray;
}

.air-insights__content {
  padding: 64px 32px;

  @media #{$desktop} {
    padding: 40px 16px;
    @include font-size(20);
  }
}

.air-insights__title {
  @include font-size(28);
  font-family: $primary-family-bold;
  line-height: 1.1;
  letter-spacing: -0.3px;
  margin-bottom: 16px;

  @media #{$desktop} {
    @include font-size(20);
  }

  @media #{$phone} {
    @include font-size(18);
  }
}

.air-insights__text {
  @include font-size(22);
  line-height: 1.3;
  letter-spacing: -0.4px;
  opacity: .7;

  @media #{$desktop} {
    @include font-size(16);
  }
}

.footer-content {
  text-align: center;
  margin: 60px auto 0;
  max-width: 500px;
  width: 100%;

  @media #{$tablet} {
    margin-top: 48px;
    max-width: 327px;
  }


  h4 {
    @include font-size(18);
    margin-bottom: 0;
    text-transform: uppercase;
    line-height: 1.3;
    font-family: $primary-family-semibold;

    @media #{$tablet} {
      @include font-size(14);
      margin-bottom: 4px;
    }
  }

  p {
    @include font-size(22);
    letter-spacing: -0.3px;
    margin-bottom: 0;

    @media #{$desktop} {
      @include font-size(20);
    }

    @media #{$tablet} {
      @include font-size(16);
      letter-spacing: -0.4px;
    }
  }

  .button {
    padding: 16px 24px;

    @media #{$tablet} {
      @include font-size(16);
    }
  }
}
