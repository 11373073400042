.default-content {
  padding-top: 197px;
  padding-bottom: 200px;
  @include font-size(24);

  @media #{$tablet} {
    padding: 100px 0;
    @include font-size(20);
  }

  @media #{$phone} {
    padding: 50px 0;
    @include font-size(18);
  }
}

.hero-image+.default-content {
  padding-top: 100px;

  @media #{$phone} {
    padding-top: 60px;
  }
}

.hero-image+.default-content .default-content__title {
  line-height: 1;
}

.default-content__inner {
  max-width: 740px;
}

.default-content--large {
  .container {
    max-width: 955px;
  }

  .default-content__title {
    max-width: 910px;

    @media #{$phone} {
      @include font-size(26);
    }
  }

  .default-content__inner {
    max-width: 910px;
  }

  .default-content__description {
    @media #{$phone} {
      @include font-size(14);
    }

    strong {
      font-family: $primary-family-bold;
    }

    ul li {
      padding-left: 30px;

      &:before {
        content: "";
        width: 5px;
        height: 5px;
        background-color: #000;
        position: absolute;
        top: 18px;
        left: 12px;
        border-radius: 50%;
        border: none;

        @media #{$phone} {
          top: 9px;
        }
      }
    }
  }
}

.default-content__title {
  @include font-size(80);
  margin-bottom: 62px;
  max-width: 840px;

  @media #{$tablet} {
    @include font-size(50);
    margin-bottom: 30px;
  }

  @media #{$phone} {
    @include font-size(38);
    margin-bottom: 20px;
  }
}

.default-content__description {
  line-height: 1.7;

  strong {
    font-family: $primary-family-semibold;

    &.show-arrow {
      position: relative;
      padding-left: 24px;

      &:before {
        content: "";
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 8px solid $black;
        position: absolute;
        top: 6px;
        left: 0;
      }
    }
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      position: relative;
      padding-left: 24px;
      margin-bottom: 20px;

      &:before {
        content: "";
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 8px solid $black;
        position: absolute;
        top: 14px;
        left: 0;
      }
    }
  }

  a {
    color: $black;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
