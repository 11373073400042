.main--hide-overflow {
  overflow: hidden;
}

.hero--support {
  padding-top: 158px;
  padding-bottom: 80px;
  background: $white;

  @media #{$tablet} {
    padding-top: 108px;
    min-height: inherit;
  }

  @media #{$phone} {
    padding-top: 40px;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .container {
    position: relative;
    z-index: 1;
  }

  .hero__title {
    @include font-size(100);
    max-width: 1040px;
    line-height: 1.1;
    margin-bottom: 35px;

    @media #{$tablet} {
      @include font-size(60);
    }

    @media #{$phone} {
      @include font-size(38);
    }

    @media #{$phone-portrait} {
      @include font-size(32);
    }
  }

  .hero__description {
    @include font-size(24);
    max-width: 770px;
    margin: 0 auto 85px;
    text-align: center;

    @media #{$phone} {
      @include font-size(20);
      margin-bottom: 40px;
    }
  }
}

.btn-group {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .btn {
    margin: 0 5px;
    white-space: nowrap;
    max-width: 100%;
    @include font-size(22);
    min-width: 381px;
    padding: 21px 30px 17px;
    text-align: center;

    .icon {
      width: 32px;
      height: 26px;
      margin-right: 5px;
      margin-top: -8px;
    }

    @media #{$tablet} {
      @include font-size(18);
      padding: 14px 30px 12px;
      margin-bottom: 10px;
      min-width: 325px;
    }

    @media #{$phone} {
      @include font-size(16);
      margin-bottom: 10px;
      width: 100%;
      text-align: center;
      max-width: 51%;
    }

    @media #{$phone-portrait} {
      max-width: 100%;
      min-width: inherit;
    }
  }
}

.support-faq {
  margin-top: 120px;
  padding: 100px 0 0;

  @media #{$large-screen} {
    margin-top: 0;
  }

  @media #{$tablet} {
    padding: 0;
  }

  .container {
    max-width: 1130px;
    box-shadow: 0 10px 44px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    display: block;
    padding: 81px 0;

    @media #{$tablet} {
      padding-top: 40px;
      padding-bottom: 50px;
    }
  }
}

.support-faq__intro {
  margin-bottom: 72px;
  padding: 0 106px;

  @media #{$tablet} {
    padding: 0 20px;
    margin-bottom: 30px;
  }
}

.support-faq__intro-subtitle {
  @include font-size(18);
  font-family: $primary-family-medium;
  margin-bottom: 40px;

  @media #{$tablet} {
    margin-bottom: 30px;
  }
}

.support-faq__intro-title {
  @include font-size(60);
  margin-bottom: 15px;
  max-width: 600px;
  line-height: 1.05;

  @media #{$large-screen} {
    @include font-size(48);
  }

  @media #{$tablet} {
    @include font-size(38);
  }
}

.support-faq__intro-description {
  max-width: 600px;
  @include font-size(24);
  color: rgba($black, 0.8);
  line-height: 1.6;

  @media #{$large-screen} {
    @include font-size(20);
  }

  @media #{$tablet} {
    @include font-size(16);
  }
}

.download-block {
  padding: 186px 0 78px;

  @media #{$large-screen} {
    padding: 100px 0 0;
  }

  @media #{$tablet} {
    padding: 50px 0 0;
  }

  .container {
    max-width: 955px;
  }
}

.download-block__heading {
  display: block;
  width: 100%;
  margin-bottom: 75px;
  @include font-size(36);
  letter-spacing: -0.5px;
  line-height: 1;

  @media #{$tablet} {
    @include font-size(24);
    margin-bottom: 50px;
  }

  @media #{$phone} {
    @include font-size(20);
    margin-bottom: 30px;
  }
}


.download-block__list {
  list-style: none;
  padding-left: 0;
}

.download-block__list-item {
  border-bottom: 1px solid #D6D6D6;
  margin-bottom: 80px;
  padding-bottom: 75px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  position: relative;

  @media #{$tablet} {
    margin-bottom: 50px;
    padding-bottom: 55px;
  }

  @media #{$phone-portrait} {
    display: block;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.download-block__list-item--mila-app {
    margin-bottom: 0;
    padding-bottom: 50px;
    border-bottom: none;
  }

  .btn--outline {
    border-width: 3px;
    padding: 18px 28px;
    @include font-size(18);
    position: absolute;
    right: 0;
    letter-spacing: -0.3px;
    top: 57px;
    min-width: 257px;
    text-align: center;

    @media #{$tablet} {
      position: static;
      margin-bottom: 20px;
    }
  }

  .btn--valign-center {
    top: 23%;
  }
}

.download-block__image {
  max-width: 109px;
  box-shadow: 0px 30px 80px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  margin-bottom: 32px;
  margin-right: 40px;

  @media #{$phone} {
    max-width: 72px;
    margin-right: 20px;
  }

}

.download-block__content {
  width: calc(100% - 151px);
  position: relative;

  @media #{$phone} {
    width: calc(100% - 110px);
  }

  @media #{$phone-portrait} {
    width: 100%;
  }
}

.download-block__title {
  @include font-size(24);
  font-family: $primary-family-medium;
  margin-bottom: 15px;
  max-width: 460px;
  margin-top: 10px;

  @media #{$phone} {
    @include font-size(20);
  }
}

.download-block__description {
  @include font-size(20);
  line-height: 1.5;
  margin-bottom: 33px;
  max-width: 420px;

  @media #{$phone} {
    @include font-size(16);
    margin-bottom: 23px;
  }
}

.download-block__version {
  @include font-size(26);
  position: absolute;
  top: -4px;
  right: 0;

  @media #{$phone} {
    position: static;
    @include font-size(18);
  }

  strong {
    font-family: $primary-family-semibold;
  }
}

.change-log__toggle {
  color: #30C8BD;

  @media #{$phone} {
    @include font-size(16);
  }

  svg {
    width: 10px;
    height: 10px;
    margin-left: 7px;
    transition: transform 0.25s ease;
  }

  &.change-log__toggle--is-active {
    svg {
      transform: rotate(180deg);
    }
  }
}

.change-log__body {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease;
}

.change-log__body-inner {
  padding-top: 50px;

  @media #{$phone} {
    padding-top: 20px;
  }
}

.change-log__card {
  border: 1px solid #E4E4E4;
  border-radius: 20px;
  padding: 30px 40px 24px;
  position: relative;
  margin-bottom: 23px;

  @media #{$phone} {
    padding: 20px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.change-log__card-version {
  font-family: $primary-family-medium;
  color: $black;
  margin-bottom: 12px;
}

.change-log__card-content {
  color: rgba(0, 0, 0, 0.7);
}

.change-log__card-description {
  @media #{$phone} {
    @include font-size(14);
  }

  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    li {
      position: relative;
      margin-bottom: 6px;
      padding-left: 10px;

      &:before {
        content: "-";
        position: absolute;
        top: 0;
        left: 0;
      }

      img {
        max-width: 20px;
      }
    }
  }

  strong {
    span {
      @include font-size(20);
      display: inline-block;
    }
  }

  p img {
    max-width: 20px;
  }

  a {
    color: $brand-color--tertiary;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.change-log__card-release {
  color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 27px;
  right: 40px;

  @media #{$phone} {
    @include font-size(14);
    top: 20px;
    right: 20px;
  }
}

.contact-block {
  padding: 70px 0 150px;

  @media #{$large-screen} {
    padding: 50px 0 40px;
  }

  .container {
    max-width: 960px;
  }
}

.contact-block__intro {
  margin-bottom: 43px;
}

.contact-block__subtitle {
  @include font-size(18);
  font-family: $primary-family-medium;
}

.contact-block__title {
  @include font-size(60);
  margin-bottom: 15px;

  @media #{$large-screen} {
    @include font-size(48);
  }

  @media #{$tablet} {
    @include font-size(38);
  }
}

.contact-block__description {
  max-width: 775px;
  @include font-size(24);
  color: rgba($black, 0.8);
  line-height: 1.6;

  @media #{$tablet} {
    @include font-size(20);
  }

  @media #{$phone} {
    @include font-size(16);
  }
}

.contact-block__detail {
  margin-bottom: 37px;
  @include font-size(18);

  h3 {
    @include font-size(24);
    font-family: $primary-family-semibold;
    margin-bottom: 15px;

    @media #{$tablet} {
      margin-bottom: 10px;
      @include font-size(20);
    }
  }

  a {
    line-height: 1;
    color: $primary-color;
    @include font-size(20);
    display: inline-block;
    text-decoration: none;
    position: relative;
    transition: all 0.25s ease;

    &:before {
      content: "";
      display: block;
      position: absolute;
      bottom: 1px;
      left: 0;
      width: 100%;
      border-bottom: 1px solid;
    }

    &:hover,
    &:focus {
      color: darken($primary-color, 20%);
    }
  }
}

.contact-block__address {
  font-style: normal;
  @include font-size(20);
  font-family: $primary-family;
  margin-top: 50px;

  @media #{$phone} {
    @include font-size(16);
    margin-top: 30px;
  }
}
