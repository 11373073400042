.header {
  position: fixed;
  left: 0;
  top: var(--offsetTop, 51px);
  right: 0;
  z-index: 99;
  min-height: 92px;

  @media #{$phone} {
    min-height: 70px;
  }

  &.submenu--active {
    &::after {
      opacity: 1;
    }
  }

  &::after {
    content: "";
    position: fixed;
    z-index: -1;
    width: 100vw;
    top: 0;
    height: 100vh;
    height: 100dvh;
    left: 0;
    background-color: rgba($white, 0.5);
    backdrop-filter: blur(12px);
    opacity: 0;
    transition: all 300ms ease;
    pointer-events: none;
  }

  .container {
    max-width: 1260px;
  }
  &.header--sticky {
    min-height: 76px;

    @media #{$phone} {
      min-height: 68px;
    }
  }
}

.header__inner {
  position: relative;
  z-index: 9;
  background-color: $white;
  padding: 24px 0 23px;
  border-bottom: 1px solid transparent;

  .header--sticky & {
    padding: 16px 0 15px;
  }

  @media #{$phone} {
    padding: 17px 0 16px;
  }

  .submenu--active & {
    border-color: #e2e2e2;
  }
}

.header__logo {


  a {
    display: block;
    width: 45px;

    @media #{$phone} {
      width: 36px;
    }
  }

  img {
    @media #{$phone} {
      max-width: 36px;
    }
  }
}

.header__grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__nav {
  @media #{$tablet} {
    position: fixed;
    top: var(--menuOffset, 142px);
    left: 0;
    right: 0;
    z-index: 9;
    border-top: 1px solid #e2e2e2;
    height: calc(100vh - var(--menuOffset));
    height: calc(100dvh - var(--menuOffset));
    background-color: $white;
    padding: 28px 16px 40px;
    overflow-y: auto;
    display: none;
    justify-content: space-between;
    gap: 40px;
    flex-direction: column;
    align-items: flex-start;
  }

  &.header__nav--active {
    @media #{$tablet} {
      display: flex;
    }
  }

  .button {
    max-width: 480px;
    width: 100%;
    margin-top: auto;
    @include font-size(20);
    padding: 16px 30px;
  }

  .login-button {
    display: none;

    @media #{$tablet} {
      display: flex;
    }
  }
}

.header__menu-toggle {
  display: none;

  @media #{$tablet} {
    display: flex;
  }

  @media #{$phone} {
    margin-right: 16px;
  }
}

.header__menu {
  display: flex;
  align-items: center;
  gap: 16px;
  list-style-type: none;
  padding: 0;

  @media #{$tablet} {
    flex-wrap: wrap;
    gap: 24px;
    padding: 0 32px;
  }

  li {
    @media #{$tablet} {
      width: 100%;
    }

    a {
      padding: 14px 24px;
      display: flex;
      align-items: center;
      text-transform: capitalize;
      font-family: $primary-family-semibold;
      color: $black;
      text-decoration: none;
      gap: 12px;
      background-color: $white;
      border-radius: 57px;
      @include font-size(16);
      transition: all 300ms ease;
      line-height: 1;

      @media #{$tablet} {
        @include font-size(32);
        border: none;
        border-radius: 0;
        box-shadow: none !important;
        padding: 12px 0;
      }

      span {
        position: relative;
        transition: all 300ms ease;
        will-change: color, font-weight;
      }

      &:hover {
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04),
          0px 2px 12px 0px rgba(0, 0, 0, 0.06);
      }

      &.header__menu-item--current,
      &.header__menu-item--active {
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04),
          0px 2px 12px 0px rgba(0, 0, 0, 0.06);

        span {
          &::after {
            font-family: inherit;
          }
        }

        &::after {
          transform: rotateX(180deg);
        }
      }

      &::after {
        height: 16px;
        width: 16px;
        display: inline-block;
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='10' viewBox='0 0 20 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.75 2L10 8.34615L18.25 2' stroke='black' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center;
        transition: all 300ms ease;
        background-size: contain;

        @media #{$tablet} {
          height: 24px;
          width: 24px;
          background-size: 22px;
          top: 1px;
          position: relative;
        }
      }

      &.has-submenu {
        &::after {
          content: "";
        }
      }

      svg {
        height: 16px;
        width: 16px;
        flex: 0 0 auto;
      }
    }
  }
}

.header__actions {
  display: flex;
  gap: 8px;

  @media #{$tablet} {
    gap: 16px;
    align-items: center;
  }

  .login-button {
    @media #{$tablet} {
      display: none;
    }
  }

  .button--order {
    @media #{$phone} {
      padding: 10px 15px;
      @include font-size(14);
    }
  }
}

.mega-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 8;
  background-color: $white;
  filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.06)) drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.04));

  @media #{$tablet} {
    filter: none;
    position: relative;
    top: 0;
    margin-bottom: -10px;
    border-bottom: 1px solid #e2e2e2;
  }

  @media #{$phone} {
    border-top: none;
    border-bottom: none;
  }
}

.mega-menu__grid {
  margin: 0 auto;
  padding: 32px 20px;
  max-width: 1064px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  @media #{$tablet} {
    padding: 20px 0;
  }

  @media #{$phone} {
    padding: 0;
    grid-template-columns: 1fr;
    gap: 0;
  }
}

.mega-menu__item {
  @media #{$phone} {
    padding: 16px 0;
    border-top: 1px solid #e2e2e2;
    width: 100%;
  }

  &:last-child {
    @media #{$phone} {
      border-bottom: 1px solid #e2e2e2;
    }
  }
}

.menu-product {
  display: grid;
  grid-template-columns: 192px auto;
  gap: 32px;

  @media #{$tablet} {
    gap: 16px;
    grid-template-columns: 142px auto;
    position: relative;
    z-index: 1;
  }

  @media #{$phone} {
    grid-template-columns: 88px auto;
  }
}

.menu-product__image {
  img {
    border-radius: 12px;

    @media #{$tablet} {
      border-radius: 8px;
    }

    @media #{$phone} {
      border-radius: 6px;
    }
  }
}

.menu-product__content {
  padding: 11px 0;
  max-width: 248px;
  align-content: center;

  .menu-product__description {
    @media #{$phone} {
      display: none !important;
    }
  }

  .button {
    @media #{$tablet} {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      color: transparent;
      background-color: transparent;
      box-shadow: none;
    }
  }
}

.menu-product__price {
  @include font-size(17);
  line-height: 1;
  letter-spacing: -0.3px;
  margin-bottom: 12px;
  font-family: $primary-family-medium;

  del {
    color: #949494;
  }

  span {
    display: block;
    color: rgba($black, 0.5);
    @include font-size(12);
    margin-top: 3px;
  }
}

.menu-product__title {
  @include font-size(20);
  font-family: $primary-family-bold;
  line-height: normal;
  margin-bottom: 6px;
}

.menu-product__description {
  @include font-size(14);
  letter-spacing: -0.3px;
  line-height: 1.5;
  margin-bottom: 16px;

  @media #{$tablet} {
    margin-bottom: 0;
  }
}

//button

.button {
  padding: 14px 20px;
  font-family: $primary-family-medium;
  border-radius: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: -0.3px;
  transition: scale 300ms ease;
  backface-visibility: hidden;
  will-change: scale;
  cursor: pointer;
  text-decoration: none;
  line-height: 1;
  @include font-size(16);

  &:hover,
  &:focus {
    scale: 1.03;
  }
}

$air-primary-color: #000;

.button--white {
  color: $black;
  background-color: $white;
}

.button--black {
  color: $white;
  background-color: $black;
}

.button--primary {
  background-color: $air-primary-color;
  color: $white;
  padding: 14px 24px;
}

.button--shadow-dark {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04),
    0px 2px 12px 0px rgba(0, 0, 0, 0.06);
}

.button--shadow-primary {
  box-shadow: 0 4px 16px rgba($air-primary-color, 0.2);
}

.button--lg {
  @include font-size(18);
}

.button--md {
  @include font-size(16);
}

.button--sm {
  @include font-size(14);
  padding: 12px 24px;
}

//Order List

.header__sidebar {
  position: fixed;
  top: var(--menuOffset, 142px);
  right: 0;
  z-index: 9;
  height: calc(100vh - var(--menuOffset));
  height: calc(100dvh - var(--menuOffset));
  background-color: $white;
  padding: 40px 20px;
  overflow-y: auto;
  max-width: 504px;
  width: 100%;

  @media #{$tablet} {
    padding: 40px 20px;
    max-width: 400px;
  }
}

.cart-accordion {
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 40px;

    @media #{$phone} {
      margin-bottom: 24px;
    }
  }
}

.cart-accordion__header {
  border-bottom: 1px solid #e2e2e2;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  padding-bottom: 16px;
  cursor: pointer;

  .cart-accordion--active & {
    &:after {
      transform: rotateX(180deg);
    }
  }

  &:after {
    content: "";
    height: 16px;
    width: 16px;
    display: inline-block;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.5 6L8 10.2308L13.5 6' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    transition: all 300ms ease;
    transition: transform 300ms ease;
    transform: rotateX(0);
  }
}

.cart-accordion__body {
  padding: 16px 0 0;
}

.cart-accordion__title {
  @include font-size(24);
  font-family: $primary-family-bold;
  line-height: 1;
  margin-bottom: 0;

  @media #{$phone} {
    @include font-size(20);
  }
}

.mila-item {
  display: grid;
  grid-template-columns: 112px auto;
  gap: 24px;
  align-items: center;

  @media #{$tablet} {
    position: relative;
    z-index: 1;
  }

  @media #{$phone} {
    grid-template-columns: 88px auto;
    gap: 16px;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.mila-item__image {
  height: 112px;
  width: 112px;
  border-radius: 10px;
  background-color: #f9f9f9;

  @media #{$phone} {
    height: 88px;
    width: 88px;
  }

  img {
    border-radius: 10px;

    @media #{$tablet} {
      border-radius: 8px;
    }

    @media #{$phone} {
      border-radius: 6px;
    }
  }
}

.mila-item__title {
  @include font-size(18);
  margin-bottom: 6px;
  line-height: 1;
}

.mila-item__content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;

  @media #{$phone} {}

  .button {
    flex: 0 0 auto;

    @media #{$tablet} {
      color: transparent;
      background-color: transparent;
      position: absolute;
      font-size: 0;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
    }

    .mila-item--filter & {
      align-self: flex-end;
    }
  }

  .button--primary {
    transform-origin: right center;
  }
}

.mila-item__price {
  @extend .menu-product__price;
  @include font-size(14);
  margin-bottom: 0;
}

.mila-item__heading {
  .button {
    padding: 12px 16px;
    gap: 8px;
    margin-top: 12px;

    @media #{$phone} {
      display: inline-flex;
      margin-top: 8px;
    }

    svg {
      height: 10px;
      width: 10px;
      flex: 0 0 auto;
    }
  }
}
