.filter-main {
  position: relative;
  padding-top: 53px;

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 2400px;
    background: linear-gradient(15.38deg, rgba(196, 196, 196, 0.16) -4.47%, rgba(255, 255, 255, 0) 59.49%);
    transform: matrix(1, 0, 0, -1, 0, 0);
    position: absolute;
    top: 0;
    left: 0;
  }
}

.filter-block {
  padding-top: 100px;
  padding-bottom: 100px;

  @media #{$large-screen} {
    padding-top: 100px;
    padding-bottom: 0;
  }

  @media #{$phone} {
    padding-top: 70px;
    padding-bottom: 0;
  }

  .container {
    max-width: 1250px;
  }

  .btn--outline {
    transition: all 0.3s ease;
  }
}

.filter-block__primary {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.filter-block__image {
  width: 54%;
  max-width: 625px;
  margin-top: 50px;

  @media #{$phone} {
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    display: none;
  }
}

.filter-block__content {
  width: 38%;
  padding-bottom: 150px;

  @media #{$tablet} {
    width: 43%;
  }

  @media #{$phone} {
    width: 100%;
  }

  .filter-block__image {
    display: none;

    @media #{$phone} {
      display: block;
    }
  }
}

.filter-block__title {
  @include font-size(58);
  margin-bottom: 21px;

  @media #{$large-screen} {
    @include font-size(48);
  }

  @media #{$tablet} {
    @include font-size(38);
  }

  @media #{$phone} {
    @include font-size(28);
    margin-bottom: 15px;
  }

  img {
    max-width: 60px;
    margin-top: -10px;

    @media #{$large-screen} {
      max-width: 50px;
    }

    @media #{$tablet} {
      max-width: 40px;
    }

    @media #{$phone} {
      max-width: 34px;
    }
  }
}

.filter-block__subtitle {
  @include font-size(34);
  font-family: $primary-family-medium;
  line-height: 1.4;

  @media #{$large-screen} {
    @include font-size(28);
  }

  @media #{$tablet} {
    @include font-size(22);
  }

  @media #{$phone} {
    @include font-size(20);
    margin-bottom: 30px;
  }

  @media #{$phone-portrait} {
    max-width: 232px;
  }
}

.filter-block__description {
  margin-bottom: 70px;
  @include font-size(22);
  margin-top: 66px;
  line-height: 1.75;
  padding-right: 50px;
  color: rgba($black, 0.8);

  @media #{$large-screen} {
    margin-top: 46px;
    @include font-size(20);
  }

  @media #{$tablet} {
    margin-top: 20px;
    @include font-size(18);
    margin-bottom: 34px;
  }

  @media #{$phone} {
    @include font-size(16);
  }
}

#rookie-parent .btn--outline {
  &:hover {
    background-color: #8852F6;
    color: $white !important;
  }
}

#big-sneeze .btn--outline {
  &:hover {
    background-color: #FFCA0C;
    color: $white !important;
  }
}

#home-wrecker .btn--outline {
  &:hover {
    background-color: #FF810D;
    color: $white !important;
  }
}

#critter-cuddler .btn--outline {
  &:hover {
    background-color: #F42D53;
    color: $white !important;
  }
}

#mama-to-be .btn--outline {
  &:hover {
    background-color: #1CE4D5;
    color: $white !important;
  }
}

#basic-breather .btn--outline {
  &:hover {
    background-color: #3E4649;
    color: $white !important;
  }
}

#overreactor .btn--outline {
  &:hover {
    background-color: #0A56EA;
    color: $white !important;
  }
}

.icon-list {
  width: 100%;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  max-width: 1170px;
  margin: 100px 0 0;
  left: -32px;
  position: relative;

  @media #{$large-screen} {
    left: -22px;
    margin-top: 90px;
  }

  @media #{$tablet} {
    margin-top: 60px;
    left: 0;
    justify-content: space-between;
  }
}

.icon-list__item {
  width: 25%;
  list-style: none;
  padding: 0 32px;
  @include font-size(18);
  line-height: 1.5;
  margin-bottom: 40px;

  @media #{$large-screen} {
    padding: 0 22px;
  }

  @media #{$tablet} {
    width: 42%;
    padding: 0;
  }

  @media #{$phone} {
    @include font-size(14);
  }

  @media screen and (max-width: 320px) {
    width: 47%;
  }

  .icon-ideal-room-size,
  .icon-filter-coating {
    width: 48px;
    height: 48px;

    @media #{$phone} {
      width: 36px;
      height: 36px;
    }
  }

  .icon-carbon-filter,
  .icon-hepa-filter {
    width: 44px;
    height: 44px;

    @media #{$phone} {
      width: 36px;
      height: 36px;
    }
  }

  p img {
    display: inline-block;
    max-width: 20px;
    margin-top: -4px;
    margin-left: 3px;
  }
}

.icon-list__title {
  @include font-size(20);
  margin-top: 27px;
  margin-bottom: 16px;
  font-family: $primary-family-semibold;

  @media #{$phone} {
    margin-top: 18px;
    @include font-size(16);
    margin-bottom: 14px;
  }
}

.filter-stats {
  padding-top: 85px;
  padding-bottom: 70px;
  overflow: hidden;
  position: relative;
  z-index: 1;

  @media #{$large-screen} {
    padding-top: 65px;
    padding-bottom: 100px;
  }

  @media #{$tablet} {
    padding-top: 35px;
    padding-bottom: 65px;
  }

  .container {
    max-width: 1330px;
  }
}

.filter-stats__header {
  max-width: 860px;
  margin: 0 auto 115px;
  text-align: center;

  @media #{$large-screen} {
    margin-bottom: 60px;
    max-width: 750px;
  }
}

.filter-stats__title {
  margin-bottom: 30px;

  @media #{$phone} {
    @include font-size(32);
    margin-bottom: 36px;
  }
}

.filter-stats__subtitle {
  padding: 0 20px;
  @include font-size(38);
  font-family: $primary-family-medium;
  line-height: 1.25;

  @media #{$large-screen} {
    @include font-size(28);
  }

  @media #{$phone} {
    @include font-size(20);
    color: rgba($black, 0.8);
    font-family: $primary-family;
  }
}

.row-subheader {
  z-index: 1;
  position: relative;
  display: flex;
}

.row-label__image {
  max-width: 24px;
  margin-right: 5px;
}

.row-subheader strong {
  width: 50%;
  min-width: 75px;
  @include font-size(20);
  font-family: $primary-family-medium;

  @media #{$desktop} {
    @include font-size(16);
  }

  @media #{$phone} {
    @include font-size(14);
    min-width: 90px;
  }
}

.row-subheader--two-col strong {
  width: 50%;

  @media #{$tablet} {
    min-width: 100px;
  }

  br {
    display: none;

    @media #{$phone} {
      display: block;
    }
  }
}

.row-header {
  font-family: $primary-family-semibold;
  @include font-size(26);
  padding: 20px;

  @media #{$phone} {
    @include font-size(20);
  }
}

.row-label__button {
  font-family: $primary-family-semibold;
  @include font-size(16);
  border: 2px solid $primary-color;
  text-transform: none;
  text-decoration: none;
  padding: 2px 15px 2px 8px;
  border-radius: 30px;
  display: inline-block;
  color: $primary-color;
  margin-left: 17px;

  @media #{$phone} {
    margin-left: 7px;
    @include font-size(14);
  }

  @media #{$phone-portrait} {
    @include font-size(12);
  }
}

.row-header__value {
  position: relative;
  z-index: 2;
}

.filter-stats__table {
  border-collapse: collapse;
  margin-bottom: 53px;

  tr.tr-exclude:first-child {
    @media #{$tablet} {
      display: none;
    }
  }

  .row-label {
    font-family: $primary-family-semibold;
    @include font-size(18);
    text-transform: none;
    color: $black;
    white-space: nowrap;

    @media #{$desktop} {
      @include font-size(16);
    }

    @media #{$phone} {
      @include font-size(14);
    }

    @media #{$phone-portrait} {
      @include font-size(12);
    }
  }

  .row-value {
    @include font-size(16);
    width: 50%;

    @media #{$phone} {
      @include font-size(14);
    }
  }

  .row-wrap {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    white-space: pre-wrap;
    padding: 6px 0 1px;
  }

  .row-wrap--two-col {
    .row-value {
      width: 50%;
    }
  }

  td {
    padding: 23px 54px;

    @media #{$tablet} {
      padding: 10px 20px;
    }

    &:nth-child(1) {
      width: 16.5%;
    }

    &:nth-child(2) {
      width: 25%;
    }

    &:nth-child(3) {
      width: 27.6%;
    }
  }

  tr:first-child {
    td {
      padding: 0;
    }

    td:nth-child(2) {
      border-radius: 10px 10px 0 0;
    }
  }

  tr:last-child {
    td:nth-child(2) {
      border-radius: 0 0 10px 10px;
    }
  }

  .btn {
    min-width: 257px;
    text-align: center;
    padding: 17px 20px;
    margin-bottom: 30px;

    @media #{$phone} {
      @include font-size(14);
      padding: 10px 20px;
      min-width: 157px;
    }
  }
}

.filter-stats__table td:nth-child(2) {
  background-color: #ffffff;
  box-shadow: 0 24px 70px rgba(0, 0, 0, 0.05);
  text-align: center;
  position: relative;
  padding: 23px 24px;

  @media #{$tablet} {
    padding: 10px 20px;
  }
}

.filter-stats__table td:nth-child(2n):before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: white;
  z-index: 1;
  border-radius: 10px;
}

.filter-stats__table tr {
  &:not(.tr-exclude):hover {
    background-color: #F7F8FA;
    cursor: pointer;

    td:nth-child(1) {
      border-radius: 50px 0 0 50px;
    }

    td:nth-child(3) {
      border-radius: 0 50px 50px 0;
    }

    td:nth-child(2):before {
      background-color: #F7F8FA;
      border-radius: 0;
    }
  }
}

.filter-stats__table td:nth-child(3) {
  text-align: center;
  min-width: 290px;
}

.filter-stats__inner {
  display: block;

  @media #{$phone} {
    display: none;
  }

  .filter-stats__note {
    padding: 0 45px;

    @media #{$tablet} {
      padding: 0;
    }
  }
}

.filter-stats__carousel {
  display: none;
  max-width: 600px;
  margin: 0 auto;

  @media #{$phone} {
    display: block;
    left: -20px;
    width: calc(100% + 40px);
  }

  .slick-arrow {
    position: absolute;
    top: 0;
    width: 30px;
    height: 30px;
    color: #000;
    z-index: 1;
    transition: 0.3s ease;

    svg {
      width: 30px;
      height: 30px;
    }

    &.slick-disabled {
      color: $black;
      opacity: 0.15;
    }
  }
}

.filter-stats__slide-next {
  right: 65px;

  @media #{$phone-portrait} {
    right: 25px;
  }
}

.filter-stats__slide-prev {
  left: 65px;

  @media #{$phone-portrait} {
    left: 25px;
  }
}

.filter-stats__slide {
  text-align: center;

  .filter-stats__slide-image {
    max-width: 28px;
    display: inline-block;
    margin-top: -10px;
    margin-right: 5px;
  }
}

.filter-stats__slide-title {
  padding: 5px 110px;
  @include font-size(20);
  font-family: $primary-family-semibold;
  margin: 0 auto 35px;

  @media #{$phone-portrait} {
    padding: 5px 60px;
  }
}

.filter-stats__primary,
.filter-stats__secondary {
  box-shadow: 0px 24px 70px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 30px 30px 40px;
  margin: 0 32px 5px;

  @media #{$phone-portrait} {
    margin: 0 12px 5px;
    padding: 30px 10px 40px;
  }
}

.filter-stats__secondary {
  padding: 45px 30px 45px;
  margin-bottom: 60px;
}

.filter-stats__primary-title,
.filter-stats__secondary-title {
  margin-bottom: 42px;
  @include font-size(18);
  font-family: $primary-family-semibold;
}

.filter-stats__secondary-title {
  margin-bottom: 30px;
}

.filter-stats__data-list {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.filter-stats__data-item {
  max-width: 48%;

  strong {
    @include font-size(16);
    font-family: $primary-family-medium;
    display: block;
    margin: 0 auto 27px;
  }

  span {
    @include font-size(16);
  }
}

.filter-stats__data-list--two-col {
  .filter-stats__data-item {
    strong {
      width: 100%;
      margin: 0 auto 15px;
    }
  }
}


.performance-stats {
  padding: 160px 0 300px;
  overflow: hidden;
  position: relative;
  z-index: 1;

  @media #{$large-screen} {
    padding: 100px 0 200px;
  }

  @media #{$tablet} {
    padding: 10px 0 100px;
  }

  @media #{$phone} {
    padding: 0 0 50px;
  }
}

.performance-stats__title {
  max-width: 880px;
  margin: 0 auto 78px;
  text-align: center;

  @media #{$large-screen} {
    margin-bottom: 60px;
  }

  @media #{$phone} {
    margin-bottom: 38px;
    @include font-size(32);
  }
}

.performance-stats__description {
  max-width: 680px;
  margin: 0 auto 100px;
  text-align: center;
  @include font-size(24);
  color: rgba($black, 0.8);

  @media #{$large-screen} {
    @include font-size(20);
    margin-bottom: 70px;
  }

  @media #{$phone} {
    margin-bottom: 60px;
  }
}

.performance-stats {
  .row-header {
    .row-header__column-wrap {
      display: flex;
      position: relative;
      z-index: 1;
      padding-bottom: 15px;
    }

    .row-header__column {
      width: 140px;
      font-family: $primary-family-medium;
      @include font-size(18);
      display: block;
      text-align: center;
      line-height: 1.2;
      white-space: normal;

      @media #{$desktop} {
        line-height: 1;
        width: 14%;
        min-width: 100px;
      }

      @media #{$tablet} {
        text-align: left;
      }

      @media #{$phone} {
        min-width: 110px;
      }

      strong {
        font-family: $primary-family-medium;

        @media #{$desktop} {
          @include font-size(16);
        }

        @media #{$phone} {
          text-align: left;
          display: block;
          line-height: 1.4;
        }
      }

      span {
        display: block !important;
      }
    }
  }

  .row-label__image {
    max-width: 38px;
    margin: 0 auto 20px;
    display: block;

    @media #{$desktop} {
      margin-bottom: 10px;
    }

    @media #{$tablet} {
      margin: 0 0 10px;
    }

    @media #{$phone} {
      margin: 0 0 15px;
    }
  }

  .hidden-label {
    display: none;
    position: relative;
    z-index: 1;
    color: #494949;
    text-transform: uppercase;
    @include font-size(14);
    font-family: $primary-family-semibold;

    @media #{$tablet} {
      padding-top: 10px;
      display: block;
      text-align: center;
      padding-bottom: 5px;
    }

    small {
      font-family: $primary-family;
      @include font-size(14);
      text-transform: none;
      padding-left: 2px;
      color: rgba(0, 0, 0, 0.5);
    }
  }

  .trigger-info {
    cursor: pointer;
    color: #C7C7C7;
    margin-left: 7px;

    svg {
      width: 22px;
      height: 22px;
    }
  }

  .info-text {
    text-align: center;
    display: block;
    position: relative;
    z-index: 1;
    height: 0;
    opacity: 0;
    visibility: hidden;
    @include font-size(16);
    transition: all 0.3s ease;
    color: #6D6D6D;
  }

  .info-text--is-active {
    height: auto;
    opacity: 1;
    visibility: visible;
    padding-bottom: 3px;
  }

  .trigger-info--is-active {
    background: #C7C7C7;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    display: inline-block;
  }

}

.performance-stats__inner {
  .table-wrapper {
    border-right: none;
  }
}

.performance-stats__table {
  border-collapse: collapse;

  tr.tr-exclude {
    @media #{$tablet} {
      display: none;
    }
  }

  td {
    position: relative;
    padding: 23px 24px;

    @media #{$tablet} {
      padding: 10px 20px;
    }
  }

  td:first-child {
    @media #{$tablet} {
      display: none;
    }
  }

  td:last-child {
    background-color: #ffffff;
    box-shadow: 0 24px 70px rgba(0, 0, 0, 0.05);
    text-align: center;
    position: relative;
    padding: 23px 24px;

    @media #{$tablet} {
      padding: 10px 20px;
    }

    @media #{$phone} {
      padding: 10px;
    }
  }

  td:last-child:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background-color: white;
    z-index: 1;
    border-radius: 10px;
  }

  td.row-header {
    padding: 14px 24px;

    @media #{$tablet} {
      padding: 14px 21px;
    }

    @media #{$phone} {
      padding: 10px;
    }
  }

  .row-header--radius {
    border-radius: 10px 10px 0 0;
  }

  .row-value--radius {
    border-radius: 0 0 10px 10px;
  }

  .row-label {
    padding-right: 55px;

    @media #{$desktop} {
      @include font-size(14);
    }

    small {
      font-family: $primary-family;
      @include font-size(15);
      text-transform: none;
      padding-left: 5px;
      color: rgba(0, 0, 0, 0.5);
    }
  }

  .row-wrap {
    display: flex;

    @media #{$tablet} {
      flex-direction: column;
      padding: 20px 0 23px;
      position: relative;
      z-index: 1;
    }
  }

  .row-value {
    width: 140px;
    font-family: $primary-family;
    @include font-size(18);
    display: block;
    text-align: center;
    line-height: 1.2;
    white-space: normal;

    @media #{$desktop} {
      width: 14%;
      min-width: 100px;
      @include font-size(16);
    }

    @media #{$tablet} {
      width: 100%;
      text-align: right;
      color: #494949;
      padding: 10px 0;
      border-bottom: 1px solid #f1f1f1;
    }

    &:after {
      @media #{$tablet} {
        position: absolute;
        left: 0;
        content: attr(data-filter);
        font-family: $primary-family-medium;
      }
    }
  }

  .row-value--gray-box {
    color: $white;

    @media #{$tablet} {
      color: #494949;
    }

    &:before {
      content: "";
      display: block;
      width: calc(100% - 35px);
      background-color: $light-gray;
      height: calc(100% + 54px);
      position: absolute;
      top: -25px;
      left: 16px;

      @media #{$tablet} {
        display: none;
      }
    }
  }

  .row-value--top-radius:before {
    border-radius: 10px 10px 0 0;
    height: calc(100% + 70px);
  }

  .row-value--bottom-radius:before {
    border-radius: 0 0 10px 10px;
  }

  .row-value--gray-text:after {
    @media screen and (min-width: 1025px) {
      content: "N/A";
      position: absolute;
      top: -25px;
      left: 16px;
      color: rgba(0, 0, 0, 0.3);
      text-align: center;
      display: block;
      width: calc(100% - 35px);
    }
  }

  .row-value--vertical-text:after {
    content: "Tests in progress";
    text-transform: uppercase;
    position: absolute;
    top: -50px;
    left: -24px;
    color: rgba(0, 0, 0, 0.3);
    text-align: center;
    display: block;
    width: 190px;
    transform: rotate(-90deg);

    @media screen and (max-width: 1300px) {
      left: -30px;
    }

    @media screen and (max-width: 1200px) {
      left: -37px;
    }

    @media screen and (max-width: 1100px) {
      left: -42px;
    }

    @media #{$tablet} {
      display: none;
    }
  }

  .row-value--text-bold {
    font-family: $primary-family-medium;

    @media #{$tablet} {
      color: #494949;
    }
  }
}

.tooltip {
  display: inline-block;
  cursor: pointer;
  position: absolute;
  top: calc(50% - 13px);
  right: 20px;

  .icon-info {
    color: #C7C7C7;
    width: 22px;
    height: 22px;
  }
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: #4A4A4A;
  color: #fff;
  text-align: center;
  padding: 7px 10px;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 117%;
  line-height: 1.2;
  font-family: $primary-family-medium;
  @include font-size(14);
  z-index: 3;

  @media #{$tablet} {
    top: -29px;
    left: -130px;
    transform: none;
  }
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.replacement-block {
  position: relative;
  text-align: center;
  @include font-size(24);
  padding-bottom: 73px;
  overflow: hidden;

  @media #{$large-screen} {
    @include font-size(20);
  }

  @media #{$phone} {
    @include font-size(16);
    padding-bottom: 40px;
  }

  &:after {
    content: "";
    display: block;
    height: 3px;
    background-color: #EBEBEB;
    width: 1516px;
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }

  .container {
    max-width: 900px;
  }
}

.replacement-block__title {
  max-width: 640px;
  margin: 0 auto;

  @media #{$phone} {
    @include font-size(32);
  }
}

.replacement-block__content {
  margin-top: 46px;
  line-height: 1.6;
  padding: 0 50px;

  @media #{$large-screen} {
    margin-top: 50px;
  }

  @media #{$tablet} {
    padding: 0;
  }

  @media #{$phone} {
    margin-top: 30px;
  }

  strong {
    font-family: $primary-family-semibold;
  }
}

.replacement-block__image {
  margin-top: 60px;

  @media #{$phone} {
    margin-top: 30px;
  }
}


.sock-block {
  padding-bottom: 100px;
  text-align: center;
  padding-top: 180px;
  @include font-size(24);

  @media #{$large-screen} {
    @include font-size(20);
    padding-top: 100px;
    padding-bottom: 50px;
  }

  @media #{$tablet} {
    padding-top: 60px;
  }

  @media #{$phone} {
    @include font-size(16);
    padding-bottom: 30px;
  }

  .container {
    max-width: 800px;
  }
}

.sock-block__title {
  margin-bottom: 103px;

  @media #{$large-screen} {
    margin-bottom: 50px;
  }

  @media #{$phone} {
    @include font-size(32);
    margin-bottom: 30px;
  }

  img {
    max-width: 58px;
    display: inline-block;
    margin-top: -10px;

    @media #{$large-screen} {
      max-width: 48px;
    }

    @media #{$phone} {
      max-width: 32px;
    }
  }
}

.sock-block__content {
  line-height: 1.6;
}

.sock-block__image {
  margin-top: 35px;
  margin-bottom: 40px;

  img {
    max-width: 820px;
    width: 100%;
  }
}

.filter-block__price {
  width: 100%;
  margin-top: -135px;
}

.filter-block__price-info {
  margin-bottom: 35px;

  p {
    @include font-size(15);

    strong {
      @include font-size(36);
      display: block;
      font-family: $primary-family-semibold;
    }

    span.filter-block__free-sign {
      font-family: $primary-family-semibold;
    }
  }
}

.filter-block__button-wrap {
  .btn {
    @include font-size(20);
    padding: 20px 40px;
    min-width: 285px;
    text-align: center;
    margin-right: 15px;
    margin-bottom: 10px;

    @media #{$phone} {
      padding: 16px 30px;
      min-width: 265px;
      @include font-size(16);
    }

    &:hover {
      opacity: 1;
    }

    @media #{$phone-portrait} {
      width: 100%;
      margin-right: 0;
    }
  }
}
