.quote-block {
  padding: 74px 20px;

  @media #{$phone} {
    padding: 30px 0;
  }
}

.quote-block__wrap {
  background-color: rgba($brand-color--tertiary, .54);
  border-radius: 15px;
  padding: 50px;

  @media #{$phone} {
    background-color: $brand-color--tertiary;
  }

  @media #{$phone} {
    border-radius: 0;
    padding: 30px 20px;
  }
}

.quote-block__card {
  max-width: 1070px;
  width: 100%;
  margin: 0 auto;
  padding: 70px 40px;
  background-color: $white;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  text-align: center;
  box-shadow: 0 50px 120px rgba(0, 0, 0, .04);

  @media screen and (max-width:640px) {
    padding: 62px 33px 38px;
    max-width: 326px;
    margin: 0 auto;
  }
}

.quote-block__quote {
  @include font-size(35);
  line-height: 1.1;
  font-family: $primary-family-medium;
  letter-spacing: -.5px;

  @media #{$desktop} {
    @include font-size(28);
  }

  @media screen and (max-width:640px) {
    @include font-size(18);
    font-family: $primary-family;
    line-height: normal;
    line-height: calc(20/18);
  }

  p {
    margin-bottom: 40px;

    br {
      @media screen and (min-width:641px) {
        display: none;
      }
    }

    @media screen and (max-width:640px) {
      margin-bottom: 25px;
      position: relative;
      z-index: 1;
    }

    strong {
      font-family: $primary-family-medium;
      background-color: #DDFCFA;
    }

    &::after,
    &::before {
      content: '';
      display: inline-block;
      vertical-align: top;
      height: 20px;
      width: 21px;
      position: relative;
      margin: 0 3px;

      @media #{$phone} {
        opacity: .54;
        background-size: 20px;
        background-repeat: no-repeat;
      }

      @media screen and (max-width:640px) {
        position: absolute;
        z-index: 1;
      }
    }


    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 14.77C0 13.8367 0.552332 12.1333 1.657 9.66C2.80585 7.14 4.30819 4.34 6.16403 1.26C6.65008 0.42 7.31288 0 8.15243 0C9.03616 0 9.69896 0.186667 10.1408 0.560001C10.6269 0.886666 10.8699 1.35333 10.8699 1.96C10.8699 2.38 10.6932 3.03333 10.3397 3.92C10.0304 4.76 9.72105 5.48333 9.41175 6.09C9.14663 6.69667 8.94779 7.11667 8.81523 7.35C7.09195 10.8033 5.87682 13.72 5.16983 16.1C4.9489 16.8467 4.68378 17.43 4.37447 17.85C4.10935 18.2233 3.66749 18.41 3.04887 18.41C2.34189 18.41 1.657 18.0133 0.994198 17.22C0.331399 16.4267 0 15.61 0 14.77ZM10.4059 15.12C10.4059 13.58 10.7594 12.1567 11.4664 10.85C12.1734 9.49667 13.1897 7.95667 14.5153 6.23C14.913 5.76333 15.3107 5.22667 15.7083 4.62C16.1944 3.92 16.8572 3.57 17.6967 3.57C18.4921 3.57 19.1107 3.75667 19.5526 4.13C20.0386 4.50334 20.2816 4.97 20.2816 5.53C20.2816 6.13667 20.1049 6.81334 19.7514 7.56C19.4421 8.26 18.8898 9.31 18.0944 10.71C17.2549 12.2967 16.6142 13.5333 16.1723 14.42C15.7746 15.3067 15.4874 16.1933 15.3107 17.08C15.2223 17.64 15.0013 18.13 14.6479 18.55C14.3386 18.9233 13.8967 19.11 13.3223 19.11C12.6595 19.11 11.9967 18.69 11.3339 17.85C10.7153 17.01 10.4059 16.1 10.4059 15.12Z' fill='%231CE4D5'/%3E%3C/svg%3E%0A");
      margin-right: .3em;

      @media screen and (max-width:640px) {
        top: -19px;
        left: 13px;
      }
    }

    &::after {
      background-image: url("data:image/svg+xml,%3Csvg width='22' height='19' viewBox='0 0 22 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.1234 17.57C14.449 17.57 15.3107 15.96 15.7746 14.7C17.2991 10.36 21.4747 4.34 21.4747 2.87C21.4747 1.61 20.4805 0 18.5584 0C17.1665 0 16.2386 0.420003 15.6421 1.54C13.5211 5.88 10.6048 10.08 10.6048 14.07C10.6048 15.19 11.4001 17.57 13.1234 17.57ZM2.58492 18.06C3.84423 18.06 4.57331 17.01 5.03727 15.82C5.89891 13.16 9.41175 6.09 9.41175 4.13C9.41175 2.24 8.01987 1.05 5.96519 1.05C4.97099 1.05 4.24191 1.68 3.84423 2.87C2.6512 6.51 0 11.83 0 14.35C0 15.82 0.795359 18.06 2.58492 18.06Z' fill='%231CE4D5'/%3E%3C/svg%3E%0A");
      margin-left: .3em;

      @media screen and (max-width:640px) {
        right: 12px;
        margin: 0;
        bottom: -8px;
      }
    }
  }

  cite {
    font-family: $alt-family;
    @include font-size(40);
    line-height: calc(29/40);
    letter-spacing: -0.5px;


    @media #{$desktop} {
      @include font-size(35);
    }
  }
}

.quote-block__card-inner {
  max-width: 730px;
}
