.hero-refresh {
  margin-bottom: 60px;

  .container {
    max-width: 1360px;
    padding: 0;

    @media #{$phone} {
      padding: 0 5px;
    }
  }

  .slick-dots {
    justify-content: center;
    gap: 10px;

    li {
      margin: 0;
      height: auto;
      border-radius: none;

      &.slick-active {
        button {
          outline-color: $black;
          width: 24px;

          &:hover,
          &:focus {
            outline: 2px solid $black !important;
          }

          &::after {
            transform: translateX(var(--progress));
          }
        }
      }

      button {
        height: 6px;
        width: 6px;
        outline: 2px solid #d3d3d3;
        will-change: width;
        border: none;
        overflow: hidden;

        &:hover,
        &:focus {
          border-color: none;
          outline: 2px solid #d3d3d3 !important;

          &::after {
            background-color: $black;
          }
        }

        &::before {
          display: none;
        }

        &::after {
          content: "";
          height: 6px;
          border-radius: inherit;
          left: -1px;
          top: 0;
          background-color: $black;
          position: absolute;
          z-index: 1;
          width: 28px;
          transform: translateX(-100%);
        }
      }
    }
  }

  .slick-list {
    overflow: visible;
    margin: 0 0 24px 0 !important;

    @media #{$phone} {
      overflow: hidden;
    }

    .slick-track {
      display: flex;

      .slick-slide {
        display: flex;
        height: auto;
        justify-self: unset;
        justify-content: center;

        >div {
          display: flex !important;
          width: 100%;

          @media #{$phone} {
            margin: 0 16px;
          }
        }
      }
    }
  }
}

.hero-refresh--newyear {
  .container {
    max-width: 1430px;
  }
}

.hero-item {
  position: relative;
  z-index: 1;
  aspect-ratio: 1320 / 680;
  height: auto;
  width: 100%;
  padding: 74px 102px;
  border-radius: 16px;
  background-color: #525252;
  overflow: hidden;
  margin: 0 10px;

  &.hero-item--04 {
    padding: 134px 102px;
    background: linear-gradient(270deg, #383737 0%, #1E1D1E 100%);

    @media #{$tablet} {
      padding: 54px 60px;
    }

    @media #{$phone} {
      padding: 48px 30px;
    }
  }

  // min-height: 460px;
  @media #{$desktop} {
    min-height: 560px;
    padding: 54px 60px;
  }

  @media #{$tablet} {
    min-height: 430px;
    padding: 40px;
  }

  @media #{$phone} {
    min-height: 613px;
    aspect-ratio: 358 / 549;
    max-width: 380px;
    margin: 0 auto;
    padding: 46px 30px 20px;
    display: flex !important;
  }

  &.hero-item--03 {
    @media #{$phone} {
      padding-bottom: 40px;
    }
  }
}

.hero-item__image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 16px;
  pointer-events: none;
  height: 100%;
  width: 100%;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center bottom;

    .hero-item--04 & {
      @media #{$phone} {
        aspect-ratio: 179 / 201;
        height: auto;
        object-fit: contain;
      }
    }
  }
}

.hero-item__body {
  width: 100%;

  @media #{$phone} {
    margin-top: auto;

    .hero-item--02 & {
      margin-top: 0;
    }
  }
}

.hero-item__content {
  color: $white;
  margin-bottom: 177px;

  .hero-item--04 & {
    margin-bottom: 40px;

    @media #{$phone} {
      margin-bottom: 30px;
    }
  }

  @media #{$desktop} {
    margin-bottom: 100px;
  }

  @media #{$tablet} {
    margin-bottom: 32px;
  }

  @media #{$phone} {
    .hero-item--03 & {
      margin-bottom: 16px;
    }
  }

  .hero-item--02 & {
    color: $black;

    @media #{$phone} {
      color: $white;
    }
  }
}

.hero-item__subtitle {
  @include font-size(18);
  line-height: 1.5;
  font-family: $primary-family-medium;
  text-transform: uppercase;
  margin-bottom: 8px;
  display: block;

  @media #{$tablet} {
    @include font-size(14);
  }
}

.hero-item__title {
  margin-bottom: 18px;
  @include font-size(48);
  line-height: 1.1;
  letter-spacing: -0.3px;

  .hero-item--04 & {
    @media #{$phone} {
      br {
        display: none;
      }
    }
  }

  @media #{$tablet} {
    @include font-size(24);
  }
}

.hero-item__description {
  @include font-size(18);
  line-height: 1.3;
  font-weight: 400;
  letter-spacing: -0.3px;
  max-width: 370px;
  width: 100%;

  .hero-item--04 & {
    max-width: 273px;
  }

  @media #{$tablet} {
    @include font-size(14);
    max-width: 260px;

    .hero-item--03 & {
      max-width: 299px;
    }
  }

  .hero-item--02 & {
    max-width: 273px;

    @media #{$tablet} {
      max-width: 230px;
    }
  }

}

.hero-item__button {
  padding: 16px 24px;
  @include font-size(18);
  line-height: 1;
  font-family: $primary-family-semibold;
  letter-spacing: -0.3px;

  @media #{$tablet} {
    padding: 10px 16px;
    @include font-size(14);
    color: $white;
    background-color: $black;

    .hero-item--02 & {
      @media #{$phone} {
        color: $black;
        background-color: $white;
      }
    }

    .hero-item--03 &,
    .hero-item--04 & {
      @media #{$tablet} {
        color: $black;
        background-color: $white;
      }
    }
  }

  .hero-item--02 & {
    color: $white;
    background-color: $black;

    @media #{$phone} {
      color: $black;
      background-color: $white;
    }
  }
}

.hero-item__badge {
  height: 169px;
  width: 169px;
  background-color: $black;
  border-radius: 50%;
  position: absolute;
  top: 137px;
  right: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $white;
  letter-spacing: -0.03em;
  font-family: $primary-family-medium;
  text-align: center;
  transform: rotate(4.67deg);
  @include font-size(18);

  @media #{$desktop} {
    top: 90px;
    right: 180px;
  }

  @media #{$tablet} {
    @include font-size(10);
    height: 113px;
    width: 113px;
    top: 75px;
    right: 105px;

  }

  @media #{$phone} {
    top: 25px;
    right: 16px;
  }

  span {
    line-height: normal;
    display: block;
  }

  strong {
    display: block;
    @include font-size(39);
    line-height: 1;
    font-family: $primary-family-bold;
    margin: 5px 0;

    @media #{$tablet} {
      @include font-size(24);
    }
  }

  del {
    @media #{$tablet} {
      @include font-size(15);
    }
  }
}


.hero-item--best-product {
  background-color: #F0ECE5;
  position: relative;
  flex-wrap: wrap;
  z-index: 1;

  @media #{$phone} {
    padding-top: 23px;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    border-top: 1px solid #EAE6DF;
    top: calc(100% - 78px);
    background: linear-gradient(180deg, #C9C3BA 0%, #F0ECE5 100%);

    @media #{$phone} {
      display: none;
    }
  }

  .hero-item__title {
    @media #{$phone} {
      max-width: 232px;
      margin-bottom: 16px;
    }
  }

  .hero-item__image {
    position: absolute;
    right: 63px;
    bottom: 57px;
    height: auto;
    top: auto;
    left: auto;
    width: 100%;
    max-width: 956px;

    @media #{$desktop} {
      max-width: 800px;
    }

    @media #{$tablet} {
      right: 40px;
      left: 200px;
      width: auto;
      bottom: 63px;
    }

    @media #{$phone} {
      position: static;
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: -27px;
      z-index: 3;
      align-items: flex-end;
    }

    img {
      width: 100%;
      object-fit: contain;
      height: auto;

      @media #{$phone} {
        position: relative;
        right: 20px;
        min-width: 460px;
        z-index: 3;
      }
    }
  }

  .hero-item__content {
    color: $black;
    max-width: 468px;
    margin-bottom: 90px;
    position: relative;
    z-index: 1;

    @media #{$phone} {
      margin-bottom: 55px;
      padding: 32px 30px;
      min-width: auto;
      margin: 0 -30px;
      z-index: 4;
      width: auto;
    }


    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border-top: 1px solid #EAE6DF;
      top: 0;
      background: linear-gradient(180deg, #C9C3BA 0%, #F0ECE5 100%);
      display: none;
      z-index: -1;

      @media #{$phone} {
        display: block;
      }
    }
  }

  .hero-item__description {
    max-width: 316px;
    margin-bottom: 7px;

    @media #{$phone} {
      max-width: 271px;
    }
  }
}

.hero-item__award {
  margin-bottom: 16px;
  display: flex;

  @media #{$phone} {
    width: 100%;
    justify-content: center;
    margin-bottom: 106px;
  }
}

.hero-item__color-list {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 2px;
  border-radius: 100px;
  padding: 3px;
  background-color: $white;

  @media #{$phone} {
    margin-bottom: 16px;
  }

  li {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;

    &:first-child {
      border-color: $black;
    }
  }
}


.hero-item__text-badge {
  height: 169px;
  width: 169px;
  background-color: $black;
  border-radius: 50%;
  position: absolute;
  bottom: 321px;
  right: 63px;
  color: $white;
  letter-spacing: -0.03em;
  font-family: $primary-family-medium;
  text-align: center;
  transform: rotate(7deg);
  @include font-size(20);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  z-index: 2;

  @media #{$tablet} {
    @include font-size(14);
    height: 113px;
    width: 113px;
    top: 85px;
    right: 43px;
  }

  @media #{$phone} {
    padding-top: 0;
    right: 15px;
    top: 94px;
  }

  p {
    line-height: normal;
  }

  strong {
    font-family: $primary-family-bold;
  }
}


.hero-item--la-strong {
  align-items: flex-end;
  display: flex;

  @media #{$phone} {
    padding-bottom: 23px;
  }

  .hero-item__image {
    img {
      @media #{$phone} {
        object-position: center 37%;
      }
    }
  }

  .hero-item__content {
    text-align: center;
    margin: 0 auto;

    @media #{$phone} {
      text-align: left;
      margin: 0;
    }
  }

  .hero-item__title {
    @include font-size(86);
    color: $black;
    background-color: $white;
    display: inline-block;
    padding: .1em .1em 0;
    line-height: .8;
    letter-spacing: -2px;

    @media #{$desktop} {
      @include font-size(54);
      letter-spacing: -1.25px;
    }

    @media #{$phone} {
      margin-bottom: 5px;
    }

    span {
      background-image: url("data:image/svg+xml,%3Csvg width='96' height='96' viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M94.004 45.8941C99.1416 32.3373 94.1061 17.0423 81.9107 9.16145C69.7153 1.28064 53.6606 2.94691 43.3535 13.1632C42.0961 14.411 41.4044 16.117 41.4387 17.8858C41.4637 19.6678 42.2074 21.3646 43.5019 22.5932L43.5834 22.6645C44.7641 23.7322 46.2896 24.3423 47.8829 24.384C47.8296 24.3802 47.7767 24.3727 47.7245 24.3613C47.8225 24.3804 47.9221 24.39 48.022 24.39H48.0332C50.0292 24.3291 51.9127 23.4539 53.2436 21.9692C54.7655 20.5219 56.5508 19.3781 58.5033 18.5992L58.6091 18.5618C66.3874 15.3708 75.341 18.177 79.8908 25.2319C82.6583 29.255 83.5645 34.268 82.3801 39.0017C87.2535 38.9083 91.7583 41.5795 94.004 45.8941Z' fill='%23F42C52'/%3E%3Cpath d='M87.0098 47.3941C84.4326 44.8262 80.2565 44.8271 77.6804 47.396C77.6428 47.4334 47.9775 76.9134 47.9775 76.9134L33.5422 62.517C32.4302 67.1334 28.8051 70.731 24.1709 71.8172L41.002 88.6496C44.5757 92.2175 45.6266 92.8447 48.031 92.8447C49.698 92.8503 51.3036 92.2182 52.5172 91.0785L52.5497 91.047L87.0088 56.6948C89.5797 54.1249 89.5802 49.9646 87.0098 47.3941Z' fill='%231CE4D5'/%3E%3Cpath d='M38.6947 8.472C39.6139 7.55275 40.6697 6.78043 41.825 6.1823C37.0023 4.29945 31.7728 3.69472 26.6461 4.42706C19.4534 5.46169 12.8441 8.95544 7.94787 14.3113C2.81023 19.9528 -0.0248959 27.3074 0.000164731 34.9284C0.000164731 44.0023 4.31035 52.1379 8.58738 56.1852L16.1793 63.8051C18.7243 66.3521 22.8486 66.3878 25.4375 63.8852C28.0264 61.3826 28.1195 57.2701 25.6464 54.6537L25.6139 54.6202L18.3645 47.3904C14.136 43.2393 12.3204 37.225 13.5482 31.4355C14.4938 26.7551 17.3047 22.6583 21.3367 20.0842C25.3686 17.5102 30.2758 16.6795 34.9336 17.7827V17.7791C34.8457 14.292 36.2068 10.9237 38.6947 8.472Z' fill='%23FFC140'/%3E%3C/svg%3E%0A");
      height: 6rem;
      width: 6rem;
      display: inline-block;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin: -20px .05em -16px;

      @media #{$desktop} {
        height: 60px;
        width: 60px;
        margin: -20px .05em -10px;
      }
    }
  }

  .hero-item__description {
    text-align: center;
    margin: 0 auto 30px;
    @include font-size(18);
    line-height: 1.3;
    letter-spacing: -0.3px;
    max-width: 396px;

    @media #{$tablet} {
      color: $black;
    }

    @media #{$phone} {
      max-width: 282px;
      text-align: left;
      margin-bottom: 9px;
    }

    p {
      @media #{$tablet} {
        display: inline;
        background-image: linear-gradient(to right, $white, $white);
        line-height: inherit;
        background-position: 0 calc(100% - 1px);
        background-size: 100%;
        background-repeat: no-repeat;
        padding: .1em 0;
      }
    }
  }

  .button {
    @media #{$tablet} {
      background-color: $white;
      color: $black;
    }
  }
}

.hero-item__tag {
  display: inline-block;
  vertical-align: middle;
  inline-size: inherit;
  font-family: $primary-family-bold;
  @include font-size(40);
  line-height: 1;
  letter-spacing: -0.53px;
  color: $black;
  background-color: $white;
  padding: .1em .2em;
  margin-bottom: 30px;

  @media #{$desktop} {
    @include font-size(32);
  }

  @media #{$phone} {
    @include font-size(24);
    margin-bottom: 155px;
  }
}

// Immersive
.hero-item--immersive {
  background-color: #272727;
  color: $white;
  display: flex;

  @media #{$phone} {
    flex-direction: column-reverse;
  }

  .hero-item__content {
    max-width: 432px;
    margin-bottom: 60px;
    align-self: center;
    margin-top: auto;

    @media #{$phone} {
      margin-bottom: 20px;
    }
  }

  .hero-item__media {
    position: relative;
    z-index: 1;
    position: absolute;
    right: 26px;
    top: 0;
    bottom: 0;
    max-width: 724px;
    width: 100%;

    @media #{$desktop} {
      max-width: 644px;
    }

    @media screen and (max-width: 1240px) {
      max-width: 514px;
    }

    @media #{$tablet} {
      max-width: 410px;
      right: 10px;
    }

    @media #{$phone} {
      position: relative;
      left: 0;
      top: auto;
      right: 0;
      bottom: -23px;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: -279px;
      z-index: -1;
      border-radius: 1177px;
      background: radial-gradient(50% 50% at 50% 50%, #737373 0%, rgba(217, 217, 217, 0.00) 100%);
      filter: blur(34px);
      width: 1177px;
      height: 134px;

      @media #{$phone} {
        width: 610px;
        transform: translateX(-50%);
        left: 50%;
        right: auto;
        bottom: -20px;
        border-radius: 610px;
        background: radial-gradient(50% 50% at 50% 50%, #737373 0%, rgba(217, 217, 217, 0.00) 100%);
      }
    }
  }

  .hero-item__image {
    object-fit: contain;
    height: auto;
    bottom: -28px;
    z-index: 2;
    top: auto;
    aspect-ratio: 724/572;

    @media #{$tablet} {
      aspect-ratio: 320/285;
    }

    @media #{$phone} {
      position: relative;
      bottom: 0;
    }

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  .hero-item__subtitle {
    @media #{$phone} {
      font-family: $primary-family-semibold;
    }
  }

  .hero-item__description {
    margin-bottom: 88px;

    @media #{$tablet} {
      max-width: 290px;
    }

    @media #{$phone} {
      margin-bottom: 29px;
      max-width: 294px;
      @include font-size(16);
      letter-spacing: -.4px;
    }
  }

  .hero-item__media-tag {
    @include font-size(276);
    letter-spacing: -0.33px;
    text-transform: capitalize;
    line-height: 1.1;
    font-family: $primary-family-bold;
    color: #D9D9D9;
    position: relative;
    z-index: 1;
    top: 30px;
    left: 15px;
    white-space: nowrap;

    @media #{$phone} {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media #{$desktop} {
      @include font-size(240);
    }

    @media screen and (max-width: 1200px) {
      @include font-size(200);
      top: 94px;
      left: 25px;
    }

    @media #{$tablet} {
      @include font-size(140);
      top: 40px;
      left: 54px;
    }

    @media #{$phone} {
      margin-bottom: 0;
      @include font-size(134);
      top: 92px;
      left: 3px;
    }
  }
}


.hero-item--valentine {
  background-color: #FDF3FA;

  .hero-refresh__sale {
    @include font-size(35);
    line-height: 1.1;
    letter-spacing: -0.3px;
    color: $black;
    text-align: center;
    position: absolute;
    max-width: 729px;
    right: 0;
    z-index: -1;
    width: 100%;
    top: 18%;

    @media #{$desktop} {
      top: 12%;
      max-width: 629px;
    }

    @media screen and (max-width: 1200px) {
      top: 15%;
      max-width: 520px;
      @include font-size(21);
      letter-spacing: -0.17px;
      line-height: 1.1;
    }

    @media #{$tablet} {
      top: 14%;
      max-width: 420px;
    }

    @media #{$phone} {
      top: 26px;
    }


    strong {
      @include font-size(203);
      font-family: $primary-family-bold;
      letter-spacing: -0.02em;
      color: $brand-color--secondary;
      line-height: .98;

      @media screen and (max-width: 1200px) {
        @include font-size(180);
      }

      @media #{$tablet} {
        @include font-size(132);
      }

      @media #{$phone} {
        line-height: .9;
      }
    }
  }

  .hero-item__content {
    max-width: 421px;
    color: $black;

    @media screen and (max-width: 1200px) {
      max-width: 330px;
    }

    @media #{$tablet} {
      max-width: 280px;
    }

    @media #{$phone} {
      max-width: 100%;
      margin-bottom: 8px;
    }
  }

  .hero-refresh__title {
    @include font-size(40);
    line-height: 1.1;
    letter-spacing: -0.3px;

    @media screen and (max-width: 1200px) {
      @include font-size(32);
    }

    @media #{$tablet} {
      @include font-size(24);
      margin-bottom: 18px;
    }

    &::after {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg width='33' height='33' viewBox='0 0 33 33' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1.5' y='1.5' width='29.9297' height='29.9297' rx='5.305' stroke='black' stroke-width='3'/%3E%3Cpath d='M16.3463 26.0315C11.0936 26.0315 8.63884 23.6031 8.48047 19.5119H13.2844C13.4428 21.0692 14.1819 22.125 16.0559 22.125C17.7188 22.125 18.5371 21.3331 18.5371 19.987C18.5371 18.5088 17.7452 17.7698 16.0559 17.7698H14.5514V14.4176H15.9504C17.6397 14.4176 18.4579 13.6521 18.4579 12.3323C18.4579 11.1973 17.7189 10.4582 16.2935 10.4582C14.8418 10.4582 13.9971 11.3293 13.7859 12.8602H9.00838C9.24593 9.13848 11.7271 6.57812 16.4255 6.57812C20.8599 6.57812 23.5258 8.61057 23.5258 11.7516C23.5258 13.8632 22.3116 15.2358 20.332 15.8165C22.4964 16.2652 24.0009 17.7962 24.0009 20.0926C24.0009 23.6823 21.335 26.0315 16.3463 26.0315Z' fill='black'/%3E%3C/svg%3E%0A");
      background-size: 33px;
      background-repeat: no-repeat;
      background-position: center;
      display: inline-block;
      width: 33px;
      height: 33px;
      margin-left: 4px;
      margin-top: 5px;
      vertical-align: top;

      @media screen and (max-width: 1200px) {
        background-size: 28px;
        width: 28px;
        height: 28px;
        margin-left: 3px;
        margin-top: 3px;
      }

      @media #{$tablet} {
        background-size: 21px;
        width: 22px;
        height: 22px;
        margin-left: 4px;
        margin-top: 1px;
      }
    }
  }

  .hero-refresh__description {
    margin-bottom: 104px;

    @media screen and (max-width: 1200px) {
      @include font-size(16);
      margin-bottom: 40px;
    }

    @media #{$phone} {
      margin-bottom: 29px;
      max-width: 295px;
    }
  }

  .hero-item__image {
    position: absolute;
    max-width: 819px;
    right: 0;
    bottom: 0;
    left: auto;

    @media #{$desktop} {
      max-width: 744px;
    }

    @media screen and (max-width: 1280px) {
      max-width: 644px;
    }

    @media screen and (max-width: 1200px) {
      max-width: 600px;
    }

    @media #{$tablet} {
      max-width: 512px;
    }

    @media #{$phone} {
      display: flex;
      justify-content: center;
      top: 29px;
    }

    img {
      aspect-ratio: 819 / 581;
      object-fit: contain;

      @media #{$phone} {
        min-width: 533px;
        aspect-ratio: 533 / 297;
        height: auto;
      }
    }
  }

  .hero-refresh__subtitle {
    font-family: $primary-family-medium;
    text-transform: uppercase;
    margin-bottom: 8px;
    @include font-size(18);
    line-height: 1.5;
    display: block;

    @media #{$tablet} {
      @include font-size(14);
    }
  }
}

.no-break {
  white-space: nowrap
}
