.hero-feb {
  padding: 31px 0 88px;

  @media #{$phone} {
    padding: 30px 0 50px;
  }

  .hero-feb__content {
    max-width: 997px;
    margin: 0 auto;
    width: 100%;
    position: relative;
    z-index: 1;
  }

  .hero-feb__title {
    text-align: inherit;
    line-height: 1.125;
    letter-spacing: -0.3px;
    margin-bottom: 20px;
    @include font-size(45);
    text-align: center;

    br {
      @media screen and (min-width:768px) {
        display: none;
      }
    }

    @media #{$tablet} {
      @include font-size(36);
    }

    @media #{$phone} {
      @include font-size(25);
      margin-bottom: 10px;
    }

    span {
      display: block;
      position: relative;
      top: 29px;

      @media #{$desktop} {
        top: 15px;
      }

      @media #{$tablet} {
        top: 20px;
      }

      @media #{$phone} {
        top: 10px;
      }
    }

    strong {
      position: relative;
      @include font-size(325);
      display: block;
      color: $brand-color--secondary;
      letter-spacing: -.03em;
      margin-top: 3px;
      line-height: 1.12;

      @supports(-webkit-background-clip: text) {
        background: linear-gradient(180deg, #F42D53 33.22%, #FF0D3B 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }

      @media #{$desktop} {
        @include font-size(240);
      }

      @media #{$tablet} {
        line-height: 1;
        left: 0;
      }

      @media #{$phone} {
        @include font-size(200);
        margin-top: 13px;
      }

      @media #{$phone-portrait} {
        @include font-size(160);
      }

      @media screen and (max-width: 350px) {
        @include font-size(140);
      }
    }
  }
}

.hero-feb__details {
  max-width: 997px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  @include font-size(25);
  letter-spacing: -.3px;
  line-height: 1.16;

  @media #{$phone} {
    @include font-size(15);
  }

  .btn--medium {
    @include font-size(16);
    padding: 18px 32px 15px;
    will-change: transform;
    min-width: auto;

    @media #{$phone} {
      @include font-size(15);
      padding: 13px 17px 11px;
      display: none;
    }
  }
}

.hero-feb__media {
  margin: -180px auto 54px;
  max-width: 460px;
  width: 100%;
  position: relative;
  z-index: 2;

  @media #{$desktop} {
    max-width: 360px;
    margin-top: -120px;
  }

  @media #{$tablet} {
    margin-top: -80px;
    margin-bottom: 40px;
  }

  @media #{$phone} {
    max-width: 258px;
    margin-top: -67px;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 350px) {
    margin-top: -50px;
    max-width: 210px;
  }
}

.hero-feb__description {
  margin-bottom: 27px;

  @media #{$phone} {
    display: none;
    max-width: 290px;
    margin: 0 auto 20px;
  }
}

.hero-feb__description--mobile {
  display: none;

  @media #{$phone} {
    display: inline;
    max-width: 290px;
    margin: 0 auto 20px;
  }
}

.hero-feb__quotes {
  &.hero-feb__quotes--desktop {
    @media #{$tablet} {
      display: none !important;
    }
  }

  &.hero-feb__quotes--tablet {
    display: none !important;
    margin-bottom: 50px;

    @media #{$phone} {
      margin-bottom: 30px;
    }

    @media #{$tablet} {
      display: block !important;
    }

    &:after {
      content: '';
      position: absolute;
      width: 93px;
      top: 0;
      bottom: 0;
      right: -20px;
      z-index: 1;
      background: linear-gradient(278.95deg, #FFFFFF 61.19%, rgba(255, 255, 255, 0) 99.58%);
    }
  }

  .slick-list {
    margin: 0 -20px;
    padding: 28px 20px;

    @media #{$phone} {
      margin: 0 -25px;
    }

    .slick-track {
      display: flex;
      align-items: center;
    }

    .slick-slide {
      .hero-feb__quote {
        opacity: 1;
        transition: opacity 400ms ease;
      }
    }

    .slick-slide[data-index^='-'] {
      .hero-feb__quote {
        opacity: 0;
      }
    }
  }

  .slick-arrow {
    height: 42px;
    width: 42px;
    border-radius: 50%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background-color: #ECEBEB;
    opacity: 1;
    transition: all 300ms ease;
    top: calc(50% - 21px);
    position: absolute;
    z-index: 9;

    @media #{$phone} {
      height: 22px;
      width: 22px;
      top: calc(50% - 11px);
    }

    &:hover,
    &:focus {
      background-color: $brand-color--secondary;
    }

    &.slick-next {
      right: 0;

      @media #{$phone} {
        right: -5px;
      }
    }

    &.slick-prev {
      left: 0;
      display: none !important;

      @media #{$phone} {
        left: -5px;
      }
    }

    &.slick-disabled {
      opacity: 0;
      pointer-events: none;
    }

    svg {
      height: 18px;
      width: 18px;

      @media #{$phone} {
        height: 12px;
        width: 7px;
      }
    }
  }
}

.slick-not-loaded {
  @media #{$tablet} {
    height: 158px;
  }
}

.hero-feb__quote {
  width: 100%;
  position: absolute;
  z-index: 9;

  @media #{$tablet} {
    position: static;
    margin-right: 20px;
  }

  @media #{$phone} {
    margin-right: 14px;
  }

  &:nth-child(n+2) {
    .slick-not-loaded & {
      @media #{$tablet} {
        display: none;
      }
    }
  }
}

.hero-feb__quote--first {
  max-width: 258px;
  left: -81px;
  top: 120px;

  @media #{$desktop} {
    left: 1px;
    top: 100px;
  }

  @media #{$phone} {
    max-width: 173px;
  }
}

.hero-feb__quote--second {
  max-width: 257px;
  top: 347px;
  left: -33px;

  @media #{$desktop} {
    left: 21px;
    top: 300px;
  }

  @media #{$phone} {
    max-width: 173px;
  }
}

.hero-feb__quote--third {
  max-width: 301px;
  top: 92px;
  right: -104px;

  @media #{$desktop} {
    right: -20px;
  }

  @media #{$phone} {
    max-width: 173px;
  }
}

.hero-feb__quote--fourth {
  max-width: 232px;
  right: -35px;
  top: 297px;

  @media #{$desktop} {
    right: 45px;
    top: 275px;
  }

  @media #{$phone} {
    max-width: 173px;
  }
}

.hero-feb__quote-card {
  border-radius: 15px;
  filter: drop-shadow(0 4px 16px rgba($black, .1));
  background-color: $white;
  position: relative;
  z-index: 1;
  display: inline-block;
  padding: 20px 17px 11px;
  font-family: $primary-family-medium;
  text-align: center;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;

  @media #{$phone} {
    padding: 15px 11px;
  }

  .hero-feb__quote--third & {
    padding: 25px 17px 15px;

    @media #{$phone} {
      padding: 15px 11px;
    }
  }

  .hero-feb__quote--fourth & {
    padding: 25px 20px 15px;

    @media #{$phone} {
      padding: 15px 11px;
    }
  }

  blockquote {
    @include font-size(14);
    line-height: 1.357;
    color: $gray-light;

    @media #{$phone} {
      @include font-size(10);
    }

    p {
      margin-bottom: 4px;
    }

    cite {
      all: unset;
      color: $brand-color--secondary;
    }
  }

  &:after {
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg width='17' height='15' viewBox='0 0 17 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.66003 14.8502C7.98071 15.347 7.12424 14.5199 7.34533 13.633C7.84149 11.6426 8.22312 9.05879 7.35413 7.70197C5.32222 4.52939 0.0820312 0.5 0.0820312 0.5H14.6234C14.6234 0.5 17.4607 3.38079 15.7474 7.70197C14.8094 10.0676 11.0826 13.0784 8.66003 14.8502Z' fill='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    position: absolute;
    z-index: 1;
    height: 15px;
    width: 17px;
    bottom: -10px;
    right: 25px;

    .hero-feb__quote--first & {
      right: 80px;
    }

    .hero-feb__quote--second & {
      right: 55px;
    }

    .hero-feb__quote--third & {
      right: 85px;
    }

    .hero-feb__quote--fourth & {
      right: 57px;
    }
  }
}
