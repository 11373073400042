.app-widgets {
  &.app-widgets--light {
    background-color: $white;
    padding: 62px 0 135px;
    background-image: none !important;

    @media #{$desktop} {
      padding: 62px 0 80px;
    }

    @media #{$tablet} {
      padding: 40px 0 60px;
    }

    @media #{$phone} {
      padding: 0;
    }
  }

  &.app-widgets--dark {
    background-color: $black;
    color: $white;
    padding: 81px 0 137px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media #{$desktop} {
      padding: 62px 0 80px;
    }

    @media #{$tablet} {
      padding: 60px 0;
    }

    @media #{$phone} {
      padding: 50px 0 0;
    }
  }

  .container {
    max-width: 1194px;
  }
}

.app-widgets__heading {
  font-size: clamp(1.75rem, 4vw, 3rem);
  line-height: calc(58/48);
  letter-spacing: -.5px;
  font-family: $primary-family-bold;
  margin-bottom: 63px;
  text-align: center;

  @media #{$tablet} {
    margin-bottom: 20px;
  }

  @media #{$phone} {
    max-width: 270px;
    margin: 0 auto 26px;
    margin-bottom: 0;
  }
}

.widgets-card__icon {
  background-color: #9A9A9A;
  height: 51px;
  width: 51px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;


  .app-widgets--light & {
    background-color: #9A9A9A;
  }

  .app-widgets--dark & {
    background-color: $brand-color--tertiary;
  }
}

.app-widgets__grid {
  display: flex;
  flex-wrap: wrap;
  padding: 36px 36px 50px;
  overflow: hidden;
  margin: 0 -36px;

  @media #{$tablet} {
    margin: 0 -56px;
    padding: 36px 46px 60px;
  }

  .slick-list {
    overflow: visible !important;
    width: 100%;
  }

  .slick-track {
    display: flex;

    .slick-slide {
      height: auto !important;
      // opacity: 0;
      transition: opacity 300ms ease;

      @media screen and (max-width:960px) {
        opacity: 1;
      }

      &.slick-active {
        opacity: 1;
      }

      >div,
      .app-widgets__item,
      .widgets-card {
        height: 100%;
      }
    }
  }

  .slick-dots {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 35px;

    li {
      &.slick-active {
        button {
          background-color: $brand-color--tertiary;
          border-color: $brand-color--tertiary;

          &.app-widgets--dark {
            background-color: $white;
            border-color: $white;
          }
        }
      }

      button {
        border-radius: 50%;
        height: 12px;
        width: 12px;
        background-color: transparent;
        border: 2px solid #D3D3D3;
        margin: 0;
        gap: 16px;

        &::after,
        &::before {
          display: none;
        }
      }
    }
  }
}

.app-widgets__item {
  padding: 0 22px;

  &:not(:first-child) {
    opacity: 0;

    .slick-initialized & {
      opacity: 1;
    }
  }

  @media #{$desktop} {
    padding: 0 15px;
  }

  @media #{$phone} {
    padding: 0 10px;
  }
}

.widgets-card {
  border-radius: 20px;
  padding: 50px 45px 70px;
  max-width: 347px;
  width: 100%;

  .app-widgets--light & {
    box-shadow: 0px 15px 63px rgba(0, 0, 0, 0.08);
    background-color: $white;
  }

  .app-widgets--dark & {
    border: 2px solid #979797;
    background-color: transparent;
  }

  @media #{$desktop} {
    padding: 40px 35px;
  }

  @media screen and (max-width:860px) {
    max-width: 300px;
  }

  @media screen and (max-width:640px) {
    max-width: 246px;
    border-radius: 15px;
    padding: 40px 35px 55px;
    box-shadow: 0px 12px 50px rgba(0, 0, 0, 0.08);
  }
}

.widgets-card__title {
  @include font-size(26);
  font-family: $primary-family-semibold;
  letter-spacing: -0.523px;
  line-height: calc(32/26);
  margin-bottom: 15px;

  @media screen and (max-width:640px) {
    @include font-size(16);
    letter-spacing: -.41px;
  }
}

.widgets-card__description {
  @include font-size(18);
  line-height: calc(26/18);
  letter-spacing: -0.298px;

  .app-widgets--light & {
    color: rgba($black, .8);
  }

  .app-widgets--dark & {
    color: rgba($white, .8);
  }

  @media screen and (max-width:640px) {
    @include font-size(15);
    letter-spacing: -.23px;
    line-height: calc(25/15);
  }
}
