.mila-chart {
  padding: 0;
  position: relative;
  z-index: 4;

  .container {
    @media #{$phone} {
      padding: 0 14px;
    }
  }
}

.mila-chart__header {
  margin-bottom: 28px;
  text-align: center;

  @media #{$desktop} {
    margin-bottom: 20px;
  }

  @media #{$phone} {
    margin-bottom: 4px;
  }
}

.mila-chart__title {
  @include font-size(34);
  letter-spacing: -0.3px;
  font-family: $primary-family-bold;
  max-width: 691px;
  margin: 0 auto 16px;
  line-height: 1.1;

  @media #{$tablet} {
    @include font-size(28);
    max-width: 491px;
  }

  @media #{$phone} {
    @include font-size(20);
    max-width: 328px;
  }

  br {
    @media screen and (min-width: 1025px) {
      display: none;
    }
  }
}

.mila-chart__card {
  box-shadow: 0px 34px 74px -10px rgba(0, 0, 0, 0.1);
  padding: 40px 29px 100px;
  border-radius: 15px;
  background-color: $white;
  margin-top: -15px;
  position: relative;
  z-index: 2;

  @media #{$desktop} {
    padding: 40px 20px 60px;
  }

  @media #{$tablet} {
    padding: 40px 20px 40px;
  }

  @media #{$phone} {
    padding: 30px 8px 0;
    margin-top: 0;
  }
}

.mila-chart__body {
  // max-width: 993px;
  margin: 0 auto;
  aspect-ratio: 1010/379;
  width: 100%;
  height: auto;
  position: relative;

  @media #{$phone} {
    aspect-ratio: 351/256;
  }

  >div {
    left: 0;
    right: 0;
    top: 0;
  }
}

.mila-chart__image {
  margin: 0 auto;
  width: 100%;

  img {
    width: 100%;
    max-width: 100%;
  }
}

.mila-chart__footer {
  padding-top: 30px;
  border-top: 1px solid #EBEBEB;

  @media #{$phone} {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 8px;
    padding-top: 16px;
  }
}

.mila-chart__info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 28px;
  margin-bottom: 40px;
  gap: 20px;

  @media #{$tablet} {
    flex-direction: column-reverse;
    align-items: center;
  }

  @media #{$phone} {
    gap: 13px;
    padding: 16px 16px 20px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mila-chart__info-text {
  max-width: 569px;
  @include font-size(16);
  line-height: calc(20 / 16);
  margin-bottom: 0;
  opacity: .8;
  min-height: 98px;

  @media #{$tablet} {
    text-align: center;
    margin: 0 auto;
  }

  @media #{$phone} {
    @include font-size(14);
    margin: 0;
    text-align: left;
    max-width: 314px;
  }
}

.mila-chart__info-scheme {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  flex: 0 0 auto;

  @media #{$desktop} {
    gap: 20px;
  }

  @media #{$phone} {
    max-width: 280px;
    margin: 0 auto 16px;
    gap: 10px;
  }

  p {
    padding: 9px 24px;
    @include font-size(16);
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    margin-bottom: 0;
    font-family: $primary-family-medium;

    @media #{$desktop} {
      padding: 0;
      gap: 10px;
    }

    @media #{$phone} {
      @include font-size(11);
      gap: 5px;
    }
  }
}

.mila-chart__filter {
  text-align: center;
  // overflow: hidden;
  // margin: 0 -29px;
  // @media #{$desktop} {
  //   margin: 0 -20px;
  // }

  // @media #{$phone} {
  //   margin: 0 -8px;
  // }
}

.mila-chart__filter-center {
  // display: inline-block;
  // margin: 0 auto;
  vertical-align: top;
  max-width: 100%;
}

.mila-chart__filter-list {
  display: flex;
  gap: 10px;
  align-items: center;
  list-style: none;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 0;
  justify-content: center;
  // padding: 0 29px 20px;
  // overflow-y: hidden;
  // overflow-x: auto;

  // @media #{$desktop} {
  //   padding: 0 0 20px;
  // }

  @media #{$tablet} {
    // padding: 0 29px;
    // padding: 0 29px 120px;
    // margin-bottom: -100px;
  }

  @media #{$phone} {
    // padding: 0 0 20px;
    gap: 4px;
  }

  li {
    flex: 1;

    @media #{$tablet} {
      flex: none;
    }

    &:nth-child(1) {
      @media #{$phone} {
        order: 6;
      }
    }

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      order: 1;
    }

    button {
      white-space: nowrap;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.07);
      padding: 13px 24px;
      min-width: 176px;
      transition: 0.3s ease;
      letter-spacing: -0.3px;
      width: 100%;

      @media #{$desktop} {
        min-width: 130px;
      }

      @media #{$tablet} {
        width: auto;
      }

      @media #{$phone} {
        @include font-size(14);
        padding: 12px;
        min-width: 108px;
        font-family: $primary-family-medium;
      }

      @media screen and (max-width: 376px) {
        @include font-size(12);
        min-width: 96px;
      }

      span {
        @media #{$tablet} {
          display: none;
        }
      }

      &:hover,
      &:focus {
        scale: 1;
        box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.1);
      }

      &.active {
        background-color: $black;
        color: $white;
      }
    }
  }
}


.mila-chart__footer-bottom {
  text-align: center;
  margin-top: 70px;

  @media #{$desktop} {
    margin-top: 48px;
  }
}

.mila-chart__link {
  font-family: $primary-family-medium;
  text-align: center;
  @include font-size(30);
  line-height: 1.6;
  letter-spacing: -0.3px;
  color: $black;
  transition: color 0.3s ease;

  @media #{$desktop} {
    @include font-size(28);
  }

  @media #{$tablet} {
    @include font-size(24);
  }

  @media #{$phone} {
    @include font-size(18);
  }

  &:hover,
  &:focus {
    color: #1be4d5;
  }
}
