.filter-billboard {
  width: 100%;
  padding: 50px 0 0 12.5%;
  border-radius: 15px;

  @media #{$small-screen} {
    padding: 50px 32px 0 100px;
    border-radius: 0;
  }

  @media #{$phone} {
    padding: 75px 32px 0;
  }

  &--landscape-image {
    .filter-billboard__media {
      width: 49%;
      margin-right: 0;

      @media #{$tablet} {
        width: 42%;
      }
    }
  }
}

.filter-billboard__wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 350px;

  @media #{$small-screen} {
    min-height: 335px;
  }

  @media #{$tablet} {
    min-height: inherit;
  }
}

.filter-billboard__content {
  width: 43.1%;
  color: $white;
  @include font-size(24);
  line-height: 1.416;
  padding-bottom: 112px;

  @media #{$small-screen} {
    line-height: 1.7;
    padding-bottom: 76px;
    width: 50.1%;
  }

  @media #{$phone} {
    width: 100%;
  }

  >p {
    max-width: 360px;

    @media #{$phone} {
      @include font-size(18);
    }
  }

  .btn--outline {
    padding: 11px 21px;
    background-color: transparent;
    margin-bottom: 60px;
    margin-left: -90px;
    color: $white;
    border-color: $white;

    @media #{$small-screen} {
      margin-left: -60px;
    }

    @media #{$phone} {
      display: none;
    }

    svg {
      width: 8px;
      height: 14px;
      margin-right: 16px;
      margin-top: -3px;
    }
  }
}

.filter-billboard__media {
  width: 42%;
  margin-right: 60px;
  display: flex;
  justify-content: center;
  position: relative;

  @media #{$small-screen} {
    width: 40%;
    margin-right: 20px;
  }

  @media #{$tablet} {
    margin-right: 0;
  }

  @media #{$phone} {
    display: none;
  }

  img {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%)
  }
}

.filter-billboard__title {
  @include font-size(100);
  letter-spacing: -0.3px;
  line-height: 0.98;

  @media #{$small-screen} {
    margin-bottom: 30px;
  }

  @media #{$tablet} {
    @include font-size(50);
    margin-bottom: 37px;
  }

  @media #{$phone} {
    @include font-size(46);
    margin-bottom: 25px;

    span {
      display: block !important;
    }
  }

  img {
    display: none;

    @media #{$phone} {
      display: block;
      width: 46px;
      height: 46px;
      margin-bottom: 15px;
    }
  }
}

.filter-billboard-standalone {
  padding-top: 134px;
  overflow-y: visible;

  .filter-billboard__media img {
    max-width: 630px;
    max-height: 700px;
  }

  .filter-billboard__content {
    padding-bottom: 150px;

    @media #{$tablet} {
      padding-bottom: 80px;
    }
  }
}

.filter-billboard--landscape-image {
  overflow-y: visible;

  .filter-billboard__media img {
    max-width: 630px;
    max-height: 680px;

    @media #{$tablet} {
      max-width: 430px;
      max-height: 480px;
    }
  }

  @media #{$phone} {
    margin-top: 0px;
  }
}

#mama-to-be-modal {
  .filter-billboard--landscape-image .filter-billboard__media img {
    max-height: 740px;

    @media #{$tablet} {
      max-height: 550px;
    }
  }
}


.filter-order {
  @include font-size(20);
  color: $black;
  line-height: 1.7;
  width: 100%;
  background: $white;
  position: relative;
  z-index: 2;
  padding: 70px 0;

  @media #{$tablet} {
    padding: 45px 0;
  }

  @media #{$phone} {
    padding-bottom: 30px;
    z-index: 3;
  }

  .container {
    max-width: 1310px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media #{$tablet} {
      padding: 0 32px;
    }
  }
}

.filter-order__intro {
  width: 57%;
  padding-left: 100px;

  @media #{$small-screen} {
    padding-left: 80px;
    max-width: 520px;
  }

  @media #{$tablet} {
    order: 1;
    padding-left: 30px;
  }

  @media #{$phone} {
    width: 100%;
    padding-left: 0;
  }
}

.filter-order__content {
  max-width: 625px;

  @media #{$small-screen} {
    @include font-size(18);
  }

  @media #{$tablet} {
    @include font-size(16);
  }
}

.filter-order__intro-title {
  font-family: $primary-family-medium;
  @include font-size(32);
  letter-spacing: -0.3px;
  line-height: 1.375;
  margin-bottom: 40px;
  max-width: 450px;

  @media #{$small-screen} {
    @include font-size(24);
    line-height: 1.5;
    margin-bottom: 33px;
  }

  @media #{$tablet} {
    font-family: $primary-family-medium;
    @include font-size(20);
    margin-bottom: 20px;
  }
}

.filter-order__card {
  width: 32.7%;
  position: absolute;
  right: -0;
  top: -156px;

  @media #{$small-screen} {
    width: 30.8%;
    right: 95px;
    top: -110px;
    max-width: 300px;
  }

  @media screen and (max-width: 1100px) {
    right: 30px;
  }

  @media #{$tablet} {
    max-width: 600px;
    width: 100%;
    position: static;
    margin: -85px auto 40px;
  }

  @media #{$phone} {
    position: static;
    width: 100%;
    margin-bottom: 50px;
    margin-top: -85px;
  }

  .btn--order {
    @media #{$phone} {
      padding: 24px 47px;
    }
  }
}

.filter-order__card-primary,
.filter-order__card-secondary {
  padding: 40px 58px 60px;
  border-radius: 15px;
  background-color: $white;
  margin-bottom: 5px;
  position: relative;
  box-shadow: 0px 32px 44px rgba(0, 0, 0, 0.0538134);

  @media #{$small-screen} {
    padding: 50px 40px;
  }

  @media #{$phone} {
    margin-bottom: 3px;
  }
}

.filter-order__card-primary {
  .icon-plus {
    width: 50px;
    height: 50px;
    color: $primary-color;
    position: absolute;
    bottom: -27px;
    left: 60px;
    z-index: 2;

    @media #{$small-screen} {
      left: 23px;
      width: 40px;
      height: 40px;
      bottom: -20px;
    }
  }
}

.filter-order__card-secondary {
  padding-top: 50px;

  @media #{$small-screen} {
    padding-bottom: 24px;
  }

  .filter-order__card-title {
    @media #{$small-screen} {
      margin-bottom: 25px;
    }
  }
}

.filter-order__card-title {
  @include font-size(22);
  font-family: $primary-family;
  margin-bottom: 30px;

  @media #{$small-screen} {
    @include font-size(18);
    margin-bottom: 25px;
  }

  strong {
    display: block;
    @include font-size(26);
    font-family: $primary-family-medium;
    margin-bottom: 4px;
    max-width: 280px;
    line-height: 1.4;

    @media #{$small-screen} {
      @include font-size(18);
    }
  }
}

.filter-order__card-price {
  font-family: $primary-family;
  @include font-size(22);
  line-height: 1;
  margin-bottom: 5px;

  @media #{$small-screen} {
    @include font-size(18);
  }

  .filter-order__price-amount {
    @include font-size(50);
    font-family: $primary-family-medium;
    display: block;

    @media #{$small-screen} {
      @include font-size(36);
      margin-bottom: 7px;
    }

    .currency-symbol {
      @include font-size(26);
    }
  }
}

.filter-order__note {
  @include font-size(22);
  margin-top: 10px;
  margin-bottom: 40px;

  @media #{$small-screen} {
    @include font-size(18);
    line-height: 1.4;
    margin-top: 15px;
  }

  @media #{$phone} {
    margin-bottom: 20px;
  }

  strong {
    font-family: $primary-family-semibold;
    text-transform: uppercase;
  }
}

.filter-order__button {
  @media #{$phone} {
    transform: translate3d(0, 0, 0);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 2.9%, #FFFFFF 35.48%);
    z-index: 2;
  }

  .btn--order {
    border-radius: 100px;

    @media #{$small-screen} {
      @include font-size(18);
      padding-top: 17px;
      padding-bottom: 17px;
    }

    @media #{$phone} {
      width: 100%;
    }
  }
}

.filter-order__button-close {
  display: none;

  @media #{$phone} {
    display: block;
  }

  svg {
    width: 37px;
    height: 37px;
  }
}

.filter-feature {
  overflow: hidden;
  padding-bottom: 90px;
  background-color: $white;

  @media #{$small-screen} {
    padding-bottom: 0;
  }

  @media #{$tablet} {
    padding-bottom: 38px;
  }

  .container {
    @media #{$phone} {
      padding: 0;
    }
  }
}

.filter-feature__image-wrap {
  position: relative;
  max-width: 930px;

  @media #{$small-screen} {
    max-width: 660px;
  }

  @media #{$tablet} {
    max-width: 100%;
  }
}

.filter-feature__icon-button {
  padding-left: 0;
  list-style: none;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  .icon-box {
    width: 70px;
    height: 70px;
    position: absolute;

    @media #{$small-screen} {
      width: 50px;
      height: 50px;
    }

    @media #{$tablet} {
      width: 70px;
      height: 70px;
    }

    @media #{$phone} {
      width: 50px;
      height: 50px;
    }
  }
}

.filter-feature__icon-button--basic-breather {
  .icon-box--1 {
    top: 20%;
    left: 23%;

    @media #{$small-screen} {
      top: 19.5%;
      left: 21.5%;
    }

    @media #{$tablet} {
      left: 21.5%;
    }

    @media #{$phone} {
      left: 20.5%;
    }

    @media #{$phone-portrait} {
      left: 13.5%;
      top: 18%;
    }
  }

  .icon-box--2 {
    top: 52%;
    left: 28%;

    @media #{$small-screen} {
      left: 26%;
    }

    @media #{$tablet} {
      left: 27%;
    }

    @media #{$phone} {
      left: 25%;
    }

    @media #{$phone-portrait} {
      left: 19%;
      top: 50%;
    }
  }

  .icon-box--3 {
    top: 58%;
    left: 62%;

    @media #{$small-screen} {
      left: 58%;
    }

    @media #{$tablet} {
      left: 58%;
    }

    @media #{$phone} {
      left: 57%;
    }

    @media #{$phone-portrait} {
      top: 54%;
    }
  }
}

.filter-feature__icon-button--rookie-parent {
  .icon-box--1 {
    top: 20%;
    left: 23%;

    @media #{$small-screen} {
      top: 19.5%;
      left: 21%;
    }

    @media #{$tablet} {
      left: 20.5%;
    }

    @media #{$phone-portrait} {
      left: 13.5%;
      top: 18%;
    }
  }

  .icon-box--2 {
    top: 52%;
    left: 67%;

    @media #{$small-screen} {
      top: 50%;
      left: 62.5%;
    }

    @media #{$tablet} {
      left: 62%;
      top: 50%;
    }

    @media #{$phone-portrait} {
      top: 48%;
    }
  }

  .icon-box--3 {
    top: 33%;
    left: 56%;

    @media #{$small-screen} {
      left: 34%;
    }

    @media #{$tablet} {
      left: 53%;
    }

    @media #{$phone-portrait} {
      top: 29%;
      left: 51%;
    }
  }

  .icon-box--4 {
    top: 27%;
    left: 42%;

    @media #{$small-screen} {
      top: 25%;
      left: 39.3%;
    }

    @media #{$tablet} {
      left: 39%;
    }

    @media #{$phone-portrait} {
      left: 34%;
      top: 23%;
    }
  }

}

.filter-feature__icon-button--big-sneeze {
  .icon-box--1 {
    top: 18%;
    left: 22.5%;

    @media #{$small-screen} {
      left: 20.5%;
    }

    @media #{$phone-portrait} {
      left: 12.5%;
      top: 14%;
    }
  }

  .icon-box--2 {
    top: 59.5%;
    left: 54.5%;

    @media #{$small-screen} {
      left: 51%;
    }

    @media #{$phone-portrait} {
      left: 49%;
      top: 55%;
    }
  }

  .icon-box--3 {
    top: 49.5%;
    left: 65.5%;

    @media #{$small-screen} {
      left: 61.5%;
    }

    @media #{$phone-portrait} {
      top: 44%;
    }
  }

  .icon-box--4 {
    top: 60%;
    left: 83%;

    @media #{$small-screen} {
      left: 78%;
    }

    @media #{$phone-portrait} {
      left: 81%;
      top: 55%;
    }
  }

  .icon-box--5 {
    top: 37%;
    left: 24%;

    @media #{$small-screen} {
      left: 22.5%;
    }

    @media #{$phone-portrait} {
      left: 14.5%;
      top: 35%;
    }
  }
}

.filter-feature__icon-button--critter-cuddler {
  .icon-box {
    @media #{$phone-portrait} {
      width: 35px;
      height: 35px;
    }
  }

  .icon-box--1 {
    top: 60%;
    left: 84.5%;

    @media #{$small-screen} {
      left: 79%;
    }

    @media #{$phone-portrait} {
      top: 58%;
      left: 84%;
    }
  }

  .icon-box--2 {
    top: 59.5%;
    left: 61.5%;

    @media #{$small-screen} {
      left: 58%;
    }

    @media #{$phone-portrait} {
      top: 58.5%;
      left: 59%;
    }
  }

  .icon-box--3 {
    top: 50.5%;
    left: 71.5%;

    @media #{$small-screen} {
      left: 67.5%;
    }

    @media #{$phone-portrait} {
      top: 49.5%;
      left: 70.5%;
    }
  }
}

.filter-feature__icon-button--mama-to-be {
  .icon-box--1 {
    top: 18%;
    left: 23%;

    @media #{$small-screen} {
      left: 21%;
    }

    @media #{$phone-portrait} {
      top: 14%;
      left: 14%;
    }
  }

  .icon-box--2 {
    top: 46.5%;
    left: 64.5%;

    @media #{$small-screen} {
      left: 60.5%;
    }

    @media #{$phone-portrait} {
      top: 42%;
    }
  }

  .icon-box--3 {
    top: 23.5%;
    left: 53.5%;

    @media #{$small-screen} {
      left: 50.5%;
    }

    @media #{$phone-portrait} {
      left: 48.5%;
      top: 20%;
    }
  }

  .icon-box--4 {
    top: 45%;
    left: 29.5%;

    @media #{$small-screen} {
      left: 27.5%;
    }

    @media #{$phone-portrait} {
      left: 20.5%;
      top: 41%;
    }
  }
}

.filter-feature__icon-button--home-wrecker {
  .icon-box--1 {
    top: 60%;
    left: 67.5%;

    @media #{$small-screen} {
      left: 63%;
    }

    @media #{$phone-portrait} {
      top: 56%;
    }
  }

  .icon-box--2 {
    top: 13%;
    left: 63%;

    @media #{$small-screen} {
      left: 59%;
    }

    @media #{$phone-portrait} {
      top: 10%;
    }
  }

  .icon-box--3 {
    top: 27.5%;
    left: 40.5%;

    @media #{$small-screen} {
      left: 36.5%;
    }

    @media #{$phone-portrait} {
      left: 31.5%;
      top: 24%;
    }
  }

  .icon-box--4 {
    top: 56%;
    left: 46.5%;

    @media #{$small-screen} {
      left: 43.5%;
    }

    @media #{$phone-portrait} {
      left: 39.5%;
      top: 52%;
    }
  }

  .icon-box--5 {
    top: 60.5%;
    left: 89%;

    @media #{$small-screen} {
      left: 82%;
      top: 58%;
    }

    @media #{$phone-portrait} {
      left: 85%;
      top: 54%;
    }
  }
}

.filter-feature__icon-button--overreactor {
  .icon-box--1 {
    top: 19%;
    left: 23%;

    @media #{$small-screen} {
      left: 21%;
    }

    @media #{$phone-portrait} {
      left: 13%;
      top: 15%;
    }
  }

  .icon-box--2 {
    top: 55%;
    left: 23%;

    @media #{$small-screen} {
      left: 21%;
    }

    @media #{$phone-portrait} {
      left: 12%;
    }
  }

  .icon-box--3 {
    top: 50.5%;
    left: 77.5%;

    @media #{$small-screen} {
      left: 72.5%;
    }

    @media #{$phone-portrait} {
      left: 74.5%;
      top: 47%;
    }
  }

  .icon-box--4 {
    top: 41%;
    left: 28%;

    @media #{$small-screen} {
      top: 40%;
      left: 26%;
    }

    @media #{$phone-portrait} {
      top: 39%;
      left: 20%;
      width: 40px;
      height: 35px;
    }
  }

  .icon-box--5 {
    top: 29.5%;
    left: 43.5%;

    @media #{$small-screen} {
      left: 46.5%;
    }

    @media #{$phone-portrait} {
      left: 40.5%;
      top: 27%;
    }
  }
}

.filter-feature__slider {
  max-width: 960px;
  margin-top: -60px;

  @media #{$small-screen} {
    margin-top: 0;
    max-width: 760px;
  }

  @media #{$tablet} {
    max-width: 100%;
  }

  @media #{$phone} {
    margin: -50px 0 0;
    overflow: hidden;
    max-width: calc(100% + 34px);
    width: calc(100% + 34px);
    left: -17px;
  }

  .slick-list {
    position: relative;
    z-index: 0;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: -1px;
      left: -2px;
      height: calc(100% + 2px);
      width: 218px;
      pointer-events: none;
      background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 50%);
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: -1px;
      right: -2px;
      z-index: 1;
      height: calc(100% + 2px);
      width: 218px;
      pointer-events: none;
      background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 50%);
    }

    @media #{$phone} {
      overflow: visible;
    }
  }

  .slick-dots {
    justify-content: center;

    @media #{$phone} {
      margin-top: -36px;
      z-index: 2;
      position: relative;
    }

    >li:only-child {
      display: none;
    }
  }

  .slick-dots li.slick-active button {
    width: 12px;
  }

  .slick-dots li.slick-active button:before {
    @media #{$phone} {
      height: calc(100% + 2px);
      width: calc(100% + 2px);
      animation: none;
      top: -1px;
      left: -1px;
    }
  }

  .slick-slide {
    &>div {
      border-radius: 15px;
      border: 1px solid #979797;
      padding: 46px 40px 46px 52px;
      color: rgba(0, 0, 0, 0.25);
      margin: 0 15px 90px;
      line-height: 1.8;
      cursor: pointer;

      @media #{$small-screen} {
        @include font-size(16);
        padding: 36px 35px 30px;
        margin: 0 7px 90px;
        line-height: 1.5;
      }

      @media #{$tablet} {
        padding: 38px 33px 46px 40px;
        color: #8D8D8D;
        @include font-size(14);
      }

      @media #{$phone} {
        margin: 45px 8px 65px;
        background-color: $white;
        border: 1px solid rgba(151, 151, 151, 0.25);
        line-height: 1.5;
      }
    }

    @include font-size(18);
    position: relative;
    height: auto;

    p img {
      max-width: 18px;
      display: inline-block;

      @media #{$small-screen} {
        max-width: 16px;
      }

      @media #{$tablet} {
        max-width: 14px;
      }
    }

    &.slick-current {
      &>div {
        border-color: $white;
        box-shadow: 0px 14px 84px rgba(32, 32, 32, 0.08);
        color: $black;
      }

      @media #{$tablet} {
        color: #8D8D8D;
      }

      .filter-feature__slide-title {
        color: $black;
      }

      // &:after{
      //    display: none;
      // }
      // + .slick-slide{
      //    &:after{
      //       transform: rotate(-180deg);
      //       left: auto;
      //       right: -2px;
      //    }
      // }
    }

  }
}

#rookie-parent-modal .slick-dots li {
  &:hover button {
    border-color: #8451EE;
  }

  &.slick-active button {
    border-color: #8451EE;

    &:before {
      background-color: #8451EE;
    }
  }
}

#big-sneeze-modal .slick-dots li {
  &:hover button {
    border-color: #FBC70D;
  }

  &.slick-active button {
    border-color: #FBC70D;

    &:before {
      background-color: #FBC70D;
    }
  }
}

#home-wrecker-modal .slick-dots li {
  &:hover button {
    border-color: #F27E0F;
  }

  &.slick-active button {
    border-color: #F27E0F;

    &:before {
      background-color: #F27E0F;
    }
  }
}

#critter-cuddler-modal .slick-dots li {
  &:hover button {
    border-color: #ED2C51;
  }

  &.slick-active button {
    border-color: #ED2C51;

    &:before {
      background-color: #ED2C51;
    }
  }
}

#basic-breather-modal .slick-dots li {
  &:hover button {
    border-color: #3E4649;
  }

  &.slick-active button {
    border-color: #3E4649;

    &:before {
      background-color: #3E4649;
    }
  }
}

#overreactor-modal .slick-dots li {
  &:hover button {
    border-color: #0A54E3;
  }

  &.slick-active button {
    border-color: #0A54E3;

    &:before {
      background-color: #0A54E3;
    }
  }
}

#mama-to-be-modal .slick-dots li {
  &:hover button {
    border-color: #1CE1D3;
  }

  &.slick-active button {
    border-color: #1CE1D3;

    &:before {
      background-color: #1CE1D3;
    }
  }
}

.filter-feature__slide-title {
  font-family: $primary-family-semibold;
  @include font-size(20);
  color: rgba(0, 0, 0, 0.25);
  margin-bottom: 25px;

  @media #{$small-screen} {
    @include font-size(16);
    margin-bottom: 20px;
  }
}

.filter-feature__image-slider {
  max-width: 930px;

  @media #{$small-screen} {
    max-width: 660px;
    width: 100%;
  }

  @media #{$tablet} {
    max-width: 100%;
  }

  .slick-arrow {
    position: absolute;
    top: 40%;
    width: 45px;
    height: 45px;
    color: #000;
    z-index: 1;
    transition: 0.3s ease;

    @media #{$small-screen} {
      top: 32%;
    }
  }
}

.filter-feature__slide-prev,
.filter-feature__slide-next {
  width: 45px;
  height: 45px;

  &.slick-disabled {
    pointer-events: none;

    svg {
      color: rgba($black, 0.15) !important;
    }
  }

  svg {
    width: 45px;
    height: 45px;

    #rookie-parent-modal & {
      color: #8451EE;
    }

    #big-sneeze-modal & {
      color: #FBC70D;
    }

    #home-wrecker-modal & {
      color: #FC8310;
    }

    #critter-cuddler-modal & {
      color: #ED2C51;
    }

    #basic-breather-modal & {
      color: #3E4649;
    }

    #overreactor-modal & {
      color: #0A54E3;
    }

    #mama-to-be-modal & {
      color: #1CE1D3;
    }
  }
}

.filter-feature__slide-prev {
  left: 65px;

  @media #{$small-screen} {
    left: 40px;
  }

  @media #{$tablet} {
    left: 0;
  }
}

.filter-feature__slide-next {
  right: 65px;

  @media #{$small-screen} {
    right: 50px;
  }

  @media #{$tablet} {
    right: 0;
  }
}

.filter-feature__image-slide img {
  max-width: 987px;

  @media #{$small-screen} {
    max-width: 100%;
  }

  @media #{$phone-portrait} {
    max-width: inherit;
    width: 120%;
    margin-left: -10%;
  }
}


.filter-detail {
  overflow: hidden;
  background-color: #FFF8F1;
  padding: 115px 0 80px;

  @media #{$small-screen} {
    padding-top: 90px;
    padding-bottom: 15px;
  }

  #rookie-parent-modal & {
    background-color: #F7F4FC;
  }

  #big-sneeze-modal & {
    background-color: #FAF6E9;
  }

  #home-wrecker-modal & {
    background-color: #FFF8F1;
  }

  #critter-cuddler-modal & {
    background-color: #FFF2F5;
  }

  #basic-breather-modal & {
    background-color: #F0F0F0;
  }

  #overreactor-modal & {
    background-color: #EBF2FF;
  }

  #mama-to-be-modal & {
    background-color: #F4FFFE;
  }

  @media #{$tablet} {
    padding-top: 50px;
    padding-bottom: 70px;
  }

  .container {
    @media #{$phone} {
      padding: 0;
    }
  }
}

.filter-detail__intro {
  padding-left: 110px;

  @media #{$tablet} {
    padding-left: 30px;
  }

  @media #{$phone} {
    padding: 0 32px;
  }
}

.filter-detail__intro-title {
  @include font-size(32);
  font-family: $primary-family-medium;
  max-width: 380px;
  line-height: 1.3;

  @media #{$small-screen} {
    @include font-size(24);
    line-height: 1.5;
    margin-bottom: 30px;
  }

  @media #{$tablet} {
    @include font-size(20);
  }

  @media #{$phone} {
    max-width: 200px;
  }
}

.filter-detail__intro-description {
  @include font-size(20);
  max-width: 610px;
  line-height: 1.7;
  margin-top: 53px;

  @media #{$small-screen} {
    margin-top: 25px;
    @include font-size(18);
    max-width: 410px;
  }

  @media #{$tablet} {
    @include font-size(16);
    margin-top: 27px;
    line-height: 1.5;
  }
}

.filter-detail__slider {
  max-width: 930px;
  margin-top: 80px;

  @media #{$small-screen} {
    max-width: 740px;
  }

  @media #{$tablet} {
    margin-top: 40px;
    max-width: 100%;
  }

  @media #{$phone} {
    left: -17px;
    max-width: calc(100% + 34px);
    width: calc(100% + 34px);
  }

  .slick-list {
    position: relative;
    z-index: 0;

    @media #{$phone} {
      overflow: visible;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: -1px;
      left: -2px;
      height: calc(100% + 2px);
      width: 218px;
      pointer-events: none;
      background: linear-gradient(90deg, #FFF8F1 0%, rgba(255, 255, 255, 0) 50%);

      #rookie-parent-modal & {
        background: linear-gradient(90deg, #F7F4FC 0%, rgba(255, 255, 255, 0) 50%);
      }

      #big-sneeze-modal & {
        background: linear-gradient(90deg, #FAF6E9 0%, rgba(255, 255, 255, 0) 50%);
      }

      #home-wrecker-modal & {
        background: linear-gradient(90deg, #FFF8F1 0%, rgba(255, 255, 255, 0) 50%);
      }

      #critter-cuddler-modal & {
        background: linear-gradient(90deg, #FFF2F5 0%, rgba(255, 255, 255, 0) 50%);
      }

      #basic-breather-modal & {
        background: linear-gradient(90deg, #F0F0F0 0%, rgba(255, 255, 255, 0) 50%);
      }

      #overreactor-modal & {
        background: linear-gradient(90deg, #EBF2FF 0%, rgba(255, 255, 255, 0) 50%);
      }

      #mama-to-be-modal & {
        background: linear-gradient(90deg, #F4FFFE 0%, rgba(255, 255, 255, 0) 50%);
      }
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      z-index: 1;
      top: -1px;
      right: -2px;
      height: calc(100% + 2px);
      width: 218px;
      pointer-events: none;
      background: linear-gradient(270deg, #FFF8F1 0%, rgba(255, 255, 255, 0) 50%);

      #rookie-parent-modal & {
        background: linear-gradient(270deg, #F7F4FC 0%, rgba(255, 255, 255, 0) 50%);
      }

      #big-sneeze-modal & {
        background: linear-gradient(270deg, #FAF6E9 0%, rgba(255, 255, 255, 0) 50%);
      }

      #home-wrecker-modal & {
        background: linear-gradient(270deg, #FFF8F1 0%, rgba(255, 255, 255, 0) 50%);
      }

      #critter-cuddler-modal & {
        background: linear-gradient(270deg, #FFF2F5 0%, rgba(255, 255, 255, 0) 50%);
      }

      #basic-breather-modal & {
        background: linear-gradient(270deg, #F0F0F0 0%, rgba(255, 255, 255, 0) 50%);
      }

      #overreactor-modal & {
        background: linear-gradient(270deg, #EBF2FF 0%, rgba(255, 255, 255, 0) 50%);
      }

      #mama-to-be-modal & {
        background: linear-gradient(270deg, #F4FFFE 0%, rgba(255, 255, 255, 0) 50%);
      }
    }

  }

  .slick-track {
    display: flex;
  }

  .slick-dots {
    justify-content: center;
  }

  .slick-dots li.slick-active button {
    width: 12px;
  }

  .slick-dots li.slick-active button:before {
    @media #{$phone} {
      height: calc(100% + 2px);
      width: calc(100% + 2px);
      animation: none;
      top: -1px;
      left: -1px;
    }
  }

  .slick-slide {
    &>div {
      border-radius: 15px;
      border: 1px solid #979797;
      padding: 42px 44px 50px 52px;
      color: rgba(0, 0, 0, 0.25);
      margin: 0 15px 90px;
      line-height: 1.8;
      cursor: pointer;

      @media #{$small-screen} {
        padding: 36px 35px 30px;
        margin: 0 7px 90px;
        line-height: 1.5;
        @include font-size(16);

      }

      @media #{$tablet} {
        @include font-size(14);
      }

      @media #{$phone} {
        // width: 245px;
        margin: 0 8px 30px;
        padding: 45px 55px 53px 40px;
        background-color: $white;
        border: 1px solid rgba(151, 151, 151, 0.25);
      }
    }

    @include font-size(18);
    position: relative;
    height: auto;

    img {
      max-width: 18px;
      display: inline-block;
      margin-top: -8px;
    }

    &.slick-active {
      &>div {
        background-color: $white;
        border-color: $white;
        box-shadow: 0px 14px 84px rgba(32, 32, 32, 0.08);
        color: $black;
      }

      @media #{$phone} {
        margin-left: 8px;
        margin-right: 8px;
      }

      .filter-detail__slide-title {
        color: $black;
      }
    }
  }

}

.filter-detail__slider--has-detail {
  .slick-list {
    padding: 0 !important;

    &:after,
    &:before {
      display: none;
    }

    @media #{$phone} {
      margin-bottom: 10px;
    }
  }

  .slick-track {
    width: auto !important;
    transform: translate(0, 0) !important;
  }

  .slick-slide:not(.slick-active) {
    display: none;
  }

  .slick-slide.slick-active {
    display: block;
    width: calc(100% - 17px) !important;
    max-width: 730px;
    transition: 0.7s;

    @media #{$phone} {
      width: calc(100% - 50px) !important;
    }

    margin-left: 25px;
    margin-right: 25px;

    &>div {
      box-shadow: 0px 32px 44px rgba(0, 0, 0, 0.05);
      padding: 60px 44px 50px 52px;
      margin: 0;
      position: relative;
      cursor: auto;

      @media #{$phone} {
        padding: 60px 25px 30px;
      }
    }
  }

  .filter-detail__open-link {
    display: none;
  }

  .filter-detail__slide-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .filter-detail__primary {
    max-width: 233px;
    width: 37%;

    @media #{$phone} {
      width: 100%;
      max-width: inherit;
    }
  }

  .filter-detail__slide-title {
    margin-bottom: 40px;

    @media #{$small-screen} {
      @include font-size(16);
    }
  }

  .filter-detail__content {
    margin-bottom: 33px;

    @media #{$tablet} {
      @include font-size(14);
    }
  }

  .filter-detail__secondary {
    display: block;

    @media #{$phone} {
      width: 100%;
    }
  }

  .filter-detail__close-link {
    @media #{$phone} {
      position: absolute;
      top: 20px;
      right: 20px;
    }

  }
}

.filter-detail__slide-title {
  font-family: $primary-family-semibold;
  @include font-size(20);
  color: rgba(0, 0, 0, 0.25);
  margin-bottom: 20px;
  line-height: 1.6;

  @media #{$small-screen} {
    @include font-size(16);
    line-height: 1.6;
  }
}

.filter-detail__content {
  @media #{$small-screen} {
    @include font-size(14);
  }

  @media #{$tablet} {
    line-height: 1.5;
  }
}

.filter-detail__image-slider {
  margin-top: 80px;
  max-width: 930px;

  @media #{$small-screen} {
    max-width: 660px;
    margin-top: 30px;
  }

  @media #{$tablet} {
    max-width: 100%;
  }

  @media #{$phone} {
    max-width: 100%;
    margin-top: 35px;
  }

  .slick-arrow {
    position: absolute;
    top: 40%;
    width: 45px;
    height: 45px;
    color: #000;
    z-index: 1;
    transition: 0.3s ease;
  }
}

.filter-detail__slide-prev,
.filter-detail__slide-next {
  width: 45px;
  height: 45px;

  &.slick-disabled {
    pointer-events: none;

    svg {
      color: rgba($black, 0.15) !important;
    }
  }

  svg {
    width: 45px;
    height: 45px;

    #rookie-parent-modal & {
      color: #8451EE;
    }

    #big-sneeze-modal & {
      color: #FBC70D;
    }

    #home-wrecker-modal & {
      color: #FC8310;
    }

    #critter-cuddler-modal & {
      color: #ED2C51;
    }

    #basic-breather-modal & {
      color: #3E4649;
    }

    #overreactor-modal & {
      color: #0A54E3;
    }

    #mama-to-be-modal & {
      color: #1CE1D3;
    }
  }
}

.filter-detail__slide-prev {
  left: 105px;

  @media #{$small-screen} {
    left: 40px;
  }
}

.filter-detail__slide-next {
  right: 105px;

  @media #{$small-screen} {
    right: 40px;
  }
}

.filter-detail__image-slide {
  // display: flex !important;
  // justify-content: center;

  span {
    max-width: 420px;
    margin: 0 auto;
    display: block !important;

    @media #{$small-screen} {
      max-width: 392px;
    }

    @media #{$phone-portrait} {
      max-width: 272px;
    }
  }
}

.filter-detail__open-link,
.filter-detail__close-link {
  font-family: $primary-family-semibold;
  text-decoration: none;
  @include font-size(18);

  @media #{$small-screen} {
    @include font-size(16);
  }

  #rookie-parent-modal & {
    color: #8451EE;

    &:hover,
    &:focus {
      opacity: 0.7
    }
  }

  #big-sneeze-modal & {
    color: #FBC70D;

    &:hover,
    &:focus {
      opacity: 0.7
    }
  }

  #home-wrecker-modal & {
    color: #FC8310;

    &:hover,
    &:focus {
      opacity: 0.7
    }
  }

  #critter-cuddler-modal & {
    color: #ED2C51;

    &:hover,
    &:focus {
      opacity: 0.7
    }
  }

  #basic-breather-modal & {
    color: #3E4649;

    &:hover,
    &:focus {
      opacity: 0.7
    }
  }

  #overreactor-modal & {
    color: #0A54E3;

    &:hover,
    &:focus {
      opacity: 0.7
    }
  }

  #mama-to-be-modal & {
    color: #1CE1D3;

    &:hover,
    &:focus {
      opacity: 0.7
    }
  }
}

.filter-detail__arrow {
  display: none;

  svg {
    width: 22px;
    height: 15px;
    margin-left: 10px;
  }

  &.expanded {
    display: block;
  }
}

.filter-detail__secondary {
  text-align: right;
  width: 46%;
  display: none;
}

.filter-detail__close-link {
  display: inline-block;
}

.filter-detail__list {
  list-style: none;
  padding-left: 0;
  text-align: left;
  margin-top: 75px;
}

.filter-detail__list-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 32px;

  .tooltip {
    top: -3px;
    right: 4px;
    line-height: 1;
  }
}

.filter-detail__list-label {
  position: relative;
  padding-right: 33px;
  width: 74%;

  @media #{$phone} {
    width: 68%;
  }
}

.filter-detail__label-title {
  font-family: $primary-family-semibold;
  @include font-size(16);
  text-transform: uppercase;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 0;

  @media #{$phone} {
    @include font-size(14);
  }
}

.filter-detail__list-value {
  line-height: 1;
  width: 24%;
  text-align: center;

  strong {
    font-family: $primary-family-medium;
    @include font-size(18);

    @media #{$phone} {
      @include font-size(14);
    }
  }
}

.filter-card {
  padding: 157px 160px;
  background-color: $white;

  @media #{$small-screen} {
    padding: 100px;
  }

  @media #{$tablet} {
    padding: 77px 40px 70px;
  }

  @media #{$phone} {
    padding: 50px 10px 0;
  }
}

.filter-card__title {
  @include font-size(32);
  font-family: $primary-family-medium;
  margin-bottom: 63px;
  padding: 0 12px;

  @media #{$small-screen} {
    @include font-size(24);
  }

  @media #{$tablet} {
    margin-bottom: 40px;
  }

  @media #{$phone} {
    margin-bottom: 10px;
    @include font-size(20);
    line-height: 1.4;
  }

  @media #{$phone-portrait} {
    max-width: 200px;
  }
}

.filter-card__slider {
  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit;

    @media #{$small-screen} {
      height: 100%;
    }
  }

  .slick-dots {
    justify-content: center;
    margin-top: 26px;

    @media #{$phone} {
      margin-top: 6px;
      margin-bottom: 20px;
    }
  }

  .slick-dots li.slick-active button {
    width: 12px;
  }

  .slick-dots li.slick-active button:before {
    @media #{$phone} {
      height: calc(100% + 2px);
      width: calc(100% + 2px);
      animation: none;
      top: -1px;
      left: -1px;
    }
  }

  .filter-card__slide-prev {
    left: -100px;

    @media #{$small-screen} {
      left: auto;
      right: 58px;
    }
  }

  .filter-card__slide-next {
    right: -100px;

    @media #{$small-screen} {
      right: 10px;
    }
  }

  .slick-arrow {
    position: absolute;
    top: calc(50% - 31px);
    width: 62px;
    height: 62px;
    color: $black;
    z-index: 1;
    transition: 0.3s ease;
    background-color: $white;
    border-radius: 50%;

    @media #{$small-screen} {
      top: -100px;
      width: 42px;
      height: 42px;
    }

    @media #{$tablet} {
      top: -80px;
    }

    svg {
      width: 62px;
      height: 62px;

      @media #{$small-screen} {
        width: 42px;
        height: 42px;
      }
    }

    &.slick-disabled {
      color: rgba($black, 0.08);
    }
  }


  .slick-slide {
    &>div {
      margin: 0 12px;
      padding-bottom: 20px;
      height: 100% !important;

      @media #{$small-screen} {
        margin: 30px 7px;
        margin-bottom: 0;
        padding-bottom: 30px;
      }

      .filter-card__slide-item {
        height: 100% !important;
        display: flex;

        &>a {
          border-radius: 18px;
          padding: 70px 35px 230px 55px;
          box-shadow: 0px 3px 10px rgba(53, 53, 53, 0.1);
          text-decoration: none;
          position: relative;
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          height: 100%;

          @media #{$small-screen} {
            padding: 50px 35px 190px 45px;
            border-width: 3px;
          }

          @media #{$phone} {
            padding: 50px 25px 150px;
            border-radius: 10px;
          }

          @media #{$phone-portrait} {
            padding-bottom: 190px;
            border-radius: 10px;
          }

          &:before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border: 3px solid $white;
            border-radius: 18px;
            transition: border 0.25s ease;
          }

          &:hover:before {
            border-color: currentColor;
          }
        }
      }
    }

    outline: none;
    border: none;
    position: relative;

    a {
      text-decoration: none;
    }
  }

}

.filter-card__slide-icon {
  max-width: 96px;
  margin-bottom: 47px;
}

.filter-card__slide-title {
  margin-bottom: 35px;
  text-decoration: none;
  @include font-size(32);

  @media #{$small-screen} {
    @include font-size(24);
    line-height: 1.2;
    margin-bottom: 8px;
  }

  @media #{$phone} {
    @include font-size(20);
    margin-bottom: 20px;
  }

  img {
    max-width: 32px;
    margin-top: -10px;
    display: inline-block;
    margin-left: 5px;
    vertical-align: middle;

    @media #{$phone} {
      max-width: 25px;
    }
  }
}

.filter-card__slide-description {
  @include font-size(20);
  font-family: $primary-family-medium;
  max-width: 180px;
  line-height: 1.5;
  margin-bottom: 30px;
  min-height: 60px;
  color: $black;

  @media #{$small-screen} {
    line-height: 1.3;
    @include font-size(16);
  }

  @media #{$phone} {
    @include font-size(16);
  }
}

.filter-card__slide-image {
  position: absolute;
  height: 232px;
  bottom: -1px;
  width: 320px;
  left: 50%;
  transform: translate(-50%, 0);

  @media #{$small-screen} {
    height: 183px;
    width: 250px;
  }

  @media #{$phone} {
    height: 160px;
    width: 220px;
  }

  @media #{$phone-portrait} {
    height: 196px;
    width: 270px;
  }
}

.filter-single {
  background-color: #FFFFFF;

  .header--fixed {
    position: static;
  }

  .sticky-navigation.sticky-navigation--fullwidth {
    display: none;
    z-index: 13;
  }

  .header--fixed+.filter-single__wrapper .sticky-navigation.sticky-navigation--fullwidth {
    @media #{$phone} {
      display: block;
    }
  }
}

.filter-single__wrapper {
  margin: 50px auto;
  border-radius: 15px;
  max-width: 1440px;
  width: 100%;
  padding: 0;

  @media #{$tablet} {
    margin-top: 0;
  }


}

.filter-single__wrapper-standalone {
  overflow: visible;

  @media #{$phone} {
    margin-top: 0px;
  }
}
