.product-faqs {
  padding: 80px 0;

  @media #{$small-screen} {
    padding: 60px 0;
  }

  @media #{$phone} {
    padding: 30px 0;
  }
}

.product-faqs__card {
  background-color: $white;
  border-radius: 16px;
  box-shadow: 0 14px 84px rgba(0, 0, 0, 0.08);
  padding: 80px;
  overflow: hidden;

  @media #{$small-screen} {
    padding: 60px;
  }

  @media #{$phone} {
    padding: 32px;
  }
}

.product-faqs__title {
  @include font-size(56);
  line-height: 1;
  letter-spacing: -0.31px;
  margin-bottom: 56px;

  @media #{$small-screen} {
    @include font-size(40);
    margin-bottom: 40px;
  }

  @media #{$tablet} {
    @include font-size(36);
    margin-bottom: 32px;
  }

  @media #{$phone} {
    @include font-size(28);
    margin-bottom: 20px;
    text-align: center;
  }
}

.product-faqs__item {
  position: relative;
  z-index: 1;
  padding-bottom: 6px;

  &:not(:last-child) {
    border-bottom: 1px solid #D6D6D6;
  }

  &:last-child {
    &::after {
      bottom: -81px;
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: -1px;
    left: -100px;
    right: -100px;
    z-index: -1;
    background-color: #F7F7F7;
    transition: all 300ms ease;
    opacity: 0;
  }

  &.product-faqs__item--current {
    &::after {
      opacity: 1;
    }
  }
}

.product-faqs__header {
  position: relative;
  padding: 38px 30px 32px 0;
  cursor: pointer;

  @media #{$small-screen} {
    padding: 30px 24px 24px 0;
  }
  @media #{$small-screen} {
    padding: 28px 24px 24px 0;
  }
  .icon-down-arrow-thin {
    width: 18px;
    height: 8px;
    position: absolute;
    top: calc(50% - 2px);
    right: 0;
    transition: all 300ms ease;

    .product-faqs__item--current & {
      color: var(--primary-color);
      transform: rotateX(180deg);
    }
  }
}

.product-faqs__item-title {
  @include font-size(22);
  letter-spacing: -0.2px;
  font-family: $primary-family-semibold;
  transition: all 300ms ease;
  margin-bottom: 0;

  @media #{$small-screen} {
    @include font-size(20);
  }

  .product-faqs__item--current & {
    color: var(--primary-color);
  }
}

.product-faqs__body {
  // display: none;
  @include font-size(20);
  letter-spacing: -0.17px;
  line-height: calc(32/20);

  @media #{$small-screen} {
    @include font-size(18);
  }

}

.product-faqs__body-inner {
  padding: 0 0 32px;

  @media #{$tablet} {
    padding-bottom: 24px;
  }
}
