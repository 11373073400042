.wall-of-love-refactored {
  background-color: #fbfbfb;

  @media #{$phone} {
    background-color: $white;
  }
}

.hero--love {
  background: none !important;
  padding-top: 20px !important;
  max-width: 1680px;
  position: relative;

  @media only screen and (min-width: 1720px) {
    margin: 0 auto;
  }

  .hero__inner {
    background-color: transparent;
    border-radius: 15px;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;

    @media #{$phone} {
      border-radius: 0;
      margin: 0;
    }
  }

  .hero__image--mobile {
    display: none;

    @media #{$phone} {
      display: block;
    }
  }

  .hero__image--desktop {
    display: block;

    @media #{$phone} {
      display: none;
    }
  }

  .hero__scroll-to {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
  }
}

.scroll-to-section--hero {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 6px 24px -2px rgba(0, 0, 0, 0.3);
  display: block;
  margin: 0 auto;
  color: #000000;
  position: absolute;
  bottom: 30px;
  left: calc(50% - 10px);
  line-height: 1;

  @media #{$phone} {
    width: 40px;
    height: 40px;
    box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.3);
  }

  svg {
    color: $black;
    font-size: 16px;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
    height: 12px;
    display: block;

    @media #{$phone} {
      height: 8px;
    }
  }
}

.hero__video {
  width: 100%;
}

.filter-items__mobile-item.showAll {
  display: none;
}

.filter-items__mobile-item.show-filter {
  display: flex;
}

.filter-items {
  position: absolute;
  right: 50px;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 2;

  @media screen and (max-width: #{1200px}) {
    right: 10px;
  }

  @media #{$phone} {
    display: none;
  }

  &--selected {
    .filter-items__item {
      filter: gray;
      /* IE6-9 */
      -webkit-filter: grayscale(1);
      /* Google Chrome, Safari 6+ & Opera 15+ */
      filter: grayscale(1);
      /* Microsoft Edge and Firefox 35+ */
    }

    .filter-items__item--active {
      -webkit-filter: grayscale(0);
      filter: none;
    }
  }

  &.active {
    @media #{$phone} {
      top: 74px;
      width: 100%;
      right: 0;
    }

    .icon-close-alt {
      @media #{$phone} {
        display: none;
      }
    }
  }
}

.sticky-outer-wrapper {
  z-index: 997;
  position: relative;
}

.filter-items__item {
  border-radius: 150px;
  text-decoration: none;
  width: 66px;
  height: 66px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  margin-bottom: 10px;
  border: 3px solid transparent;
  position: relative;

  svg {
    display: none;
    position: absolute;
    top: -4px;
    right: -5px;
    width: 22px;
    height: 20px;
  }

  strong {
    width: 0;
    opacity: 0;
    visibility: hidden;
    display: inline-block;
    vertical-align: middle;
    font-family: $primary-family-medium;
    @include font-size(18);
    color: $black;
    white-space: nowrap;
  }

  img {
    transition: all 0.3s ease;
  }

  &:hover {
    width: auto;
    padding-left: 26px;
    padding-right: 20px;
    box-shadow: 0 7px 30px rgba(#717171, 0.1);

    strong {
      width: auto;
      opacity: 1;
      visibility: visible;
      padding-right: 10px;
    }

    img {
      transform: scale(0.7);
      min-width: 32px;
    }
  }
}

.filter-items__item--active {
  svg {
    display: block;
  }
}

.filter-items__icon {
  max-width: 34px;
  display: inline-block;
  vertical-align: middle;
}

.review-block {
  position: relative;
  overflow: hidden;
  z-index: 9;
}

.review-block__wrap-holder {
  max-width: 918px;
  padding: 77px 30px;
  width: 100%;
  z-index: 1;
  position: relative;
  margin: 0 auto;
  min-height: 100vh;

  @media #{$phone} {
    padding: 30px;
  }
}

.review-block__wrap {
  margin: 0 -15px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  .review-card--alt & {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.review-block__grid-sizer,
.review-card__item {
  width: 50%;
  margin-bottom: 30px;
  padding: 0 15px;

  @media #{$phone} {
    width: 100%;
  }
}

.review-card__item {
  display: none;
  position: relative;
  z-index: 0;

  &:hover,
  &:focus {
    z-index: 99;
  }

  .review-card--alt & {
    width: 100%;
  }
}

.review-card__wrap-column {
  width: 48%;
}

.review-block__item-inner {
  background-color: $white;
  padding: 30px 40px 10px;
  box-shadow: 0 22px 40px rgba(#858585, 0.05);
  border-radius: 15px;
  position: relative;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 22px 60px rgba(#858585, 0.15);
    transform: scale(1.05);

    .review-block__share {
      opacity: 1;
      visibility: visible;
      transition: all 0.3s ease;
    }

    .review-block__item-bg {
      opacity: 1;
      height: calc(100% + 53px);
      transition: all 0.3s ease;
    }

    .review-block__profile-image {
      transform: scale(1.15);
      transition: all ease 0.3s;
    }
  }

  @media #{$phone} {
    padding: 30px 23px 10px;
  }
}

.review-block__item-bg {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: $white;
  box-shadow: 0 22px 40px rgba(#858585, 0.05);
  border-radius: 15px;
  opacity: 0;
  z-index: 0;
  transition: ease all 0.3s;
}

.review-card__item.active {
  display: block;
}

.review-card__item--image {
  .review-block__share {
    bottom: -80px;
  }
}

.review-block__share {
  position: absolute;
  bottom: -60px;
  width: 100%;
  left: 0;
  padding: 0 40px 40px;
  opacity: 0;
  visibility: hidden;
  border-radius: 0 0 15px 15px;
  transition: all 0.3s ease;
  z-index: 2;
}

.review-block__header {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 22px;
  z-index: 1;
  position: relative;
}

.review-card__icon {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 10px;

  img {
    width: 34px;
    height: 34px;
  }
}

.review-block__profile-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
  transition: ease all 0.3s;
}

.filter-items__item--big-sneeze {
  background-color: #fbf5e8;
}

.filter-items__item--critter-cuddler {
  background-color: #fae6ea;
}

.filter-items__item--basic-breather {
  background-color: #f1f1f1;
}

.filter-items__item--rookie-parent {
  background-color: #f0eafb;
}

.filter-items__item--home-wrecker {
  background-color: #faefe3;
}

.filter-items__item--mama-to-be {
  background-color: #e5f9f7;
}

.filter-items__item--overreactor {
  background-color: #e3ebf9;
}

.filter-items__item--big-sneeze.filter-items__item--active {
  border-color: #ffc140;
}

.filter-items__item--critter-cuddler.filter-items__item--active {
  border-color: #f42c52;
}

.filter-items__item--basic-breather.filter-items__item--active {
  border-color: #3e4649;
}

.filter-items__item--rookie-parent.filter-items__item--active {
  border-color: #8852f6;
}

.filter-items__item--home-wrecker.filter-items__item--active {
  border-color: #f27e0f;
}

.filter-items__item--mama-to-be.filter-items__item--active {
  border-color: #1ce4d5;
}

.filter-items__item--overreactor.filter-items__item--active {
  border-color: #0a56ea;
}

.filter-items__item--big-sneeze.filter-items__item--active svg {
  color: #ffc140;
}

.filter-items__item--critter-cuddler.filter-items__item--active svg {
  color: #f42c52;
}

.filter-items__item--basic-breather.filter-items__item--active svg {
  color: #3e4649;
}

.filter-items__item--rookie-parent.filter-items__item--active svg {
  color: #8852f6;
}

.filter-items__item--home-wrecker.filter-items__item--active svg {
  color: #f27e0f;
}

.filter-items__item--mama-to-be.filter-items__item--active svg {
  color: #1ce4d5;
}

.filter-items__item--overreactor.filter-items__item--active svg {
  color: #0a56ea;
}

.review-block__title {
  font-family: $primary-family-semibold;
  @include font-size(20);
  margin-bottom: 0;
}

.review-block__subtitle {
  font-family: $primary-family-medium;
  @include font-size(16);
  color: #8e8e8e;
  margin-bottom: 0;
}

.review-block__content {
  @include font-size(22);
  word-break: break-word;
  margin-bottom: 40px;
  position: relative;
  z-index: 3;

  @media #{$phone} {
    padding: 0 17px;
    @include font-size(20);
    margin-bottom: 32px;
  }

  p img {
    display: inline-block;
    max-width: 22px;
    margin-top: -4px;
  }
}

.review-card__image {
  border-radius: 15px;
  overflow: hidden;
  margin: 42px -30px 0;
  z-index: 3;
  position: relative;

  @media #{$phone} {
    margin: 42px -12px 0;
  }
}

.client-quote {
  display: none;
  padding: 123px 0 82px;
  text-align: center;
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 720px auto;
  border-radius: 15px;
  margin: 30px 0 70px;
  width: 96vw;
  left: calc(50% - 48vw) !important;
  position: relative;

  @media #{$phone} {
    padding: 70px 0;
    background-size: 500px auto !important;
    margin-top: 0;
    margin-bottom: 30px;
  }

  .review-card--alt & {
    position: relative;
  }
}

.client-quote.active {
  display: block;
}

.client-quote__inner {
  max-width: 753px;
  margin: 0 auto;

  .btn {
    padding: 17px 55px;
  }
}

.client-quote--large {
  .client-quote__inner {
    max-width: 925px;
  }
}

.client-quote__content {
  font-family: $primary-family-bold;
  @include font-size(48);
  margin-bottom: 60px;
  quotes: "\201c""\201d";

  @media #{$tablet} {
    @include font-size(38);
    padding: 0 40px;
  }

  @media #{$phone} {
    @include font-size(28);
  }

  p {
    line-height: 1;
    margin-bottom: 25px;
  }

  p:before,
  p:after {
    font-family: $alt-family;
    @include font-size(88);
    color: $primary-color;

    @media #{$phone} {
      @include font-size(44);
    }
  }

  p:before {
    content: open-quote;
    left: -25px;
    position: relative;
    top: 11px;

    @media #{$phone} {
      left: -5px;
      top: 1px;
    }
  }

  p:after {
    content: close-quote;
    height: 0;
    display: inline-block;
    margin-top: -22px;
    line-height: 1;
    position: relative;
    top: 22px;
    right: -22px;

    @media #{$phone} {
      top: 7px;
      right: -12px;
    }
  }

  footer {
    font-family: $alt-family;
    line-height: 1.2;
  }
}

.client-quote.mama-to-be {
  background-color: #e5f9f7;

  p:before,
  p:after {
    color: #1ce4d5;
  }

  .btn {
    background-color: #1ce4d5;
    border-color: #1ce4d5;
  }
}

.client-quote.big-sneeze {
  background-color: #fbf5e8;

  p:before,
  p:after {
    color: #ffc140;
  }

  .btn {
    background-color: #ffc140;
    border-color: #ffc140;
  }
}

.client-quote.home-wrecker {
  background-color: #faefe3;

  p:before,
  p:after {
    color: #f27e0f;
  }

  .btn {
    background-color: #f27e0f;
    border-color: #f27e0f;
  }
}

.client-quote.overreactor {
  background-color: #e3ebf9;

  p:before,
  p:after {
    color: #0a56ea;
  }

  .btn {
    background-color: #0a56ea;
    border-color: #0a56ea;
  }
}

.client-quote.rookie-parent {
  background-color: #f0eafb;

  p:before,
  p:after {
    color: #8852f6;
  }

  .btn {
    background-color: #8852f6;
    border-color: #8852f6;
  }
}

.client-quote.basic-breather {
  background-color: #f1f1f1;

  p:before,
  p:after {
    color: #3e4649;
  }

  .btn {
    background-color: #3e4649;
    border-color: #3e4649;
  }
}

.client-quote.critter-cuddler {
  background-color: #fae6ea;

  p:before,
  p:after {
    color: #f42d53;
  }

  .btn {
    background-color: #f42d53;
    border-color: #f42d53;
  }
}

.filter-items__mobile-nav {
  z-index: 2;
  width: 100%;
  position: relative;
  transition-property: transform, background-color, -webkit-transform, opacity,
    visibility;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.09, 0.47, 0.18, 0.99);
  opacity: 1;

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 1;
    visibility: visible;
    padding-top: 0;
    width: 100%;
    z-index: 2;
  }
}

.filter-items__toggle {
  display: none;
  width: 100%;
  text-align: center;
  padding: 14px 30px;
  color: $black;
  text-decoration: none;
  border-radius: 0 0 15px 15px;
  box-shadow: 0 18px 40px rgba($black, 0.08);
  background-color: $white;
  height: 73px;
  position: relative;
  transition: ease all 0.3s;
  z-index: 2;

  @media #{$phone} {
    display: flex;
    align-items: center;
  }

  > svg {
    position: absolute;
    right: 35px;
    width: 10px;
    height: 10px;
    top: calc(50% - 5px);
    display: block;
  }

  &.selected {
    border-radius: 0 0 15px 15px;
    box-shadow: 0 18px 40px rgba($black, 0.08);
  }

  &.active {
    transition: ease all 0.3s;
    box-shadow: none;

    > svg {
      transform: rotate(180deg);
      transition: ease all 0.3s;
    }

    @media #{$phone} {
      border-radius: 0;
    }
  }

  &.active strong,
  &.selected strong {
    font-family: $primary-family-semibold;
    @include font-size(16);
  }
}

.filter-option__active-tab {
  font-family: $primary-family-semibold;
  @include font-size(15);
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 23px;
    height: 15px;
    margin-right: 14px;
  }
}

.filter-items__mobile {
  display: none;
  background-color: $white;
  border-radius: 0 0 15px 15px;
  box-shadow: 0 18px 40px rgba($black, 0.08);
  padding: 10px 30px 20px;
  border-top: 1px solid #ebebeb;
  transition: ease all 0.3s;

  &.active {
    position: absolute;
    top: 73px;
    width: 100%;
    z-index: 2;
    transition: ease all 0.3s;

    @media #{$phone} {
      display: block;
      transition: ease all 0.3s;
    }
  }
}

.filter-items__mobile-item {
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 8px 0;
  position: relative;
  transition: ease all 0.3s;
  z-index: 1;

  strong {
    font-family: $primary-family-medium;
    @include font-size(16);
    color: $black;
  }

  &.selected-item {
    display: none;
  }
}

.filter-items__mobile-icon {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 12px;
}
