.app-fatures {
  padding: 102px 0 62px;

  @media #{$desktop} {
    padding: 60px 0;
  }

  @media #{$phone} {
    padding: 0 0 50px;
  }

  .container {
    max-width: 1106px;
  }
}

.app-fatures__grid {
  display: flex;

  @media #{$phone} {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    align-items: center;
  }
}

.app-fatures__content {
  width: 47.8384%;
  padding: 36px 0 0;
  position: relative;
  z-index: 2;

  @media #{$tablet} {
    padding-top: 20px;
  }

  @media #{$phone} {
    width: 100%;
    margin-bottom: 10px;
  }
}

.app-features__media {
  width: 52.1616%;

  @media #{$phone} {
    width: 100%;
    margin-bottom: 10px;
  }

  video {
    width: 100%;
    position: relative;
    z-index: 1;

    @media #{$tablet} {
      width: calc(100% + 90px);
      left: -90px;
    }

    @media #{$phone} {
      max-width: 329px;
      width: 100%;
      left: -43px;
    }
  }
}

.app-features__title {
  font-size: clamp(1.75rem, 4vw, 3rem);
  line-height: calc(58/48);
  letter-spacing: -.5px;
  font-family: $primary-family-bold;
  margin-bottom: 32px;

  @media #{$tablet} {
    margin-bottom: 20px;
  }

  @media #{$phone} {
    max-width: 270px;
    margin: 0 auto 26px;
  }
}

.app-features__subtitle {
  letter-spacing: -.3px;
  @include font-size(28);
  line-height: calc(36/28);
  font-family: $primary-family-medium;
  margin-bottom: 45px;
  max-width: 404px;
  width: 100%;

  @media #{$tablet} {
    margin-bottom: 30px;
    @include font-size(24);
  }

  @media #{$phone} {
    color: $black;
    @include font-size(20);
    line-height: calc(27/20);
    font-family: $primary-family-bold;
    max-width: 246px;
    margin-bottom: 26px;
  }
}

.app-fatures__description {
  @include font-size(20);
  color: rgba($black, .8);
  line-height: calc(36/20);
  max-width: 404px;
  width: 100%;

  @media #{$phone} {
    color: $black;
    @include font-size(16);
    line-height: calc(25/16);
    letter-spacing: -.23px;
    margin: 0 auto;
  }

  &.for-phone {
    @media #{$phone} {
      max-width: 290px;
    }
  }
}

.for-phone {
  display: none !important;

  @media #{$phone} {
    display: block !important;
  }
}

.for-desktop {
  display: block;

  @media #{$phone} {
    display: none !important;
  }
}
