.product-stats {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding: 80px 0 128px;
  background-color: var(--tertiary-color);
  color: $white;

  @media #{$tablet} {
    padding: 64px 0;
  }

  &::after,
  &::before {
    content: '';
    position: absolute;
    border-radius: 100%;
    z-index: -1;
    background-color: var(--primary-color);
    opacity: .25;
    left: 50%;
    top: 0;
    filter: blur(350px);
  }

  &::before {
    width: 1681px;
    height: 464px;
    transform: translate(-50%, -50%);
  }

  &::after {
    width: 807px;
    height: 368px;
    bottom: 0;
    top: auto;
    transform: translate(-50%, 78%);
  }

  .product-stats__shape {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: -1;
    mix-blend-mode: hard-light;

    &::after,
    &::before {
      content: '';
      background-image: url('assets/images/products/ovel-rope-shape.png');
      height: 517px;
      width: 688px;
      background-repeat: no-repeat;
      background-position: center center;
      position: absolute;


    }

    &::before {
      transform: rotate(22.91deg);
      left: -165px;
      top: -60px;

      @media #{$phone} {
        transform: rotate(22.91deg) scale(.8);
        transform-origin: top center;
        left: -170px;
      }
    }

    &::after {
      right: -234px;
      bottom: -241px;
      transform: rotateX(180deg) rotateZ(135.65deg);
    }
  }
}

.product-stats__header {
  max-width: 853px;
  width: 100%;
  margin: 0 auto 64px;
  text-align: center;

  @media #{$phone} {
    margin-bottom: 48px;
  }
}

.product-stats__title {
  @include font-size(48);
  letter-spacing: -0.31px;
  font-family: $primary-family-bold;
  line-height: calc(58/48);
  margin-bottom: 80px;
  margin: 0 auto 48px;
  width: 100%;

  @media #{$small-screen} {
    @include font-size(36);
    margin-bottom: 28px;
  }

  @media #{$phone} {
    @include font-size(28);
  }
}

.product-stats__description {
  @include font-size(28);
  line-height: calc(30/28);
  font-family: $primary-family-medium;

  @media #{$small-screen} {
    @include font-size(24);
  }

  @media #{$tablet} {
    @include font-size(20);
    line-height: 1.5;
  }

  @media #{$phone} {
    @include font-size(16);
    font-family: $primary-family;
  }
}

.product-stats__image {
  max-width: 1070px;
  margin: 0 auto 64px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media #{$phone} {
    margin-bottom: 48px;
  }

  img {
    @media #{$phone} {
      max-width: 350px;
    }
  }
}

.product-stats__content {
  max-width: 814px;
  width: 100%;
  margin: 0 auto;
  letter-spacing: -0.3px;
  @include font-size(20);
  line-height: 1.5;

  @media #{$tablet} {
    @include font-size(18);
  }

  @media #{$phone} {
    @include font-size(16);
  }
}
