.update-features {
  padding: 74px 0 66px;

  &.update-features--alt {
    background-color: $primary-color;
    padding: 74px 0 46px;

    @media screen and (max-width:640px) {
      padding: 44px 0 20px;
    }
  }

  @media screen and (max-width:640px) {
    padding: 34px 0 20px;
  }

  .container {
    max-width: 1342px;
    padding: 0;
  }
}

.update-features__header {
  text-align: center;
  margin-bottom: 25px;
  padding: 0 20px;

  .update-features--alt & {
    color: $white;
  }

  @media screen and (max-width:640px) {
    margin-bottom: 0;
  }
}

.update-features__description {
  @media screen and (max-width:640px) {
    max-width: 310px;
    margin: 0 auto;
  }
}

.update-features__grid {
  display: flex;
  flex-wrap: wrap;
  padding: 36px 36px 60px;
  overflow: hidden;
  position: relative;
  z-index: 1;

  &::after,
  &:before {
    content: '';
    position: absolute;
    width: 49px;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: none;

    @media screen and (max-width:640px) {
      display: block;
    }

    .update-features--alt & {
      width: 122px;
    }

  }

  &::before {
    background: linear-gradient(90deg, #ffffff 18.86%, rgba(255, 255, 255, 0) 100%);
    left: -10px;

    .update-features--alt & {
      background: linear-gradient(90deg, #1CE4D5 -45%, rgba(28, 228, 213, 0) 100%);
      left: -41px;
    }
  }

  &::after {
    background: linear-gradient(270deg, #ffffff 18.86%, rgba(255, 255, 255, 0) 100%);
    right: -10px;

    .update-features--alt & {
      background: linear-gradient(270deg, #1CE4D5 -45%, rgba(28, 228, 213, 0) 100%);
      right: -41px;
    }
  }

  @media #{$tablet} {
    padding: 36px 18px 60px;
  }

  @media screen and (max-width:640px) {
    padding: 36px 0 60px;
  }

  .slick-list {
    overflow: visible !important;
    width: 100%;

    .slick-track {
      display: flex !important;
    }

    .slick-slide {
      opacity: 0;
      transition: opacity 300ms ease;
      height: auto !important;

      @media #{$phone} {
        opacity: 1;
      }

      &.slick-active {
        opacity: 1;
      }

      >div,
      .feature-card,
      .update-features__item {
        height: 100%;
      }
    }
  }
}

.update-features__item {
  padding: 0 18px;

  @media #{$phone} {
    padding: 0 5px;
  }
}

.update-features__title {
  font-size: clamp(1.75rem, 5vw, 3rem);
  letter-spacing: -0.5px;
  line-height: calc(58/48);
  margin-bottom: 12px;

  @media #{$phone} {
    margin-bottom: 0;
  }
}

.update-features__description {
  @include font-size(28);
  line-height: calc(35/28);

  @media #{$phone} {
    @include font-size(16);
    letter-spacing: -.23px;
  }
}


.feature-card {
  border-radius: 20px;
  background-color: $white;
  box-shadow: 0px 16px 64px rgba(0, 0, 0, 0.08);
  padding: 40px 20px 40px 40px;

  @media screen and (max-width:640px) {
    padding: 35px 14px 35px 40px;
  }
}

.feature-card__icon {
  max-width: 52px;
  margin-bottom: 11px;
}

.feature-card__title {
  @include font-size(26);
  line-height: calc(32/26);
  letter-spacing: -.52px;
  font-family: $primary-family-semibold;
  font-weight: normal;

  @media #{$phone} {
    @include font-size(23);
  }
}

.feature-card__detail {
  padding-right: 25px;

  .update-features--alt & {
    padding-right: 0;
  }

  @media #{$phone} {
    @include font-size(16);
  }
}
