body,
button,
input,
select,
textarea {
  color: $black;
  font-family: $primary-family;
  @include font-size(18);
  line-height: $font__line-height-body;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  font-family: $primary-family-bold;
  margin: 0 0 20px 0;
  line-height: 1.15;
}

h1 {
  @include font-size(72);
  @media #{$phone} {
    @include font-size(42);
  }
}

h2 {
  @include font-size(68);
  @media #{$large-screen} {
    @include font-size(48);
  }
  @media #{$phone} {
    @include font-size(38);
  }
}

h3 {
  @include font-size(38);
  @media #{$phone} {
    @include font-size(30);
  }
}

h4 {
  @include font-size(34);
  @media #{$phone} {
    @include font-size(26);
  }
}

h5 {
  @include font-size(32);
  @media #{$phone} {
    @include font-size(22);
  }
}

h5 {
  @include font-size(26);
  @media #{$phone} {
    @include font-size(20);
  }
}

strong{
  font-weight: normal;
  font-family: $primary-family-bold;
}

p {
  margin: 0 0 20px;
  &:last-child{
    margin-bottom: 0;
  }
}

dfn, cite, em, i {
  font-style: italic;
}

blockquote {
  margin: 0;
}

address {
  margin: 0;
}

pre {
  background: #f1f1f1;
  font-family: $font__pre;
  @include font-size(0.9375);
  line-height: $font__line-height-pre;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding:30px;
}

code, kbd, tt, var {
  font-family: $font__code;
  @include font-size(0.9375);
}

abbr, acronym {
  border-bottom: 1px dotted #666;
  cursor: help;
}

mark, ins {
  background: #fff9c0;
  text-decoration: none;
}

big {
  font-size: 125%;
}
:focus{
  outline: none !important;
}
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}