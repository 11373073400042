.peacekeeper-body {
  .btn--red {
    background-color: #FFC554;
    border-color: #FFC554;
  }

  .slick-dots li {
    &.slick-active {

      button {
        border-color: #FFC554;


        &:before {
          background-color: #FFC554;
        }
      }
    }

    button {

      &:hover,
      &:focus {
        border-color: #FFC554;
      }
    }
  }


  .filter-card__slider {
    .slick-dots li {
      color: currentColor;

      &.slick-active {
        button {
          border-color: currentColor;

          &:before {
            background-color: currentColor;
          }
        }
      }

      button {
        color: currentColor;

        &:hover,
        &:focus {
          border-color: currentColor;
        }
      }
    }
  }
}

#peacekeeper-modal,
#gottcha-modal {
  max-width: 1610px;
  margin-top: 50px;

  @media #{$phone} {
    margin-top: 0;
  }

  .filter-single__wrapper {
    @media #{$phone} {
      border-radius: 0;
    }
  }

  .filter-billboard {
    position: relative;
    z-index: 1;
    border-radius: 15px;
    padding: 50px 0 0 10.5%;
    margin-top: 0;

    @media #{$small-screen} {
      padding: 50px 32px 0 50px;
      border-radius: 0;
    }

    @media #{$phone} {
      padding: 75px 32px 0;
    }

    &::after {
      content: '';
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
      mix-blend-mode: overlay;
      left: 0;
      top: 34.5%;
      bottom: 0;
      right: 0;
      z-index: -1;
      position: absolute;
      border-radius: 15px;

      @media #{$phone} {
        border-radius: 0;
      }
    }
  }

  .filter-billboard__title {
    font-size: clamp(2.75rem, 6vw, 5.625rem);
    line-height: 1.08;
  }

  .filter-feature__slider {
    .slick-dots {
      width: 100%;

      li {
        button {

          &:focus,
          &:hover {
            border-color: #FFC554;
          }
        }

        &.slick-active {
          button {
            border-color: #FFC554;

            &:before {
              background-color: #FFC554;
            }
          }
        }
      }
    }
  }

  .filter-order__card-title {
    .for-desktop {
      @media #{$phone} {
        display: none;
      }
    }

    .for-phone {
      display: none;

      @media #{$phone} {
        display: inline-block;
      }
    }
  }

  .filter-billboard__title-into {
    position: relative;
    z-index: 1;

    &::after {
      content: '🕊️';
      display: inline-block;
      vertical-align: top;
      margin-left: .1em;
      width: 0;

      @media #{$phone} {
        display: none;
      }
    }
  }

  .filter-content {
    max-width: 1440px;
    margin: 0 auto;
  }

  .filter-billboard__content {
    width: 50.1%;

    @media #{$phone} {
      width: 100%;
    }

    >p {
      max-width: 600px;
    }

    .btn--outline {
      margin-left: -80px;

      @media #{$small-screen} {
        margin-left: -20px;
      }
    }
  }

  .filter-billboard--landscape-image {
    .filter-billboard__media {
      img {
        max-width: 750px;
        margin-bottom: -1px;
        left: calc(50% + 10px);
        width: 100%;
        object-fit: contain;
      }
    }
  }

  .filter-feature__slide-next,
  .filter-feature__slide-prev,
  .filter-detail__image-slider .slick-arrow {
    color: #FFC554;
  }

  .filter-feature {
    .icon-box {
      border-radius: 50%;
    }

    .icon-box--1 {
      top: 58.5%;
      left: 67.5%;
    }

    .icon-box--2 {
      top: 12.5%;
      left: 63.1%;
    }

    .icon-box--3 {
      top: 54.7%;
      left: 46.3%;
    }

    .icon-box--4 {
      top: 26.2%;
      left: 37.2%;
    }

    .icon-box--5 {
      top: 43.7%;
      left: 23.1%;
    }
  }

  .filter-detail {
    background-color: #FFF7E7;
  }

  .filter-detail__slider {
    .slick-list {
      &:after {
        background-image: linear-gradient(90deg, #FFF7E7 0%, rgba(255, 255, 255, 0) 50%);
      }

      &:before {
        background-image: linear-gradient(270deg, #FFF7E7 0%, rgba(255, 255, 255, 0) 50%);
      }
    }
  }

  .filter-order__button {
    &.filter-order__button--inside {
      display: none;

      @media #{$phone} {
        display: block;
      }
    }

    &.filter-order__button--outside {
      @media #{$phone} {
        display: none;
      }

      .btn {
        border-radius: 15px;
      }
    }
  }

  .filter-card {
    padding-left: 75px;
    padding-right: 180px;

    @media #{$tablet} {
      padding: 77px 40px 70px;
    }

    .slick-arrow {
      height: 60px;
      width: 60px;

      @media #{$small-screen} {
        height: 40px;
        width: 40px;
      }

      @media #{$tablet} {
        display: none;
      }
    }

    .filter-card__slide-prev,
    .filter-card__slide-next {
      left: calc(100% + 33px);
    }

    .filter-card__slide-prev {
      top: 31.8%;

      @media #{$small-screen} {
        top: calc(31.8% + 20px);
      }
    }

    .filter-card__slide-next {
      top: calc(50% + 4px);

      @media #{$small-screen} {
        top: calc(50% + 16px);
      }
    }
  }

  .filter-card__slider .slick-slide>div {
    padding-bottom: 0;

    @media #{$tablet} {
      padding-bottom: 20px;
    }
  }

  .filter-card__slider .slick-slide>div>div>a:before {
    border-color: currentColor;
  }

  .filter-card__slide-image {
    display: none;
  }

  .filter-card__slider .slick-slide>div>div>a {
    padding-bottom: 85px;
    box-shadow: none;

    @media #{$tablet} {
      padding-bottom: 40px;
    }

    @media #{$phone} {
      padding-bottom: 15px;
    }
  }
}

#gottcha-modal {
  .filter-billboard {
    padding: 50px 0 0 100px;

    @media #{$phone} {
      padding: 75px 36px 0;
      text-align: center;
    }
  }

  .filter-billboard--landscape-image {
    .filter-billboard__media {
      img {
        max-width: 580px;
      }
    }
  }

  .filter-billboard__title {
    &::after {
      display: none;
    }
  }

  .filter-billboard__sub-title {
    @include font-size(40);
    font-family: $primary-family-semibold;
    margin-bottom: 82px;

    br {
      display: none;

      @media #{$phone} {
        display: unset;
      }
    }

    &::after {
      content: '🥳';
      font-size: inherit;
      line-height: inherit;
      display: none;
      margin-left: .2em;

      @media #{$phone} {
        display: unset;
      }
    }

    @media #{$phone} {
      @include font-size(24);
      margin-bottom: 42px;
    }
  }


  .filter-billboard__content {
    padding-bottom: 100px;

    @media #{$phone} {
      padding-bottom: 40px;
    }

    >p {
      max-width: 600px;

      @media screen and (max-width:360px) {
        max-width: 238px;
      }
    }

    .btn--outline {
      margin-left: 0;

      @media #{$small-screen} {
        margin-left: 0;
      }
    }
  }

  .filter-card__title {
    @media #{$phone} {
      max-width: 260px;
    }
  }
}


.filter-shop {
  padding-left: 75px;
  padding-right: 180px;
  padding-top: 118px;
  position: relative;
  z-index: 8;

  @media #{$tablet} {
    padding: 77px 40px 70px;
  }

  @media #{$phone} {
    padding: 0 18px;
  }

  .filter-shop__content {
    max-width: 629px;
    width: 100%;

    @media #{$phone} {
      max-width: 100%;
      background: #FFFFFF;
      box-shadow: 0px 32px 44px rgba(0, 0, 0, 0.0538134);
      border-radius: 15px;
      padding: 33px 38px 46px;
    }
  }

  .filter-shop__leadtext {
    @include font-size(30);
    font-family: $primary-family-medium;
    letter-spacing: -.01em;
    line-height: 1.45;
    max-width: 388px;
    width: 100%;
    margin-bottom: 30px;

    @media #{$phone} {
      @include font-size(20);
    }
  }

  .filter-shop__heading {
    @include font-size(35);
    letter-spacing: -.3px;
    font-family: $primary-family-semibold;
    max-width: 518px;
    width: 100%;
    margin-bottom: 30px;

    strong {
      font-weight: normal;
      color: #FFC554;
      font-family: inherit;
    }

    @media #{$phone} {
      @include font-size(25);
      line-height: 1.257;
    }
  }

  .filter-shop__details {
    @include font-size(20);
    line-height: 1.7;
    margin-bottom: 54px;

    @media #{$phone} {
      margin-bottom: 63px;
    }

    p {
      &:not(:last-child) {
        margin-bottom: 34px;
      }
    }
  }

  .filter-shop__button {
    max-width: 415px;
    width: 100%;
    text-align: center;

    @media #{$phone} {
      max-width: 100%;
    }

    .btn {
      width: 100%;
      border-radius: 40px;
      text-align: center;
      letter-spacing: -.3px;
    }
  }

}
