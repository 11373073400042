.background-tab {
  position: relative;
  background-color: $white;
  z-index: 1;
  padding: 4px 16px 16px;

  @media #{$phone} {
    padding: 4px 8px 8px;
  }

  .background-tab__card {
    border-radius: 16px;
    min-height: 880px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    aspect-ratio: 16/7.5;
    width: 100%;

    @media #{$small-screen} {
      min-height: 780px;
    }

    @media #{$phone} {
      min-height: 632px;
    }

    &::before {
      content: "";
      position: absolute;
      inset: 0 0 calc(100% - 302px);
      background: linear-gradient(180deg,
          rgba(0, 0, 0, 1) 25%,
          rgba(0, 0, 0, 0) 100%);
      opacity: 0.3;
      z-index: 2;

      @media #{$phone} {
        height: 365px;
        background: linear-gradient(180deg,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 0) 100%);
      }
    }
  }

  .background-tab__image {
    position: absolute;
    inset: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 16px;

    @media #{$phone} {}
  }
}

.background-tab__wrapper {
  position: absolute;
  inset: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  padding: 140px 0 20px 0;

  @media #{$medium-screen} {
    padding-top: 80px;
  }

  @media #{$phone} {
    padding-bottom: 0;
  }
}

.background-tab__title {
  @include font-size(36);
  font-family: $primary-family-bold;
  line-height: 1.1;
  text-align: center;
  width: 100%;
  margin-bottom: 40px;
  letter-spacing: -0.3px;
  color: $white;

  @media #{$tablet} {
    @include font-size(24);
  }

  @media #{$phone} {
    @include font-size(20);
    letter-spacing: -0.3px;
    margin-bottom: 90px;
  }

  br {
    display: none;
  }
}

.background-tab__scroller-holder {
  overflow: hidden;
  width: 100%;
  text-align: center;
  margin-bottom: 40px;

  @media #{$small-screen} {
    margin-bottom: 10px;
  }

  @media #{$phone} {
    margin-bottom: 0;
  }
}

.background-tab__scroller {
  max-width: 100%;
  margin: 0 auto;
  display: inline-block;
}

.background-tab__list {
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  list-style: none;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 16px 60px;
  margin: 0 0 -60px;

  @media #{$phone} {
    gap: 8px;
    padding: 0 10px 60px;
    margin-bottom: -40px;
  }

  li {
    button {
      background: $white;
      padding: 24px 15px;
      border-radius: 14px;
      width: 157px;
      box-shadow: 0px 32px 44px 0px rgba(0, 0, 0, 0.0538);
      @include font-size(18);
      font-family: $primary-family-semibold;
      line-height: 1.22;
      flex-shrink: 0;
      display: block;
      text-align: left;
      transition: all 300ms ease;
      color: $black;

      @media #{$tablet} {
        @include font-size(16);
      }

      @media #{$phone} {
        width: 106px;
        padding: 16px 10px;
        @include font-size(13);
        line-height: calc(15/13);
      }

      &.button-white {
        span {
          &::before {
            border: 2px solid #e0e0e0;
          }
        }

        &.active {
          color: $black;

          span {
            &::before {
              border: 2px solid white;
            }
          }
        }
      }

      &.active {
        background-color: var(--color);
        color: $white;
      }

      span {
        display: block;
        position: relative;

        &::before {
          content: "";
          width: 26px;
          height: 26px;
          border-radius: 14px;
          margin-bottom: 8px;
          display: block;
          background-color: var(--color);

          @media #{$phone} {
            height: 19px;
            width: 19px;
            margin-bottom: 6px;
          }
        }
      }
    }
  }
}
