.about-block {
  margin: 40px 40px 0;
  background-color: #f7f8fa;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  z-index: 1;

  @media #{$large-screen} {
    margin: 20px 20px 0;
  }

  @media #{$tablet} {
    margin: 0;
  }
}

.about-block__inner {
  padding-top: 127px;
  border-radius: 15px;
  max-width: 1600px;
  margin: 0 auto;
  color: $black;
  background-color: #f7f8fa;
  position: relative;
  min-height: 1156px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media #{$large-screen} {
    padding-top: 107px;
    min-height: inherit;
  }

  @media #{$tablet} {
    padding-top: 80px;
    min-height: inherit;
    border-radius: 0;
  }

  @media #{$phone} {
    padding-top: 50px;
  }
}

.about-block__intro {
  max-width: 785px;
  margin: 0 auto 50px;
  padding: 0 20px;
  text-align: center;
  @include font-size(32);
  position: relative;
  z-index: 1;

  @media #{$large-screen} {
    @include font-size(20);
    margin-bottom: 30px;
    max-width: 450px;
    line-height: 1.6;
  }

  @media #{$phone} {
    margin-bottom: 20px;
    @include font-size(16);
    max-width: 330px;
  }
}

.about-block__title {
  margin-bottom: 30px;

  @media #{$phone} {
    @include font-size(28);
    margin-bottom: 20px;
  }
}

.about-block__image-carousel {
  position: absolute;
  top: 0;
  left: calc(50% - 125px);
  width: 100%;
  height: 100%;

  @media #{$phone} {
    position: relative;
    left: 0;
  }

  .slick-list,
  .slick-track {
    height: 100%;

    @media #{$phone} {
      height: auto;
    }
  }

  .slick-slide {
    display: flex;
    height: 100%;

    >div {
      height: 100%;
      display: flex;
    }
  }
}

.about-block__image-slide {
  min-height: 1156px;
  position: relative;

  @media #{$large-screen} {
    min-height: inherit;
  }

  @media #{$phone} {
    position: relative;
  }

  &:before {
    @media #{$phone} {
      content: "";
      display: block;
      height: 135px;
      width: 100%;
      position: absolute;
      bottom: -50px;
      left: 0;
      z-index: 1;
      background: linear-gradient(179.77deg,
          #f0f1f3 0.51%,
          rgba(255, 255, 255, 0) 0.52%,
          #f7f8fa 62.87%);
    }
  }

  img {
    position: absolute;
    bottom: 0;
    left: 0;

    @media #{$phone} {
      position: static;
      width: 100%;
    }
  }
}

.about-block__image {
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.7, 0, 0.3, 1);

  .slick-current & {
    opacity: 1;
  }
}

.about-block__image-slide1 img {
  max-width: 1310px;

  @media #{$large-screen} {
    max-width: 1110px;
  }

  @media #{$tablet} {
    max-width: 910px;
  }
}

.about-block__image-slide2 img {
  max-width: 1542px;

  @media #{$large-screen} {
    max-width: 1242px;
  }

  @media #{$tablet} {
    max-width: 942px;
  }
}

.about-block__image-slide3 img {
  max-width: 1153px;

  @media #{$large-screen} {
    max-width: 953px;
  }

  @media #{$tablet} {
    max-width: 753px;
  }
}

.about-block__image-slide4 img {
  max-width: 1600px;

  @media #{$large-screen} {
    max-width: 1300px;
  }

  @media #{$tablet} {
    max-width: 1000px;
  }
}
.about-block__carousel-wrap {
  @media #{$phone} {
    height: auto !important;
  }
}
.about-block__carousel {
  max-width: 650px;
  margin: 0 123px;
  position: relative;
  z-index: 2;

  @media #{$large-screen} {
    max-width: 510px;
  }

  @media screen and (max-width: 1300px) {
    margin: 0 33px;
  }

  @media #{$phone} {
    margin: 0;
    max-width: 100%;
    padding-top: 0;
    left: 24px;
    overflow: hidden;
  }

  .slick-list {
    height: auto !important;
    @media #{$phone} {
      width: calc(100% - 150px);
      overflow: visible !important;
    }
  }

  .slick-track {
    @media #{$phone} {
      display: flex;
    }

    .slick-slide {
      margin: 0 30px !important;

      @media screen and (min-width: 768px) {
        width: auto !important;
      }

      @media #{$phone} {
        margin: 0 8px !important;
        height: inherit !important;
        display: flex;
      }

      >div {
        display: flex;
      }
    }
  }

  .slick-dots {
    position: absolute;
    top: 94px;
    transform: translateY(-50%);
    left: -60px;
    display: block !important;

    @media screen and (max-width: 1300px) {
      left: -10px;
    }

    @media #{$phone} {
      display: none !important;
    }

    li {
      margin: 18px 0;
      height: auto;

      button {
        border-color: #d3d3d3;
        overflow: hidden;
      }

      &.slick-active button {
        height: 32px;
        border-color: $primary-color;
        width: 16px;

        &::before {
          background-color: $primary-color;
          animation: move-top-to-bottom 7.2s linear infinite;
        }
      }

      &:hover button {
        border-color: $primary-color;
      }
    }
  }
}

.about-block__slide {
  border: 3px solid #979797;
  padding: 35px 60px 50px !important;
  max-width: 520px;
  border-radius: 15px;
  transition: all 0.3s ease;
  position: relative;
  cursor: pointer;
  height: 365px;
  margin: 12px 0 !important;

  @media screen and (max-width: 1300px) {
    padding: 15px 30px 45px;
    max-width: 400px;
  }

  @media #{$tablet} {
    height: 275px;
  }

  @media #{$phone} {
    padding: 20px 28px 25px !important;
    border: 2px solid #979797;
    margin: 50px 0 65px !important;
    height: unset;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: -3px;
    z-index: 1;
    width: calc(100% + 6px);
    height: calc(100% + 3px);
    background: -moz-linear-gradient(top,
        rgba(245, 246, 246, 0) 0%,
        rgba(245, 246, 246, 0.92) 40%,
        rgba(245, 246, 246, 1) 70%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top,
        rgba(245, 246, 246, 0) 0%,
        rgba(245, 246, 246, 0.92) 40%,
        rgba(245, 246, 246, 1) 70%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,
        rgba(245, 246, 246, 0) 0%,
        rgba(245, 246, 246, 0.92) 40%,
        rgba(245, 246, 246, 1) 70%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00f5f6f6', endColorstr='#f5f6f6', GradientType=0);

    @media #{$phone} {
      display: none;
    }
  }

  .slick-current & {
    border-color: $white;
    background-color: $white;
    box-shadow: 0 32px 44px rgba(0, 0, 0, 0.05);

    &:before {
      display: none;
    }

    h3 {
      color: $black;

      @media #{$phone} {
        @include font-size(18);
      }
    }

    .about-block__slide-number {
      color: $primary-color;

      @media #{$phone} {
        @include font-size(20);
        font-family: $primary-family-medium;
      }
    }

    p {
      color: $black;
      @include font-size(18);

      @media #{$tablet} {
        line-height: 1.5;
        @include font-size(16);
      }

      @media #{$phone} {
        color: #8d8d8d;
        @include font-size(14);
      }
    }
  }
}

.about-block__slide-title {
  font-family: $primary-family-semibold;
  @include font-size(26);
  color: #979797;
  line-height: 1.4;

  @media #{$large-screen} {
    @include font-size(22);
  }

  @media screen and (max-width: 1300px) {
    margin-bottom: 10px;
    line-height: 1.1;
  }

  @media #{$phone} {
    @include font-size(18);
    margin-bottom: 25px;
    line-height: 1.3;
  }
}

.about-block__slide-number {
  font-family: $primary-family-semibold;
  @include font-size(36);
  color: #8c8c8c;
  display: block;
  margin-bottom: 12px;

  @media screen and (max-width: 1300px) {
    margin-bottom: 0;
  }

  @media #{$phone} {
    @include font-size(20);
    font-family: $primary-family-medium;
    margin-bottom: 6px;
  }
}

.about-block__slide-description {
  color: #979797;
  @include font-size(22);
  line-height: 1.6;

  @media #{$large-screen} {
    @include font-size(18);
  }

  @media #{$phone} {
    line-height: 1.5;
    color: #8d8d8d;
    @include font-size(14);
  }

  img {
    max-width: 100%;
    margin-top: -4px;
    display: inline-block;

    @media #{$phone} {
      max-width: 14px;
    }
  }
}
