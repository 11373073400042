.general-content--accessibility {
  h2 {
    @include font-size(30);
    font-family: $primary-family-semibold;

    &:not(:first-child) {
      margin-top: 36px;
    }

    @media #{$tablet} {
      @include font-size(24);
    }
    @media #{$phone} {
      @include font-size(20);
    }
  }
  .general-content__description {
    @include font-size(18);
    line-height: 1.5;
    max-width: 100%;
    text-wrap: pretty;

    @media #{$tablet} {
      @include font-size(16);
    }
  }
}
