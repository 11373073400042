.support-card {
  padding: 72px 0 130px;

  @media #{$small-screen} {
    padding: 60px 0;
  }

  @media #{$phone} {
    padding: 40px 0;
  }

  .container {
    @media #{$phone} {
      padding: 0;
    }
  }
}

.support-card__card {
  background-color: #f8f8f8;
  border-radius: 35px;
  max-width: 1186px;
  padding: 77px 106px 130px;
  @media #{$small-screen} {
    padding: 77px 106px;
  }

  @media #{$tablet} {
    padding: 60px;
  }

  @media #{$phone} {
    padding: 40px 20px;
    border-radius: 0;
  }
}
.support-card__leadtext {
  font-family: $primary-family-medium;
  font-weight: normal;
  margin-bottom: 24px;
  display: block;

  @media #{$phone} {
    margin-bottom: 16px;
  }
}

.support-card__title {
  @include font-size(60);
  margin-bottom: 16px;

  @media #{$small-screen} {
    @include font-size(48);
  }

  @media #{$tablet} {
    @include font-size(36);
  }

  @media #{$phone} {
    @include font-size(32);
  }
}

.support-card__description {
  @include font-size(24);
  color: rgba($black, 0.8);
  line-height: 1.6;
  max-width: 810px;
  margin-bottom: 49px;

  @media #{$large-screen} {
    @include font-size(20);
  }

  @media #{$tablet} {
    @include font-size(16);
    margin-bottom: 20px;
  }
}

.support-card__email {
  @include font-size(24);
  font-family: $primary-family-semibold;
  @media #{$phone} {
    @include font-size(20);
  }
  a {
    font-family: $primary-family;
    @include font-size(20);
    color: $brand-color--tertiary;
    background-image: linear-gradient(90deg, currentColor, currentColor);
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: 100% 0.07em;
    text-decoration: none;
    transition: all 300ms ease;

    @media #{$phone} {
      @include font-size(18);
    }
    &:hover,
    &:focus {
      color: $brand-color--secondary;
    }
  }
}

.faq-screenshot-img {
  img {
    max-width: 320px;
  }
}

.faq-grid {
  display: flex;
  flex-wrap: wrap;
}

.faq-grid__content {
  margin-bottom: 24px;
}

.faq-grid__media {
  width: 100%;
}

.faq-dimention-img {
  max-width: 640px;
}
