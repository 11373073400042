.video-content {
  padding: 100px 20px 108px;

  @media #{$desktop} {
    padding: 80px 16px;
  }

  .container {
    max-width: 100%;
    padding: 0;
  }



  .slick-dots {
    justify-content: center;
    gap: 10px;
    position: absolute;
    bottom: 45px;
    right: 60px;
    z-index: 1;

    @media #{$desktop} {
      right: 40px;
    }

    @media #{$tablet} {
      bottom: 30px;
      right: 30px;
    }

    @media #{$phone} {
      right: 18px;
      bottom: 18px;
      gap: 8px;
    }


    li {
      margin: 0;
      height: auto;
      border-radius: none;

      &.slick-active {
        button {
          outline-color: $white;
          width: 24px;

          @media #{$phone} {
            width: 16px;
          }

          &:hover,
          &:focus {
            outline: 2px solid $white !important;

            @media #{$phone} {
              outline: 1px solid $white !important;
            }
          }

          &::after {
            transform: translateX(var(--progress));
          }
        }
      }

      button {
        height: 6px;
        width: 6px;
        outline: 2px solid #d3d3d3;
        will-change: width;
        border: none;
        overflow: hidden;

        @media #{$phone} {
          height: 4px;
          width: 4px;
        }

        &:hover,
        &:focus {
          border-color: none;
          outline: 2px solid #d3d3d3 !important;

          @media #{$phone} {
            outline: 1px solid $white !important;
          }

          &::after {
            background-color: $white;
          }
        }

        &::before {
          display: none;
        }

        &::after {
          content: "";
          height: 6px;
          border-radius: inherit;
          left: -1px;
          top: 0;
          background-color: $white;
          position: absolute;
          z-index: 1;
          width: 28px;
          transform: translateX(-100%);

          @media #{$phone} {
            width: 18px;
            height: 4px;
          }
        }
      }
    }
  }
}

.video-content__header {
  max-width: 922px;
  margin: 0 auto 56px;
  width: 100%;
  text-align: center;

  @media #{$large-screen} {
    margin-bottom: 54px;
  }

  @media #{$phone} {
    margin-bottom: 28px;
  }
}

.video-content__title {
  letter-spacing: -0.3px;
  @include font-size(48);
  line-height: 1;
  max-width: 565px;
  margin: 0 auto 34px;

  @media #{$tablet} {
    @include font-size(24);
    margin-bottom: 24px;
    max-width: 361px;
  }

}

.video-content__short-intro {
  line-height: calc(38 / 28);
  @include font-size(28);
  letter-spacing: -0.3px;
  max-width: 922px;
  margin: 0 auto;
  font-family: $primary-family-medium;

  @media #{$large-screen} {
    line-height: 1.3;
  }

  @media #{$tablet} {
    @include font-size(20);
  }

  @media #{$phone} {
    max-width: 318px;
    @include font-size(16);
    font-family: $primary-family;
  }
}

.video-content__slider {
  width: 100%;
  margin: 0 auto;
  border-radius: 16px;
}

.video-content__slide {
  position: relative;
  z-index: 1;
}

.video-content__media {
  position: relative;
  z-index: 1;

  &::after {
    content: "";
    border-radius: 16px;
    background: linear-gradient(180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 100%);
    top: 63.4%;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    position: absolute;
  }
}

.video-content__video {
  width: 100%;
  aspect-ratio: 1340/830;
  object-fit: cover;
  object-position: center;
  border-radius: 16px;
  position: relative;
  z-index: 1;

  @media screen and (min-width: 1441px) {
    aspect-ratio: 1340 / 680;
  }

  @media screen and (min-width: 1920px) {
    aspect-ratio: 1340 / 602;
  }

  @media screen and (max-width: 480px) {
    aspect-ratio: 358/370;
    border-radius: 16px;
  }
}

.video-content__info {
  position: absolute;
  bottom: 38px;
  left: 45px;
  right: 45px;
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 5px;

  @media #{$tablet} {
    left: 25px;
    right: 25px;
    bottom: 25px;
  }

  @media #{$phone} {
    left: 16px;
    right: 17px;
    bottom: 16px;
  }

  @media screen and (max-width: 480px) {
    left: 16px;
    right: 16px;
  }

  br {
    @media screen and (min-width: 769px) {
      display: none;
    }
  }
}

.video-content__text {
  letter-spacing: -0.3px;
  font-family: $primary-family;
  color: $white;

  @media #{$phone} {
    max-width: 240px;
  }

  p {
    @include font-size(26);
    line-height: 1.2;
    margin-bottom: 4px;
    font-family: $primary-family-medium;

    @media #{$tablet} {
      @include font-size(24);
    }

    @media #{$phone} {
      @include font-size(18);
      margin-bottom: 8px;
      font-family: $primary-family-bold;
    }

    @media screen and (max-width: 480px) {
      @include font-size(16);
    }
  }

  em {
    @include font-size(18);
    line-height: 1.3;
    display: block;

    @media #{$tablet} {
      @include font-size(16);
    }

    @media screen and (max-width: 480px) {
      @include font-size(14);
    }
  }
}

.video-content__progress {
  height: 82px;
  width: 82px;
  border-radius: 50%;
  display: none;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  flex: 0 0 auto;

  @media #{$phone} {
    height: 72px;
    width: 72px;
  }

  @media screen and (max-width: 480px) {
    height: 54px;
    width: 54px;
  }

  &::after {
    content: "";
    position: absolute;
    background-color: $white;
    inset: 4px;
    z-index: -1;
    display: block;
    border-radius: inherit;
    background-image: url("data:image/svg+xml,%3Csvg width='44' height='35' viewBox='0 0 44 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.9765 3.11572C3.9765 2.49716 4.47794 1.99572 5.0965 1.99572H38.4515C39.0701 1.99572 39.5715 2.49717 39.5715 3.11572V15.9377C39.5715 16.5562 39.0701 17.0577 38.4515 17.0577H5.0965C4.47794 17.0577 3.9765 16.5562 3.9765 15.9377V3.11572Z' stroke='black' stroke-width='3.76'/%3E%3Cpath d='M10.8169 13.8042C10.8169 12.0074 12.2734 10.5509 14.0702 10.5509H29.4699C31.2667 10.5509 32.7232 12.0074 32.7232 13.8042C32.7232 15.6009 31.2667 17.0574 29.4699 17.0574H14.0702C12.2734 17.0574 10.8169 15.6009 10.8169 13.8042Z' stroke='black' stroke-width='3.76'/%3E%3Cpath d='M2.26287 22.36C2.26287 19.6182 4.48549 17.3956 7.22722 17.3956H36.3157C39.0575 17.3956 41.2801 19.6182 41.2801 22.36C41.2801 25.1017 39.0575 27.3243 36.3157 27.3243H7.22722C4.48549 27.3243 2.26287 25.1017 2.26287 22.36Z' stroke='black' stroke-width='3.76'/%3E%3Cline x1='5.35035' y1='32.4574' x2='5.35035' y2='29.3731' stroke='black' stroke-width='3.76' stroke-linecap='round' stroke-linejoin='bevel'/%3E%3Cline x1='37.8599' y1='32.4574' x2='37.8599' y2='29.3731' stroke='black' stroke-width='3.76' stroke-linecap='round' stroke-linejoin='bevel'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 42px;

    @media #{$phone} {
      background-size: 36px;
    }

    @media screen and (max-width: 480px) {
      background-size: 24px;
      inset: 2px;
    }
  }

  svg {
    height: 100%;
    width: 100%;
  }
}

.video-content__footer-bottom {
  &.footer-content {
    max-width: 692px;

    @media #{$phone} {
      max-width: 327px;
    }

    p {
      letter-spacing: -0.391px;
      margin-bottom: 0;

      @media #{$phone} {
        letter-spacing: -0.4px;
      }
    }
  }
}
