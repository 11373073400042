.mila-showcase {
  padding: 40px 20px;

  @media #{$phone} {
    padding: 48px 8px 8px;
  }
}

.mila-showcase__content {
  position: absolute;
  top: 108px;
  z-index: 3;
  left: 0;
  right: 0;

  @media #{$desktop} {
    top: 60px;
  }

  @media #{$tablet} {
    top: 40px;
  }

  @media #{$phone} {
    bottom: 12px;
    display: flex;
    padding: 0 12px;
  }
}

.mila-showcase__header {
  max-width: 878px;
  width: 100%;
  margin: 0 auto;

  @media #{$desktop} {
    max-width: 578px;
  }

  @media #{$tablet} {
    max-width: 428px;
  }

  @media #{$phone} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.mila-showcase__title {
  margin: 0 auto;
  max-width: 718px;
  width: 100%;
  @include font-size(48);
  color: $white;
  text-align: center;
  margin-bottom: 40px;

  @media #{$desktop} {
    @include font-size(36);
    margin-bottom: 30px;
  }

  @media #{$tablet} {
    margin-bottom: 16px;
    @include font-size(24);
  }

  @media #{$phone} {
    // color: $black;
    max-width: 276px;
  }

  br {
    @media #{$phone} {
      display: none;
    }
  }
}

.mila-showcase__card {
  position: relative;
  z-index: 1;

  @media #{$phone} {
    max-width: 480px;
    margin: 0 auto;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.29735644257703087) 0%, rgba(0, 0, 0, 0) 100%);
    border-radius: 16px;
  }

}

.mila-showcase__slide {
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
  aspect-ratio: 1400/636;
  position: relative;
  z-index: 1;
  display: flex !important;

  @media #{$medium-screen} {
    aspect-ratio: 1400/860;
  }

  @media #{$tablet} {
    aspect-ratio: 1400/960;
  }

  @media #{$phone} {
    aspect-ratio: 375/605;
  }

  // &.last-slide {
  //   img {
  //     @media #{$phone} {
  //       object-position: 80% bottom;
  //     }
  //   }
  // }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    pointer-events: none;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, .3) 0%, rgba(255, 255, 255, 0) 100%);
  }

  img {
    border-radius: inherit;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center bottom;
  }
}

.mila-showcase__img {
  width: 100%;
}

.mila-showcase__header {
  text-align: center;
}

.mila-showcase__list-wrapper {
  position: relative;
  z-index: 1;
  border-radius: 100px;
  padding: 4px;
  display: inline-block;

  @media #{$phone} {
    overflow: hidden;
    width: 100%;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background-color: rgba($white, .3);
    border: 1px solid rgba($white, .2);
    border-radius: inherit;
    backdrop-filter: blur(8px);

    @media #{$phone} {
      background-color: rgba($black, .1);
      border: 1px solid rgba($black, .05);
    }
  }

  @media #{$phone} {
    width: 100%;
  }
}

.mila-showcase__list-overflow {
  @media #{$phone} {
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0 20px 50px;
    margin-bottom: -50px;
    margin: 0 -20px -50px;
  }
}

.mila-showcase__list {
  list-style-type: none;
  display: inline-flex;
  justify-content: center;
  padding: 0;

  @media #{$phone} {
    display: flex;
  }

  li {
    min-width: 200px;
    padding: 16px 36px;
    text-align: center;
    border-radius: 40px;
    color: $white;
    cursor: pointer;
    min-height: 67px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: all 300ms ease;

    @media #{$desktop} {
      min-height: 62px;
    }

    @media #{$tablet} {
      min-width: 140px;
      white-space: nowrap;
      padding: 10px 20px;
      min-height: 50px;
    }

    @media #{$phone} {
      // color: $black;
      min-width: auto;
      padding: 10px 16px;
      flex: 1;
    }

    &.active {
      background-color: $white;
      color: $black;


      .mila-showcase__progressbar {
        max-height: 100%;
        opacity: 1;
      }

      strong {
        padding-bottom: 12px;

        @media #{$desktop} {
          padding-bottom: 8px;
        }

        @media #{$tablet} {
          padding-bottom: 6px;
        }
      }
    }

    strong {
      @include font-size(18);
      font-family: $primary-family-semibold;
      text-transform: capitalize;
      display: block;
      padding-bottom: 0;
      line-height: 1;
      transition: padding-bottom .3s ease;

      @media #{$desktop} {
        @include font-size(16);
      }

      @media #{$tablet} {
        @include font-size(14);
      }
    }
  }
}

.mila-showcase__progressbar {
  width: 100%;
  border-radius: 20px;
  background-color: #EEEEEE;
  display: block;
  overflow: hidden;
  height: 5px;
  max-height: 0;
  opacity: 0;
  transition: max-height 0.3s ease, opacity 0.2s ease;

  @media #{$tablet} {
    height: 4px;
  }

  span {
    background-color: #1CE4D5;
    display: block;
    height: 5px;
    width: var(--progress);
    border-radius: inherit;

    @media #{$tablet} {
      height: 4px;
    }
  }
}
