.product-features {
  padding: 80px 0;

  @media #{$phone} {
    padding: 40px 0;
  }
}

.product-features__grid {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;

}

.product-features__content,
.product-features__media {
  width: calc(50% - 15px);

  @media #{$tablet} {
    width: 100%;
  }
}


.product-features__title {
  @include font-size(48);
  letter-spacing: -0.31px;
  font-family: $primary-family-bold;
  line-height: calc(58/48);
  margin-bottom: 80px;

  @media #{$small-screen} {
    margin-bottom: 60px;
  }

  @media #{$small-screen} {
    @include font-size(36);
    margin-bottom: 40px;
  }

  @media #{$phone} {
    @include font-size(28);
    margin-bottom: 32px;
  }
}

.product-features__description {
  @include font-size(20);
  line-height: calc(34/20);
}

.product-features__description--highlited {
  font-family: $primary-family-medium;
  @include font-size(28);
  line-height: calc(34/28);
  letter-spacing: -0.3px;
  margin-bottom: 24px;

  @media #{$small-screen} {
    @include font-size(24);
  }

  @media #{$phone} {
    @include font-size(20);
  }
}


.featured-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 96px;
}

.featured-list--default {
  @media #{$tablet} {
    display: none;
  }
}

.featured-list--slider {
  display: none;

  @media #{$tablet} {
    display: block;
    margin: 0 -10px;
  }

  .slick-track {
    display: flex;

    .slick-slide {
      height: auto;
      display: flex;

      >div {
        height: 100%;
      }
    }
  }

  .slick-list {
    overflow: visible !important;
  }
}

.featured-list__item {
  width: 25%;
  display: flex;

  &:nth-child(2),
  &:nth-child(3) {
    justify-content: center;
  }

  &:nth-child(4) {
    justify-content: flex-end;
  }

  @media #{$tablet} {
    padding: 0 8px;
    width: 100%;
  }
}

.features-item {
  @media #{$tablet} {
    height: 100%;
    display: flex;
  }
}

.features-item__card {
  max-width: 226px;
  width: 100%;

  @media #{$tablet} {
    max-width: 259px;
    padding: 32px;
    background-color: $white;
    box-shadow: 0 14px 84px rgba(0, 0, 0, .08);
    border-radius: 15px;
  }
}

.features-item__icon {
  margin-bottom: 16px;
}

.features-item__title {
  @include font-size(20);
  line-height: 1;
  letter-spacing: -0.3px;
  font-family: $primary-family-semibold;
  margin-bottom: 12px;

  sup {
    font-size: 70%;
    top: -.65em;
  }
}

.features-item__description {
  @include font-size(18);
  letter-spacing: -0.3px;
  line-height: calc(27/18);

  @media #{$tablet} {
    @include font-size(16);
  }
}

.product-features__image {
  @media #{$tablet} {
    margin: -30px 0;
  }
}
