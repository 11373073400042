button,
input[type="submit"],
button[type="submit"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  background: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.btn,
input[type="submit"],
button[type="submit"] {
  background-color: $primary-color;
  border: 1px solid $primary-color;
  color: $white;
  display: inline-block;
  transition: all .2s ease-in-out;
  @include font-size(20);
  padding: 27px 47px;
  line-height: 1.2;
  text-decoration: none;
  font-family: $primary-family-semibold;
  border-radius: 57px;
  max-width: 346px;
  text-align: left;
  position: relative;

  &:hover,
  &:focus {
    transform: scale(1.05);
  }
}

.btn--fullwidth {
  width: 100%;
  text-align: center;
  max-width: 100%;
}

.btn--large {
  padding: 27px 100px;

  @media #{$phone} {
    padding: 19px 50px;
    @include font-size(16);
  }

  @media #{$phone-portrait} {
    width: 100%;
    text-align: center;
    padding: 15px 30px;
  }
}

.btn--small {
  padding: 12px 47px;
  @include font-size(16);
}

.btn--white {
  background-color: $white;
  border: 1px solid $white;
  @include font-size(18);
  color: $black;
  padding: 28px 47px 28px 35px;

  @media #{$phone} {
    padding: 23px 35px 23px 26px;
  }

  svg {
    width: 18px;
    height: 24px;
    color: $primary-color;
    vertical-align: middle;
    margin-right: 20px;
  }
}

.btn--order {
  border-radius: 15px;
  padding: 29px 47px;

  @media #{$phone} {
    padding: 18px 47px;
  }
}

.btn--outline {
  @include font-size(20);
  background-color: $white;
  border: 2px solid $primary-color;
  color: $primary-color;
  padding: 21px 90px;
  font-family: $primary-family-semibold;

  @media #{$phone} {
    padding: 19px 55px;
    @include font-size(16);
  }

  &:hover,
  &:active,
  &:focus {
    opacity: 0.8;
  }
}

.btn--icon {
  @include font-size(20);
  border: 2px solid $primary-color;
  padding: 22px 30px 18px;

  .icon-chat {
    width: 31px;
    height: 28px;
    color: $white;
    margin-right: 10px;

    @media #{$phone} {
      width: 24px;
      height: 22px;
    }
  }
}

.btn--border {
  background-color: $white;
  border: 3px solid $primary-color;
  padding: 20px 30px;
  color: $primary-color;

  .icon-envelope {
    width: 31px;
    height: 24px;
    color: $primary-color;
    margin-right: 10px;

    @media #{$phone} {
      width: 24px;
      height: 18px;
    }
  }
}

.btn--share {
  background-color: $white;
  border: none;
  @include font-size(16);
  font-family: $primary-family-semibold;
  color: $black;
  padding: 15px 25px;
  box-shadow: 0 18px 40px rgba($black, 0.08);

  &:hover,
  &:active,
  &:focus {
    background-color: #F42D53;
    color: $white;
    transform: scale(1);
  }

  svg {
    width: 19px;
    height: 17px;
    vertical-align: middle;
    margin-right: 10px;
  }
}

.btn--black {
  background-color: $black;
  border-color: $black;

  &:hover {
    background-color: $primary-color;
    border-color: $primary-color;
  }
}

.btn--checkout {
  box-shadow: 0 15px 44px rgba($primary-color, 0.3);
  border-radius: 15px;
  padding: 22px 87px;

  @media #{$phone} {
    padding: 16px 40px;
    @include font-size(16);
  }
}

.btn--red {
  background-color: #F42D53;
  border: 2px solid #F42D53;
}
.btn--yellow {
  background-color: $brand-color--primary;
  border: 2px solid $brand-color--primary;
}
.btn--medium {
  padding: 14px 26px 13px;
  letter-spacing: -0.3px;
  min-width: 210px;
  text-align: center;
  @include font-size(24);

  @media screen and (max-width: 640px) {
    padding: 9px 24px 8px;
    min-width: auto;
    @include font-size(13);
  }
}

.btn--shadow {
  box-shadow: 0 18px 40px rgba($black, .08);

  @media screen and (max-width: 640px) {
    box-shadow: 0 4px 4px rgba($black, .15);
  }
}
