.page-header {
  padding: 156px 0 140px;
  @media #{$small-screen} {
    padding: 80px 0;
  }

  @media #{$phone} {
    padding: 40px 0;
  }
  + .support-block {
    margin-top: 0;
    padding-top: 0;

    .container {
      max-width: 1365px;
      border-radius: 0;
      box-shadow: none;
      padding: 0 20px;
      @media #{$tablet} {
        padding: 0;
      }
    }
    .support-faq__inner {
      max-width: 1130px;
      box-shadow: 0 10px 44px rgba(0, 0, 0, 0.05);
      border-radius: 15px;
      padding: 81px 0;

      @media #{$tablet} {
        padding: 40px 0 50px;
      }
    }
  }
}
