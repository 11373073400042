.footer {
  background: #262626;
  border-radius: 15px 15px 0 0;
  padding: 85px 0 67px;
  color: $white;
  @media #{$tablet} {
    padding: 55px 0 40px;
  }
  @media #{$phone} {
    padding-top: 60px;
  }

  .container {
    max-width: 1330px;
    padding-left: 30px;
    @media #{$desktop} {
      padding-left: 20px;
    }
  }

  .social-link {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0 -11px 17px;
    padding-left: 0;
    li {
      padding: 0 11px;
      margin-bottom: 22px;

      a {
        color: $white;
        display: inline-block;
        vertical-align: top;
        transition: all 300ms ease;

        &:hover, &:focus {
          color: $primary-color;
        }

        svg {
          height: 29px;
          width: 29px;
          vertical-align: top;
        }
      }
    }
  }
}

.footer--alt {
  border-radius: 0 15px 0 0;
  position: relative;
  @media #{$desktop} {
    border-radius: 0;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 160px;
    background-color: $primary-color;
    @media #{$large-screen} {
      width: 100px;
    }
    @media #{$desktop} {
      display: none;
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 140px;
    height: 100%;
    width: 20px;
    background-color: #262626;
    border-radius: 0 15px 0 0;
    @media #{$large-screen} {
      right: 80px;
    }
    @media #{$desktop} {
      display: none;
    }
  }
}

.footer--alt-white {
  &:before {
    background-color: $white;
  }

  &:after {
    background-color: #262626;
  }
}

.footer__primary {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 42px;
  @media #{$phone} {
    padding-bottom: 25px;
  }
}

.footer__primary-inner {
  width: calc(60% - 20px);
  padding-right: 20px;
  border-right: 2px solid rgba($white, 0.2);
  @media #{$tablet} {
    padding-right: 0;
    max-width: 500px;
    margin: 0 auto 40px;
    width: 100%;
    border-right: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  @media #{$phone} {
    padding-right: 0;
    max-width: 400px;
    margin: 0 auto;
  }

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.subscribe-block {
  width: 34.5%;
  padding-left: 110px;
  padding-bottom: 8px;
  @media #{$tablet} {
    padding-left: 20px;
    margin: 0 auto;
    width: 100%;
    max-width: 500px;
    border-top: 2px solid rgba($white, 0.3);
    padding-top: 35px;
    margin-top: 15px;
  }
}

.footer__logo {
  max-width: 150px;
  float: left;
  @media #{$tablet} {
    max-width: 100%;
    width: 50%;
    text-align: left;
    margin-bottom: 40px;
  }
  @media #{$phone} {
    width: 40%;
  }
  img {
    @media #{$phone} {
      padding-left: 20px;
      max-width: 140px;
    }
  }
}

.footer__nav {
  float: right;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 57%;
  margin-top: -4px;
  @media #{$tablet} {
    width: 30%;
  }
  @media #{$phone} {
    width: 40%;
  }
  ul {
    width: 48%;
    @media #{$tablet} {
      width: 100%;
    }
  }
}

.footer__nav-list {
  padding-left: 0;

  li {
    list-style: none;
    margin-bottom: 23px;
    @media #{$tablet} {
      margin-bottom: 15px;
    }

    a {
      color: $white;
      @include font-size(20);
      text-decoration: none;
      font-family: $primary-family-medium;
      transition: 0.3s ease;
      @media #{$phone} {
        @include font-size(16);
      }

      &:hover,
      &:focus {
        color: $primary-color;
      }
    }
  }
}

.subscribe-block__title {
  max-width: 290px;
  @include font-size(24);
  font-family: $primary-family-semibold;
  margin-bottom: 26px;
  @media #{$tablet} {
    max-width: 100%;
  }
  @media #{$phone} {
    font-family: $primary-family-medium;
    @include font-size(16);
  }
}

.subscribe-block__description {
  color: rgba($white, 0.7);
  line-height: 1.3;
  margin-bottom: 25px;
  max-width: 290px;
  @media #{$tablet} {
    max-width: 100%;
  }
  @media #{$phone} {
    max-width: 240px;
    @include font-size(16);
  }

  img {
    display: inline-block;
    max-width: 18px;
    margin-top: -4px;
  }
}

.subscribe-block__form {
  position: relative;

  .subscribe-block__field-input {
    box-shadow: 0 15px 10px rgba(51, 54, 54, 0.125738);
    border-radius: 40px;
    padding: 18px 60px 17px 30px;
    background-color: $white;
    width: 100%;
    @media #{$phone} {
      @include font-size(16);
      padding: 19px 60px 19px 30px;
    }

    &::-webkit-input-placeholder {
      color: #B0B0B0;
    }

    &::-moz-placeholder {
      color: #B0B0B0;
    }

    &:-ms-input-placeholder {
      color: #B0B0B0;
    }

    &:-moz-placeholder {
      color: #B0B0B0;
    }
  }

  .subscribe-block__submit {
    width: 65px;
    height: 60px;
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
  }

  .subscribe-block__submit-button {
    padding: 16px 13px 20px;
    width: 65px;
    border-radius: 0;
    background-color: transparent;
    border-color: transparent;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      transform: scale(1) translate(10px, 0);
    }

    svg {
      color: $primary-color;
      width: 20px;
      height: 13px;
    }
  }
}

.footer__secondary {
  margin-top: -80px;
  @media #{$tablet} {
    max-width: 500px;
    margin: 0 auto;
  }
  @media #{$phone} {
    padding-left: 20px;
    text-align: center;
  }
}

.copyright {
  @include font-size(15);
  font-family: $primary-family-medium;
  margin-bottom: 5px;
  @media #{$phone} {
    @include font-size(13);
    color: rgba($white, 0.3);
  }
}

.payment-image{
  max-width: 154px;
}

.subscribe-block__form-message {
  @include font-size(18);
  font-style: italic;
  color: rgba($white, 0.7);
  margin-top: 26px;
}
