// .heroFlex {
//   + .price-table {
//     @media #{$phone} {
//       padding-top: 0;
//       // background-color: #f9f9f9;
//     }
//   }
// }

.price-table {
  padding: 48px 0;
  + .thumbnail-block {
    margin-top: 0;
  }
  @media #{$phone} {
    padding: 33px 0;
  }

  .container {
    max-width: 1170px;
  }
}

.price-table__title {
  letter-spacing: -0.33px;
  @include font-size(20);
  display: none;
  margin-bottom: 17px;

  @media #{$phone} {
    display: block;
  }
}

.price-table__grid {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 52px;

  @media #{$phone} {
    margin-bottom: 20px;
  }
}

.price-table__column-title {
  letter-spacing: -0.33px;
  @include font-size(20);
  margin-bottom: 27px;

  @media screen and (max-width: 860px) {
    margin-bottom: 16px;
  }

  @media #{$phone} {
    margin-bottom: 12px;
    margin-left: 20px;
  }
}

.price-table__column--offer {
  width: calc(100% - 298px);
  padding-right: 30px;
  align-self: flex-end;

  @media #{$phone} {
    width: 100%;
    padding-right: 0;
    margin-bottom: 10px;
  }
}

.price-table__column--regular {
  max-width: 298px;
  width: 100%;

  @media screen and (max-width: 860px) {
    flex-direction: column;
    display: flex;
  }

  @media #{$phone} {
    max-width: 100%;
  }
}

.hero-price-card {
  background-color: rgba($brand-color--secondary, 0.1);
  padding: 20px;
  border-radius: 20px;
  flex: 1;
  .price-table--bb & {
    background-color: rgba($brand-color--primary, 0.1);
  }
  .price-table--color-inherit & {
    background-color: rgba(var(--color), 0.1);
  }
  @media #{$phone} {
    padding: 15px;
  }
}

.price-card--offer {
  @media screen and (max-width: 860px) {
    width: 100%;
    flex: auto;
  }
}

.price-card--regular {
  background-color: transparent !important;
  border: 2px solid #c4c4c4;

  @media screen and (max-width: 860px) {
    flex: 1 1 auto;
  }
}

.price-table__inner-grid {
  display: flex;
  gap: 15px;

  @media screen and (max-width: 860px) {
    flex-wrap: wrap;
  }
}

.price-card__info {
  letter-spacing: -0.33px;

  strong {
    display: block;
  }

  .price-card__discount {
    background-color: $brand-color--secondary;
    display: inline-block;
    vertical-align: top;
    font-family: $primary-family-medium;
    border-radius: 20px;
    color: $white;
    padding: 6px 10px;
    line-height: 1;
    margin-bottom: 16px;
    @include font-size(18);
    font-weight: normal;

    .price-table--bb & {
      background-color: $brand-color--primary;
    }
    .price-table--color-inherit & {
      background-color: rgba(var(--color), 1);
      flex: 0 0 auto;
      @media #{$phone} {
        @include font-size(16);
        letter-spacing: -0.33px;
      }
    }
    .price-card--regular & {
      background-color: #ebeff1;
      color: $black;

      @media #{$phone} {
        @include font-size(16);
        margin-bottom: 0;
      }
    }

    .price-card--offer & {
      @media screen and (max-width: 860px) {
        @include font-size(16);
        margin-bottom: 0;
      }
    }
  }
}

.price-card__info-column {
  &:first-child {
    .price-card--offer & {
      @media screen and (max-width: 860px) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 10px;
      }
    }

    .price-card--regular & {
      @media #{$phone} {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 10px;
      }
    }
    .price-table--color-inherit & {
      @media #{$phone} {
        flex-wrap: nowrap;
        align-items: flex-start;
      }
    }
  }

  &:last-child {
    .price-card--offer & {
      @media screen and (max-width: 860px) {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        flex-wrap: wrap;
        align-items: flex-end;
      }
    }

    .price-card--regular & {
      @media #{$phone} {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        flex-wrap: wrap;
        align-items: flex-end;
      }
    }
  }
}

.price-card__count {
  @include font-size(20);
  font-family: $primary-family-bold;
  margin-bottom: 27px;

  .price-card--offer & {
    @media screen and (max-width: 860px) {
      margin-bottom: 0;
      @include font-size(16);
      letter-spacing: -0.33px;
    }
    .price-table--color-inherit & {
      @media #{$phone} {
        flex: 1 1 auto;
        @include font-size(16);
        line-height: normal;
      }
    }
  }

  span {
    @media #{$phone} {
      font-size: 0;
    }
    &:after {
      content: "w/ ";
      display: none;
      vertical-align: top;

      @media #{$phone} {
        display: inline;
        @include font-size(16);
        letter-spacing: -0.33px;
      }
    }
  }
  .price-card--regular & {
    @media #{$phone} {
      margin-bottom: 0;
      @include font-size(16);
      letter-spacing: -0.33px;
    }
  }
  &.price-card__count--black,
  &.price-card__count--white {
    position: relative;
    z-index: 1;

    &::after {
      content: "";
      height: 20px;
      width: 20px;
      border-radius: 50%;
      border: 2px solid $black;
      display: inline-block;
      vertical-align: top;
      margin-top: 3px;
      margin-left: 8px;
      @media #{$phone} {
        display: none;
      }
    }
  }
}

.price-card__count--black {
  &::after {
    background-color: #3b3b3b;
  }
}

.price-card__count--white {
  &::after {
    background-color: $white;
  }
}
.price-card__amount {
  @include font-size(56);
  font-family: $primary-family-semibold;
  letter-spacing: -1px;
  line-height: 1;
  margin-bottom: 27px;

  .price-card--offer & {
    @media screen and (max-width: 860px) {
      margin-bottom: 0;
      @include font-size(38);
      text-align: right;
    }
  }

  .price-card--regular & {
    @media #{$phone} {
      margin-bottom: 0;
      @include font-size(38);
      text-align: right;
    }
  }

  span {
    @include font-size(14);
    letter-spacing: -0.23px;
    font-family: $primary-family-medium;
    display: block;
  }
}

.price-card__old-price {
  line-height: 1;
  @include font-size(14);
  letter-spacing: -0.23px;
  font-family: $primary-family-medium;

  s {
    font-family: $primary-family-semibold;
    @include font-size(22);
    letter-spacing: -1px;
    margin-bottom: 2px;
    display: block;
  }
}

.price-table__button {
  display: flex;
  justify-content: center;
  margin-bottom: 44px;

  @media #{$phone} {
    margin-bottom: 20px;
  }

  .btn {
    max-width: 687px;
    width: 100%;
    .price-table--bb &,
    .price-table--color-inherit & {
      max-width: 415px;
    }
    @media #{$phone} {
      max-width: 317px;
      .price-table--bb &,
      .price-table--color-inherit & {
        max-width: 337px;
      }
    }
  }

  .btn--medium {
    background-color: $brand-color--secondary;

    text-align: center;
    @include font-size(16);
    padding: 21px 40px;

    @media #{$phone} {
      padding: 11px 40px 10px;
      .price-table--bb &,
      .price-table--color-inherit & {
        padding: 16px 40px 15px;
      }
    }
    .price-table--bb & {
      background-color: $brand-color--primary;
      border-color: $brand-color--primary;
    }
    .price-table--color-inherit & {
      background-color: rgba(var(--color), 1);
      border-color: rgba(var(--color), 1);
    }
  }
}

.price-table__list {
  list-style: none;
  display: flex;
  gap: 28px;
  padding-left: 0;

  @media #{$phone} {
    flex-wrap: wrap;
    gap: 16px;
  }

  li {
    font-family: $primary-family-medium;
    @include font-size(18);
    color: $black;
    letter-spacing: -0.33px;
    padding-left: 50px;
    position: relative;
    z-index: 1;
    min-height: 40px;
    display: flex;
    align-items: center;

    @media #{$phone} {
      padding-left: 34px;
      min-height: 24px;
      @include font-size(16);
    }

    .icon {
      height: 40px;
      width: 40px;
      position: absolute;
      left: 0;
      top: 0;

      @media #{$phone} {
        height: 24px;
        width: 24px;
      }
    }
  }
}
