.update-hero {
  background-color: $black;

  @media #{$phone} {
    padding-top: 80px;
  }

  .container {
    &.container--small {
      max-width: 796px;

      @media #{$phone} {
        display: flex;
        flex-direction: column-reverse;
      }
    }
  }
}

.update-hero__content {
  text-align: center;
  padding: 90px 0 0;

  @media #{$phone} {
    padding: 30px 0 0;
  }
}

.update-hero__title {
  color: $white;
  font-size: clamp(2rem, 4vw, 4.375rem);
  line-height: calc(80/70);
  letter-spacing: -.3px;
}

.update-hero__description {
  @include font-size(27);
  color: rgba($white, .8);
  line-height: 1.5;
  margin-bottom: 40px;

  @media #{$tablet} {
    @include font-size(20);
    letter-spacing: -.02em;
    margin-bottom: 20px;
  }
}

.update-hero__media {
  max-width: 512px;
  margin: 0 auto;
  width: 100%;
  position: relative;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 64px;
    left: 0;
    right: 0;
    background-image: linear-gradient(0, $black 0%, rgba(0, 0, 0, 0) 100%);
    bottom: 0;
    z-index: 1;
    display: none;

    @media #{$phone} {
      display: block;
    }
  }

  @media #{$phone} {
    max-width: 295px;
  }
}

.update-hero__buttons {
  margin-bottom: 64px;

  .btn {
    padding: 18px 65px;
    display: inline-flex;
    align-items: center;
    margin: 0 10px;
    will-change: transform;
    backface-visibility: hidden;
    font-family: $primary-family-medium;
    font-weight: normal;
    transform: translateZ(0);
    justify-content: center;
    text-align: center;

    @media #{$phone} {
      @include font-size(16);
      min-width: 200px;
      padding: 13px 20px;
      margin: 12px 10px;
    }

    img {
      margin-right: 10px;
    }

    &:hover,
    &:focus {
      transform: translateZ(0) scale(1.05);
    }
  }

  .btn--dark-gray {
    background-color: $dark-gray;
    border: $dark-gray;
  }

  .btn__apple-icon {
    margin-top: -2px;
  }
}
