.top-bar {
  text-align: center;
  background-color: $black;
  color: $white;
  padding: 14px 0;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;

  @media #{$phone} {
    padding: 8px 0;
  }

  &__info {
    @include font-size(16);

    @media #{$tablet} {
      @include font-size(14);
    }

    b {
      font-family: $primary-family-bold;
    }

    span {
      font-family: $primary-family-semibold;
    }

    strong {
      font-family: $primary-family-bold;
    }

    img {
      max-width: 18px;
      display: inline-block;
      margin-top: -4px;
    }
  }

  +.header-refactored {
    top: 50px;

    @media #{$phone} {
      top: 56px;
    }
  }

  &.top-bar--red {
    background-color: #f42d53;
    color: $white;
  }

  ~.main {
    padding-top: 144px;

    @media #{$phone} {
      padding-top: 130px;
    }
  }
}

.icon {
  display: inline-block;
  fill: currentColor;
  height: 1em;
  position: relative;
  vertical-align: middle;
  width: 1em;
}

.header-refactored {
  padding: 28px 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 998;
  background-color: $white;

  // @media #{$phone} {
  //   background-color: $light-gray;
  // }
  &.header-refactored--alt {
    background-color: $light-gray;

    @media #{$phone} {
      background-color: $white;
    }

    &.header-refactored--fixed {
      background-color: $white;
    }
  }

  &.header-refactored--no-nav {
    display: flex;
  }

  @media #{$tablet} {
    margin-top: 0;
  }

  @media #{$phone} {
    padding: 20px 0;
  }

  .nav__visible & {
    @media #{$header} {
      background-color: #fff;
      min-height: 100vh;
    }
  }

  &--fixed {
    background-color: $white;
    position: fixed;
    margin-top: 0;
    padding: 10px 0;
    max-width: 100%;
    justify-content: center;
    border-radius: 0 0 20px 20px;
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.08);
    z-index: 998;

    @media #{$header} {
      padding: 14px 0;
    }

    .logo {
      max-width: 36px;
    }

    .primary-nav .primary-nav__item {
      padding: 0 25px;

      @media #{$desktop} {
        padding: 0 15px;
      }

      @media #{$tablet} {
        padding: 0 12px;
      }

      a {
        padding: 10px 0;
        box-shadow: none;
        position: relative;
        border-radius: 0;
        transition: none;

        &.primary-nav__item--current:after {
          content: "";
          width: 100%;
          height: 4px;
          position: absolute;
          left: 0;
          bottom: -15px;
          display: block;
          background-color: $black;

          @media #{$header} {
            bottom: 0;
            height: 3px;
          }
        }
      }

      &.primary-nav__item--btn {
        a {
          padding: 10px 30px;
          border-radius: 30px;
        }
      }
    }
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    max-width: 1330px;
    padding: 0 20px;

    @media #{$desktop} {
      padding: 0 40px;
    }

    @media #{$phone-portrait} {
      padding: 0 20px;
    }
  }

  .primary-nav+.btn--small {
    padding: 13px 26px 11px;
    margin: 4px 0;
    background-color: $black;
    border-color: $black;

    @media #{$header} {
      position: absolute;
      top: -3px;
      right: 40px;
      margin: 0;
      padding: 10px 20px 9px;
    }

    @media #{$phone} {
      padding: 9px 13px;
      @include font-size(13);
    }

    @media #{$phone-portrait} {
      right: 20px;
    }
  }
}

.container {
  max-width: $container;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  margin: 0 auto;

  &.container--md {
    max-width: 1330px;
  }
}

.logo {
  width: 45px;
  max-width: 100%;
  position: relative;
  z-index: 3;

  @media #{$phone} {
    max-width: 36px;
  }
}

.primary-nav {
  display: block;

  @media #{$header} {
    display: none;
  }
}

.primary-nav__inner {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 0;
}

.primary-nav__item {
  margin: 0 12px;
  max-width: 320px;

  @media screen and (max-width: 1100px) {
    margin: 0 3px;
  }

  a {
    font-family: $primary-family-medium;
    display: block;
    padding: 10px 25px;
    text-decoration: none;
    @include font-size(16);
    color: $black;
    line-height: 1.4;
    position: relative;
    border-radius: 57px;
    transition: box-shadow 0.3s ease, background-color 0.3s ease;
    text-align: center;

    @media #{$desktop} {
      padding: 10px 15px;
    }

    @media #{$tablet} {
      padding: 8px 12px;
    }

    @media #{$header} {
      padding: 10px 30px;
    }

    &.primary-nav__item--current {
      font-family: $primary-family-semibold;
      background-color: $white;
    }

    &.primary-nav__item--current,
    &:hover {
      box-shadow: 0 2px 6px rgba($black, 0.15);
      background-color: $white;
    }
  }
}

.header--reverse .primary-nav__item a {
  color: $white;

  @media #{$header} {
    color: $black;
  }

  &.primary-nav__item--current,
  &:hover {
    box-shadow: 0 2px 6px rgba($black, 0.15);
    color: $black;
  }
}

.header--reverse.header-refactored--fixed .primary-nav__item a {
  color: $black;

  &.primary-nav__item--current,
  &:hover {
    color: $black;
    box-shadow: none;
  }
}

.primary-nav__item--btn {
  display: none;

  a {
    color: $white;

    &:hover {
      background-color: darken($primary-color, 10%);
      border-color: darken($primary-color, 10%);
      color: $white;
    }
  }

  @media #{$header} {
    display: block;
  }
}

/* Destop Logo */
.desktop-logo {
  display: block;

  @media #{$header} {
    display: none;

    .header-refactored--no-nav & {
      display: block;
    }
  }
}

/* Mobile Nav Trigger Button */
.mobileNav__toggle {
  display: none;

  @media #{$header} {
    display: block;
    z-index: 3;
  }
}

.menuBar__container {
  position: relative;
  display: block;
  width: 24px;
  height: 23px;
  transition: 0.4s ease;
  margin: 0 auto;

  .nav__visible & {
    transform: rotate(-180deg);
  }
}

.menuBar {
  display: block;
  height: 3px;
  position: absolute;
  top: calc(50% - 2px);
  left: 0;
  width: 100%;
  background-color: $black;
  transition: 0.4s ease;
  border-radius: 10px;

  .nav__visible & {
    background-color: $primary-color;
  }

  &:first-child {
    top: 6px;
    margin-top: 0;

    .nav__visible & {
      transform: rotate(45deg);
      top: 11px;
    }
  }

  &:last-child {
    top: auto;
    bottom: 6px;
    margin-top: 0;

    .nav__visible & {
      transform: rotate(-45deg);
      bottom: 9px;
    }
  }
}

/* Mobile Nav Popup */
.nav__visible {
  .primary-nav {
    @media #{$header} {
      display: block;
      width: 100%;
      height: 100%;
      padding: 30px 20px 20px;
      z-index: 2;
    }
  }

  .primary-nav__inner {
    @media #{$header} {
      flex-direction: column;
    }
  }

  .primary-nav__item {
    margin: 2vh 0;

    @media #{$phone} {
      margin: 1vh 0;
    }
  }
}

// Header Cart
.dropdown-overlay:before {
  content: "";
  display: block;
  width: 100%;
  height: calc(100% + 132px);
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(66, 66, 66, 0.6);
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;
}

.dropdown-overlay {
  position: relative;

  @media #{$phone} {
    overflow: hidden;
  }

  &:before {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease;
  }

  .header-refactored {
    z-index: inherit;
  }

  .header-refactored--fixed {
    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(66, 66, 66, 0.6);
      z-index: 10;
      border-radius: 0 0 20px 20px;
    }
  }
}

.cart-trigger {
  background-color: $white;
  padding: 18px 72px 13px 18px;
  border-radius: 15px;
  line-height: 1.2;
  font-family: $primary-family-medium;
  position: relative;
  min-width: 182px;
  text-align: left;
  box-shadow: 0 2px 6px rgba($black, 0.15);

  @media #{$phone} {
    padding: 14px 42px 13px 17px;
    min-width: 142px;
  }

  .icon-basket {
    width: 27px;
    height: 21px;
    margin-right: 10px;
    margin-top: -5px;

    @media #{$phone} {
      width: 22px;
      height: 17px;
    }
  }

  .icon-arrow-down {
    color: #494949;
    width: 15px;
    height: 9px;
    position: absolute;
    right: 17px;
    top: 50%;
    margin-top: -4px;
    transition: all 0.3s ease;

    @media #{$phone} {
      right: 14px;
    }
  }

  &--is-active {
    background-color: #ebebeb;
    border-radius: 15px 15px 0 0;

    .icon-arrow-down {
      transform: rotate(180deg);
    }
  }
}

.quick-cart {
  position: relative;
  z-index: 10;
  display: inline-block;
  margin-left: 30px;

  .nav__visible & {
    @media #{$header} {
      display: none;
    }
  }
}

.quick-cart__wrap {
  position: absolute;
  top: 52px;
  right: 0;
  width: 740px;
  background-color: $white;
  padding: 67px 80px 35px 74px;
  border-radius: 15px 0 15px 15px;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in;

  &--is-active {
    opacity: 1;
    visibility: visible;
    height: calc(100vh - 120px);
    overflow-x: auto;
  }

  @media #{$phone} {
    padding: 30px;
    width: 450px;
    top: 48px;
  }

  @media #{$phone-portrait} {
    padding: 20px;
    width: 100vw;
    right: -20px;
    border-radius: 0;
  }

  @media screen and (max-width: 320px) {
    width: 100vw;
    max-height: calc(100vh - 115px);
    overflow: scroll;
    right: -20px;
    border-radius: 0;
  }
}

.quick-cart__list {
  padding-left: 0;
  list-style: none;
}

.quick-cart__list-item {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px dashed #c9c9c9;
  @include font-size(20);
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media #{$phone-portrait} {
    padding-top: 36px;
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
}

.quick-cart__product-title {
  text-decoration: none;
  color: $black;
  width: calc(100% - 200px);

  @media #{$phone} {
    @include font-size(16);
  }

  @media #{$phone-portrait} {
    width: calc(100% - 70px);
  }
}

.quick-cart__product-quantity {
  width: 100px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-right: 17px;

  @media #{$phone-portrait} {
    width: 80px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .product-quantity__input-field {
    width: 100px;
    padding: 8px 36px;
    height: 36px;
    @include font-size(16);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08) inset;
    background-color: #f7f7f7;

    @media #{$phone-portrait} {
      height: 28px;
      width: 80px;
      @include font-size(16);
      padding: 4px 28px;
    }
  }

  button.product-quantity__item-remove,
  button.product-quantity__item-add {
    top: 2px;
    width: 32px;
    height: 32px;

    @media #{$phone-portrait} {
      width: 24px;
      height: 24px;
      top: 3px;
    }

    svg {
      width: 8px;
      height: 8px;
      top: -2px;
    }
  }

  .product-quantity__item-remove {
    left: 2px;
  }

  .product-quantity__item-add {
    right: 2px;
  }
}

.quick-cart__product-price {
  margin-left: auto;

  @media #{$phone} {
    @include font-size(16);
  }

  strong {
    font-family: $primary-family-medium;
  }
}

.quick-cart__calculate {
  padding-left: 0;
  margin-top: 37px;
}

.quick-cart__calculate-item {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @include font-size(20);
  margin-bottom: 22px;

  @media #{$phone} {
    @include font-size(16);
  }

  @media #{$phone-portrait} {
    margin-bottom: 15px;
  }

  >strong {
    font-family: $primary-family;
  }
}

.quick-cart__shipping-amount {
  strong {
    font-family: $primary-family-medium;
  }
}

.quick-cart__calculate-item--total {
  margin-top: 55px;
  margin-bottom: 35px;

  strong {
    font-family: $primary-family-semibold;
  }

  @media #{$phone-portrait} {
    margin-top: 20px;
    margin-bottom: 15px;
  }
}

.quick-cart__calculate-item--checkbox {
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0;
  justify-content: center;
}

.quick-cart__total-amount {
  position: relative;

  &:before {
    content: "";
    width: 18px;
    height: 2px;
    background-color: black;
    position: absolute;
    top: -33px;
    right: 0;

    @media #{$phone-portrait} {
      display: none;
    }
  }
}

input.product-quantity__input-field {
  width: 150px;
  background-color: #ececec;
  border-radius: 73px;
  padding: 8px 50px;
  color: #252525;
  @include font-size(20);
  font-family: $primary-family-medium;
  box-shadow: 0 2px 8px rgba($black, 0.08) inset;
  text-align: center;
  height: 44px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
}

button.product-quantity__item-remove,
button.product-quantity__item-add {
  position: absolute;
  top: 3px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: white;

  svg {
    width: 15px;
    height: 15px;
  }
}

.product-quantity__item-remove {
  left: 3px;
}

.product-quantity__item-add {
  right: 3px;
}
