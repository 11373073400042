.product-filter {
  padding: 80px 0;

  @media #{$tablet} {
    padding: 60px 0;
  }

  @media #{$phone} {
    padding: 40px 0;
  }
}

.product-filter__header {
  max-width: 846px;
  width: 100%;
  margin: 0 auto 56px;
  text-align: center;

  @media #{$phone} {
    margin-bottom: 20px;
  }

}

.product-filter__heading {
  @include font-size(48);
  letter-spacing: -0.31px;
  font-family: $primary-family-bold;
  line-height: calc(58/48);
  margin: 0 auto 48px;
  width: 100%;


  @media #{$small-screen} {
    @include font-size(36);
    margin-bottom: 28px;
  }

  @media #{$phone} {
    @include font-size(28);
  }
}

.product-filter__subtitle {
  color: var(--primary-color);
  @include font-size(28);
  line-height: calc(34/28);
  letter-spacing: -0.31px;
  font-family: $primary-family-medium;
  margin-bottom: 24px;

  @media #{$phone} {
    @include font-size(20);
    margin-bottom: 12px;
  }
}

.product-filter__description {
  @include font-size(20);
  line-height: 1.5;

  br {
    @media screen and (max-width:860px) {
      display: none;
    }
  }

  @media #{$phone} {
    @include font-size(16);
  }
}


.product-filter__grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;

  @media #{$tablet} {
    flex-direction: column-reverse;
  }
}

.product-filter__content,
.product-filter__media {
  width: 50%;
  padding: 0 15px;

  @media #{$tablet} {
    width: 100%;
  }
}

.product-filter__media {
  @media #{$tablet} {
    margin-bottom: 20px;
  }
}

.product-filter__list,
.product-filter__media-list {
  list-style: none;
  padding: 0;
}

.product-filter__item {
  padding: 40px;
  border-radius: 16px;
  background-color: #F7F7F7;
  cursor: pointer;
  border: 3px solid #F7F7F7;

  @media #{$phone} {
    padding: 32px;
  }

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.product-filter__item--current {
  border: 3px solid var(--primary-color);
  background-color: var(--secondary-color);

  @media #{$tablet} {
    background-color: #F7F7F7;
  }
}

.product-filter__item-header {
  position: relative;
  z-index: 1;
}

.product-filter__toggle {
  position: absolute;
  top: calc(50% - 15px);
  right: 0;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(-90deg);
  transition: all 300ms ease;

  .product-filter__item--current & {
    transform: rotate(0deg);
  }

  svg {
    height: auto;
    width: 16px;
  }
}

.product-filter__title {
  @include font-size(28);
  line-height: 1;
  font-family: $primary-family-medium;
  letter-spacing: -0.3px;
  margin-bottom: 0;

  @media #{$phone} {
    @include font-size(20);
  }
}

.product-filter__item-body {
  display: none;
}


.product-filter__item-description {
  @include font-size(18);
  line-height: 1.5;
  padding-top: 16px;

  @media #{$phone} {
    @include font-size(16);
  }
}

.product-filter__media-list {
  aspect-ratio: 630/472;
  width: 100%;
  position: relative;

  @media #{$tablet} {
    max-height: 472px;
    margin: 0 auto;
    width: auto;
    height: 100%;
  }
}

.product-filter__media-item {
  opacity: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  position: absolute;
  transition: all 300ms ease-in-out;

  &.product-filter__media-item--current {
    opacity: 1;
  }
}
