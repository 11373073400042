
.unit-carousel {
  .slick-arrow {
    position: absolute;
    bottom: 97px;
    width: 60px;
    height: 60px;
    color: #fff;
    z-index: 1;
    transition: 0.3s ease;

    @media #{$large-screen} {
      bottom: 57px;
      width: 45px;
      height: 45px;
    }

    @media #{$tablet} {
      bottom: auto;
      top: calc(50% - 160px);
    }

    @media #{$phone} {
      top: calc(50% - 212px);
    }

    @media #{$phone} {
      top: calc(50% - 251px);
    }

    svg {
      width: 60px;
      height: 60px;

      @media #{$large-screen} {
        width: 45px;
        height: 45px;
      }
    }

    &.slick-disabled {
      color: #828282;
      opacity: 1;
    }
  }
}

.unit-carousel__slide-next {
  left: calc(50% + 400px);

  @media #{$tablet} {
    left: auto;
    right: 10px;
  }
}

.unit-carousel__slide-prev {
  left: calc(50% + 330px);

  @media #{$large-screen} {
    left: calc(50% + 340px);
  }

  @media #{$tablet} {
    left: 10px;
  }
}

.unit-slide {
  background-color: #232323;
  color: $white;
}

.unit-slide__inner {
  height: 1334px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  max-width: 1678px;
  margin: 0 auto;
  position: relative;

  @media #{$large-screen} {
    margin-top: 90px;
    height: 1000px;
  }

  @media #{$tablet} {
    margin-top: 70px;
    height: auto;
    background: none !important;
  }
}

.unit-slide__header {
  max-width: 965px;
  margin: 0 auto;
  padding: 117px 20px 0;

  @media #{$large-screen} {
    padding-top: 0;
    position: relative;
    z-index: 1;
  }
}

.unit-slide__header-subtitle {
  @include font-size(18);
  font-family: $primary-family-medium;
  margin-bottom: 30px;
}

.unit-slide__header-title {
  @include font-size(60);
  font-family: $primary-family-bold;
  max-width: 502px;
  line-height: 1;

  @media #{$large-screen} {
    @include font-size(48);
  }

  @media #{$tablet} {
    @include font-size(38);
  }
}

.unit-slide__image {
  display: none;

  @media #{$tablet} {
    display: block;
    margin-bottom: 30px;
    margin-top: -230px;
  }

  @media #{$phone} {
    margin-top: -130px;
  }

  img {
    @media #{$phone} {
      display: block;
      left: -25%;
      position: relative;
      width: 150%;
      max-width: inherit;
    }
  }
}

.unit-slide__footer {
  position: absolute;
  bottom: 95px;
  max-width: 965px;
  margin: 0 auto;
  left: 50%;
  width: 100%;
  transform: translate(-50%, 0);
  padding: 0 20px;

  @media #{$large-screen} {
    bottom: 55px;
  }

  @media #{$tablet} {
    position: static;
    transform: translate(0, 0);
    margin-top: -140px;
  }

  @media #{$phone} {
    margin-top: -130px;
  }

  h3 {
    @include font-size(36);
    font-family: $primary-family-medium;
    margin-bottom: 0;

    @media #{$large-screen} {
      @include font-size(26);
    }

    @media #{$tablet} {
      @include font-size(20);
    }
  }

  p {
    @include font-size(22);
    color: rgba($white, 0.7);
    max-width: 80%;

    @media #{$large-screen} {
      @include font-size(18);
    }

    @media #{$tablet} {
      @include font-size(16);
    }
  }
}

.unit-slide__content {
  max-width: 1130px;
  margin: 0 auto;

  @media #{$tablet} {
    margin: 40px auto;
    padding: 0 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    z-index: 1;
    position: relative;
  }
}

.unit-slide__detail-box {
  color: $white;
  @include font-size(18);

  @media #{$phone} {
    @include font-size(16);
  }

  h3 {
    @include font-size(18);
    font-family: $primary-family-semibold;
    margin-bottom: 5px;
  }

  .unit-slide__detail-text {
    max-width: 220px;
  }
}

.unit-slide__detail-box--1 {
  display: flex;
  width: 570px;
  padding-bottom: 16px;
  position: absolute;
  top: calc(50% - 306px);
  left: calc(50% - 105px);

  @media #{$large-screen} {
    left: calc(50% - 93px);
  }

  @media screen and (max-width: 1200px) {
    left: calc(50% - 80px);
    width: 400px;
  }

  @media #{$tablet} {
    position: static;
    width: 49%;
    padding: 20px;
    margin-bottom: 20px;
    border: 2px solid #fff;
    border-radius: 10px;
  }

  @media #{$phone} {
    width: 100%;
    margin-bottom: 10px;
  }

  .unit-slide__line {
    width: 323px;
    height: 246px;
    position: relative;
    margin-right: 27px;

    @media screen and (max-width: 1200px) {
      width: 213px;
    }

    @media #{$tablet} {
      display: none;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 59%;
      border-bottom: 2px solid $primary-color;
      border-right: 2px solid $primary-color;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 41%;
      border-left: 2px solid $primary-color;
    }
  }

  .unit-slide__detail-text {
    max-width: 220px;

    @media #{$tablet} {
      max-width: 100%;
    }
  }
}

.unit-slide__detail-box--2 {
  display: flex;
  justify-content: flex-end;
  width: 375px;
  border-bottom: 2px solid $primary-color;
  padding-bottom: 16px;
  position: absolute;
  bottom: calc(50% - 43px);
  left: calc(50% + 175px);

  @media #{$large-screen} {
    bottom: calc(50% - 33px);
  }

  @media screen and (max-width: 1200px) {
    width: 305px;
    left: calc(50% + 140px);
  }

  @media #{$tablet} {
    position: static;
    width: 49%;
    padding: 20px;
    margin-bottom: 20px;
    border: 2px solid #fff;
    border-radius: 10px;
  }

  @media #{$phone} {
    width: 100%;
    margin-bottom: 10px;
  }

  .unit-slide__detail-text {
    max-width: 205px;

    @media #{$tablet} {
      max-width: 100%;
      width: 100%;
    }

    .unit-slide--2 & {
      max-width: 195px;

      @media #{$tablet} {
        max-width: 100%;
      }
    }
  }
}

.unit-slide__detail-box--3 {
  display: flex;
  width: 420px;
  position: absolute;
  top: calc(50% + 107px);
  left: calc(50% + 170px);

  @media #{$large-screen} {
    top: calc(50% + 97px);
  }

  @media screen and (max-width: 1200px) {
    top: calc(50% + 81px);
    left: calc(50% + 140px);
    width: 320px;
  }

  @media #{$tablet} {
    position: static;
    width: 49%;
    padding: 20px;
    margin-bottom: 20px;
    border: 2px solid #fff;
    border-radius: 10px;
  }

  @media #{$phone} {
    width: 100%;
    margin-bottom: 10px;
  }

  .unit-slide__line {
    width: 152px;
    position: relative;

    @media #{$tablet} {
      display: none;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      border-top: 2px solid $primary-color;
      border-right: 2px solid $primary-color;
      padding-bottom: 15px;
    }
  }

  .unit-slide__detail-text {
    max-width: 250px;
    padding: 80px 0 15px 22px;
    position: relative;

    @media #{$tablet} {
      padding: 0;
      max-width: 100%;
    }

    &:after {
      content: "";
      height: 2px;
      display: block;
      background-color: $primary-color;
      position: absolute;
      bottom: 0;
      width: calc(100% - 44px);
      left: 0;

      @media #{$tablet} {
        display: none;
      }
    }
  }
}

.unit-slide__detail-box--4 {
  width: 310px;
  border-bottom: 2px solid $primary-color;
  padding-bottom: 26px;
  position: absolute;
  bottom: calc(50% - 107px);
  left: calc(50% - 465px);

  @media screen and (max-width: 1200px) {
    left: calc(50% - 435px);
    bottom: calc(50% - 79px);
  }

  @media #{$tablet} {
    position: static;
    width: 49%;
    padding: 20px;
    margin-bottom: 20px;
    border: 2px solid #fff;
    border-radius: 10px;
  }

  @media #{$phone} {
    width: 100%;
    margin-bottom: 10px;
  }

  .unit-slide__detail-text {
    max-width: 220px;

    @media #{$tablet} {
      max-width: 100%;
    }

    .unit-slide--2 & {
      max-width: 270px;

      @media #{$tablet} {
        max-width: 100%;
      }
    }
  }

  .unit-slide--2 & {
    width: 375px;
    left: calc(50% - 530px);

    @media screen and (max-width: 1200px) {
      width: 305px;
      left: calc(50% - 430px);
    }

    @media #{$tablet} {
      width: 49%;
    }

    @media #{$phone} {
      width: 100%;
    }
  }
}
