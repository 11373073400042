.mila-timeline {
  padding: 0 30px 30px;

  @media #{$desktop} {
    padding: 0 20px 20px;
  }

  @media #{$phone} {
    padding: 0 8px 16px;
  }

  .container {
    max-width: 1148px;
    padding: 0 !important;
  }
}

.mila-timeline__card {
  border-radius: 16px;
  background-color: #f6f6f6;
  padding: 91px 20px 110px;

  @media #{$tablet} {
    padding: 80px 20px;
  }

  @media #{$phone} {
    padding: 80px 16px 40px;
  }
}

.mila-timeline__header {
  text-align: center;
  margin-bottom: 80px;

  @media #{$desktop} {
    margin-bottom: 60px;
  }
}

.mila-timeline__title {
  letter-spacing: -0.3px;
  @include font-size(48);
  line-height: 1;
  font-family: $primary-family-bold;
  text-align: center;
  max-width: 650px;
  margin: 0 auto 34px;

  @media #{$tablet} {
    @include font-size(32);
    max-width: 361px;
  }

  @media #{$phone} {
    @include font-size(24);
    margin-bottom: 24px;
    max-width: 301px;
  }
}

.mila-timeline__description {
  max-width: 775px;
  margin: 0 auto;
  @include font-size(22);
  line-height: 1.4;
  letter-spacing: -0.4px;

  @media #{$tablet} {
    @include font-size(20);
  }

  @media #{$phone} {
    @include font-size(16);
    max-width: 313px;
    line-height: 1.3;
  }

  .subtitle {
    font-family: $primary-family-medium;
    display: none;

    @media #{$phone} {
      @include font-size(20);
      line-height: 1.3;
    }

    br {
      @media screen and (min-width: 768px) {
        display: none;
      }
    }
  }

  p {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}

.mila-timeline__slider {
  width: 100%;

  .slick-track {
    display: flex;
  }

  .slick-dots {
    justify-content: center;
    margin-top: 60px;
    gap: 10px;

    li {
      margin: 0;
      height: auto;
      border-radius: none;

      &.slick-active {
        button {
          outline-color: $black;
          width: 24px;

          &:hover,
          &:focus {
            outline: 2px solid $black !important;
          }

          &::after {
            transform: translateX(var(--progress));
          }
        }
      }

      button {
        height: 6px;
        width: 6px;
        outline: 2px solid #d3d3d3;
        will-change: width;
        border: none;
        overflow: hidden;

        &:hover,
        &:focus {
          border-color: none;
          outline: 2px solid #d3d3d3 !important;
          transition: none;

          &::after {
            background-color: $black;
          }
        }

        &::before {
          display: none;
        }

        &::after {
          content: "";
          height: 6px;
          border-radius: inherit;
          left: -1px;
          top: 0;
          background-color: $black;
          position: absolute;
          z-index: 1;
          width: 28px;
          transform: translateX(-100%);
        }
      }
    }
  }

  .slick-slide {
    @media #{$tablet} {
      transition: opacity 300ms ease;
      opacity: 0.4;
    }

    &.slick-current,
    &.slick-active {
      @media #{$tablet} {
        opacity: 1;
      }
    }
  }
}

.mila-timeline__grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;

  @media #{$desktop} {
    gap: 30px 20px;
  }

  @media #{$header} {
    grid-template-columns: repeat(2, 1fr);
  }
}

.timeline-item {
  display: flex;
  flex-direction: column;

  @media #{$phone} {
    max-width: 400px;
    margin: 0 auto;
  }

  &:not(:last-child) {
    margin-bottom: 24px;

    @media #{$desktop} {
      margin-bottom: 16px;
    }

    @media #{$phone} {
      margin-bottom: 16px;
    }
  }
}

.timeline-card {
  background-color: $white;
  border-radius: 16px;
  border: 2px solid #d6d6d6;
  flex: 0 0 auto;
  min-height: 360px;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  @media #{$desktop} {
    margin-bottom: 16px;
  }

  @media #{$phone} {
    min-height: auto;
    display: block;
    margin-bottom: 0;
  }

  &::after,
  &::before {
    content: "";
    position: absolute;
    height: 27px;
    width: 27px;
    border-radius: 50%;
    border: 2px solid #d6d6d6;
    background-color: #f6f6f6;
    top: 20px;

    @media #{$phone} {
      transition: opacity 300ms ease;
      opacity: 0;
    }

    .active & {
      @media #{$phone} {
        opacity: 1;
      }
    }
  }

  &::after {
    left: calc(25% - 13px);
  }

  &::before {
    left: calc(75% - 13px);
  }
}

.timeline-item__description {
  flex: 1 1 auto;

  @media #{$tablet} {
    @include font-size(16);
  }

  @media #{$phone} {
    padding: 0 24px;
    overflow: hidden;
  }
}

.timeline-item__day {
  font-family: $primary-family-bold;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  z-index: 1;

  @media #{$phone} {
    flex-direction: unset;
    gap: 5px;
    transition: all 300ms ease;

    .active & {
      display: inline-flex;
      flex-direction: column;
      gap: 0;
    }
  }

  strong {
    @include font-size(80);
    line-height: .8;
    letter-spacing: -0.3px;
    margin-bottom: -0.011em;

    @media #{$phone} {
      @include font-size(24);
      display: inline-block;
      transition: all 300ms ease;
      line-height: 1;

      .active & {
        @include font-size(80);
        line-height: .9;
      }
    }

    span {
      background-image: url("data:image/svg+xml,%3Csvg width='49' height='9' viewBox='0 0 49 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.77344 6.83688C16.0089 1.56447 32.4735 2.4495 47.3899 2.03516' stroke='black' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E%0A");
      display: inline-block;
      letter-spacing: -0.68px;
      @include font-size(26);
      left: 100%;
      position: absolute;
      top: 0.3em;
      padding-right: 0.4em;
      padding-bottom: 0.175em;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left bottom;

      @media #{$phone} {
        transition: opacity 300ms ease;
        opacity: 0;
      }

      .active & {
        @media #{$phone} {
          opacity: 1;
        }
      }
    }
  }

  .day-text {
    letter-spacing: -1px;
    @include font-size(26);
    line-height: 1;

    @media #{$phone} {
      @include font-size(24);
      display: inline-block;

      .active & {
        @include font-size(26);
        display: block;
      }
    }
  }
}

.timeline-item__toggle {
  display: none;
  position: relative;
  padding: 24px;

  @media #{$phone} {
    display: flex;

    .active & {
      transform: rotate(-180deg);
    }
  }

  svg {
    width: 12px;
    height: 7px;
  }
}

.timeline-card__header {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  padding: 20px 40px 0;

  @media #{$phone} {
    padding: 20px;
    transition: all 300ms ease;
  }

  .active & {
    @media #{$phone} {
      padding-top: 76px;
      padding-bottom: 40px;
      padding: 76px 40px 40px;
    }
  }
}

.timeline-item__title {
  @include font-size(20);
  line-height: 1.2;
  letter-spacing: -0.5px;
  flex: 1 1 auto;
  font-family: $primary-family-medium;
  margin-bottom: 0;

  @media #{$desktop} {
    @include font-size(18);
    letter-spacing: -0.3px;
  }

  @media #{$phone} {
    max-width: 275px;
  }
}

.timeline-card__footer {
  border-top: 2px dashed #d6d6d6;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  padding: 20px 30px 20px 40px;
  min-height: 110px;

  @media #{$desktop} {
    padding: 20px;
  }

  @media #{$phone} {
    min-height: auto;
    padding-bottom: 0;

    .active & {
      padding-bottom: 20px;
    }
  }
}

.timeline-item__icon {
  flex: 0 0 auto;
  @include font-size(42);
  line-height: 0;
  letter-spacing: -0.5px;

  @media #{$phone} {
    right: 20px;
    top: 34px;
    position: absolute;
    z-index: 1;
    opacity: 0;
    animation: fadeOut 400ms ease forwards 200ms reverse;

    .active & {
      position: relative;
      top: auto;
      right: auto;
      opacity: 0;
      animation: fadeInOut 400ms ease forwards 200ms;
    }
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
