.featured-content {
  @media #{$tablet} {
    margin-bottom: 100px;
  }

  &.featured-content--first {
    padding-top: clamp(1.5625rem, 8vw, 7.3125rem);
  }

  &.featured-content--third,
  &.featured-content--forth {
    padding-top: clamp(1.5625rem, 9vw, 12.7125rem);

    @media #{$tablet} {
      padding-top: 0;
    }
  }

  &.featured-content--fifth {
    padding-top: clamp(1.5625rem, 10vw, 21.7125rem);

    @media #{$tablet} {
      padding-top: 0;
    }
  }

  &.featured-content--sixth {
    padding-top: clamp(1.5625rem, 9vw, 12.5rem);

    @media #{$tablet} {
      padding-top: 0;
    }
  }

  &.featured-content--seventh {
    padding-top: 45px;

    @media #{$tablet} {
      padding-top: 0;
    }
  }

  &.featured-content--eighth,
  .featured-content--tenth & {
    padding-top: clamp(1.5625rem, 9vw, 10rem);

    @media #{$tablet} {
      padding-top: 0;
    }
  }

  &.featured-content--ninth {
    padding-top: clamp(1.5625rem, 13vw, 19.7125rem);

    @media #{$tablet} {
      padding-top: 0;
    }
  }

  &.featured-content--tenth {
    padding-top: clamp(1.5625rem, 13vw, 17.7125rem);
    padding-bottom: 136px;

    @media #{$desktop} {
      padding-bottom: 80px;
    }

    @media #{$tablet} {
      padding-top: 0;
      padding-bottom: 60px;
      margin-bottom: 0;
    }
  }
}

.container {
  .featured-content & {
    max-width: 1188px;
  }

  .featured-content--second & {
    max-width: 1456px;

    @media #{$medium-screen} {
      max-width: 1280px;
    }
  }

  .featured-content--forth & {
    max-width: 1320px;
  }

  .mfeatured-content--fifth &,
  .featured-content--seventh & {
    max-width: 1240px;
  }

  .featured-content--sixth & {
    max-width: 1576px;
  }

  .featured-content--eighth & {
    max-width: 1100px;
  }

  .featured-content--ninth & {
    max-width: 1160px;
  }

  .featured-content--tenth & {
    max-width: 1204px;
  }
}

.featured-content__grid {
  display: flex;

  @media #{$tablet} {
    flex-direction: column-reverse;
  }

  .featured-content--reverse & {
    flex-direction: row-reverse;

    @media #{$desktop} {
      justify-content: center;
    }

    @media #{$tablet} {
      flex-direction: column-reverse;
    }
  }

  .featured-content--first & {
    @media #{$desktop} {
      align-items: center;
    }
  }

  .featured-content--forth & {
    margin: 0 -60px;

    @media #{$tablet} {
      margin: 0;
    }
  }
}

.featured-content__content {
  width: 100%;

  @media #{$tablet} {
    max-width: 100% !important;
    width: 100% !important;
  }

  .featured-content--first & {
    max-width: 475px;

  }

  .featured-content--second & {
    width: calc(100% - 560px);
    padding-top: clamp(0rem, 7vw, 13.375rem);

    @media #{$desktop} {
      padding-left: 30px;
    }

    @media #{$tablet} {
      padding-left: 0;
    }

    @media #{$phone} {
      padding-top: 15px;
    }
  }

  .featured-content--third & {
    max-width: 470px;
    padding-top: 48px;
  }

  .featured-content--forth & {
    width: 50%;
    padding: 70px 60px 0;
    align-items: center;

    @media #{$tablet} {
      width: 100%;
      padding: 0;
    }
  }

  .featured-content--fifth & {
    max-width: 578px;
    padding-top: 82px;

    @media #{$small-screen} {
      padding-top: 0;
    }
  }

  .featured-content--sixth & {
    max-width: 665px;
    padding-top: 130px;

    @media #{$desktop} {
      max-width: 465px;
      padding-top: 110px;
    }

    @media #{$tablet} {
      max-width: 465px;
      padding-top: 0;

    }
  }

  .featured-content--seventh & {
    padding-top: 256px;
    max-width: 555px;

    @media #{$tablet} {
      padding-top: 0;
    }
  }

  .featured-content--eighth & {
    padding-top: 171px;
    max-width: 533px;

    @media #{$tablet} {
      padding-top: 0;
      max-width: 100%;
    }
  }

  .featured-content--ninth & {
    max-width: 422px;
  }

  .featured-content--tenth & {
    max-width: 631px;
    padding-top: 140px;

    @media #{$tablet} {
      padding-top: 0;
      max-width: 100%;
    }
  }
}

.featured-content__media {

  .featured-content--first & {
    width: calc(100% - 475px);

    @media #{$tablet} {
      width: 100%;
      margin-bottom: 38px;
    }

  }

  span {
    @include clearfix;
    display: block;
  }


  .featured-content--second & {
    max-width: 560px;

    @media #{$tablet} {
      width: 100%;
      max-width: 100%;
      display: flex;
    }
  }

  .featured-content--third & {
    width: calc(100% - 470px);

    @media #{$tablet} {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .featured-content--forth & {
    width: 50%;
    padding: 0 60px;

    @media #{$tablet} {
      padding: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
    }
  }

  .featured-content--fifth & {
    width: calc(100% - 578px);

    @media #{$tablet} {
      width: 100%;
      margin-bottom: 30px;
      display: flex;
      justify-content: center;
    }
  }

  .featured-content--sixth & {
    width: calc(100% - 665px);
    padding-right: 52px;
    padding-left: 32px;

    @media #{$desktop} {
      width: calc(100% - 465px);
      padding-left: 0;
    }

    @media #{$tablet} {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
      padding-right: 0;
    }
  }

  .featured-content--seventh & {
    width: calc(100% - 555px);

    @media #{$tablet} {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
    }
  }

  .featured-content--eighth & {
    width: calc(100% - 533px);

    @media #{$tablet} {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
    }
  }

  .featured-content--ninth & {
    width: calc(100% - 422px);
    margin-top: auto;
    padding-top: 62px;

    @media screen and (max-width:1170px) {
      padding-top: 0;
    }

    @media #{$tablet} {
      width: auto;
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
    }

    @media #{$phone-portrait} {
      margin: 0 -50px 35px;
    }
  }

  .featured-content--tenth & {
    width: calc(100% - 631px);
    position: relative;
    z-index: 1;

    @media #{$tablet} {
      width: auto;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin: 0 auto 30px;
    }
  }

  img {
    width: auto;
    position: relative;

    @media #{$tablet} {
      margin: 0 auto;
    }

    .featured-content--first & {
      right: -320px;
      max-width: 1183px;
      float: right;

      @media #{$medium-screen} {
        right: 0;
        max-width: 800px;
      }

      @media #{$tablet} {
        width: 100%;
        right: 22%;
      }

      @media #{$phone-portrait} {
        width: auto;
        max-width: 420px;
        right: 10%;
      }
    }

    .featured-content--third & {
      max-width: 928px;
      right: -166px;

      @media #{$medium-screen} {
        width: 100%;
        right: 0;
      }

      @media #{$tablet} {
        max-width: 450px;
        margin: 0 auto;
      }
    }

    .featured-content--forth & {
      box-shadow: 40px 80px 173.25px rgba(0, 0, 0, 0.4);
      border-radius: 50%;
      max-width: 543px;

      @media #{$small-screen} {
        width: 100%;
      }

      @media #{$tablet} {
        max-width: 340px;
        box-shadow: 20px 30px 70px rgba(0, 0, 0, 0.6);
      }

      @media #{$phone-portrait} {
        max-width: 222px;
      }
    }

    .featured-content--fifth & {
      max-width: 1087px;
      left: -8px;

      @media #{$small-screen} {
        left: 0;
        width: 100%;
      }

      @media #{$tablet} {
        left: -5%;
        max-width: 450px;
      }

      @media #{$phone-portrait} {
        left: -5%;
        max-width: 320px;
      }
    }

    .featured-content--sixth & {
      @media #{$tablet} {
        max-width: 550px;
        width: 100%;
      }

      @media #{$phone} {
        max-width: 370px;
        width: auto;
      }
    }

    .featured-content--seventh & {
      max-width: 811px;
      right: -153px;

      @media #{$medium-screen} {
        max-width: 650px;
        right: -100px;


        @media #{$tablet} {
          max-width: 450px;
          margin: 0 auto;
        }
      }

      @media #{$desktop} {
        width: 100%;
        right: 0;
      }

      @media #{$tablet} {
        max-width: 450px;
      }

      @media #{$phone} {
        max-width: 350px;
      }

      @media #{$phone-portrait} {
        max-width: 235px;
      }
    }

    .featured-content--eighth &,
    .featured-content--tenth & {
      max-width: 571px;
      float: right;
      left: -142px;

      @media #{$small-screen} {
        left: -10%;
        width: 100%;
      }

      @media #{$tablet} {
        max-width: 371px;
      }

      @media #{$phone} {
        left: -13.3%;
      }
    }

    .featured-content--ninth & {
      max-width: 1150px;
      left: -100px;

      @media #{$desktop} {
        left: -200px;
      }

      @media screen and (max-width:1170px) {
        left: 0;
        width: 100%;
        max-width: 640px;
      }
    }

    .featured-content--tenth & {
      max-width: 571px;
      float: right;
      left: -142px;

      @media #{$small-screen} {
        left: -10%;
        width: 100%;
      }

      @media #{$tablet} {
        max-width: 371px;
      }

      @media #{$phone-portrait} {
        left: -16%;
        max-width: 291px;
      }
    }
  }
}

.featured-content__video {
  width: 100%;
  max-width: 550px;

  @media #{$desktop} {
    max-width: 350px;
  }

  @media #{$tablet} {
    position: relative;
    left: -10%;
    margin: 0 auto;
  }
}

.featured-content__title {
  font-style: normal;
  font-weight: 700;
  font-size: clamp(2rem, 4vw, 4.25rem);
  line-height: 1.3235;
  letter-spacing: -0.3px;
  color: $black;
  margin-bottom: clamp(1.4rem, 4vw, 4.375rem);

  .featured-content--second & {
    margin-bottom: clamp(1.4rem, 2vw, 3.5rem);
  }

  .featured-content--tenth & {
    margin-bottom: 38px;

    @media #{$phone} {
      margin-bottom: 20px;
    }
  }

  @media #{$desktop} {
    margin-top: 0;
  }

  @media #{$phone} {
    line-height: 1.061;
  }
}

.featured-content__description {
  font-size: clamp(.9375rem, 2vw, 2rem);
  line-height: 1.375;
  letter-spacing: -.3px;

  @media #{$tablet} {
    max-width: 100% !important;
  }

  .featured-content--second & {
    max-width: 568px;
  }

  .featured-content--seventh & {
    max-width: 480px;
  }
}


.featured-content__content-inner {
  width: 100%;

  @media #{$tablet} {
    max-width: 100% !important;
    width: 100% !important;
    margin-left: 0 !important;
  }

  .featured-content--second & {
    max-width: 619px;
    margin-left: auto;
  }

  .featured-content--forth & {
    max-width: 568px;
  }
}

.featured-content__logo {
  max-width: 109px;
  border-radius: 10px;
  margin-top: 10px;

  &.featured-content__logo--large {
    @media #{$tablet} {
      display: none;
    }
  }

  &.featured-content__logo--small {
    display: none;
    position: absolute !important;
    max-width: 47px !important;
    top: 24px;
    right: -4px;
    left: auto !important;
    z-index: 2 !important;

    @media #{$tablet} {
      display: block;
    }
  }
}
