.featured-carousel {
  padding-bottom: 210px;
  padding-top: 195px;

  @media #{$tablet} {
    padding-bottom: 100px;
    padding-top: 100px;
  }

  @media #{$phone} {
    padding: 60px 0;
  }

  .container {
    max-width: 1360px;
  }
}

.featured-carousel__header {
  max-width: 1070px;
  margin: 0 auto 152px;
  text-align: center;

  @media #{$tablet} {
    margin-bottom: 100px;
  }

  @media #{$phone} {
    margin-bottom: 30px;
  }
}

.featured-carousel__header-title {
  margin-bottom: 28px;

  @media #{$phone} {
    @include font-size(28);
  }
}

.featured-carousel__header-subtitle {
  font-family: $primary-family-medium;
  @include font-size(38);
  line-height: 1.3;
  max-width: 600px;
  margin: 0 auto 15px;

  @media #{$large-screen} {
    @include font-size(28);
  }

  @media #{$tablet} {
    padding: 0 40px;
  }

  @media #{$phone} {
    @include font-size(20);
    padding: 0;
  }
}

.featured-carousel__description {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1000px;
  margin: 116px auto 0;
  text-align: left;
  color: rgba($black, 0.8);
  line-height: 1.65;

  @media #{$large-screen} {
    margin-top: 80px;
  }

  @media #{$phone} {
    margin-top: 30px;
  }
}

.featured-carousel__description-half {
  width: 44%;
  @include font-size(24);

  @media #{$large-screen} {
    @include font-size(18);
  }

  @media #{$phone} {
    @include font-size(16);
    width: 100%;
    margin-bottom: 20px;
  }
}

.featured-carousel__slider {
  @media #{$phone} {
    left: -20px;
    width: calc(100% + 40px);
  }

  .featured-carousel__slide-prev {
    left: -110px;

    @media screen and (max-width: 1600px) {
      left: -3px;
    }
  }

  .featured-carousel__slide-next {
    right: -110px;

    @media screen and (max-width: 1600px) {
      right: -3px;
    }
  }

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;
    display: flex;
    flex-direction: column;
    margin: 0 15px;

    @media #{$phone} {
      margin: 0 6px;
    }

    >div {
      height: 100%;
      display: flex;
    }
  }

  .slick-arrow {
    position: absolute;
    top: calc(50% - 31px);
    width: 62px;
    height: 62px;
    color: $black;
    z-index: 1;
    transition: 0.3s ease;
    background-color: $white;
    border-radius: 50%;

    @media screen and (max-width: 1600px) {
      top: calc(50% - 20px);
      width: 40px;
      height: 40px;
    }

    svg {
      width: 62px;
      height: 62px;

      @media screen and (max-width: 1600px) {
        width: 40px;
        height: 40px;
      }
    }

    &.slick-disabled {
      color: rgba($black, 0.08);
    }
  }

  .slick-dots {
    justify-content: center;
    margin-top: 40px;
  }

  .slick-dots li:hover button {
    border-color: $primary-color;
  }

  .slick-dots li.slick-active button {
    width: 12px;
    border-color: $primary-color;
  }

  .slick-dots li.slick-active button:before {
    @media #{$phone} {
      background-color: $primary-color;
      height: calc(100% + 2px);
      width: calc(100% + 2px);
      animation: none;
      top: -1px;
      left: -1px;
    }
  }
}

.featured-carousel__slide-item {
  border: 3px solid #ECECEC;
  border-radius: 18px;
  padding: 45px 45px 24px;
  height: 100%;
  height: inherit !important;
  display: flex !important;
  flex-direction: column;

  @media #{$phone} {
    border-width: 2px;
    padding: 27px 34px 13px;
  }

  @media #{$phone-portrait} {
    padding: 25px;
  }
}

.featured-carousel__slide-icon {
  max-width: 96px;
  margin-bottom: 47px;

  @media #{$phone} {
    max-width: 57px;
    margin-bottom: 33px;
  }
}

.featured-carousel__slide-title {
  margin-bottom: 27px;
  @include font-size(36);
  min-height: 87px;

  @media #{$large-screen} {
    @include font-size(30);
    min-height: 66px;
  }

  @media #{$phone} {
    @include font-size(24);
    margin-bottom: 10px;
  }

  span {
    font-family: $primary-family-medium;
  }
}

.featured-carousel__slide-subtitle {
  font-family: $primary-family-medium;
  @include font-size(20);
  color: #1CE4D5;

  @media #{$phone} {
    @include font-size(14);
    margin-bottom: 10px;
  }
}

.featured-carousel__slide-description {
  @include font-size(20);
  margin-bottom: 64px;
  max-width: 260px;

  &.featured-carousel__slide-description--full-width {
    max-width: 100%;
  }

  @media #{$tablet} {
    @include font-size(18);
  }

  @media #{$phone} {
    @include font-size(14);
    margin-bottom: 20px;
  }
}
