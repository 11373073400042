.update-info {
  padding-top: clamp(5rem, 10vw, 9.5rem);
  padding-bottom: 35px;
  position: relative;
  z-index: 2;
}

.container {
  .update-info--default & {
    max-width: 1000px;
  }

  .update-info--reverse & {
    max-width: 1150px;
  }
}

.update-info__grid {
  display: flex;
  flex-wrap: wrap;

  .update-info--reverse & {
    flex-direction: row-reverse;

    @media #{$phone} {
      flex-direction: none;
    }
  }
}

.update-info__content {
  width: 53.75%;
  position: relative;
  z-index: 2;

  .update-info--reverse.update-info--03 & {
    width: 50%;
    padding-left: 65px;

    @media #{$small-screen} {
      padding-left: 30px;
    }

    @media #{$phone} {
      padding-left: 0;
      width: 100%;
    }
  }

  @media #{$phone} {
    text-align: center;
    width: 100%;
  }
}

.update-info__title {
  font-size: clamp(1.75rem, 5vw, 3rem);
  letter-spacing: -0.5px;
  line-height: calc(58/48);
  margin-bottom: 50px;

  @media #{$phone} {
    margin-bottom: 24px;
  }

  .update-info--04 & {
    max-width: 468px;

    @media #{$small-screen} {
      max-width: 100%;
    }
  }
}

.update-info__description {
  @include font-size(20);
  line-height: calc(34/20);
  width: 100%;

  color: rgba($black, .8);

  .update-info--01 & {
    max-width: 410px;

    @media #{$phone} {
      max-width: 100%;
    }
  }

  .update-info--02 & {
    max-width: 468px;

    @media #{$phone} {
      max-width: 100%;
    }
  }

  .update-info--04 & {
    max-width: 468px;

    @media #{$small-screen} {
      max-width: 100%;
    }
  }

  @media #{$phone} {
    @include font-size(16);
  }

  p {
    &:not(:last-child) {
      margin-bottom: 34px;

      @media #{$phone} {
        margin-bottom: 20px;
      }
    }
  }
}

.update-info__media {
  width: 46.25%;

  .update-info--reverse.update-info--03 & {
    width: 50%;
    padding-right: 60px;

    @media #{$desktop} {
      padding-right: 0;
    }

    @media #{$phone} {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  @media #{$phone} {
    width: 100%;
  }

  .update-info--01 & {
    padding-left: 67px;
    padding-top: 45px;

    @media #{$desktop} {
      padding-left: 30px;
    }

    @media #{$phone} {
      padding-left: 0;
      text-align: center;
      padding-top: 30px;
    }
  }


  .update-info--02 & {
    display: flex;
    justify-content: center;
    padding-left: 72px;
    margin-top: -36px;

    @media #{$desktop} {
      padding-left: 0;
    }

    @media #{$phone} {
      margin-top: 22px;
      padding-right: 20px;
    }
  }

  .update-info--03 & {
    display: flex;
    justify-content: flex-end;
    margin-top: -36px;

    @media #{$desktop} {
      padding-left: 0;
    }

    @media #{$phone} {
      margin-top: 14px;
    }
  }

  .update-info--04 & {
    margin-top: -69px;

    @media #{$phone} {
      display: flex;
      justify-content: center;
      margin-top: 14px;
    }
  }

  img {
    max-width: none;
    width: auto;
    position: relative;

    @media #{$desktop} {
      max-width: 100%;
    }

    .update-info--01 & {
      @media #{$phone} {
        max-width: 340px;
      }
    }

    .update-info--02 & {
      @media #{$desktop} {
        max-width: 860px;
      }

      @media #{$phone} {
        max-width: 580px;
      }
    }

    .update-info--03 & {
      @media #{$phone} {
        left: -12.955%;
      }

      @media screen and (max-width:560px) {
        max-width: 344px;
      }
    }

    .update-info--04 & {
      left: -28px;

      @media #{$phone} {
        left: -12.955%;
      }

      @media screen and (max-width:560px) {
        max-width: 344px;
      }
    }
  }

  video {
    width: 100%;
    height: auto;
    vertical-align: top;
  }
}


.update-info--default {
  .container {
    max-width: 1026px;
  }

  .update-info__content {
    width: calc(100% - 545px);
    align-self: center;

    @media #{$desktop} {
      width: 50%;
      max-width: 100%;
    }

    @media screen and (max-width:860px) {
      width: 100%;
      margin-bottom: 40px;
      text-align: center
    }
  }

  .update-info__media {
    max-width: 545px;
    width: 100%;
    overflow: hidden;
    position: relative;

    @media #{$desktop} {
      width: 50%;
      max-width: 100%;
    }

    @media screen and (max-width:860px) {
      max-width: 450px;
      left: -40px;
      margin: 0 auto;
      width: 100%;
    }

    @media #{$phone} {
      max-width: 310px;
    }

    video {
      position: relative;
      left: -2px;
    }
  }
}
