.heroFlex {
  .heroFlex__card {
    @media #{$phone} {
      // flex-direction: column !important;
      gap:0;
    }
  }
}
.hero.hero--june2024 {
  margin-bottom: 15px;
  .hero__title {
    strong {
      color: var(--color);
      @include font-size(340);
      letter-spacing: -0.03em !important;

      @media #{$small-screen} {
        @include font-size(260);
      }

      @media #{$tablet} {
        @include font-size(200);
      }

      @media #{$phone} {
        @include font-size(167);
      }
    }
  }

  .hero__image--june2024 {
    display: flex;
    justify-content: center;
    padding-bottom: 35px;
    @media #{$phone} {
      margin: -63px auto 0;
      padding-bottom: 14px;
    }
    img {
      width: 1376px;
      max-width: none;
      margin: -107px -5px 0 0;

      @media #{$small-screen} {
        max-width: 1024px;
        margin: -50px 0 0;
      }

      @media #{$tablet} {
        max-width: 768px;
        margin-top: -24px;
      }
      @media #{$phone} {
        max-width: 441px;
        margin-top: 0;
        margin-right: -14px;
        .heroFlex--alt & {
          margin-top: -20px;
          margin-right: 0;
          max-width: 465px;
        }
      }
    }
  }

  .hero__content {
    max-width: 626px;
    .hero__subtitle {
      letter-spacing: -0.3px;
      margin-bottom: 0;
    }
  }
}
