.accordion-items__parent {
  padding: 0 108px;
  transition: background-color 400ms ease;
  position: relative;
  z-index: 1;

  @media #{$tablet} {
    padding: 0;
  }

  &::after {
    content: "";
    position: absolute;
    left: 108px;
    right: 108px;
    bottom: 0;
    background-color: #d6d6d6;
    z-index: -1;
    height: 1px;
    transition: all 400ms ease;

    @media #{$tablet} {
      left: 20px;
      right: 20px;
    }
  }

  &.is-active {
    background-color: #f7f7f7;

    &::after {
      opacity: 0;
    }

    .accordion-items__header {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .accordion-item__header {
    padding-top: 20px;

    @media #{$tablet} {
      padding-right: 46px;
      padding-left: 0;
    }
  }

  .accordion-item__content {
    @media #{$tablet} {
      padding-left: 0;
      padding-right: 0;
    }

    ul {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.accordion-items__list {
  @media #{$tablet} {
    padding: 0 20px;
  }
}

.accordion-items__header {
  cursor: pointer;
  line-height: 1.45;
}

.accordion-items__title {
  margin-bottom: 8px;
  @include font-size(22);
  font-family: $primary-family-semibold;
  font-weight: normal;
  line-height: 1.45;

  @media #{$tablet} {
    @include font-size(18);
    margin-bottom: 0;
  }
}

.accordion-item {
  border-bottom: 1px solid #979797;

  &:last-child {
    .accordion-items__parent & {
      border-bottom: none;
    }
  }

  .accordion-items__parent & {
    border-color: #d6d6d6;
  }
}

.accordion-item__header,
.accordion-items__header {
  cursor: pointer;
  line-height: 1.45;
  font-family: $primary-family-semibold;
  position: relative;
  font-weight: normal;
  padding: 33px 0 23px;

  @media #{$tablet} {
    padding: 20px 66px 20px 20px;
  }

  &.active {
    svg {
      transform: rotate(180deg);
      color: $primary-color;
    }
  }

  svg {
    position: absolute;
    right: 0;
    top: calc(50% - 2px);
    width: 18px;
    height: 12px;
    transition: 0.2s ease;
    cursor: pointer;
    color: $brand-color--tertiary;

    @media #{$tablet} {
      width: 26px;
      right: 20px;
      top: 33px;
    }

    @media #{$phone} {
      width: 16px;
      top: 28px;
    }
  }
}

.accordion-item__title {
  margin-bottom: 8px;
  @include font-size(22);
  font-family: $primary-family-semibold;
  font-weight: normal;
  line-height: 1.45;

  .accordion-items__parent & {
    color: $brand-color--tertiary;
    font-family: $primary-family-medium;
  }

  @media #{$tablet} {
    @include font-size(18);
  }
}

.no-margin {
  line-height: inherit;
  font-size: inherit;
  font-family: inherit;
  margin: 0;
}

.accordion-item__body {
  line-height: 1.7;

  .accordion-items__parent & {
    @include font-size(20);

    @media #{$tablet} {
      @include font-size(18);
    }
    @media #{$phone} {
      @include font-size(16);
    }
  }

  a {
    color: $primary-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  ul {
    margin-bottom: 20px;
  }
}

.accordion-item__content {
  padding: 0 30px 80px 0;

  .accordion-items__parent & {
    padding-bottom: 60px;
    @media #{$phone} {
      padding-bottom: 20px;
    }
  }

  @media #{$tablet} {
    padding: 0 20px 60px;
  }

  @media #{$phone} {
    padding: 0 20px 20px;
  }
}
