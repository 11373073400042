.comparison-block {
  padding-top: 120px;
  padding-bottom: 123px;
  position: relative;
  overflow: hidden;

  @media #{$tablet} {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  @media #{$phone} {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .container {
    max-width: 1180px;
  }

  &:after {
    content: "";
    display: block;
    height: 3px;
    background-color: #EBEBEB;
    width: 1516px;
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
}

.comparison-block__header {
  text-align: center;
  max-width: 1000px;
  margin: 0 auto 80px;
  position: relative;
  z-index: 1;

  @media #{$large-screen} {
    max-width: 800px;
  }

  @media #{$phone} {
    margin-bottom: 25px;
    max-width: 290px;
  }
}

.comparison-block__header-title {
  @media #{$phone} {
    @include font-size(28);
    margin-bottom: 30px;
  }
}

.comparison-block__header-description {
  font-family: $primary-family-medium;
  @include font-size(38);
  padding: 0 40px;

  @media #{$large-screen} {
    @include font-size(28);
    padding: 0 60px;
  }

  @media #{$phone} {
    @include font-size(20);
    padding: 0;
    margin-bottom: 30px;
  }
}

.comparison-block__option {
  @include font-size(32);
  margin-top: 110px;

  @media #{$large-screen} {
    @include font-size(24);
    margin-top: 40px;
  }

  @media #{$tablet} {
    margin-top: 40px;
  }

  @media #{$phone} {
    @include font-size(18);
    margin-top: 20px;
    line-height: 1.8;
  }

  strong {
    font-family: $primary-family-medium;
  }
}

.product-dropdown {
  display: inline-block;
  position: relative;
  width: 225px;
  text-align: left;
  padding-right: 5px;
  line-height: 1.25;
  vertical-align: top;
  padding-left: 7px;

  @media #{$large-screen} {
    width: 180px;
  }

  @media #{$phone} {
    width: 145px;
  }
}

.product-dropdown__selected {
  font-family: $primary-family-medium;
  color: $primary-color;
  border-bottom: 2px dotted $primary-color;
  display: block;
  padding-right: 30px;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  font-size: inherit;
  width: 100%;
  text-align: left;
  line-height: inherit;

  svg {
    width: 15px;
    height: 13px;
    position: absolute;
    right: 4px;
    top: calc(50% - 4px);
  }
}

.product-dropdown__list {
  list-style: none;
  padding-left: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease;
  position: absolute;
  top: -6px;
  width: 95%;
  left: 6px;
  background-color: $white;
  box-shadow: 0px 24px 70px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &.product-dropdown__list--is-active {
    opacity: 1;
    visibility: visible;
  }

  a {
    color: $black;
    text-decoration: none;
  }
}

.product-dropdown__list-item {
  cursor: pointer;
  padding: 11px 17px;
  margin-top: -2px;
  @include font-size(32);
  font-family: $primary-family;
  border-radius: 0;
  position: relative;

  @media #{$large-screen} {
    @include font-size(24);
  }

  @media #{$phone} {
    @include font-size(18);
  }

  &:last-child {
    border-bottom: 0;
  }

  &:hover {
    background-color: #F7F8FA;
  }

  &.selected {
    color: $primary-color;
    font-family: $primary-family-medium;
    order: -1;

    svg {
      opacity: 1;
    }
  }

  svg {
    opacity: 0;
    width: 15px;
    height: 13px;
    position: absolute;
    right: 24px;
    top: calc(50% - 6px);
    transform: rotate(180deg);

    @media #{$phone} {
      right: 14px;
    }
  }
}

.row-header {
  font-family: $primary-family-semibold;
  @include font-size(26);
  padding: 20px;
  text-align: center;

  @media #{$phone} {
    @include font-size(20);
  }
}

.row-label {
  font-family: $primary-family-semibold;
  @include font-size(16);
  text-transform: uppercase;
  color: rgba($black, 0.7);

  @media #{$phone} {
    @include font-size(14);
  }

  @media #{$phone-portrait} {
    @include font-size(12);
  }
}

.row-label__button {
  font-family: $primary-family-semibold;
  @include font-size(16);
  border: 2px solid $primary-color;
  text-transform: none;
  text-decoration: none;
  padding: 2px 15px 2px 8px;
  border-radius: 30px;
  display: inline-block;
  color: $primary-color;
  margin-left: 17px;

  @media #{$phone} {
    margin-left: 7px;
    @include font-size(14);
  }

  @media #{$phone-portrait} {
    @include font-size(12);
  }
}

.row-value {
  @include font-size(20);
  font-family: $primary-family;
  text-align: center;
  position: relative;
  z-index: 2;
  display: block;

  @media #{$phone} {
    @include font-size(16);
    color: #494949;
  }
}

.row-discount-value {
  @include font-size(20);
  font-family: $primary-family;
  color: #979797;
  overflow: hidden;
  margin-right: 15px;
  z-index: 2;
  position: relative;

  @media #{$phone} {
    @include font-size(16);
  }
}

.row-header__value {
  position: relative;
  z-index: 2;
}

.comparison-block__table-wrap {
  padding-top: 20px;

  @media #{$phone} {
    padding-top: 0;
  }

  .table-wrapper {
    overflow: inherit;
    border-right: none;

    .pinned {
      display: none;
    }

    .scrollable {
      margin-left: 0;
      overflow: inherit;
    }
  }
}

.comparison-block__table {
  border-collapse: collapse;

  td {
    padding: 23px 54px;

    @media #{$tablet} {
      padding: 12px 24px;
    }

    @media #{$phone} {
      padding: 14px 24px;
    }

    &:nth-child(1) {
      width: 33.7%;
    }

    &:nth-child(2) {
      width: 38.6%;

      @media #{$phone} {
        width: 50%;
      }
    }

    &:nth-child(3) {
      width: 27.6%;

      @media #{$phone} {
        width: 50%;
      }
    }

    .hidden-label {
      display: none;
      color: #494949;
      text-transform: uppercase;
      @include font-size(14);
      margin-bottom: 10px;
      font-family: $primary-family-semibold;
      z-index: 2;
      position: relative;

      .row-label__button {
        @include font-size(14);
        font-family: $primary-family-semibold;
        margin-top: 6px;
        margin-left: 0;
        padding: 3px 10px;
      }

      @media #{$phone} {
        display: block;
      }
    }

    .hidden-label--dropdown {
      @media #{$phone} {
        padding-bottom: 52px;
      }
    }
  }

  tr:first-child {
    td {
      padding: 0;
    }

    td:nth-child(2) {
      border-radius: 10px 10px 0 0;
    }
  }

  tr:last-child {
    td:nth-child(2) {
      border-radius: 0 0 10px 10px;
    }
  }

  .btn {
    min-width: 257px;
    text-align: center;
    padding: 17px 20px;
    margin-bottom: 30px;

    @media #{$phone} {
      @include font-size(16);
      padding: 12px 20px;
      min-width: 151px;
    }
  }
}

.comparison-block__table td:nth-child(n+4) {
  display: none;
}

.comparison-block__table td:nth-child(2) {
  background-color: #ffffff;
  box-shadow: 0 24px 70px rgba(0, 0, 0, 0.05);
  text-align: center;
  position: relative;

  @media #{$phone} {
    text-align: left;
    white-space: normal;
  }

  .row-value {
    font-family: $primary-family-medium;

    @media #{$phone} {
      text-align: left;
      font-family: $primary-family;
    }
  }
}

.comparison-block__table td.row-header {
  text-align: center;
}

.comparison-block__table td.unit-price-cell {
  display: flex;
  justify-content: center;
  width: 100%;

  @media #{$phone} {
    display: table-cell;
  }

  img {
    position: absolute;
    top: 10px;
    transform: rotate(3.42deg);

    @media #{$phone} {
      top: 4px;
    }
  }
}

.comparison-block__table td:nth-child(2):before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: white;
  z-index: 1;
  border-radius: 10px;
}

.comparison-block__table tr {
  &:not(.tr-exclude):hover {
    background-color: #F7F8FA;
    cursor: pointer;

    td:nth-child(1) {
      border-radius: 50px 0 0 50px;
    }

    td:nth-child(3) {
      border-radius: 0 50px 50px 0;

      @media #{$phone} {
        border-radius: 0;
      }
    }

    td:nth-child(2):before {
      background-color: #F7F8FA;
      border-radius: 0;
    }
  }
}

.comparison-block__table td:nth-child(3) {
  text-align: center;

  .row-value {
    @media #{$phone} {
      text-align: left;
    }
  }
}

.comparison-block__table td:nth-child(4) {
  .row-value {
    @media #{$phone} {
      text-align: left;
    }
  }
}

.row-value__dropdown {
  position: absolute;
  top: 7px;
  left: calc(50% - 121px);
  width: 272px;
  z-index: 3;

  @media #{$tablet} {
    width: 230px;
    left: calc(50% - 115px);
  }

  @media #{$phone} {
    width: 170px;
    left: 10px;
    top: 47px;
  }
}

button.row-value__dropdown-handler {
  background-color: #FFFFFF;
  border: 2px solid #000000;
  border-radius: 68px;
  color: $black;
  height: 60px;
  padding: 10px 44px 10px 24px;
  line-height: 1;
  font-family: $primary-family-medium;
  @include font-size(18);
  text-align: left;
  display: flex;
  align-items: center;
  text-decoration: none;
  position: relative;
  z-index: 1;
  width: 100%;

  @media #{$tablet} {
    @include font-size(14);
    height: 36px;
    padding: 6px 44px 6px 24px;
  }

  @media #{$phone} {
    @include font-size(13);
    padding: 6px 24px 6px 14px;
    height: 50px;
  }

  img {
    max-width: 20px;
    margin-top: -6px;
    margin-right: 4px;
    vertical-align: middle;

    @media #{$phone} {
      display: none;
      @include font-size(12);
    }
  }

  svg {
    width: 15px;
    position: absolute;
    top: 20px;
    right: 25px;
    transition: transform 0.25s ease;

    @media #{$tablet} {
      width: 12px;
      top: calc(50% - 7px);
    }

    @media #{$phone} {
      right: 10px;
    }
  }
}

button.row-value__dropdown-handler--is-active {
  border-color: $white;
  border-radius: 20px 20px 0 0;

  svg {
    transform: rotate(180deg);
  }
}

.row-value__dropdown-list {
  list-style: none;
  padding-left: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease;
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
  background-color: $white;
  box-shadow: 0px 24px 70px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding-top: 60px;
  margin-top: -60px;
  padding-bottom: 18px;

  &.row-value__dropdown-list--is-active {
    opacity: 1;
    visibility: visible;
  }

  @media #{$tablet} {
    padding-top: 45px;
    margin-top: -45px;
  }
}

.row-value__list-item {
  cursor: pointer;
  padding: 9px 25px 8px;
  @include font-size(18);
  font-family: $primary-family-medium;
  border-radius: 0;
  text-align: left;

  &:hover {
    background-color: #F7F8FA;
  }

  &:last-child {
    border-bottom: 0;
  }

  &.selected {
    color: $primary-color;
    font-family: $primary-family-semibold;
  }

  img {
    max-width: 20px;
    margin-top: -6px;
    margin-right: 4px;
    vertical-align: middle;

    @media #{$phone} {
      display: none;
    }
  }

  @media #{$tablet} {
    @include font-size(14);
  }

  @media #{$phone} {
    @include font-size(12);
    padding: 5px 15px;
  }
}

/* Mobile */
@media only screen and (max-width: 780px) and (min-width: 768px) {
  .pinned {
    display: none;
  }
}

@media only screen and (max-width: 768px) {

  table.responsive {
    margin-bottom: 0;
  }

  .pinned {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    background: #fff;
    width: 35%;
    overflow: hidden;
    overflow-x: scroll;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
  }

  .pinned table {
    border-right: none;
    border-left: none;
    width: 100%;
  }

  .pinned table th,
  .pinned table td {
    white-space: nowrap;
  }

  .pinned td:last-child {
    border-bottom: 0;
  }

  div.table-wrapper {
    position: relative;
    margin-bottom: 20px;
    overflow: hidden;
    border-right: 1px solid #ccc;
  }

  div.table-wrapper div.scrollable {
    margin-left: 35%;
  }

  div.table-wrapper div.scrollable {
    overflow: scroll;
    overflow-y: hidden;
  }

  table.responsive td,
  table.responsive th {
    position: relative;
    white-space: nowrap;
  }

  table.responsive th:first-child,
  table.responsive td:first-child,
  table.responsive td:first-child,
  table.responsive.pinned td {
    display: none;
  }
}


.row-toggle {
  display: none;

  &.active {
    display: table-row;
  }

  .row-label {
    font-family: $primary-family;
    @include font-size(12);
    line-height: 1.4;
    display: block;
    color: rgba($black, 0.5);

    strong {
      font-family: $primary-family-medium;
      @include font-size(14);
      display: block;
      color: rgba($black, 0.7);
    }
  }
}
