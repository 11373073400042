.rejoice-video {
  background-color: $white;
  color: $white;
  position: relative;
  z-index: 1;
  padding: 26px;

  @media #{$phone} {
    padding: 20px 8px;
  }

  .container {
    padding: 220px 20px 160px;
    min-height: 946px;
    aspect-ratio: 16/9;
    max-width: 100%;
    position: relative;
    z-index: 1;

    @media screen and (min-width: 1921px) {
      aspect-ratio: 16/7.5;
    }

    @media #{$small-screen} {
      min-height: 680px;
      padding: 180px 20px 160px;
      aspect-ratio: unset;
    }

    @media #{$desktop} {
      min-height: 540px;
      padding: 120px 20px;
    }

    @media #{$phone} {
      padding: 147px 20px;
      min-height: 672px;
      border-radius: 16px;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: -1;
      border-radius: 16px;

      @media #{$tablet} {
        background: linear-gradient(180deg,
            rgba(0, 0, 0, 0.6) 0%,
            rgba(0, 0, 0, 0) 61.38%);
      }
    }
  }
}

.rejoice-video__bg {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  object-position: center bottom;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  bottom: 0;
}

.rejoice-video__content {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.rejoice-video__title {
  @include font-size(68);
  line-height: 1;
  margin-bottom: 14px;
  display: block;
  letter-spacing: -0.3px;

  @media #{$desktop} {
    @include font-size(48);
  }

  @media #{$tablet} {
    @include font-size(36);
  }

  @media #{$phone} {
    max-width: 303px;
    @include font-size(32);
  }
}

.rejoice__description {
  @include font-size(28);
  line-height: 1.4;
  letter-spacing: -0.39px;
  max-width: 728px;
  font-family: $primary-family-medium;

  @media #{$desktop} {
    @include font-size(24);
    max-width: 528px;
    line-height: 1.3;
  }

  @media #{$tablet} {
    @include font-size(20);
    max-width: 495px;
    letter-spacing: -0.3px;
  }

  @media #{$phone} {
    max-width: 267px;
  }
}
