.hero-black {
  padding: 32px 60px 2px;

  @media #{$tablet} {
    padding: 0;
  }

  .slick-dots {
    justify-content: center;
    gap: 10px;

    li {
      margin: 0;
      height: auto;
      border-radius: none;

      &.slick-active {
        button {
          outline-color: $black;
          width: 24px;

          &:hover,
          &:focus {
            outline: 2px solid $black !important;
          }

          &::after {
            transform: translateX(var(--progress));
          }
        }
      }

      button {
        height: 6px;
        width: 6px;
        outline: 2px solid #d3d3d3;
        will-change: width;
        border: none;
        overflow: hidden;

        &:hover,
        &:focus {
          border-color: none;
          outline: 2px solid #d3d3d3 !important;

          &::after {
            background-color: $black;
          }
        }

        &::before {
          display: none;
        }

        &::after {
          content: "";
          height: 6px;
          border-radius: inherit;
          left: -1px;
          top: 0;
          background-color: $black;
          position: absolute;
          z-index: 1;
          width: 28px;
          transform: translateX(-100%);
        }
      }
    }
  }


  //button

  .button {
    padding: 14px 20px;
    font-family: $primary-family-medium;
    border-radius: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: -0.3px;
    transition: scale 300ms ease;
    backface-visibility: hidden;
    will-change: scale;
    cursor: pointer;
    text-decoration: none;
    line-height: 1;
    @include font-size(16);

    &:hover,
    &:focus {
      scale: 1.03;
    }
  }

  $air-primary-color: #000;

  .button--white {
    color: $black;
    background-color: $white;
  }

  .button--black {
    color: $white;
    background-color: $black;
  }

  .button--primary {
    background-color: $air-primary-color;
    color: $white;
    padding: 16px 26px;
  }

  .button--shadow-dark {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04),
      0px 2px 12px 0px rgba(0, 0, 0, 0.06);
  }

  .button--shadow-primary {
    box-shadow: 0 4px 16px rgba($air-primary-color, 0.2);
  }

  .button--lg {
    @include font-size(18);
  }

  .button--md {
    @include font-size(16);
  }

  .button--sm {
    @include font-size(14);
    padding: 12px 24px;
  }

}

.hero-black__container {
  margin: 0 auto;
  width: 100%;
  padding: 0 20px;
  max-width: 1440px;

  .slick-list {
    // overflow: visible;
    margin: 0 -20px 24px 0 !important;

    .slick-track {
      display: flex;

      .slick-slide {
        display: flex;
        height: auto;

        >div {
          display: flex;
          width: 100%;
        }
      }
    }
  }
}

.hero-black__item {
  background-size: contain;
  background-position: right bottom;
  background-repeat: no-repeat;
  border-radius: 16px;
  min-height: 680px;
  margin-right: 20px;
  aspect-ratio: 16/7;
  padding: 137px 108px 60px;
  position: relative;
  z-index: 1;
  background-color: #ECEBE7;
  height: 100%;
  width: 100%;
  overflow: hidden;

  @media #{$large-screen} {
    aspect-ratio: auto;
  }

  @media #{$small-screen} {
    min-height: 576px;
    padding: 120px 60px 60px;
  }

  @media #{$desktop} {
    padding: 120px 40px 60px;
  }

  @media #{$tablet} {
    min-height: 360px;
    padding: 60px 30px;
  }

  @media #{$phone} {
    background-size: 768px auto;
    background-position: right bottom;
    padding: 40px 0 213px;
  }

  @media screen and (max-width: 630px) {
    background-size: auto 305px;
    background-position: 69% bottom;
  }

  &.hero-black__item--alt {
    @media #{$phone} {
      padding: 0;
      display: flex;
      width: 100%;
    }
  }
}

.hero-black__item-bg {
  background-size: cover;
  background-position: left bottom;
  background-repeat: no-repeat;
  border-radius: 16px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: block;

  @media #{$tablet} {
    background-size: 117%;
    background-position: calc(100% + 112px) 58%;
  }

  @media #{$phone} {
    background-size: 768px auto;
    background-position: right bottom;
  }

  @media screen and (max-width: 630px) {
    background-size: auto 305px;
    background-position: 69% bottom;
  }
}

.hero-black__item-grid {
  @media #{$phone} {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .button {
    @media #{$phone} {
      left: 0;
      position: absolute;
      bottom: -40px;
      z-index: 1;
    }
  }
}

.hero-black__item-content {
  width: 100%;
  max-width: 1316px;
  margin: 0 auto;
  position: relative;
  z-index: 2;

  @media #{$phone} {
    flex: 1 1 auto;
    display: flex;
    padding: 0 30px;

    .hero-black__item--alt & {
      padding: 45px 30px 0;
    }
  }
}

.hero-black__item-content-inner {
  max-width: 418px;
  width: 100%;

  @media #{$desktop} {
    max-width: 340px;
  }

  @media #{$tablet} {
    max-width: 273px;
  }

  @media #{$phone} {
    max-width: 340px;
    position: relative;
    z-index: 1;
  }
}

.hero-black__leadtext {
  font-family: $primary-family-medium;
  text-transform: uppercase;
  line-height: 1.5;
  @include font-size(18);
  font-weight: normal;
  display: block;
  margin-bottom: 6px;

  @media #{$desktop} {
    @include font-size(16);
    margin-bottom: 10px;
  }

  @media #{$tablet} {
    @include font-size(14);
    margin-bottom: 8px;
  }
}

.hero-black__title {
  letter-spacing: -0.3px;
  @include font-size(48);
  margin-bottom: 18px;
  line-height: 1.1;

  .hero-black__item--alt & {
    max-width: 402px;
  }

  @media #{$desktop} {
    @include font-size(36);
  }

  @media #{$tablet} {
    @include font-size(24);
    margin-bottom: 18px;
  }
}

.hero-black__description {
  line-height: 1.5;
  @include font-size(18);
  font-family: $primary-family;
  max-width: 278px;
  letter-spacing: -0.3px;

  .hero-black__item--alt & {
    max-width: 322px;
  }

  @media #{$desktop} {
    @include font-size(16);
  }

  @media #{$tablet} {
    line-height: 1.3;
    max-width: 262px;
  }

  &:not(:last-child) {
    margin-bottom: 101px;

    @media #{$small-screen} {
      margin-bottom: 60px;
    }

    @media #{$tablet} {
      margin-bottom: 34px;
    }

    @media #{$phone} {
      .hero-black__item-grid & {
        margin-bottom: 44px;
      }
    }
  }
}

.hero-black__item-media {
  position: absolute;
  top: 16px;
  bottom: 16px;
  left: 50%;
  right: 16px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 16px;
  padding: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  @media #{$medium-screen} {
    left: calc(100% - 58.71%);
  }

  @media #{$desktop} {
    left: calc(100% - 56%);
  }

  @media #{$tablet} {
    padding: 24px;
    left: calc(100% - 54%);
  }

  @media #{$phone} {
    position: relative;
    left: 0;
    right: 0;
    border-radius: 0 0 16px 16px;
    aspect-ratio: 358/320;
    height: auto;
    width: 100%;
    flex: 0 0 auto;
    padding: 51px 28px;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.hero-black__badge {
  height: 192px;
  width: 192px;
  background-color: #FFB317;
  border-radius: 50%;
  padding: 32px 24px;
  @include font-size(22);
  font-family: $primary-family-medium;
  letter-spacing: -0.66px;
  color: $black;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 11.43%;
  right: 13.66%;
  z-index: 1;

  @media #{$small-screen} {
    @include font-size(18);
    height: 140px;
    width: 140px;
  }

  @media #{$tablet} {
    @include font-size(13);
    height: 113px;
    width: 113px;
    padding: 16px;
  }

  @media #{$phone} {
    right: 10px;
    bottom: 156px;
    left: auto !important;
    top: auto !important;
    height: 94px;
    width: 94px;
    padding: 12px;
  }

  span {
    transform: rotate(5.711deg);
    display: block;
  }

  strong {
    @include font-size(60);
    display: block;
    letter-spacing: -1.8px;
    line-height: 1;

    @media #{$small-screen} {
      @include font-size(48);
    }

    @media #{$tablet} {
      @include font-size(36);
      letter-spacing: -.802px;
    }

    @media #{$tablet} {
      @include font-size(27);
    }
  }
}

.hero-black__badge--top-right {
  left: auto;
  top: 20%;
  right: 11.9%;
}

.hero-black__badge--top-left {
  left: 55px;
  top: 68px;
  right: auto;
  bottom: auto;

  @media #{$tablet} {
    top: 36px;
    left: 36px;
  }

  @media #{$phone} {
    right: 30px !important;
    bottom: 30px !important;
  }
}

.hero-black__item--gray {
  background-color: #F9F9F9;
}

.hero-black__item--dark {
  background-color: $black;
  color: $white;
}

.hero-black__badge-title {
  line-height: 0.8;
  letter-spacing: -0.03em;
  @include font-size(140);
  font-family: $primary-family-bold;
  font-weight: normal;
  color: $white;

  @media #{$small-screen} {
    @include font-size(100);
  }

  @media #{$desktop} {
    @include font-size(80);
  }

  @media #{$tablet} {
    @include font-size(60);
  }
}

.hero-black__offer {
  font-family: $primary-family-bold;
  line-height: 1.3;
  letter-spacing: -0.03px;

  @media #{$tablet} {
    font-family: inherit;
  }
}
