.mila-intro {
  padding: 160px 0 108px;
  text-align: center;

  @media #{$desktop} {
    padding: 120px 0;
  }

  @media #{$phone} {
    padding: 80px 0;
  }
}

.mila-intro__title {
  @include font-size(68);
  letter-spacing: -0.8px;
  line-height: 1;
  margin-bottom: 24px;
  position: relative;
  z-index: 2;

  @media #{$desktop} {
    @include font-size(48);
    letter-spacing: -0.3px;
  }

  @media #{$phone} {
    @include font-size(32);
  }

  strong {
    display: block;
    line-height: 0.99;
    @include font-size(308);

    @media #{$desktop} {
      @include font-size(220);
    }

    @media #{$phone} {
      @include font-size(124);
    }
  }
}

.mila-intro__image {
  --width: 745px;

  @media #{$desktop} {
    --width: 515px;
  }

  @media #{$phone} {
    --width: 288px;
  }

  width: var(--width);
  aspect-ratio: 745 / 757;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -175px auto 0;
  position: relative;
  z-index: 1;

  @media #{$desktop} {
    margin-top: -130px;
  }

  @media #{$phone} {
    margin-top: -86px;
  }

  img {
    pointer-events: none;
    max-width: none;
    width: calc(var(--width) * 1.35973154);
    height: auto;
  }
}

.mila-intro__content {
  max-width: 839px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 2;

  @media #{$phone} {
    max-width: 358px;
  }
}

.mila-intro__subtitle {
  @include font-size(48);
  letter-spacing: -0.8px;
  line-height: 1.1;
  margin: 0 auto 50px;
  max-width: 610px;

  @media #{$desktop} {
    @include font-size(48);
    margin-bottom: 24px;
    letter-spacing: -0.31px;
  }

  @media #{$phone} {
    @include font-size(28);
  }
}

.mila-intro__text-highlight {
  @include font-size(28);
  line-height: calc(38 / 28);
  letter-spacing: -0.3px;
  font-family: $primary-family-medium;
  max-width: 735px;
  margin: 0 auto 34px;

  @media #{$desktop} {
    @include font-size(24);
    margin-bottom: 20px;
  }

  @media #{$phone} {
    @include font-size(20);
    max-width: 320px;
    margin-bottom: 16px;
  }

  br {
    @media screen and (min-width: 769px) {
      display: none;
    }
  }
}

.mila-intro__text {
  @include font-size(22);
  line-height: 1.4;
  letter-spacing: -0.8px;
  max-width: 810px;
  margin: 0 auto;

  @media #{$desktop} {
    @include font-size(20);
    line-height: 1.3;
    letter-spacing: -0.4px;
  }

  @media #{$phone} {
    max-width: 286px;
    margin: 0 auto;
    @include font-size(16);
  }
}
