.hero--multicolor-heading {
  text-align: center;
  padding-bottom: 260px;
  padding-top: 135px;

  @media #{$tablet} {
    padding-top: 90px;
  }

  @media #{$phone} {
    padding-top: 60px;
    padding-bottom: 200px;
  }

  .hero__title {
    margin-bottom: 25px;

    @media #{$phone} {
      @include font-size(21);
    }
  }

  strong {
    @include font-size(176);

    @media #{$tablet} {
      @include font-size(110);
      letter-spacing: 0;
    }

    @media #{$phone} {
      @include font-size(66);
      letter-spacing: 0;
    }

    @media #{$phone-portrait} {
      @include font-size(50);
    }

    span {
      color: #DDDDDD;
    }
  }
}

.appointment-block {
  margin-top: -185px;
  padding-bottom: 143px;

  @media #{$phone} {
    margin-top: -155px;
    padding-bottom: 50px;
  }

  .btn--large {
    padding: 27px 50px;
    cursor: pointer;

    @media #{$phone} {
      padding: 15px 20px;
    }
  }
}

.appointment-block__inner {
  max-width: 1130px;
  margin: 0 auto;
  background-color: $white;
  padding: 77px 50px 72px;
  @include font-size(22);
  text-align: center;
  box-shadow: 0px 50px 120px rgba(0, 0, 0, 0.04);
  border-radius: 15px;
  position: relative;

  @media #{$tablet} {
    padding: 45px 30px;
  }
}

.appointment-block__content {
  @include font-size(36);
  font-family: $primary-family-bold;
  max-width: 608px;
  margin: 0 auto 40px;

  @media #{$phone} {
    @include font-size(24);
    margin-bottom: 28px;
  }

  img {
    max-width: 36px;
    display: inline-block;
    margin-top: -10px;
    vertical-align: middle;

    @media #{$phone} {
      max-width: 24px;
      margin-top: -10px;
    }
  }
}

.overlapping-block {
  padding-bottom: 180px;

  @media #{$phone} {
    padding-bottom: 40px;
  }
}

.overlapping-block__section-title {
  max-width: 380px;
  text-align: center;
  margin: 0 auto 140px;
  @include font-size(48);

  @media #{$phone} {
    @include font-size(24);
    margin-bottom: 15px;
    max-width: 210px;
  }
}

.overlapping-block__wrap {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.overlapping-block__item {
  float: left;
  width: 51%;
  margin-top: -60px;

  @media #{$phone} {
    width: 100%;
    margin-bottom: 40px;
    margin-top: 0;
  }

  &:nth-child(even) {
    float: right;
    margin-top: -300px;

    @media #{$phone} {
      margin-top: 0;
    }
  }

  .overlapping-block__image {
    max-width: 557px;
    margin-bottom: 54px;

    @media #{$phone} {
      margin: 0 auto 34px;
    }
  }

  .overlapping-block__video {
    margin-bottom: 38px;

    @media #{$phone} {
      padding-right: 70px;
      margin: 0 auto 38px;
      max-width: 450px;
    }
  }

  video {
    max-width: 390px;
    width: 100%;
    height: auto;
  }
}

.overlapping-block__title {
  @include font-size(28);
  font-family: $primary-family-medium;
  margin-bottom: 30px;

  @media #{$phone} {
    @include font-size(20);
    margin-bottom: 22px;
  }
}

.overlapping-block__description {
  @include font-size(20);
  line-height: 1.6;
  max-width: 410px;

  @media #{$phone} {
    @include font-size(16);
    max-width: 100%;
  }
}

.overlapping-block__content {
  padding-left: 110px;

  @media #{$phone} {
    padding-left: 0;
    text-align: center;
  }
}

.overlapping-block__btn-wrap {
  text-align: center;
  display: none;

  @media #{$phone} {
    display: block;
  }

  .btn--large {
    width: auto;
  }
}

.content-block--alt {
  padding-top: 115px;
  border-top: 3px solid #EBEBEB;

  @media #{$phone} {
    padding-bottom: 35px;
    padding-top: 74px;
  }

  .content-block__intro {
    margin-bottom: 35px;

    @media #{$phone} {
      width: 100%;
    }
  }

  .content-block__intro-title {
    @include font-size(48);
    max-width: 590px;
    margin: 0 auto;

    @media #{$phone} {
      @include font-size(24);
    }
  }

  .content-block__description {
    @include font-size(20);
    margin-bottom: 65px;
  }

  .content-block__intro-description {
    @include font-size(20);
    max-width: 620px;
    margin: 0 auto;
  }

  .content-block__card {
    @media #{$phone} {
      padding: 38px 30px;
      @include font-size(16);
    }
  }

  .content-block__card-title {
    @include font-size(28);

    @media #{$phone} {
      @include font-size(20);
      font-family: $primary-family-medium;
      margin-bottom: 10px;
    }
  }

  .content-block__card-description {
    @include font-size(18);
    color: rgba($black, 0.8);

    @media #{$phone} {
      @include font-size(16);
    }
  }

  .content-block__image {
    margin-bottom: 50px;

    @media #{$phone} {
      max-width: 50px;
      margin-bottom: 25px;
    }
  }
}
