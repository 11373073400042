.overlay-wrapper {
  background: linear-gradient(0deg, #fff 0%, #d7d6d7 100%);
  padding-bottom: 120px;

  @media #{$phone} {
    padding-bottom: 80px;
  }
}

.mila-overlay {
  padding-top: 130px;
  position: relative;
  z-index: 3;
  padding-bottom: 15px;

  @media #{$tablet} {
    padding-top: 80px;
  }

  @media #{$phone} {
    padding-bottom: 7px;
  }

  .container {
    max-width: 100%;
  }
}

.mila-overlay__header {
  text-align: center;
  position: relative;
  z-index: 2;
  margin-bottom: 120px;

  @media #{$desktop} {
    margin-bottom: 60px;
  }

  @media #{$tablet} {
    margin-bottom: 48px;
  }
}

.mila-overlay__title {
  letter-spacing: -0.3px;
  @include font-size(48);
  line-height: 1;
  font-family: $primary-family-bold;
  text-align: center;
  max-width: 720px;
  margin: 0 auto 34px;

  @media #{$tablet} {
    @include font-size(32);
    max-width: 361px;
  }

  @media #{$phone} {
    @include font-size(24);
    max-width: 324px;
    margin-bottom: 24px;
  }

  br {
    @media screen and (min-width: 769px) {
      display: none;
    }
  }
}

.mila-overlay__header-desc {
  @include font-size(22);
  letter-spacing: -0.3px;
  max-width: 594px;
  margin: 0 auto;

  @media #{$desktop} {
    @include font-size(18);
  }

  @media #{$phone} {
    @include font-size(16);
    max-width: 324px;
    letter-spacing: -0.4px;
  }

  strong {
    font-family: $primary-family-medium;
    @include font-size(28);
    margin-bottom: 20px;
    display: block;
    line-height: 1.3;
    letter-spacing: -0.3px;

    @media #{$tablet} {
      @include font-size(24);
    }

    @media #{$phone} {
      @include font-size(20);
      margin-bottom: 14px;
      max-width: 228px;
      margin: 0 auto 24px;
    }
  }
}

.mila-overlay__media {
  display: flex;
  justify-content: center;
  position: relative;
  object-position: center;
  max-width: 1670px;
  margin: 0 auto -25px;
  aspect-ratio: 1632 / 528;
  object-fit: contain;
  user-select: none;
  pointer-events: none;

  @media #{$desktop} {
    aspect-ratio: 1632 / 628;
  }

  @media #{$tablet} {
    aspect-ratio: 911 / 511;
    min-height: 298px;
    width: 100%;
  }

  @media #{$phone} {
    margin: 0 auto -20px;
  }

  img {
    max-width: 100%;
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(calc(-49.5%), -50%);

    @media #{$small-screen} {
      max-width: 110%;
    }

    @media #{$tablet} {
      min-width: 911px;
    }

    @media #{$phone} {
      min-width: 888px;
      transform: translate(-50%, -50%);
    }
  }
}
