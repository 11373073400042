.hero {
  overflow: hidden;
  padding-top: 330px;
  background-image: -moz-linear-gradient(left,
      rgba(196, 196, 196, 0.16) 6.89%,
      rgba(255, 255, 255, 0) 109.33%);
  /* FF3.6-15 */
  background-image: -webkit-linear-gradient(left,
      rgba(196, 196, 196, 0.16) 6.89%,
      rgba(255, 255, 255, 0) 109.33%);
  /* Chrome10-25,Safari5.1-6 */
  background-image: linear-gradient(to right,
      rgba(196, 196, 196, 0.16) 6.89%,
      rgba(255, 255, 255, 0) 109.33%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#29c4c4c4', endColorstr='#00ffffff', GradientType=1);

  /* IE6-9 */
  @media #{$tablet} {
    padding-top: 160px;
  }

  .container {
    max-width: 1680px;
  }

  .hero__image {
    img {
      text-indent: -9999px;
    }
  }
}

.hero--home-center {
  background: none;
  padding-top: 100px;

  @media #{$header} {
    padding-top: 98px;
  }

  @media #{$phone} {
    padding-top: 73px;
  }

  .container {
    @media #{$phone-portrait} {
      padding: 0;
    }
  }

  .hero__inner {
    padding-bottom: 305px;
    max-width: 1967px;
    margin: 0 auto;
    background-color: #edf0f7;

    &:before {
      content: "";
      display: block;
      width: 525px;
      height: 830px;
      background: url("assets/images/filter-boxes-left.png") no-repeat left bottom;
      position: absolute;
      top: 55px;
      left: -43px;
      background-size: contain;

      @media screen and (min-width: 2000px) {
        left: 0;
      }

      @media #{$large-screen} {
        max-width: 450px;
        left: -50px;
        height: 730px;
      }

      @media #{$small-screen} {
        top: 20px;
        height: 670px;
      }

      @media #{$desktop} {
        max-width: 400px;
        left: -50px;
      }

      @media #{$tablet} {
        max-width: 300px;
        left: -20px;
        top: 0;
        height: 640px;
      }

      @media #{$phone} {
        display: none;
      }
    }

    &:after {
      content: "";
      display: block;
      width: 570px;
      height: 834px;
      background: url("assets/images/filter-boxes-right.png") no-repeat right bottom;
      position: absolute;
      top: 55px;
      right: -40px;
      background-size: contain;

      @media screen and (min-width: 2000px) {
        right: 0;
      }

      @media #{$large-screen} {
        max-width: 450px;
        right: -50px;
        height: 730px;
      }

      @media #{$small-screen} {
        top: 20px;
        height: 670px;
      }

      @media #{$desktop} {
        max-width: 400px;
        right: -50px;
      }

      @media #{$tablet} {
        max-width: 300px;
        right: -20px;
        top: -40px;
        height: 640px;
      }

      @media #{$phone} {
        display: none;
      }
    }

    @media #{$phone} {
      padding-bottom: 255px;
    }

    .container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .hero__bottom-content {
    max-width: 760px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    z-index: 1;

    @media #{$phone} {
      max-width: 400px;
    }

    @media #{$phone-portrait} {
      padding: 0 20px;
    }
  }

  .hero__subtitle {
    @include font-size(48);
    font-family: $primary-family-bold;
    margin-bottom: 33px;
    padding: 0 25px;

    @media #{$phone} {
      padding: 0;
      @include font-size(30);
    }

    @media #{$phone-portrait} {
      @include font-size(27);
      line-height: 1.3;
      margin-bottom: 15px;
    }

    strong {
      padding: 0 3px 3px;
      display: inline-block;
      font-family: $primary-family-bold;
      background: url("assets/images/underline1.svg") no-repeat left bottom;
      background-size: contain;
    }

    strong+strong {
      background: url("assets/images/underline2.svg") no-repeat left bottom;
      background-size: contain;
    }
  }

  .hero__description {
    color: rgba($black, 0.8);
    @include font-size(26);
    font-family: $primary-family;
    line-height: calc(34 / 26);

    @media #{$phone} {
      @include font-size(20);
    }

    // @media #{$phone-portrait} {
    //   @include font-size(20);
    // }

    img {
      max-width: 26px;
      display: inline-block;
      margin-top: -8px;

      @media #{$phone-portrait} {
        max-width: 14px;
        margin-top: -5px;
      }
    }

    p {

      &:first-child {
        margin-bottom: 10px;
      }

      strong {
        font-family: $primary-family-semibold;
      }

      &.mobile-text {
        display: none;
        visibility: hidden;
      }

      @media #{$phone} {
        &.desktop-text {
          display: none;
          visibility: hidden;
        }

        &.mobile-text {
          display: inherit;
          visibility: visible;
        }
      }
    }
  }

  .hero__image {
    max-width: 442px;
    margin: -170px auto 0;
    position: relative;
    padding-bottom: 45px;

    @media #{$tablet} {
      margin: -140px auto 0;
    }

    img {
      width: 100%;
    }
  }

  &.hero--img-align {
    .hero__image--2024fab {
      top: -3px;
      right: 54px;

      @media #{$desktop} {
        top: -3px;
        right: 45px;
        margin-top: -158px;
      }

      @media #{$tablet} {
        top: 0;
        right: 0;
      }

      @media #{$phone} {
        margin-top: -140px;
      }
    }
  }

  &.hero--has-badge {
    .offer-badge {
      top: 85%;
      right: calc(98% + 2px);

      @media #{$tablet} {
        right: calc(94% + 2px);
      }
    }
  }

  &.hero--feb2024 {
    .hero__header {
      .hero__title {
        strong {
          left: -20px;
          position: relative;
          @include font-size(340);
          letter-spacing: -0.03em;
          color: var(--color);
          line-height: 0.93;

          @media screen and (max-width: 1920px) {
            @include font-size(300);
            left: 0;
          }

          @media screen and (max-width: 1440px) {
            @include font-size(220);
          }

          @media #{$phone} {
            @include font-size(167);
          }

          @media screen and (max-width: 350px) {
            @include font-size(140);
          }
        }
      }
    }

    .hero__content {
      max-width: 1122px;

      @media #{$phone} {
        max-width: 460px;
      }

      @media #{$phone-portrait} {
        max-width: 301px;
      }

      .hero__subtitle {
        padding: 0;
        margin-bottom: 25px;
        text-wrap: pretty;

        @media #{$phone} {
          margin-bottom: 15px;
        }
      }

      .hero__description {
        max-width: 886px;

        @media #{$phone} {
          max-width: 340px;
        }

        @media #{$phone-portrait} {
          max-width: 314px;
        }
      }
    }

    .hero__subtitle.hero__subtitle--alt {
      margin-top: 22px;

      @media #{$phone} {
        margin-top: 10px;
      }
    }

    .hero__description--small {
      @media #{$phone} {
        font-family: $primary-family-medium;
        max-width: 317px;
        letter-spacing: -0.02em;
        margin: 0 auto;
      }

      .for-desktop {
        @media #{$tablet} {
          display: none;
        }
      }

      .for-mobile {
        display: none;

        @media #{$tablet} {
          display: block;
        }
      }
    }
  }

  .hero__image--2024fab {
    max-width: 950px;
    margin: -260px auto 20px;
    position: relative;
    width: 100%;
    padding-bottom: 0;
    right: 0;

    @media screen and (max-width: 1920px) {
      max-width: 700px;
      margin: -210px auto 0;
      right: -50px;
    }

    @media screen and (max-width: 1440px) {
      max-width: 540px;
      margin: -165px auto 0;
      right: -41px;
    }

    @media #{$phone} {
      max-width: 372px;
      right: 0;
      margin-top: -153px;
    }
  }

  .hero__image--april2024 {
    max-width: 922px;
    margin: -88px auto 0;
    position: relative;
    width: 100%;
    padding-bottom: 0;
    right: 40px;

    @media screen and (max-width: 1920px) {
      max-width: 700px;
      margin: -73px auto 0;
      right: -15px;
    }

    @media screen and (max-width: 1440px) {
      max-width: 540px;
      margin: -56px auto 0;
      right: 1px;
    }

    @media #{$phone} {
      max-width: 372px;
      right: -11px;
      margin-top: -40px;
    }
  }

  .hero__holder {
    max-width: 1122px;
    width: 100%;
    margin: 0 auto;
    // position: relative;
    // z-index: 1;
    text-align: center;
  }

  .hero__header {
    position: relative;
    z-index: 1;
    display: inline-block;

    .hero__title {
      display: inline-block;
    }
  }

  .offer-badge {
    border-radius: 50%;
    background-color: #313131;
    height: 177px;
    width: 177px;
    text-align: center;
    color: $white;
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 75%;
    right: 89%;

    @media screen and (max-width: 1440px) {
      height: 156px;
      width: 156px;
      padding: 25px 15px;
      top: 72%;
    }

    @media #{$phone} {
      display: none;
    }
  }

  .offer-badge__original-price {
    @include font-size(28);
    line-height: calc(38 / 28);
    font-family: $primary-family-medium;
    color: #c4c4c4;
    text-decoration: none;
    position: relative;
    z-index: 1;

    @media screen and (max-width: 1440px) {
      @include font-size(24);
    }

    @media #{$tablet} {
      @include font-size(20);
    }

    &::after {
      content: "";
      background-image: url("data:image/svg+xml,%3Csvg width='74' height='19' viewBox='0 0 74 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 2C5.63189 2.09931 9.10947 2.95177 12.6404 3.76229C23.3074 6.21086 33.8818 9.01719 44.5347 11.5219C52.4049 13.3723 60.2802 15.4555 68.3001 16.5355C69.4775 16.6941 70.7987 17 72 17' stroke='%23F42D53' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;

      .hero-content__slider & {
        background-image: url("data:image/svg+xml,%3Csvg width='85' height='14' viewBox='0 0 85 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 11.4683C3.10996 12.6657 7.25333 11.4936 8.48927 11.3016C16.6833 10.0287 24.8038 8.17773 32.9785 6.75953C39.9626 5.54789 46.9447 4.2269 53.9914 3.50923C59.5475 2.94337 65.0795 3.06589 70.6395 2.75916C73.8446 2.58235 76.9153 2.09244 80.1416 2.09244C80.8449 2.09244 82.654 1.72093 83 2.46747' stroke='%23F42D53' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E%0A");
      }
    }
  }

  .offer-badge__discounted-price {
    font-family: $primary-family-semibold;
    @include font-size(42);
    line-height: calc(38 / 42);
    background-color: transparent;
    color: $white;
    display: block;
    margin-bottom: 4px;

    @media screen and (max-width: 1440px) {
      @include font-size(32);
    }

    @media #{$tablet} {
      @include font-size(28);
    }
  }

  .offer-badge__message {
    @include font-size(15);
    line-height: calc(18 / 15);
  }

  .hero__image--holiday {
    max-width: 1028px;
    margin: -112px auto 0;
    z-index: 1;

    @media #{$large-screen} {
      max-width: 900px;
    }

    @media #{$small-screen} {
      max-width: 700px;
      margin-top: -82px;
    }

    @media #{$tablet} {
      max-width: 600px;
      margin: -8% auto 0;
    }

    @media #{$phone} {
      max-width: 768px;
      margin: -19% auto 0;
    }

    @media #{$phone-portrait} {
      margin: -70px auto 0;
      left: 0;
      padding-bottom: 25px;
    }
  }

  .hero__title {
    img {
      margin-top: -12px;
    }
  }
}

.heroFlex {
  display: flex;
  flex-direction: column;

  @media #{$tablet} {
    flex-direction: column-reverse;
  }

  @media #{$phone} {
    margin-top: 0;
  }

  &:has(.heroFlex__card--default) {
    ~.price-table {
      padding-top: 0;

      @media #{$tablet} {
        padding-top: 40px;
      }
    }
  }
}

.heroFlex__card {
  background-color: #f9f9f9;
  background-color: transparent;
  border-radius: 15px;
  width: calc(100% - 40px);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 0 0 30px;
  position: relative;
  z-index: 1;

  &.heroFlex__card--default {
    background-color: transparent;
    padding: 0;

    @media #{$phone} {
      flex-direction: column;
    }
  }

  @media #{$phone} {
    flex-direction: column-reverse;
  }

  @media #{$tablet} {
    width: 100%;
    border-radius: 0;
    padding-top: 20px;
    padding-bottom: 0;
  }

  @media #{$phone} {
    flex-direction: column-reverse;
    padding-bottom: 20px;
    row-gap: 30px;
  }

  .slick-slider {
    position: static !important;
  }
}

.hero-content__slider {
  max-width: 1287px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;

  @media screen and (max-width: 1920px) {
    padding-top: 40px;
  }

  @media #{$phone} {
    padding: 0;
  }

  .slick-dots {
    display: flex;
    gap: 10px;
    position: absolute;
    right: 26px;
    bottom: 34px;
    justify-content: center;

    .heroFlex__card--default & {
      bottom: 20%;

      @media #{$desktop} {
        bottom: 28%;
      }
    }

    @media #{$tablet} {
      position: static;
      margin: 30px 0;
    }

    @media #{$phone} {
      margin: 17px 0 8px;
    }

    li {
      margin: 0;

      &.slick-active {
        button {
          border-color: var(--baseColor);
          // background-color: var(--baseColor);

          &::before {
            background-color: var(--baseColor);
            animation: move-left-to-right 6.3s linear infinite;
          }

          &:hover,
          &:focus {
            border-color: var(--baseColor);
          }
        }
      }

      button {
        border: 2px solid #d3d3d3;

        &:hover,
        &:focus {
          border-color: #d3d3d3;
        }
      }
    }
  }
}

.heroFlex--valentine {
  position: relative;
  z-index: 1;

  @media #{$tablet} {
    padding: 25px 0 0;
  }

  .hero {
    overflow: unset;
  }

  &::after {
    content: "";
    left: 20px;
    bottom: 0;
    top: 0;
    right: 20px;
    background-color: #fff3fa;
    position: absolute;
    z-index: -1;
    border-radius: 20px;

    @media #{$tablet} {
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      border-radius: 0;
    }
  }

  +.price-table {

    @media #{$phone} {
      display: none;
    }
  }
}

.hero.hero--small {
  padding-top: 0;
  margin-bottom: 50px;

  @media #{$tablet} {
    margin-bottom: 0;
  }

  .hero__title.hero__title--small {
    padding-top: 15px;
    @include font-size(48);
    letter-spacing: -0.03em;

    @media screen and (max-width: 1440px) {
      @include font-size(36);
    }

    @media #{$phone} {
      @include font-size(22);
      letter-spacing: -0.02em;
    }

    strong {
      .heroFlex--valentine & {
        color: $brand-color--secondary;
      }
    }
  }

  .hero__image.hero__image--holiday {
    @media #{$phone} {
      left: -20%;
      width: 140%;
      position: relative;
    }
  }
}

.hero.hero--jan {
  padding: 110px 0 310px;

  .hero__image.hero__image--mobile {
    display: none;
    margin-top: 20px;
    margin-bottom: 40px;

    @media #{$phone} {
      display: block;
    }
  }

  @media #{$small-screen} {
    background-size: cover;
    background-position: 20% bottom;
  }

  @media #{$tablet} {
    background-position: 30% bottom;
  }

  @media #{$phone} {
    padding: 0 0 65px;
  }

  &::before {
    display: none;
  }

  .container {
    max-width: 1340px;
    padding-right: 28px;
    padding-left: 28px;
  }

  .hero__content {
    text-align: left;
    margin: 0;
    max-width: 790px;
    font-size: 24px;

    @media #{$phone} {
      max-width: 100%;
      text-align: center;
      padding: 0;
      font-size: 22px;
    }

    p {
      max-width: 390px;
      line-height: 1.41;
      margin-bottom: 0;

      @media #{$phone} {
        max-width: 100%;
      }
    }

    .btn {
      margin-top: 100px;

      @media #{$tablet} {
        margin-top: 85px;
      }

      @media #{$phone} {
        margin-top: 55px;
      }
    }

    .desktop {
      display: block;

      @media #{$phone} {
        display: none;
      }
    }

    .mobile {
      display: none;

      @media #{$phone} {
        display: block;
      }

      @media #{$phone-portrait} {
        max-width: 300px;
        margin: 0 auto;
      }
    }

    .btn--large {
      padding: 22px 100px;
      font-size: 18px;

      @media #{$phone} {
        padding: 23px 70px;
        width: auto;
      }
    }
  }

  .hero__title.hero__title--small {
    font-size: 22px;
    margin-bottom: 30px;
    text-align: left;
    max-width: 100%;

    @media #{$phone} {
      text-align: center;
    }

    .desktop {
      display: block;

      @media #{$phone} {
        display: none;
      }
    }

    .mobile {
      display: none;

      @media #{$phone} {
        display: block;
      }
    }

    span {
      font-size: 22px;
      font-family: $primary-family-bold;
    }

    span.mobile {
      font-size: 24px;
      font-family: $primary-family-bold;
    }

    >strong {
      font-size: 75px;
      margin-top: 10px;
      margin-left: -4px;
      letter-spacing: -0.3px;
      color: $black;
      max-width: 100%;
      line-height: 0.9;

      @media #{$tablet} {
        font-size: 80px;
      }

      @media #{$phone} {
        font-size: 50px;
        margin-left: 0;
      }
    }
  }
}

.hero--home-center .hero__inner--red {
  .hero__title strong {
    color: #f42d53;
  }

  .hero__subtitle {
    strong {
      background: url("assets/images/underline1-red.svg") no-repeat left bottom;
      background-size: contain;
    }

    strong+strong {
      background: url("assets/images/underline2-red.svg") no-repeat left bottom;
      background-size: contain;
    }
  }
}

.hero--home-column {
  background: none;
  padding-top: 100px;

  @media #{$header} {
    padding-top: 73px;
  }

  .hero__inner {
    padding-top: 172px;

    @media #{$phone} {
      padding-top: 100px;
      padding-bottom: 30px;
    }
  }

  .container {
    max-width: 1100px;
    display: flex;

    @media #{$tablet} {
      flex-wrap: wrap;
    }
  }

  .hero__title-group {
    width: 60%;

    @media #{$tablet} {
      width: 100%;
    }
  }

  .hero__title {
    text-align: left;
    @include font-size(42);

    @media #{$tablet} {
      text-align: center;
    }

    @media #{$phone} {
      @include font-size(36);
    }

    strong {
      left: -20px;
      position: relative;
      @include font-size(304);
      letter-spacing: -0.03em;
      line-height: 290px;

      @media #{$tablet} {
        @include font-size(240);
        line-height: 1;
        left: 0;
      }

      @media #{$phone} {
        @include font-size(200);
      }

      @media #{$phone-portrait} {
        @include font-size(167);
      }

      @media screen and (max-width: 350px) {
        @include font-size(140);
      }
    }
  }

  .hero__subtitle {
    @include font-size(42);
    font-family: $primary-family-bold;
    margin-bottom: 17px;
    letter-spacing: -1px;
    margin-top: -23px;

    @media #{$tablet} {
      text-align: center;
      max-width: 570px;
      margin: 0 auto 30px;
    }

    @media #{$phone} {
      @include font-size(30);
      max-width: 410px;
    }

    @media #{$phone-portrait} {
      @include font-size(20);
    }

    strong {
      padding: 0 6px 6px;
      display: inline-block;
      font-family: $primary-family-bold;
      background: url("assets/images/green-line.png") no-repeat left bottom;
      background-size: contain;
    }
  }

  .hero__image {
    min-width: 522px;
    position: relative;
    left: 172px;
    margin-top: -50px;

    @media #{$tablet} {
      max-width: 382px;
      min-width: inherit;
      left: 0;
      margin: 0 auto;
    }

    @media #{$phone} {
      max-width: 302px;
    }

    @media #{$phone-portrait} {
      max-width: 262px;
    }
  }
}

.disabled__hero__slider {
  padding: 0 20px;

  @media #{$tablet} {
    padding: 0;
  }

  .slick-track {
    @media #{$tablet} {
      display: flex !important;
    }
  }

  .slick-slide {
    @media #{$tablet} {
      height: inherit !important;
    }
  }

  .slick-dots {
    position: relative;
    right: 47px;
    top: -55px;
    justify-content: flex-end;

    @media #{$small-screen} {
      top: -85px;
    }

    @media screen and (max-width: 1080px) {
      top: -55px;
    }

    @media #{$phone} {
      right: 0;
      bottom: 30px;
      justify-content: center;
    }

    li {
      button {
        border-color: $primary-color;
        overflow: hidden;
        transition: ease all 150ms;
      }

      &.slick-active button {
        border-color: $primary-color;
        transition: ease all 150ms;

        &::before {
          background-color: $primary-color;
          animation: move-left-to-right 7s cubic-bezier(0.07, 0, 0.73, 1);
        }
      }

      &:hover button {
        border-color: $primary-color;
      }
    }
  }
}

.hero--slider.hero--home-center {
  border-radius: 15px;

  @media #{$tablet} {
    border-radius: 0;
  }

  .badge-icon {
    max-width: 136px;
    position: absolute;
    top: 0;
    left: 194px;
    z-index: 1;
    color: $light-gray;

    @media #{$small-screen} {
      left: 40px;
    }

    @media #{$tablet} {
      top: 0;
      max-width: 100px;
    }

    @media #{$phone} {
      max-width: 77px;
      left: 20px;
    }
  }

  .hero__inner {
    background-color: #edf0f7;
    padding-top: 0;
    padding-bottom: 270px;
    max-width: inherit;
  }

  .hero__title {
    padding-top: 80px;

    @media #{$phone} {
      @include font-size(37);
      padding-top: 110px;
    }

    img {
      display: inline-block;
    }

    strong {
      color: $primary-color;
      @include font-size(340);

      .heroFlex--valentine & {
        letter-spacing: -0.03em;
      }

      @media #{$large-screen} {
        letter-spacing: -5px;
      }

      @media #{$small-screen} {
        @include font-size(280);
      }

      @media #{$tablet} {
        letter-spacing: initial;
      }

      @media #{$phone} {
        @include font-size(250);

        .heroFlex--valentine & {
          @include font-size(167);
        }
      }

      @media screen and (max-width: 600px) {
        @include font-size(167);
      }

      @media #{$phone-portrait} {
        @include font-size(150);

        .heroFlex--valentine & {
          @include font-size(167);
        }
      }
    }
  }

  .hero__inner:before,
  .hero__inner:after {
    display: none;
  }

  .hero__image--holiday {
    max-width: 1130px;
    margin-top: -170px;
    padding-bottom: 49px;

    .heroFlex--valentine & {
      max-width: 1338px;
      margin-top: -494px;
      padding-bottom: 0;
      right: -8px;

      @media #{$small-screen} {
        margin-top: -454px;
      }

      @media #{$desktop} {
        margin-top: -420px;
      }

      @media screen and (max-width: 1200px) {
        margin-top: -380px;
      }

      @media #{$tablet} {
        margin-top: -350px;
      }

      @media screen and (max-width: 860px) {
        margin-top: -320px;
      }

      @media #{$phone} {
        width: 446px;
        margin: -190px auto 0;
        left: 0;
        transform: none;
      }

      @media screen and (max-width: 446px) {
        transform: translateX(-50%);
        left: 50%;
      }
    }

    @media #{$small-screen} {
      margin-top: -155px;
      padding-bottom: 20px;
    }

    @media #{$tablet} {
      max-width: 960px;
    }

    @media #{$phone} {
      margin-top: -41px;
      left: 0;
    }

    @media #{$phone-portrait} {
      padding-bottom: 0px;
    }
  }

  .hero__bottom-content {
    margin: -50px auto 70px;
    z-index: 1;

    @media #{$small-screen} {
      margin-top: -70px;
    }

    @media #{$tablet} {
      display: block;
    }

    @media #{$phone} {
      max-width: 400px;
    }

    @media #{$phone-portrait} {
      padding: 40px 20px 40px;
    }
  }

  .hero__shipping {
    margin-top: 20px;
    font-size: 20px;
    line-height: 19px;
    font-weight: 600;
    text-align: center;
    display: none;

    @media #{$tablet} {
      display: block;
    }

    @media #{$phone} {
      font-size: 15px;
    }

    @media screen and (max-width: 600px) {
      font-size: 14px;
      margin-top: 18px;
    }
  }

  .hero__bottom-content--alt {
    @media #{$phone} {
      padding-bottom: 40px;
    }
  }

  .hero__subtitle {
    margin-bottom: 18px;

    strong {
      background: url("assets/images/underline1.svg") no-repeat left bottom;
      background-size: contain;

      @media #{$phone} {
        line-height: 1;
      }
    }

    strong+strong {
      background: url("assets/images/underline2.svg") no-repeat left bottom;
      background-size: contain;

      @media #{$phone} {
        line-height: 1;
      }
    }
  }
}

.hero__slider--content {
  display: none;

  @media #{$tablet} {
    display: none;
  }

  &.slide--active {
    display: block;

    @media #{$tablet} {
      display: none;
    }
  }

  .hero__bottom-content {
    margin: 0 auto 70px;
    z-index: 1;
    max-width: 700px;
    text-align: center;
  }

  .hero__subtitle {
    margin-bottom: 23px;
    font-size: 3rem;
    font-family: $primary-family-bold;

    strong {
      background: url("assets/images/underline1.svg") no-repeat left bottom;
      background-size: contain;
      padding-bottom: 5px;
    }

    strong+strong {
      background: url("assets/images/underline2.svg") no-repeat left bottom;
      background-size: contain;
      padding-bottom: 5px;
    }
  }

  .hero__description {
    color: rgba($black, 0.8);
    @include font-size(26);
    font-family: $primary-family;

    @media #{$phone} {
      @include font-size(20);
    }

    @media #{$phone-portrait} {
      @include font-size(14);
    }

    img {
      max-width: 26px;
      display: inline-block;
      margin-top: -8px;

      @media #{$phone-portrait} {
        max-width: 14px;
        margin-top: -5px;
      }
    }

    p {
      &:first-child {
        margin-bottom: 10px;
      }

      strong {
        font-family: $primary-family-semibold;
      }
    }
  }
}

.hero--inventions.hero--home-center {
  .hero__inner {
    @media #{$phone} {
      padding-top: 107px;
      padding-bottom: 10px;
    }
  }

  .badge-icon {
    max-width: 136px;
    position: absolute;
    top: 0;
    left: 194px;
    z-index: 1;
    color: $light-gray;

    @media #{$small-screen} {
      left: 40px;
    }

    @media #{$tablet} {
      top: 0;
      max-width: 100px;
    }

    @media #{$phone} {
      max-width: 77px;
      top: -107px;
      left: 26px;
    }
  }

  .hero__title {
    @media #{$phone} {
      @include font-size(37);
      padding-top: 0;
    }

    strong {
      color: #f42c52;
      @include font-size(340);

      @media #{$large-screen} {
        letter-spacing: -8px;
      }

      @media #{$small-screen} {
        @include font-size(280);
        letter-spacing: -8px;
      }

      @media screen and (max-width: 600px) {
        @include font-size(167);
      }
    }

    img {
      @media #{$phone} {
        max-width: 36px;
      }
    }
  }

  .hero__inner:before {
    display: none;
  }

  .hero__inner:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: url("assets/images/snow.png") repeat 0 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    right: auto;
    max-width: inherit;
  }

  .hero__image--holiday {
    max-width: 1450px;
    margin-top: -125px;
    left: -14px;
    position: relative;
    min-height: 927px;

    img {
      position: absolute;
      top: 0;
      left: 0;

      @media #{$phone} {
        width: 100%;
      }
    }

    @media #{$small-screen} {
      margin-top: -100px;
      min-height: inherit;
      padding-bottom: 72%;
      left: -20px;
    }

    @media #{$tablet} {
      margin-top: -80px;
      left: -10px;
    }

    @media #{$phone} {
      margin-top: -305px;
      left: 0;
      padding-bottom: 80%;
    }

    @media screen and (max-width: 600px) {
      margin-top: -226px;
    }

    @media #{$phone-portrait} {
      margin-top: -158px;
      padding-bottom: 82%;
    }
  }

  .hero__bottom-content {
    margin: -305px auto 70px;
    max-width: 580px;
    position: relative;
    z-index: 3;

    @media #{$small-screen} {
      margin-bottom: 40px;
    }

    @media #{$tablet} {
      margin-top: -255px;
    }

    @media #{$phone} {
      max-width: 400px;
      margin-top: 140px;
      margin-bottom: 0;
    }

    @media #{$phone-portrait} {
      padding: 0 20px;
      margin-top: 110px;
    }
  }

  .hero__subtitle {
    margin-bottom: 13px;
    padding: 0;

    strong {
      background: url("assets/images/underline1-red.svg") no-repeat left bottom;
      background-size: contain;

      @media #{$phone-portrait} {
        padding-bottom: 0;
      }
    }

    strong+strong {
      background: url("assets/images/underline2-red.svg") no-repeat left bottom;
      // background-size: contain;
      padding-bottom: 7px;

      @media #{$phone-portrait} {
        padding-bottom: 0;
      }
    }
  }

  .hero__description {
    max-width: 520px;
    margin: 0 auto;

    strong {
      display: none;

      @media #{$phone} {
        display: block;
        margin-top: 5px;
      }
    }
  }
}

.hero--intl.hero--home-center {
  .hero__image--holiday {
    left: -34px;

    @media #{$tablet} {
      margin-top: -90px;
      left: -30px;
    }

    @media #{$tablet} {
      left: -22px;
    }
  }
}

.hero--dark.hero--home-center {
  background-color: #171717;

  .hero__inner {
    background-image: url("assets/images/hero-image.webp");
    background-size: 1165px auto;
    background-position: top center;
    background-repeat: no-repeat;
    background-color: #171717;
    padding-bottom: 40px;
    border-radius: 0;

    @media #{$tablet} {
      background-size: contain;
      padding-top: 0;
      padding-bottom: 0;
    }

    @media #{$phone} {
      background-image: url("assets/images/mobile-mila-hero.webp");
      background-size: contain;
      padding-top: 0;
      padding-bottom: 0;
    }

    >.desktop-hero {
      display: none;

      @media #{$tablet} {
        display: block;
        opacity: 0;
      }

      @media #{$phone} {
        display: none;
      }
    }

    >.mobile-hero {
      display: none;

      @media #{$phone} {
        display: block;
        opacity: 0;
      }
    }
  }

  .badge-icon {
    max-width: 136px;
    position: absolute;
    top: 0;
    left: calc(50% - 645px);
    z-index: 1;
    color: $light-gray;

    @media #{$small-screen} {
      left: 40px;
    }

    @media #{$tablet} {
      max-width: 100px;
    }

    @media #{$phone} {
      max-width: 77px;
      left: 26px;
    }
  }

  .hero__title {
    display: block;
    color: #fff;
    margin-top: -90px;
    @include font-size(38);

    img {
      max-width: 38px;

      @media #{$phone} {
        max-width: 30px;
        margin-top: -8px;
      }
    }

    @media #{$tablet} {
      position: absolute;
      top: 5%;
      left: 0;
      width: 100%;
      margin-top: 0;
    }

    @media #{$phone} {
      @include font-size(32);
      top: 18%;
    }

    strong {
      color: #e94e3b;
      @include font-size(340);

      @media #{$large-screen} {
        letter-spacing: -8px;
      }

      @media #{$small-screen} {
        @include font-size(280);
        letter-spacing: -8px;
      }

      @media #{$phone} {
        @include font-size(240);
      }

      @media screen and (max-width: 600px) {
        @include font-size(167);
      }
    }
  }

  .hero__inner:before,
  .hero__inner:after {
    display: none;
  }

  .hero__image--holiday {
    max-width: 1302px;
    margin-top: -360px;
    left: 40px;
    position: relative;
    min-height: 927px;

    img {
      opacity: 0;

      @media #{$phone} {
        opacity: 1;
      }
    }

    @media #{$tablet} {
      margin-top: -260px;
      min-height: inherit;
      display: none;
    }

    @media #{$phone} {
      min-height: inherit;
      height: 641px;
      margin: 0;
      left: 0;
    }

    @media #{$phone-portrait} {
      margin-top: -125px;
      padding-bottom: 82%;
    }
  }

  .hero__bottom-content {
    margin: 110px auto 0;
    z-index: 1;
    max-width: 970px;
    padding: 0 20px;

    @media #{$small-screen} {
      margin-bottom: 20px;
    }

    @media #{$tablet} {
      margin-top: -95px;
      position: absolute;
      bottom: 20px;
      width: 100%;
    }

    @media #{$phone} {
      margin-top: -130px;
      margin-bottom: 20px;
    }

    @media #{$phone-portrait} {
      padding: 0 10px;
      bottom: 0;
    }
  }

  .hero__subtitle {
    margin-bottom: 23px;
    padding: 0;
    color: $white;

    @media #{$tablet} {
      @include font-size(26);
      margin-bottom: 13px;
    }

    @media #{$phone-portrait} {
      margin-bottom: 13px;
    }

    strong {
      background: url("assets/images/underline1.svg") no-repeat left bottom;
      background-size: contain;

      @media #{$phone-portrait} {
        padding-bottom: 0;
      }
    }

    strong+strong {
      background: url("assets/images/underline2.svg") no-repeat left bottom;
      background-size: contain;
      padding-bottom: 7px;

      @media #{$phone-portrait} {
        padding-bottom: 0;
      }
    }
  }

  .hero__description {
    max-width: 640px;
    margin: 0 auto;
    color: rgba($white, 0.8);

    @media #{$tablet} {
      @include font-size(20);
    }

    strong {
      display: none;

      @media #{$phone} {
        display: block;
        margin-top: 5px;
      }
    }
  }

  .hero__description--desktop {
    display: block;

    @media #{$phone} {
      display: none;
      @include font-size(14);
    }
  }

  .hero__description--mobile {
    display: none;

    @media #{$phone} {
      display: block;
      @include font-size(14);
    }
  }

  +.thumbnail-block {
    margin: 80px 70px;

    @media #{$tablet} {
      margin: -25px 0 0;
    }

    @media #{$phone} {
      margin: 0;
    }
  }
}

.hero__description--mobile {
  display: none;

  @media #{$phone} {
    display: block;
    @include font-size(14);
  }
}

.hero--dark-us.hero--home-center {
  background-color: #171717;

  .hero__inner {
    background-image: url("assets/images/mila-hero-us.jpg");

    @media #{$phone} {
      background-image: url("assets/images/mobile-mila-hero-us.jpg");
    }
  }

  .hero__description--desktop {
    display: block;

    @media #{$phone} {
      display: block;
    }
  }

  .hero__subtitle--desktop {
    display: block;

    @media #{$phone} {
      display: none;
    }
  }

  .hero__subtitle--mobile {
    display: none;

    @media #{$phone} {
      display: block;
    }
  }
}

.hero--top-bar {
  padding-top: 0 !important;
}

.hero__inner {
  background-color: $light-gray;
  border-radius: 15px;
  margin: 10px 20px 0;
  padding-top: 150px;
  padding-bottom: 295px;
  position: relative;

  @media #{$tablet} {
    padding-bottom: 70px;
    margin: 0;
    border-radius: 0;
    padding-top: 120px;
  }

  @media #{$phone} {
    padding-top: 77px;
  }

  &.hero__inner--red {
    background-color: #fff3fa;
    padding-bottom: 420px;

    @media #{$phone} {
      padding-bottom: 335px;
    }
  }
}

.shipping-badge {
  border-radius: 0 0 60px 60px;
  background-color: #262626;
  color: $white;
  @include font-size(15);
  text-align: center;
  font-family: $primary-family;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: calc(50% - 195px);
  width: 390px;
  padding: 18px;

  @media #{$phone-portrait} {
    left: calc(50% - 140px);
    width: 280px;
    padding: 10px 30px;
  }

  img {
    max-width: 16px;
    margin-top: -4px;
  }

  p {
    margin-bottom: 0;
    line-height: 1.2;
  }

  strong {
    text-transform: uppercase;
    @include font-size(15);
    line-height: 1.3;
    font-family: $primary-family-semibold;
    margin-bottom: 3px;

    @media #{$phone} {
      font-family: $primary-family-medium;
    }
  }
}

.discount-badge {
  border-radius: 50%;
  background-color: #262626;
  color: $white;
  @include font-size(25);
  text-align: center;
  font-family: $primary-family;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 65px;
  right: calc(50% - 645px);
  width: 215px;
  height: 215px;
  padding: 18px;
  z-index: 4;
  line-height: 1;

  @media #{$desktop} {
    right: 5%;
    width: 180px;
    height: 180px;
    padding: 10px 30px;
    @include font-size(18);
  }

  @media #{$tablet} {
    display: none;
  }

  strong {
    text-transform: uppercase;
    @include font-size(70);
    line-height: 1.1;
    font-family: $primary-family-bold;
    margin-bottom: 3px;

    @media #{$desktop} {
      @include font-size(50);
    }

    @media #{$phone} {
      font-family: $primary-family-medium;
    }
  }
}

.hero__title {
  @include font-size(36);
  text-align: center;
  line-height: 1;
  max-width: 1000px;
  margin: 0 auto;

  @media #{$small-screen} {
    @include font-size(32);
  }

  @media #{$phone} {
    @include font-size(30);
  }

  @media #{$phone-portrait} {
    @include font-size(24);
  }

  img {
    max-width: 46px;

    @media #{$small-screen} {
      max-width: 36px;
    }

    @media #{$phone} {
      max-width: 30px;
    }

    @media #{$phone-portrait} {
      max-width: 24px;
    }

    &.waving-hand {
      animation: rotate-left-to-right 0.7s linear 2 forwards;
      position: relative;
      left: -2px;
      top: -5px;
      transform-origin: 88% 74%;

      @media #{$phone} {
        top: -2px;
      }
    }
  }

  strong {
    @include font-size(310);
    color: $primary-color;
    display: block;
    max-width: 1057px;
    margin: 0 auto;
    letter-spacing: -0.03em;
    line-height: 1;

    @media #{$large-screen} {
      @include font-size(280);
      letter-spacing: -8px;
    }

    @media #{$small-screen} {
      @include font-size(200);
      letter-spacing: -8px;
    }

    @media screen and (max-width: 600px) {
      @include font-size(160);
    }

    @media #{$phone-portrait} {
      @include font-size(120);
    }
  }
}

@keyframes rotate-left-to-right {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(-20deg);
  }

  75% {
    transform: rotate(20deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.hero--april2024 {
  .hero__subtitle {
    margin: 10px 0 22px;

    @media #{$phone} {
      margin-bottom: 12px;
    }
  }

  .hero__content {
    @media #{$phone} {
      max-width: 303px !important;
      width: 100%;
      margin: 0 auto;
    }

    .hero__description.hero__description--small {
      @media #{$phone} {
        @include font-size(18);
        line-height: calc(24 / 18);
        letter-spacing: -0.02em;
      }
    }
  }
}

.hero--hw {
  .hero__holder {
    max-width: 1024px !important;
  }

  .hero__image--2024fab {
    @media #{$phone} {
      margin-top: -103px;
    }
  }

  .hero__content {
    max-width: 100% !important;

    .hero__subtitle {
      letter-spacing: -0.3px;

      @media #{$phone} {
        max-width: 303px !important;
      }
    }

    .hero__description {
      max-width: 855px !important;

      @media #{$phone} {
        font-family: $primary-family-medium;
        @include font-size(20);
        letter-spacing: -0.02em;
        max-width: 311px !important;
      }
    }
  }
}

.hero__description-mobile {
  display: none;

  @media #{$phone} {
    display: block;
  }
}

.hero__description-desktop {
  @media #{$phone} {
    display: none;
  }
}

//memorial-day

.heroFlex__card {
  @media screen and (max-width: 1920px) {
    padding-bottom: 50px;
  }

  @media #{$phone} {
    padding: 20px 0;
  }
}

.hero.hero--memorial-day {
  margin-bottom: 0;

  .hero__header {
    z-index: auto;
  }

  .hero__holder {
    max-width: 1400px;
  }

  .offer-badge {
    z-index: 9;
    top: 91%;
    right: 95%;
  }

  .hero__image {
    max-width: 100% !important;
    right: 0 !important;
    margin: -385px auto 0;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 1920px) {
      margin-top: -350px;
    }

    @media #{$small-screen} {
      margin-top: -273px;
    }

    @media #{$tablet} {
      margin-top: -198px;
      margin-bottom: 24px;
    }

    @media screen and (max-width: 536px) {
      margin-top: -145px;
    }

    img {
      @media screen and (max-width: 536px) {
        width: 536px;
        max-width: none !important;
      }
    }
  }

  .hero__subtitle.hero__subtitle--alt {
    margin-bottom: 0;

    @media #{$phone} {
      @include font-size(23);
    }
  }

  .hero__content {
    max-width: 100%;
  }
}
