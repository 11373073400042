.hero-image {
  height: 735px;
  background-size: cover;
  background-position: center center;

  @media #{$phone} {
    height: 450px;
  }
}



.header--reverse+.main {
  padding-top: 0 !important;
}

.default-content--wrapper {
  margin-left: 85px;
  margin-top: -230px;
  background-color: #fff;
  padding-top: 105px;
  width: calc(100% - 85px);

  @media #{$tablet} {
    width: 100%;
    margin-left: 0;
    margin-top: 0;
    padding-top: 0;
  }
}

.support-ukraine {
  height: 930px;
  background-size: cover;
  background-position: center center;
  border-radius: 15px;
  padding-bottom: 80px;
  background-image: url(assets/images/flag-ukraine.jpg);

  @media #{$small-screen} {
    height: 810px;
  }

  @media #{$tablet} {
    border-radius: 0;
    height: 810px;
  }

  h2 {
    color: $white;
  }

  .container {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }

  .btn {
    padding: 24px 37px 23px 35px;
    text-align: center;
    max-width: 280px;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25), 0px 11px 30px rgba(0, 0, 0, 0.3);

    @media #{$phone} {
      padding: 14px 37px 13px 35px;
      max-width: 240px;
    }
  }
}
