.sticky-scroll {
  z-index: 2;
  width: 100%;
  position: relative;

  &.sticky-scroll--fixed {
    position: fixed;
    left: 0;
    opacity: 1;
    transition: all 500ms cubic-bezier(.09, .47, .18, .99);

    @media #{$phone} {
      top: 0;
      display: block;
    }

    .sticky-navigation {
      box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.08);
    }

    .scroll-up & {
      transform: translateY(-105%);
      box-shadow: none;
      opacity: 0;
      z-index: 10;
    }
  }

  @media #{$desktop} {
    left: 20px;
    width: calc(100% - 40px);
  }

  @media #{$tablet} {
    left: 12px;
    width: calc(100% - 24px);
  }

  @media #{$phone} {
    display: none;
    width: 100vw;
    top: 0;
    left: 0;
  }
}

.sticky-navigation--center {
  justify-content: center;
  min-height: 80px;
  padding: 21px 200px;
  position: relative;

  @media #{$phone} {
    width: calc(100% - 18px);
    padding: 6px 20px;
    min-height: 56px;
    justify-content: flex-start;
  }

  .sticky-navigation__item {
    padding: 0 22px;

    @media #{$phone} {
      width: 110px;
    }
  }

  .sticky-navigation__item--logo {
    position: absolute;
    left: 37px;

    @media #{$phone} {
      position: static;
      width: auto;
    }

    a {
      @include font-size(16);

      @media #{$phone} {
        @include font-size(14);
      }
    }
  }

  .sticky-navigation__item--button {
    position: absolute;
    right: 0;

    @media #{$phone} {
      position: static;
    }

    button {
      color: $white;
      @include font-size(16);
      font-family: $primary-family-semibold;
      padding: 12px 47px;

      @media #{$phone} {
        margin-right: 10px;
      }
    }
  }
}

.sticky-scroll--fullwidth {
  &.sticky-scroll--is-open {
    @media #{$phone} {
      box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.08);
      border-radius: 0 0 20px 20px;
    }
  }

  .sticky-navigation {
    padding: 23px 42px;
    max-width: 1322px;
    justify-content: space-between;
    border-radius: 85px;
    box-shadow: none;

    @media #{$desktop} {
      padding: 16px 40px;
    }

    @media #{$phone} {
      display: none;
      box-shadow: none;
      margin-top: -8px;
      padding: 20px 20px 12px;
      border-radius: 0 0 15px 15px;
      border: none;
    }
  }

  .sticky-navigation__item--logo {
    @media #{$phone} {
      display: none;
    }

    img {
      max-width: 36px;
    }
  }
}

.sticky-scroll__toggle {
  display: none;
  background-color: $white;
  padding: 18px 20px;
  max-width: 100%;
  border-radius: 0 0 20px 20px;
  box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.08);
  text-decoration: none;
  color: $black;
  width: 100%;

  @media #{$phone} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 40px;
  }

  @media #{$phone-portrait} {
    padding: 14px 20px;
  }

  &.sticky-scroll__toggle--is-active {
    @media #{$phone} {
      border-radius: 0;
      box-shadow: none;
    }

    .sticky-scroll__toggle-arrow {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.sticky-scroll__toggle-logo {
  img {
    max-width: 28px;

    @media #{$phone} {
      max-width: 36px;
    }
  }
}

.sticky-scroll__toggle-arrow {
  width: 28px;
  text-align: right;

  svg {
    max-width: 11px;
    transition: all 0.25s ease;
  }
}

.sticky-scroll__active-text {
  @include font-size(14);
  font-family: $primary-family-semibold;
}

.sticky-navigation {
  background-color: $white;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.08);
  border-radius: 57px;
  display: flex;
  align-items: center;
  max-width: 1322px;
  margin: 0 auto;
  padding: 21px 46px;
  justify-content: space-between;

  &.sticky-navigation--is-active {
    @media #{$phone} {
      display: block;
      padding-top: 0;
    }
  }

  @media screen and (min-width: 768px) {
    width: auto !important;
  }

  @media #{$tablet} {
    padding: 13px 20px;
  }

  @media #{$phone} {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding: 13px 40px 13px 20px;
  }
}

.sticky-navigation__item {
  @include font-size(16);
  list-style: none;
  padding: 0 14px;

  @media #{$desktop} {
    @include font-size(14);
    padding: 0 8px;
  }

  @media #{$tablet} {
    padding: 0 4px;
  }

  @media #{$phone} {
    text-align: center;
    padding: 13px 0;
  }

  &.sticky-navigation__item--active {
    @media #{$phone} {
      display: none;
    }

    button {
      color: $black;
      font-family: $primary-family-semibold;
    }
  }

  button {
    font-family: $primary-family-medium;
    color: rgba($black, 0.3);
    text-decoration: none;
    font-size: 1rem;

    @media #{$desktop} {
      @include font-size(14);
    }

    @media #{$phone} {
      color: $black;
      font-family: $primary-family-semibold;
    }

    &:before {
      @media #{$phone} {
        content: "";
        display: block;
        width: 20px;
        height: 2px;
        background-color: #EFEFEF;
        margin: 0 auto;
        position: relative;
        top: -12px;
      }
    }
  }
}

.sticky-navigation__item--logo {
  padding-left: 43px;
  padding-right: 40px;
  position: relative;
  left: -60px;

  @media #{$desktop} {
    left: 40px;
    padding-left: 0;
    position: absolute;
  }

  @media #{$tablet} {
    padding-right: 20px;
  }

  @media #{$phone} {
    padding-right: 20px;
    width: auto;
  }

  img {
    min-width: 32px;
    max-width: 40px;

    @media #{$phone} {
      max-width: 32px;
    }
  }
}

.filter-main__fillgap {
  display: flex;
}
