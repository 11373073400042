.product-hero {
  padding: 0 30px;

  @media #{$tablet} {
    padding: 0;
  }
}

.product-hero__card {
  padding: 65px 0 157px 30px;
  background-color: #F7F8FA;
  border-radius: 16px;

  @media #{$tablet} {
    border-radius: 0;
  }

  @media #{$tablet} {
    padding-bottom: 100px;
  }

  @media #{$phone} {
    padding: 30px 0 80px;
  }
}

.product-awards {
  margin-bottom: 64px;

  @media #{$small-screen} {
    margin-bottom: 30px;
  }

  @media screen and (max-width:1200px) {
    margin-right: -160px;
  }

  @media #{$tablet} {
    margin: 0 auto;
    justify-content: center;
  }

  @media #{$phone} {
    margin: 0 -20px;
  }

  .slick-list {
    overflow: visible !important;
    width: 100%;

    .slick-track {
      display: flex !important;

      @media #{$tablet} {
        justify-content: center;
      }

      @media #{$phone} {
        display: block !important;
      }

      .slick-slide {
        transition: opacity 300ms ease;

        @media #{$phone} {
          opacity: .5;
        }

        &:not(:last-child) {
          margin-right: 40px;

          @media #{$phone} {
            margin-right: 0;
          }
        }


        &.slick-current {
          @media #{$phone} {
            opacity: 1;
          }
        }
      }
    }
  }
}

.product-awards__item {
  width: auto !important;

  @media #{$phone} {
    padding: 0 24px;
  }
}

.product-awards__item-image {
  img {
    @media #{$phone} {
      max-height: 48px;
    }
  }
}

.for-small-screen {
  display: none;

  @media #{$tablet} {
    display: flex;
  }
}

.for-large-screen {
  @media #{$tablet} {
    display: none;
  }
}

.product-hero__grid {
  display: flex;
  flex-wrap: wrap;

  @media #{$tablet} {
    flex-direction: column-reverse;
  }
}

.product-hero__content {
  max-width: 744px;

  @media #{$small-screen} {
    max-width: 100%;
    width: 55%;
  }

  @media #{$tablet} {
    width: 100%;
    text-align: center;
    max-width: 640px;
    margin: 0 auto;
  }

  @media #{$phone} {
    text-align: left;
  }
}

.product-hero__title {
  line-height: 1;
  @include font-size(96);
  letter-spacing: -0.3px;
  color: $black;
  font-family: $primary-family-bold;
  margin-bottom: 32px;

  @media #{$small-screen} {
    @include font-size(80);
  }

  @media screen and (max-width:1200px) {
    @include font-size(56);
  }

  @media #{$phone} {
    @include font-size(46);
    margin-bottom: 24px;
  }
}

.product-hero__description {
  @include font-size(28);
  line-height: calc(42/28);

  @media #{$small-screen} {
    @include font-size(24);
  }

  @media screen and (max-width:1200px) {
    @include font-size(22);
  }

  @media #{$phone} {
    @include font-size(20);
  }
}

.product-hero__media {
  width: calc(100% - 774px);
  margin-top: -65px;

  @media screen and (max-width:1679px) {
    margin-top: 0;
  }

  @media #{$small-screen} {
    width: 45%;
  }

  @media screen and (max-width:1200px) {
    align-self: flex-end;
    margin-top: 80px;
  }

  @media #{$tablet} {
    margin: 0 auto;
    width: 100%;
  }
}

.product-hero__image {
  @media #{$tablet} {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  img {
    max-width: none;
    width: 717px;

    @media screen and (max-width:1679px) {
      width: 600px;
    }

    @media #{$small-screen} {
      width: 100%;
    }

    @media #{$tablet} {
      max-width: 520px;
    }
  }
}

.above-tablet-screen {
  @media #{$tablet} {
    display: none;
  }
}

.below-tablet-screen {
  display: none;

  @media #{$tablet} {
    display: block;
  }
}
