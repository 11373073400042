.faq-block {
  padding: 100px 0 310px;

  @media #{$large-screen} {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  @media #{$phone} {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .container {
    max-width: 1110px;
  }
}

.faq-block__title {
  text-align: center;
  margin-bottom: 110px;

  @media #{$large-screen} {
    margin-bottom: 50px;
  }

  @media #{$phone} {
    margin-bottom: 30px;
    @include font-size(28);
  }
}

.faq-block__list {
  list-style: none;
  padding-left: 0;
}

.faq-accordion {
  background-color: #FFFFFF;
  box-shadow: 0px 24px 70px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 50px 55px 52px 55px;
  margin-bottom: 30px;

  @media #{$tablet} {
    padding: 20px 25px 22px 30px;
    margin-bottom: 15px;
  }

  @media #{$phone} {
    padding: 20px 15px 22px 20px;
  }

  h3 {
    padding: 10px 55px 17px 0;
    @include font-size(24);
    cursor: pointer;
    font-family: $primary-family-semibold;
    position: relative;

    @media #{$tablet} {
      @include font-size(20);
      margin-bottom: 0;
      padding-right: 30px;
    }

    @media #{$phone} {
      @include font-size(18);
    }

    svg {
      width: 35px;
      position: absolute;
      top: calc(50% - 14px);
      right: 0;
      transition: transform 0.3s ease;

      @media #{$tablet} {
        width: 20px;
        right: 2px;
      }
    }
  }

  p {
    @include font-size(24);
    line-height: 1.7;

    @media #{$large-screen} {
      @include font-size(20);
    }

    @media #{$tablet} {
      @include font-size(16);
    }

    a {
      color: $primary-color;
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}

.faq-accordion--is-active {
  h3 svg {
    transform: rotate(180deg);
  }
}
