.info-block {
  padding: 38px 0;

  @media #{$phone} {
    padding-bottom: 0;
  }

  .container {
    max-width: 1320px;
  }
}

.info-block__grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-right: 30px;

  @media screen and (max-width:860px) {
    justify-content: center;
    padding-right: 0;
    flex-direction: column-reverse;
  }

}

.info-block__media {
  max-width: 545px;

  @media #{$desktop} {
    width: 50%;
    max-width: 100%;
  }

  @media screen and (max-width:860px) {
    width: 100%;
    margin-bottom: 40px;
  }
}

.info-block__video {
  position: relative;
  z-index: 2;
  bottom: -2px;
  overflow: hidden;

  @media #{$desktop} {
    left: -40px;
  }

  @media screen and (max-width:860px) {
    max-width: 450px;
    left: -40px;
    margin: 0 auto;
  }

  @media #{$phone} {
    max-width: 310px;
  }

  video {
    width: 100%;
    height: auto;
    vertical-align: top;
    position: relative;
    left: -2px;
  }
}


.info-block__content {
  max-width: 575px;
  width: 100%;
  align-self: center;

  @media #{$desktop} {
    width: 50%;
  }

  @media screen and (max-width:860px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.info-block__title {
  font-size: clamp(1.75rem, 4vw, 3rem);
  line-height: calc(58/48);
  letter-spacing: -.3px;
  font-family: $primary-family-bold;
  margin-bottom: 32px;
  max-width: 516px;

  @media #{$tablet} {
    margin-bottom: 20px;
  }

  @media #{$phone} {
    max-width: 270px;
    margin: 0 auto 26px;
  }
}


.info-block__card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 23px;

  @media screen and (max-width:860px) {
    margin-bottom: 46px;
  }

  @media screen and (max-width:640px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 25px;
  }
}

.info-block__description {
  @include font-size(20);
  line-height: calc(34/20);
  color: rgba($black, .8);
  max-width: 455px;
  margin-bottom: 66px;

  @media #{$phone} {
    @include font-size(16);
    line-height: calc(25/16);
    letter-spacing: -.26px;
    color: $black;
    margin-bottom: 50px;
  }
}

.info-block__card {
  padding: 40px 40px 50px;
  border: 2.563px solid #D9D9D9;
  border-radius: 20px;

  @media #{$desktop} {
    padding: 30px 25px 40px;
  }

  @media #{$phone} {
    padding: 50px 40px;
  }
}

.info-block__card-title {
  @include font-size(25);
  line-height: calc(35/25);
  font-family: $primary-family-semibold;

  @media #{$phone} {
    @include font-size(20);
    line-height: calc(25/20);
  }

  br {
    @media #{$phone} {
      display: none;
    }
  }
}

.info-block__card-description {
  @include font-size(18);
  line-height: calc(26/18);
  letter-spacing: -.3px;
  color: rgba($black, .8);

  @media #{$phone} {
    @include font-size(16);
    line-height: calc(25/16);
    letter-spacing: -.26px;
    color: $black;
  }
}
