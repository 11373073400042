.mila-filters {
  padding: 0 20px 60px;

  @media #{$phone} {
    padding: 0 8px 30px
  }
}

.mila-filters__card {
  background-color: #F2F2F2;
  border-radius: 16px;
  padding: 90px 0 0;
  overflow: hidden;

  @media #{$phone} {
    padding-top: 70px;
  }

  .container {
    max-width: 1400px;
    padding: 0;
  }
}

.mila-filters__header {
  text-align: center;
  max-width: 884px;
  width: 100%;
  margin: 0 auto 44px;
  padding: 0 20px;

  @media #{$phone} {
    margin-bottom: 36px;
  }
}

.mila-filters__title {
  @include font-size(36);
  line-height: 1.2;
  letter-spacing: -0.3px;
  margin-bottom: 34px;

  @media #{$desktop} {
    @include font-size(24);
  }

  @media #{$phone} {
    @include font-size(20);
    margin-bottom: 16px;
  }
}

.mila-filters__description {
  @include font-size(22);
  line-height: 1.3;
  letter-spacing: -0.39px;

  @media #{$desktop} {
    @include font-size(20);
    letter-spacing: -0.4px;
  }

  @media #{$phone} {
    max-width: 326px;
    @include font-size(16);
    margin: 0 auto;
  }
}

.mila-filters__image {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    @media #{$phone} {
      min-width: 635px;
    }
  }
}
