.hero--fancy-heading {
  padding-top: 50px;
  padding-bottom: 113px;
  background: $light-gray;

  @media #{$tablet} {
    padding-top: 100px;
    padding-bottom: 70px;
  }

  @media #{$phone} {
    padding-top: 70px;
    padding-bottom: 50px;
    margin-bottom: 0;
  }

  .hero__description {
    color: #1D1D1B;
    @include font-size(34);
    font-family: $alt-family;
    max-width: 477px;
    margin: 0 auto;
    text-align: center;
    line-height: 1;

    @media #{$tablet} {
      @include font-size(28);
    }

    @media #{$phone} {
      @include font-size(24);
    }
  }

  .hero__title--fancy {
    max-width: 1100px;
    line-height: 1;
    text-align: center;
    @include font-size(60);
    margin-bottom: 20px;

    @media #{$tablet} {
      @include font-size(40);
    }

    @media #{$phone} {
      @include font-size(32);
    }

    .primary-title {
      display: block;
      margin: 52px auto 40px;
      max-width: 950px;

      @media #{$tablet} {
        width: 70%;
        margin: 32px auto 20px;
      }

      @media #{$tablet} {
        width: 80%;
      }

      img {
        max-width: 950px;
        width: 100%;
      }
    }

    .secondary-title {
      display: block;
      line-height: 1.1;
      max-width: 650px;
      margin: 0 auto;

      img {
        max-width: 74px;

        @media #{$tablet} {
          max-width: 40px;
        }

        @media #{$phone} {
          max-width: 32px;
        }
      }
    }
  }
}


.media-block {
  padding-top: 210px;
  overflow: hidden;

  @media #{$tablet} {
    padding-top: 100px;
  }

  @media #{$phone} {
    padding-top: 60px;
  }

  .container {
    max-width: 1240px;
  }
}

.media-block__item {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 300px;
  align-items: flex-start;

  @media #{$large-screen} {
    margin-bottom: 150px;
  }

  @media #{$tablet} {
    margin-bottom: 70px;
  }

  @media #{$phone} {
    margin-bottom: 30px;
  }
}

.media-block--reverse {
  flex-direction: row-reverse;
}

.media-block__content {
  max-width: 660px;
  width: 55%;
  @include font-size(32);

  @media #{$large-screen} {
    @include font-size(24);
  }

  @media #{$tablet} {
    @include font-size(20);
    width: 100%;
    max-width: 100%;
  }

  @media #{$phone} {
    @include font-size(18);
  }
}

.media-block__title {
  margin-bottom: 83px;

  @media #{$large-screen} {
    @include font-size(48);
    margin-bottom: 40px;
  }

  @media #{$phone} {
    @include font-size(38);
    margin-bottom: 25px;
  }

  @media #{$phone-portrait} {
    @include font-size(30);
  }

  img {
    max-width: 68px;
    margin-top: -10px;

    @media #{$large-screen} {
      max-width: 48px;
    }

    @media #{$phone} {
      max-width: 38px;
    }

    @media #{$phone-portrait} {
      max-width: 30px;
    }
  }
}

.media-block__subtitle {
  font-family: $primary-family-medium;
  line-height: 1.3;
  margin-bottom: 50px;

  @media #{$tablet} {
    @include font-size(32);
  }

  @media #{$phone} {
    @include font-size(22);
    margin-bottom: 25px;
  }
}

.media-block__image {
  max-width: 550px;
  width: 44.8%;

  @media #{$tablet} {
    width: 100%;
    padding-right: 130px;
    margin-bottom: 30px;
  }

  @media #{$phone} {
    padding-right: 100px;
  }

  @media #{$phone-portrait} {
    padding-right: 60px;
  }
}

.media-block__description {
  margin-bottom: 20px;
  color: rgba($black, 0.8);

  p {
    margin-bottom: 45px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  img {
    max-width: 32px;
  }
}

.media-block--style1 {
  .media-block__content {
    padding-top: 104px;

    @media #{$large-screen} {
      max-width: 490px;
    }

    @media #{$tablet} {
      padding-top: 0;
      max-width: 100%;
    }
  }

  .media-block__description {
    max-width: 530px;

    @media #{$large-screen} {
      max-width: 400px;
    }

    @media #{$tablet} {
      max-width: 100%;
    }
  }

  .media-block__video {
    max-width: 570px;
    width: 45%;

    @media #{$tablet} {
      width: 100%;
      padding-right: 130px;
      margin-bottom: 30px;
    }

    @media #{$phone} {
      padding-right: 90px;
    }

    @media #{$phone-portrait} {
      padding-right: 50px;
    }

    video {
      max-width: 570px;
      width: 100%;
      height: auto;
    }
  }
}

.media-block--style2 {
  position: relative;
  left: 100px;
  margin-bottom: -110px;

  @media #{$large-screen} {
    margin-bottom: 100px;
  }

  @media #{$desktop} {
    left: 0;
  }

  @media #{$tablet} {
    left: 0;
    margin-bottom: 70px;
  }

  @media #{$phone} {
    margin-bottom: 30px;
  }

  .media-block__image {
    width: 48%;
    position: relative;
    min-height: 1299px;

    @media #{$large-screen} {
      min-height: 700px;
      width: 46%;
    }

    @media #{$tablet} {
      width: 100%;
      margin-bottom: 30px;
      min-height: inherit;
      padding-right: 0;
    }

    img {
      position: absolute;
      right: 100px;
      max-width: initial;
      height: 100%;
      width: auto;
      top: -450px;

      @media #{$large-screen} {
        top: -210px;
      }

      @media #{$tablet} {
        position: static;
        max-width: 100%;
        height: auto;
        width: auto;
      }
    }
  }

  .media-block__title {
    @media #{$large-screen} {
      max-width: 500px;
    }

    @media #{$tablet} {
      max-width: 100%;
    }
  }

  .media-block__content {
    width: 54%;
    padding-top: 105px;

    @media #{$large-screen} {
      max-width: 500px;
    }

    @media #{$tablet} {
      width: 100%;
      padding-top: 0;
      max-width: 100%;
    }
  }

  .media-block__description {
    max-width: 500px;

    @media #{$large-screen} {
      max-width: 390px;
    }

    @media #{$tablet} {
      max-width: 100%;
    }
  }
}

.media-block--style3 {
  margin-bottom: 50px;

  @media #{$tablet} {
    margin-bottom: 0;
  }

  .media-block__image {
    width: 45.5%;
    position: relative;
    min-height: 876px;

    @media #{$large-screen} {
      min-height: 546px;
    }

    @media #{$tablet} {
      width: 100%;
      margin-bottom: 30px;
      min-height: inherit;
      padding-right: 0;
    }

    img {
      position: absolute;
      left: -30px;
      top: 20px;
      max-width: initial;
      height: 100%;
      width: auto;

      @media #{$tablet} {
        position: static;
        max-width: 100%;
        height: auto;
        width: auto;
      }
    }
  }

  .media-block__content {
    width: 54.5%;

    @media #{$large-screen} {
      padding-left: 80px;
      padding-right: 110px;
    }

    @media #{$tablet} {
      width: 100%;
      max-width: 100%;
      @include font-size(22);
      padding: 0;
    }

    @media #{$phone} {
      @include font-size(18);
    }
  }

  .media-block__description {
    max-width: 524px;

    @media #{$tablet} {
      max-width: 100%;
    }
  }
}

.media-block--style4 {
  align-items: center;
  left: 130px;
  position: relative;
  margin-bottom: 0;

  @media #{$desktop} {
    left: 0;
  }

  .media-block__image {
    width: 45.5%;
    position: relative;
    min-height: 870px;

    @media #{$large-screen} {
      min-height: 570px;
    }

    @media #{$tablet} {
      width: 100%;
      margin-bottom: 30px;
      min-height: inherit;
      padding-right: 0;
    }

    img {
      position: absolute;
      right: 0;
      top: 20px;
      max-width: initial;
      height: 100%;
      width: auto;

      @media #{$tablet} {
        position: static;
        max-width: 100%;
        height: auto;
        width: auto;
      }
    }
  }

  .media-block__content {
    width: 54.5%;
    margin-bottom: 50px;

    @media #{$tablet} {
      width: 100%;
      max-width: 100%;
      @include font-size(22);
      margin-top: -100px;
    }

    @media #{$phone} {
      @include font-size(18);
    }
  }

  .media-block__description {
    max-width: 510px;

    @media #{$large-screen} {
      max-width: 390px;
    }

    @media #{$tablet} {
      max-width: 100%;
    }
  }
}

.media-block--style5 {
  align-items: center;

  .media-block__image {
    width: 45.5%;
    position: relative;
    min-height: 1003px;

    @media #{$large-screen} {
      min-height: 800px;
    }

    @media #{$tablet} {
      width: 100%;
      margin-bottom: 30px;
      min-height: inherit;
      padding-right: 0;
    }

    img {
      position: absolute;
      left: -200px;
      top: 20px;
      max-width: initial;
      height: 100%;
      width: auto;

      @media #{$tablet} {
        position: static;
        max-width: 100%;
        height: auto;
        width: auto;
      }
    }
  }

  .media-block__content {
    width: 54.5%;
    margin-bottom: 200px;
    padding-left: 50px;

    @media #{$large-screen} {
      padding-left: 130px;
    }

    @media #{$tablet} {
      padding-left: 0;
      width: 100%;
      max-width: 100%;
      @include font-size(22);
      margin-bottom: 0;
    }

    @media #{$phone} {
      @include font-size(18);
    }
  }

  .media-block__description {
    max-width: 510px;

    @media #{$tablet} {
      max-width: 100%;
    }
  }
}

.media-block--style6 {
  align-items: center;

  .media-block__image {
    position: relative;
    left: -220px;

    @media #{$large-screen} {
      left: -110px;
    }

    @media #{$tablet} {
      left: 0;
      padding-right: 0;
    }

    img {
      max-width: 705px;

      @media #{$tablet} {
        max-width: 100%;
      }
    }
  }

  .media-block__content {
    width: 48%;

    @media #{$large-screen} {
      padding-left: 130px;
    }

    @media #{$tablet} {
      width: 100%;
      padding-left: 0;
    }
  }

  .media-block__description {
    max-width: 467px;

    @media #{$large-screen} {
      max-width: 400px;
    }

    @media #{$tablet} {
      max-width: 100%;
    }
  }
}

.media-block--style7 {
  .media-block__title {
    margin-bottom: 128px;

    @media #{$tablet} {
      margin-bottom: 40px;
    }
  }

  .media-block__content {
    width: 48%;

    @media #{$large-screen} {
      padding-right: 120px;
    }

    @media #{$tablet} {
      width: 100%;
      padding-right: 0;
    }
  }

  .media-block__description {
    max-width: 460px;

    @media #{$large-screen} {
      max-width: 340px;
    }

    @media #{$tablet} {
      max-width: 100%;
    }

    a {
      font-family: $primary-family-bold;
      color: $primary-color;
      text-decoration: none;

      &:hover,
      &:focus {
        color: darken($primary-color, 20%);
      }
    }
  }

  .media-block__image {
    width: 45.5%;
    position: relative;
    min-height: 889px;

    @media #{$large-screen} {
      min-height: 600px;
    }

    @media #{$tablet} {
      width: 100%;
      margin-bottom: 30px;
      min-height: inherit;
      padding-right: 0;
    }

    img {
      position: absolute;
      left: -180px;
      top: 40px;
      max-width: initial;
      height: 100%;
      width: auto;

      @media #{$tablet} {
        position: static;
        max-width: 100%;
        height: auto;
        width: auto;
      }
    }
  }
}

.media-block--style8 {
  position: relative;
  left: 80px;
  align-items: center;

  @media #{$desktop} {
    left: 0;
  }

  .media-block__content {
    width: 52%;

    @media #{$tablet} {
      width: 100%;
    }
  }

  .media-block__title {
    @media #{$large-screen} {
      max-width: 480px;
    }

    @media #{$tablet} {
      max-width: 100%;
    }
  }

  .media-block__description {
    max-width: 517px;

    @media #{$large-screen} {
      max-width: 387px;
    }

    @media #{$tablet} {
      max-width: 100%;
    }

    strong {
      color: $primary-color;
    }
  }

  .media-block__image {
    width: 45.5%;
    position: relative;
    min-height: 1108px;

    @media #{$large-screen} {
      min-height: 808px;
    }

    @media #{$desktop} {
      min-height: 600px;
    }

    @media #{$tablet} {
      width: 100%;
      margin-bottom: 30px;
      min-height: inherit;
      padding-right: 0;
    }

    img {
      position: absolute;
      right: 0;
      top: 20px;
      max-width: initial;
      height: 100%;
      width: auto;

      @media #{$desktop} {
        right: 60px;
      }

      @media #{$tablet} {
        position: static;
        max-width: 100%;
        height: auto;
        width: auto;
      }
    }
  }
}

.media-block--style9 {
  .media-block__content {
    padding-top: 160px;
    max-width: 530px;

    @media #{$large-screen} {
      max-width: 390px;
      left: -40px;
      position: relative;
    }

    @media #{$tablet} {
      max-width: 100%;
      padding-top: 0;
      left: 0;
    }
  }

  .media-block__description {
    max-width: 460px;

    @media #{$tablet} {
      max-width: 100%;
    }
  }

  .media-block__image {
    width: 47.6%;
    max-width: 100%;

    @media #{$large-screen} {
      width: 45.6%;
    }

    @media #{$tablet} {
      width: 100%;
      max-width: 550px;
    }
  }
}

.media-block--style10 {
  .media-block__content {
    width: 51%;
    padding-top: 60px;
    padding-left: 70px;

    @media #{$large-screen} {
      padding-right: 160px;
    }

    @media #{$tablet} {
      width: 100%;
      padding: 0;
    }
  }

  .media-block__description {
    max-width: 517px;

    @media #{$tablet} {
      max-width: 100%;
    }

    strong {
      color: $primary-color;
    }
  }

  .media-block__image {
    width: 45.5%;
    position: relative;
    min-height: 656px;

    @media #{$large-screen} {
      min-height: 440px;
    }

    @media #{$tablet} {
      width: 100%;
      margin-bottom: 30px;
      min-height: inherit;
      padding-right: 0;
    }

    img {
      position: absolute;
      right: 0;
      top: 0;
      max-width: initial;
      height: 100%;
      width: auto;

      @media #{$tablet} {
        position: static;
        max-width: 100%;
        height: auto;
        width: auto;
      }
    }
  }
}

.order-block--alt {
  padding-bottom: 180px;

  @media #{$desktop} {
    padding-bottom: 100px;
  }

  @media #{$tablet} {
    padding-bottom: 60px;
  }

  .container {
    @media #{$tablet} {
      padding-bottom: 0;
    }
  }

  .order-block__inner {
    padding-bottom: 0;

    &:before,
    &:after {
      display: none;
    }
  }

  .order-block__title {
    margin-bottom: 88px;

    @media #{$tablet} {
      margin-bottom: 60px;
    }

    @media #{$phone} {
      margin-bottom: 30px;
      @include font-size(32);
    }
  }

  .order-block__card-price {
    margin-bottom: 10px;
  }

  .order-block__image {
    margin-bottom: -100px;

    @media #{$tablet} {
      margin-bottom: 0;
    }
  }
}

.order-block__description--large {
  max-width: 460px;

  @media #{$large-screen} {
    max-width: 280px;
  }

  p {
    margin-bottom: 42px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  img {
    max-width: 22px;
  }
}
