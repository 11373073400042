.error404{
  padding: 123px 0 300px;
  text-align: center;
  background-color: $light-gray;
  @media #{$tablet} {
    padding:73px 0 160px;
  }
  @media #{$phone} {
    padding: 40px 0 80px;
  }
}
.error404__content{
  @include font-size(32);
  max-width: 1000px;
  margin: 0 auto;
  @media #{$tablet} {
    @include font-size(24);
  }
  @media #{$phone} {
    @include font-size(16);
  }
}
.error404__title{
  margin-bottom: 25px;
  @media #{$phone} {
    @include font-size(28);
  }
}
.error404__image{
  margin-top: 50px;
}
