.content-slider {
  padding: 80px 0 120px;

  @media #{$small-screen} {
    padding: 60px 0 80px;
  }

  @media #{$phone} {
    padding: 40px 0 70px;
  }
}

.content-slider__header {
  text-align: center;
  margin-bottom: 48px;

  @media #{$small-screen} {
    margin-bottom: 32px;
  }
}

.content-slider__heading {
  @include font-size(48);
  letter-spacing: -0.31px;
  font-family: $primary-family-bold;
  line-height: calc(58/48);
  margin-bottom: 48px;


  @media #{$small-screen} {
    @include font-size(36);
    margin-bottom: 40px;
  }

  @media #{$phone} {
    @include font-size(28);
    margin-bottom: 32px;
    max-width: 350px;
    width: 100%;
    margin: 0 auto 16px;
  }

  >span {
    background: linear-gradient(93deg, #F42D32 34.55%, #FF9C54 40.66%, #FF9A9A 55.32%, #8852F6 75.29%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: block;
    text-transform: uppercase;

    .lazy-load-image-background.opacity.lazy-load-image-loaded {
      vertical-align: text-bottom;
    }

    @media #{$phone} {
      background: none;
      display: inline;
      background-clip: unset;
      -webkit-background-clip: unset;
      -webkit-text-fill-color: unset;
      text-transform: none;
      color: var(--primary-color);
      display: block;
    }

    img {
      height: 48px;
      width: 48px;
      vertical-align: middle;

      @media #{$small-screen} {
        height: 36px;
        width: 36px;
      }

      @media #{$phone} {
        height: 32px;
        width: 32px;
      }
    }
  }
}

.content-slider__heading--lg {
  @media #{$phone} {
    display: none;
  }
}

.content-slider__heading--sm {
  display: none;

  @media #{$phone} {
    display: block;
  }
}

.content-slider__header-description {
  max-width: 742px;
  width: 100%;
  margin: 0 auto;
  @include font-size(20);
  line-height: 1.5;

  @media #{$small-screen} {
    @include font-size(18);
  }

  @media #{$small-screen} {
    @include font-size(16);
  }
}

.content-slider__grid {
  margin: 0 -15px;

  @media #{$phone} {
    margin: 0 -5px;
  }

  .slick-list {
    overflow: visible;

    .slick-track {
      display: flex;

      .slick-slide {
        height: auto;
        display: flex;

        >div {
          display: flex;
        }
      }

      .content-slider__item {
        display: flex;
      }
    }
  }

  .slick-arrow {
    height: 40px;
    width: 40px;
    font-size: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    z-index: 1;
    top: calc(100% + 16px);
    transition: all 300ms ease;

    &.slick-disabled {
      opacity: .5;
    }

    &:hover,
    &:focus {
      transform: scale(1.1);
    }
  }

  .slick-next {
    background-image: url("data:image/svg+xml,%3Csvg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='2' y='2' width='40' height='40' rx='20' stroke='black' stroke-opacity='0.4' stroke-width='3'/%3E%3Cpath d='M18.7109 14L24.7109 22L18.7109 30' stroke='black' stroke-opacity='0.4' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    left: 79px;

    @media #{$small-screen} {
      left: 69px;
    }
  }

  .slick-prev {
    background-image: url("data:image/svg+xml,%3Csvg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='2' y='2' width='40' height='40' rx='20' stroke='black' stroke-opacity='0.4' stroke-width='3'/%3E%3Cpath d='M24.7109 14L18.7109 22L24.7109 30' stroke='black' stroke-opacity='0.4' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    left: 15px;
  }

  .slick-dots {
    position: absolute;
    top: calc(100% + 13px);
    display: flex;
    justify-content: center;
    gap: 5px;
    left: 0;
    right: 0;

    li {
      margin: 0;

      &.slick-active {
        button {
          border-color: var(--primary-color);

          &::before {
            background-color: var(--primary-color);
            animation: move-left-to-right 5.8s linear infinite;
          }

          &:hover,
          &:focus {
            border-color: var(--primary-color);
          }
        }
      }

      button {
        border: 2px solid var(--primary-color);

        &:hover,
        &:focus {
          border-color: var(--primary-color);
        }
      }
    }
  }
}

.content-slider__item {
  padding: 0 15px;

  @media #{$phone} {
    padding: 0 5px;
  }
}

.content-slider__card {
  width: 520px;
  border-radius: 15px;
  background-color: $white;
  box-shadow: 0 14px 84px rgba(0, 0, 0, 0.08);
  height: 100%;

  @media screen and (max-width: 1600px) {
    width: 420px;
  }

  @media #{$small-screen} {
    width: 350px;
  }
}

.content-slider__body {
  padding: 20px 40px 40px;

  @media screen and (max-width: 1600px) {
    padding: 20px 32px 32px;
  }
}

.content-slider__image {
  img {
    border-radius: 15px 15px 0 0;
  }
}

.content-slider__title {
  @include font-size(24);
  font-family: $primary-family-semibold;
  letter-spacing: -0.3px;
  margin-bottom: 16px;

  @media #{$small-screen} {
    @include font-size(20);
    margin-bottom: 12px;

  }
}

.content-slider__description {
  @include font-size(18);
  line-height: 1.5;
  letter-spacing: -0.3px;

  @media #{$small-screen} {
    @include font-size(16);
  }
}
