.product-reviews {
  background-color: var(--secondary-color);
  padding: 80px 0;
  overflow: hidden;
  position: relative;
  z-index: 1;

  @media #{$tablet} {
    padding-bottom: 100px;
  }

  @media #{$phone} {
    padding-top: 40px;
  }

  .container {
    position: static;
  }
}

.product-reviews__title {
  @include font-size(48);
  letter-spacing: -0.31px;
  font-family: $primary-family-bold;
  line-height: calc(58/48);
  margin-bottom: 80px;
  max-width: 541px;
  margin: 0 auto 64px;
  width: 100%;
  text-align: center;

  @media #{$small-screen} {
    margin-bottom: 60px;
  }

  @media #{$small-screen} {
    @include font-size(36);
    margin-bottom: 40px;
  }

  @media #{$phone} {
    @include font-size(28);
    margin-bottom: 48px;
  }

  span {
    color: var(--primary-color);
  }
}

.product-reviews__wrapper {
  position: relative;
  z-index: 1;

  @media #{$tablet} {
    position: static;
  }
}

.product-review {
  border-radius: 15px;
  background-color: $white;
  box-shadow: 0 14px 84px rgba(0, 0, 0, 0.08);
  padding: 40px;

  @media #{$small-screen} {
    padding: 32px;
  }
}

.product-reviews__grid {
  margin: 0 -15px;

  @media #{$tablet} {
    margin: 0;
  }
}

.product-reviews__grid--masonry {
  @media #{$tablet} {
    display: none;
  }
}

.product-reviews__grid--slider {
  display: none;
  width: 100%;

  @media #{$tablet} {
    display: block;
  }

  .slick-list {
    overflow: visible;
  }

  .slick-track {
    display: flex;

  }
}

.product-reviews__item {
  width: 33.33%;
  padding: 0 15px;
  margin-bottom: 30px;

  @media #{$tablet} {
    width: 100%;
    display: flex;
    padding: 0 10px;
    margin-bottom: 0;
  }

  @media #{$phone} {
    padding: 0 5px;
  }
}

.product-review {
  @media #{$tablet} {
    max-width: 350px;
    width: 100%;
  }
}

.product-review__header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
}

.product-review__profile-image {
  height: 60px;
  width: 60px;
}

.product-review__header-content {
  padding-left: 8px;
}

.product-review__name {
  @include font-size(20);
  font-family: $primary-family-semibold;
  line-height: 1;
  margin-bottom: 4px;
}

.product-review__designation {
  display: block;
  @include font-size(16);
  font-family: $primary-family-medium;
  color: #8E8E8E;
}

.product-review__body {
  @include font-size(24);
  line-height: 1.5;

  @media #{$small-screen} {
    @include font-size(20);
  }

  @media #{$tablet} {
    @include font-size(18);
  }

  @media #{$phone} {
    @include font-size(16);
  }
}

.product-review__image {
  margin-top: 44px;


  img {
    border-radius: 12px;
    aspect-ratio: 330 / 440;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }
}


.product-reviews__balloon {
  position: absolute;
  z-index: -1;
  user-select: none;
  pointer-events: none;
}

.product-reviews__balloon--lg {
  transform: rotate(11.9deg);
  right: -131px;
  top: -239px;

  @media #{$tablet} {
    max-width: 127px;
    right: -41px;
    top: -56px;
  }
}

.product-reviews__balloon--md {
  transform: rotate(-15deg);
  top: -306px;
  left: -110px;

  @media #{$tablet} {
    max-width: 87px;
    left: -23px;
    top: 96px;
  }
}

.product-reviews__balloon--sm {
  transform: translateY(-50%) rotate(27.67deg);
  right: calc(100% + 37px);
  top: calc(50% - 30px);

  @media #{$tablet} {
    max-width: 89px;
    right: -20px;
    bottom: 100px;
    top: auto;
    transform: rotate(27.67deg);
  }
}
