.footer-refresh {
  background-color: $black;
  position: relative;
  z-index: 1;

  .container {
    max-width: 1260px;
  }
}

.footer-nav {
  padding: 74px 0 64px;
  display: flex;
  justify-content: space-between;
  gap: 30px;

  @media #{$phone} {
    flex-wrap: wrap;
    gap: 0;
    padding: 48px 0 40px;
  }
}

.footer-nav__grid {
  padding-top: 82px;

  max-width: 960px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;

  @media #{$desktop} {
    max-width: 810px;
    gap: 16px
  }

  @media #{$tablet} {
    gap: 30px 0;
    max-width: 610px;
  }

  @media #{$phone} {
    padding: 40px 0 0;
    border-top: 1px solid rgba($white, .4);
    margin: 32px 0 0;
    gap: 40px 6px;
  }
}

.footer-nav__column {
  flex: 1;

  @media #{$desktop} {
    flex: auto;
  }

  @media #{$tablet} {
    flex: 0 0 33.33%;
  }

  @media #{$phone} {
    flex: 0 0 calc(50% - 3px);
  }

}

.footer-nav__title {
  color: $white;
  @include font-size(18);
  line-height: 1;
  font-family: $primary-family-bold;
  letter-spacing: -0.3px;
  margin-bottom: 32px;

  @media #{$tablet} {
    margin-bottom: 24px;
  }
}

.footer-nav__menu {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    line-height: 1;

    &:not(:last-child) {
      margin-bottom: 16px;

      @media #{$tablet} {
        margin-bottom: 12px;
      }
    }

    a {
      color: rgba($white, .7);
      text-decoration: none;
      @include font-size(16);
      font-family: $primary-family-medium;
      transition: all 0.3s ease;
      letter-spacing: -0.3px;

      &:hover,
      &:focus {
        color: $brand-color--tertiary;
      }
    }
  }
}

.footer-refresh__secondary {
  border-top: 1px solid rgba($white, .4);
  padding: 64px 0;
  display: flex;
  justify-content: space-between;
  gap: 30px;
  align-items: flex-end;

  @media #{$tablet} {
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
  }

  @media #{$phone} {
    gap: 48px;
    padding: 36px 0 48px;
  }
}

.footer-refresh__newsletter {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  flex: 1 1 auto;
  gap: 16px;

  @media #{$tablet} {
    justify-content: center;
    max-width: 640px;
  }

  @media #{$phone} {
    max-width: 342px;
    margin: 0 auto;
    width: 100%;
    gap: 26px;
  }
}

.footer-refresh__logo {
  @media #{$phone} {
    max-width: 115px;
  }
}

.footer-refresh__newsletter-info {
  @include font-size(18);
  line-height: calc(23/18);
  margin-bottom: 24px;

  @media #{$phone} {
    max-width: 254px;
    margin: 0 auto 24px;
    @include font-size(16);
  }

  strong {
    color: $white;
    line-height: 1;
    font-family: $primary-family-semibold;
    letter-spacing: -0.36px;
    display: block;
    margin-bottom: 12px;
  }

  span {
    font-family: $primary-family;
    display: block;
    color: #90989B;
  }
}

.footer-refresh__newsletter-inner {
  max-width: 477px;
  width: 100%;

  @media #{$desktop} {
    max-width: 410px;
  }
}

.footer-refresh__newsletter-form {
  width: 100%;
  position: relative;
  z-index: 1;

  .mailchim-subscribe-form {
    position: relative;
    z-index: 1;
  }

  .subscribe-block__field-input {
    height: 60px;
    box-shadow: 0 15px 10px rgba($black, .1257);
    border: 1px solid rgba($white, .4);
    border-radius: 30px;
    color: $white !important;
    width: 100%;
    padding: 0 181px 0 20px;
    @include font-size(18);

    @media #{$tablet} {
      padding: 0 140px 0 20px;
    }

    &::placeholder {
      color: rgba($white, .4);
    }
  }

  .subscribe-block__submit-button {
    background-color: rgba($white, .9);
    text-transform: capitalize;
    border: 1px solid $white;
    padding: 14px 44px;
    color: $black;
    @include font-size(16);
    font-family: $primary-family-semibold;
    min-width: auto;
    position: absolute;
    right: 6px;
    top: 6px;
    z-index: 1;

    &:disabled {
      opacity: 0.35;
      cursor: progress;
    }

    @media #{$desktop} {
      padding: 15px 24px;
    }

    @media #{$phone} {
      padding: 15px 18px;
    }

    .icon-line-arrow-thick {
      display: none;
    }

    &:hover,
    &:focus {
      transform: scale(1);
      background-color: $brand-color--tertiary;
    }
  }

  .footer-refresh__newsletter-message {
    color: $brand-color--primary;
    font-family: $primary-family-medium;
    @include font-size(16);
    letter-spacing: -0.3px;
    font-weight: normal;
    position: absolute;
    left: 90px;
    top: calc(100% + 16px);

    @media #{$phone} {
      position: static;
      margin: 8px 0;
    }

    &.succeeded {
      color: $brand-color--tertiary;
    }

    &.failed {
      color: $brand-color--primary;
    }
  }
}

.footer-refresh__social-links {
  display: flex;
  gap: 16px;
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    line-height: 1;

    a {
      display: inline-flex;
      align-items: center;
      gap: 12px;
      @include font-size(16);
      font-family: $primary-family-semibold;
      letter-spacing: -0.3px;
      text-decoration: none;
      padding: 17px;
      color: $black;
      background-color: rgba($white, .9);
      border: 1px solid $white;
      border-radius: 30px;
      transition: all 0.3s ease;
      aspect-ratio: 1/1;

      @media #{$phone} {
        padding: 0;
        background-color: transparent;
        gap: 0;
        color: $white;
      }

      &:hover,
      &:focus {
        background-color: $brand-color--tertiary;

        @media #{$phone} {
          background-color: transparent;
          color: $brand-color--tertiary;
        }
      }

      span {
        @extend .screen-reader-text;
      }

      .icon {
        height: 24px;
        width: 24px;
      }
    }
  }
}

.footer-refresh__payment {
  line-height: 1;

  @media #{$tablet} {
    width: 100%;
  }

  span {
    line-height: 1;
    vertical-align: top;
  }

  .copyright {
    color: $white;
    @include font-size(15);
    line-height: normal;
    margin-bottom: 8px;
  }

  img {
    max-width: 154px;
    vertical-align: top;
  }
}

.accessibility-widget {
  height: 36px;
  width: 36px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 1;
  border: 1px solid $white;
  border-radius: 50%;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

  &:hover,
  &:focus {
    transform: scale(1.1);
  }

  .icon {
    height: 34px;
    width: 34px;
    vertical-align: top;
  }
}
